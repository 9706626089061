import React, { useState } from 'react';
import { FaCar } from 'react-icons/fa';
import { RiUserSearchFill } from 'react-icons/ri';
import { Typography, MenuItem, Menu, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { handleCheckboxChange } from 'reducers/PersonSearchReducer';

import {
  handlePersonFilterChange,
  handleVehicleCheckboxChange,
  handleVehicleFilterChange
} from 'reducers/NcicReducer';
import { setSearchActiveTab } from 'reducers/UiReducer';
import { useHistory } from 'react-router';
import { getAccessPermission } from 'reducers/PermissionsReducer';
const useStyles = makeStyles(theme => ({
  contextContainer: {
    backgroundColor: 'red'
  },
  contextItem: {
    cursor: 'pointer',
    padding: '10px',
    paddingRight: '60px'
  }
}));
const ContextMenuComponent = props => {
  const classes = useStyles();
  //   const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const history = useHistory();
  const [contextMenu, setContextMenu] = React.useState(null);
  const persmissions = useSelector(state => state.permissions.globals);
  const [canExecNcic, setCanExecNcic] = useState('');
  React.useEffect(() => {
    setCanExecNcic(getAccessPermission('globals', 'Execute NCIC Queries'));
  }, [persmissions]);
  const handleClick = (e, data) => {
    handleClose();
    const searchValue = window.getSelection().toString();

    if (searchValue) {
      if (data == 'person') {
        dispatch(setSearchActiveTab(1));
        props.setActiveTab && props.setActiveTab(0);

        dispatch(handleCheckboxChange(true));
        dispatch(
          handlePersonFilterChange(searchValue, {
            name: 'OperatorLicenseNumber'
          })
        );
        history.push({
          pathname: '/Search',
          state: { searchType: 'quickPerson' }
        });

        // dispatch(quickPersonSearcher(searchValue));
      } else if (data == 'vehicle') {
        dispatch(setSearchActiveTab(0));
        props.setActiveTab && props.setActiveTab(0);
        dispatch(handleVehicleCheckboxChange(true));
        history.push({
          pathname: '/Search',
          state: { searchType: 'quickVehicle' }
        });
        // dispatch(clearVehicleForm());
        // dispatch(quickVehicleSearcher(searchValue));
        dispatch(
          handleVehicleFilterChange(searchValue, {
            name: 'LicensePlateNumber'
          })
        );
      }
    }
  };

  const handleContextMenu = event => {
    // setSearchValue(window.getSelection().toString());
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };
  return (
    <div onContextMenu={handleContextMenu} className="px-3">
      <Typography> {props.value}</Typography>
      <Menu
        open={contextMenu !== null && canExecNcic}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }>
        <MenuItem
          className={classes.contextItem}
          onClick={e => handleClick(e, 'person')}>
          <RiUserSearchFill color="primary" />
          <span className="pl-2">Person</span>
        </MenuItem>
        <MenuItem
          className={classes.contextItem}
          onClick={e => handleClick(e, 'vehicle')}>
          <FaCar color="primary" />
          <span className="pl-2">Vehicle</span>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ContextMenuComponent;
