import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import formStyles from 'utils/formStyles';
import { IconButton } from '@material-ui/core';
import { AddIcon } from 'evergreen-ui';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const btnStyle = {
  margin: '0 8px 0 2px',
  padding: 0
};

const useStyles = makeStyles(theme => ({
  ...formStyles
}));

function Autocomplete2(props) {
  const classes = useStyles();

  const {
    disabled,
    error,
    value,
    options,
    className,
    label,
    onChange,

    compact,
    inputRef,
    getOptionSelected = (option, value) => {
      if (option?.Code && value?.Code) {
        return option.Code === value.Code;
      }
      return true;
    },
    getOptionLabel = option => (option.Code ? option.Code : ''),
    renderOption = option => (
      <span>
        <strong>{option.Code}</strong>
        {!!option.Description && <> - {option.Description}</>}
      </span>
    )
  } = props;
  const filter = createFilterOptions({
    stringify: option =>
      (option.Code || '') +
      (option?.Description || '') +
      (option?.AgencyID || '')
  });
  const filterOptions =
    options?.length &&
    (options[0].Code || options[0].Description || options[0].AgencyID)
      ? filter
      : undefined;

  const helperText = compact
    ? undefined
    : typeof error === 'string'
    ? error
    : ' ';
  return (
    <Autocomplete
      options={options || []}
      classes={{
        option: classes.option,
        root: classes.autocomplete
      }}
      className={className}
      disabled={disabled}
      autoHighlight
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      filterOptions={filterOptions}
      renderOption={renderOption}
      onChange={onChange}
      value={value}
      renderInput={params => (
        <TextField
          {...params}
          error={!!error}
          helperText={helperText}
          label={label}
          variant="outlined"
          size="small"
          inputRef={inputRef}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password'
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password'
          }}
        />
      )}
    />
  );
}

export default Autocomplete2;
