import { enqueueSnackbar } from '../reducers/NotifierReducer';
import store from '../config/configureStore';
import tableConfig from '../config/table-config-warrants.json';

import { showSpinner, hideSpinner } from './UiReducer';
import { getService } from './service';

export const CLEAR_RESULTS = 'CLEAR_RESULTS';

export const SET_FORM_WARRANTS_PICKERS = 'SET_FORM_WARRANTS_PICKERS';
export const SET_FORM_WARRANTS_FILTERS = 'SET_FORM_WARRANTS_FILTERS';
export const FIND_WARRANTS = 'FIND_WARRANTS';
export const LOADING_WARRANTS = 'LOADING_WARRANTS';
export const LOADING_WARRANTS_ERROR = 'LOADING_WARRANTS_ERROR';
export const OPEN_WARRANTS_DETAILS = 'OPEN_WARRANTS_DETAILS';
export const WARRANTS_DETAILS = 'WARRANTS_DETAILS';
export const SET_SETECTED_SEARCH_HISTORY = 'SET_SETECTED_SEARCH_HISTORY';
export const findWarrants = params => {
  return async dispatch => {
    const state = store.store.getState();
    const client = state.websocket.websocket;

    try {
      const service = client.service('search-warrants');
      service.timeout = 500000;
      dispatch({ type: LOADING_WARRANTS, payload: true });
      const warrants = await service.find({ query: params });
      // zconst warrants = data;

      dispatch({ type: FIND_WARRANTS, payload: { params, warrants } });
      dispatch({ type: LOADING_WARRANTS, payload: false });
    } catch (error) {
      dispatch({ type: LOADING_WARRANTS, payload: false });

      dispatch({ type: LOADING_WARRANTS_ERROR });
    }
  };
};
export const searchPeople = (search = '', ViewDeleted = false) => {
  const service = getService('cad-search-people');
  return service.find({
    query: { search, ViewDeleted, ViewImages: false, IsWarrant: true }
  });
};
/* New ncic implementation */
export const handleDateChange = (date, dbName, param) => {
  const pickerValues = store.store.getState().warrants.warrantsForm.pickers;
  return async dispatch => {
    const value = pickerValues[dbName]
      ? pickerValues[dbName]
      : { from: null, to: null };
    value[param] = date;
    if (param === 'from' && !value.to) value.to = date;
    if (param === 'to' && !value.from) value.from = date;
    if (param === 'single') {
      value.from = date;
      value.to = date;
    }
    const currentPickerValue = { ...pickerValues };
    if (value.from !== null && value.to !== null) {
      currentPickerValue[dbName] = value;
    } else {
      delete currentPickerValue[dbName];
    }
    dispatch({
      type: SET_FORM_WARRANTS_PICKERS,
      payload: currentPickerValue
    });
  };
};

export const handleFilterChange = (ev, filter) => {
  const values = store.store.getState().warrants.warrantsForm.filters;

  return async dispatch => {
    values[filter.dbName] = {
      type: filter.type,
      search: ev.target ? ev.target.value.toUpperCase() : ev.toUpperCase()
    };
    dispatch({
      type: SET_FORM_WARRANTS_FILTERS,
      payload: cleanFilters(values)
    });
  };
};
export const handleWarrantFilltersAutoFill = values => {
  return dispatch => {
    dispatch({
      type: SET_SETECTED_SEARCH_HISTORY,
      payload: JSON.parse(JSON.stringify(values))
    });
    delete values.personInfo;
    delete values.addressInfo;

    if (values['Born']) {
      dispatch({
        type: SET_FORM_WARRANTS_PICKERS,
        payload: { Born: values['Born'] }
      });
      delete values['Born'];
    } else {
      dispatch({
        type: SET_FORM_WARRANTS_PICKERS,
        payload: {}
      });
    }
    if (values['City']) {
      values['City'] = {
        type: 'string',
        search: values.cityDescription
      };
    }
    delete values.cityDescription;
    dispatch({
      type: SET_FORM_WARRANTS_FILTERS,
      payload: typeof values === 'object' ? values : {}
    });
  };
};
const cleanFilters = obj => {
  const result = {};
  Object.entries(obj).forEach(([key, val]) => {
    if (val.search !== '') result[key] = val;
  });
  return result;
};

export const clearFilters = () => {
  return async dispatch => {
    dispatch({
      type: SET_FORM_WARRANTS_FILTERS,
      payload: {}
    });
    dispatch({
      type: SET_FORM_WARRANTS_PICKERS,
      payload: {}
    });
  };
};
export const clearResults = () => {
  return async dispatch => {
    dispatch({
      type: CLEAR_RESULTS,
      payload: null
    });
  };
};
export const openShowDetails = params => {
  return async dispatch => {
    dispatch({ type: OPEN_WARRANTS_DETAILS, payload: params });
  };
};
export const closeShowDetails = () => {
  return async dispatch => {
    dispatch({ type: WARRANTS_DETAILS });
  };
};
export const getPersonWarrantCount = async ptsPersonID => {
  const service = getService('ptswarrant');
  const result = await service.find({
    query: { ptsPersonID, IsActive: 1, IsDeleted: 0 }
  });
  return result?.data.length || 0;
};

export const getPersonWarrant = ptsPersonID => {
  const service = getService('cad-search-warrants');
  return service.get(ptsPersonID);
};
export default function reducer(
  state = {
    // Gun
    warrants: {
      rows: [],
      count: 5000,
      pageNo: 0,
      search: '',
      rowsPerPage: tableConfig.rowsPerPage,
      orderBy: tableConfig.orderBy,
      orderDirection: tableConfig.orderDirection,
      loading: false,
      loaded: false,
      error: false
    },
    warrantsForm: {
      filters: {
        ['IsActive']: {
          search: 'ACTIVE',
          type: 'select'
        }
      },
      pickers: {}
    },
    warrantsDetails: {
      isShow: false,
      event: null
    },
    selectedSearchHistory: null
  },
  action
) {
  switch (action.type) {
    // Gun
    case LOADING_WARRANTS:
      return {
        ...state,
        warrants: {
          ...state.warrants,
          loading: action.payload,
          loaded: action.payload ? false : state.warrants.loaded,
          error: false
        }
      };
    case LOADING_WARRANTS_ERROR:
      return {
        ...state,
        warrants: {
          ...state.warrants,
          loading: false,
          loaded: true,
          error: true
        }
      };
    case FIND_WARRANTS:
      return {
        ...state,
        warrants: {
          rows: action.payload.warrants.data,
          ...action.payload.params,
          count: action.payload.warrants.count,
          loaded: true,
          error: false
        }
      };
    case OPEN_WARRANTS_DETAILS:
      return {
        ...state,
        warrantsDetails: {
          ...state.warrantsDetails,
          isShow: true,
          event: action.payload
        }
      };
    case WARRANTS_DETAILS:
      return {
        ...state,
        warrantsDetails: {
          ...state.warrantsDetails,
          isShow: false,
          event: null
        }
      };
    case SET_FORM_WARRANTS_PICKERS:
      return {
        ...state,
        warrantsForm: {
          ...state.warrantsForm,
          pickers: action.payload
        }
      };
    case SET_FORM_WARRANTS_FILTERS:
      return {
        ...state,
        warrantsForm: {
          ...state.warrantsForm,
          filters: action.payload
        }
      };
    case CLEAR_RESULTS:
      return {
        ...state,
        warrants: {
          rows: [],
          count: 5000,
          pageNo: 0,
          search: '',
          rowsPerPage: tableConfig.rowsPerPage,
          orderBy: tableConfig.orderBy,
          orderDirection: tableConfig.orderDirection,
          loading: false,
          loaded: false,
          error: false
        }
      };
    case SET_SETECTED_SEARCH_HISTORY:
      return {
        ...state,
        selectedSearchHistory: action.payload
      };
    default:
      break;
  }
  return state;
}
