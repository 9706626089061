/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import moment from 'moment';
import Slider from 'react-slick';
import Grid from '@material-ui/core/Grid';
import { Card, makeStyles, Typography } from '@material-ui/core';

import avatarPlaceholder from 'assets/images/avatars/avatar-526x526.png';
import { ColCard, Fills, gridStyle, Row, RowInner } from 'utils/formStyles';
import FormDivider from 'global-components/CadComponents/FormDivider';
// import RMSPersonCell from 'components/RMSList/RMSEnhancedCells/RMSPersonCell';

const useStyles = makeStyles(theme => ({
  itemWrap: {
    fontWeight: 500,
    fontSize: '13px',
    textAlign: 'center',
    height: '20px'
  },
  itemLabel: {
    display: 'block',
    fontWeight: 400,
    background: '#B6C4D2',
    color: '#0b55a1',
    padding: '5px',
    marginBottom: '3px',
    textTransform: 'uppercase',
    borderRadius: '0.2rem'
  },
  imageItem: {
    display: 'block',
    width: '100%',
    height: '100%'
  },
  imgCon: {
    height: '140px',
    margin: '5px 10px',
    minWidth: '260px'
  },
  sliderStyle: {
    width: '220px !important',
    height: '140px !important',
    textAlign: 'center !important'
  },
  editButton: {
    cursor: 'pointer',
    fontSize: '21px',
    marginLeft: '8px',
    color: '#0153a3'
  },
  listRoot: {
    margin: '0px',
    padding: '0px'
  },
  label: {
    marginRight: '20px'
  },
  item: gridStyle(100, 150),
  field: gridStyle(210, 400),
  actions: {
    textAlign: 'right',
    marginTop: 8,
    '& button': {
      marginLeft: 8,
      '& svg': {
        marginRight: theme.spacing(1)
      }
    }
  }
}));

// Slider Settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const PersonSearchInfoCard = props => {
  /** region Component Variables and Props */
  const { data, wsClient } = props;
  const classes = useStyles();
  const [personId, setPersonId] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [personInfo, setPersonInfo] = useState({});
  const [mugshot, setMugshot] = useState([]);
  /** endregion */

  /** region React Hooks */
  useEffect(() => {
    setPersonInfo({
      ptsPersonID: data?.ptsPersonID || data?.ptsPersonID,
      fullName: data?.fullName || data?.FullName,

      dob: data?.Born ? moment(data?.Born).format('MM/DD/YYYY') : null,
      firstName: data?.firstName || data?.FirstName,
      middleName: data?.middleName || data?.MiddleName,
      lastName: data?.lastName || data?.LastName,
      birthdate: data?.birthdate || data?.Born,
      suffix: data?.suffix || data?.Suffix,
      race: data?.race || data?.Race,
      sex: data?.sex || data?.Sex,
      hairColor: data?.hairColor || data?.HairColor,

      eyeColor: data?.eyeColor || data?.EyeColor,
      height: data?.height || data?.Height,
      weight: data?.weight || data?.Weight,
      age: data?.age || data?.Age,

      ethnicity: data?.ethnicity || data?.Ethnicity
    });
    setPersonId(data?.ptsPersonID);
  }, [data]);

  useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }
    if (personId) getPersonPhotos();
  }, [wsClient, personId]);
  /** endregion */
  /** region Helper functions */
  const getPersonPhotos = async () => {
    const service = wsClient?.websocket.service('record-person-photo');
    service.timeout = 200000;
    setLoaded(false);
    try {
      const result = await service.get(personId);
      if (result) {
        setLoaded(true);
        let parsedLocalMugshots =
          result.Mugshots !== null ? JSON.parse(result.Mugshots) : null;
        if (parsedLocalMugshots) {
          setMugshot(parsedLocalMugshots);
        }
      }
      setLoaded(true);
    } catch (error) {
      setLoaded(true);
      console.log(error);
    }
  };
  /** endregion */
  return (
    <Grid container spacing={2}>
      <ColCard minWidth={280} maxWidth={280}>
        <Card className={classes.imgCon}>
          {loaded ? (
            mugshot.length > 0 ? (
              <Slider {...settings} className={classes.sliderStyle}>
                {mugshot.map(m => (
                  <a href="#/" onClick={e => e.preventDefault()}>
                    <img
                      alt="..."
                      className="card-img-top rounded"
                      src={'data:image/png;base64, ' + m?.FileObject}
                      style={{
                        objectFit: 'contain',
                        width: '200px',
                        height: '160px',
                        margin: 'auto'
                      }}
                    />
                  </a>
                ))}
              </Slider>
            ) : (
              <Slider {...settings} className={classes.sliderStyle}>
                <a
                  href="#/"
                  onClick={e => e.preventDefault()}
                  className="card-img-wrapper ">
                  <img
                    alt="..."
                    className="card-img-top rounded"
                    src={avatarPlaceholder}
                    style={{
                      objectFit: 'contain',
                      width: '200px',
                      height: '160px',
                      margin: 'auto'
                    }}
                  />
                </a>
              </Slider>
            )
          ) : (
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{
                width: '100%',
                height: '100%',
                padding: '10px'
              }}>
              Loading...
            </Typography>
          )}
        </Card>
      </ColCard>

      {personInfo.ptsPersonID ? (
        <ColCard minWidth={280}>
          <Row>
            <RowInner>
              <h5>{`${personInfo.lastName || ''}, ${personInfo.firstName ||
                ''} ${personInfo.middleName || ''}`}</h5>
            </RowInner>
            <RowInner>
              <Typography variant="text" className={classes.item}>
                <b className={classes.label}>Sex</b>

                {personInfo.sex || ''}
              </Typography>

              <Typography variant="text" className={classes.item}>
                <b className={classes.label}>Race</b>

                {personInfo.race || ''}
              </Typography>

              <Typography variant="text" className={classes.item}>
                <b className={classes.label}>DOB</b>

                {personInfo.dob || ''}
              </Typography>

              <Typography variant="text" className={classes.item}>
                <b className={classes.label}>Ethnicity</b>

                {personInfo.ethnicity || ''}
              </Typography>
            </RowInner>
            <RowInner>
              <Typography variant="text" className={classes.item}>
                <b className={classes.label}>Eye Color</b>

                {personInfo.eyeColor || ''}
              </Typography>

              <Typography variant="text" className={classes.item}>
                <b className={classes.label}>Hair Color</b>

                {personInfo.hairColor || ''}
              </Typography>

              <Typography variant="text" className={classes.item}>
                <b className={classes.label}>Height</b>

                {personInfo.height || ''}
              </Typography>

              <Typography variant="text" className={classes.item}>
                <b className={classes.label}>Weight</b>

                {personInfo.weight || ''}
              </Typography>
            </RowInner>
          </Row>
        </ColCard>
      ) : (
        <p>Loading</p>
      )}
    </Grid>
  );
};

PersonSearchInfoCard.propTypes = {
  props: PropTypes
};

const mapStateToProps = state => ({
  wsClient: state.websocket
});

export default connect(mapStateToProps, {})(PersonSearchInfoCard);
