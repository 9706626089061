import React from 'react';
import { connect } from 'react-redux';
import Dialog from '../../Dialog';
import { closeAddPerson } from 'reducers/DialogsReducer';
import PersonTab from './PersonTab';

function AddPersonDialog(props) {
  const { data } = props;
  const close = () => {
    props.closeAddPerson();
  };

  return (
    <Dialog toolbar fullScreen onClose={close} title="Add Person">
      <PersonTab
        close={close}
        ptsAddressID={data !== true ? data.ptsAddressID : null}
      />
    </Dialog>
  );
}

export default connect(null, {
  closeAddPerson
})(AddPersonDialog);
