import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { displayDateTime } from 'reducers/TimeReducer';
import { closeAddressDialog } from 'reducers/DialogsReducer';
import {
  showDisplayDataWindow,
  addBulletin,
  editBulletin
} from 'reducers/DialogsReducer';
import { handleError } from 'reducers/ErrorReducer';
import PrintsSearch from '../../Search/components/PrintsSearch';
import { XGrid } from '@material-ui/x-grid';
import Tooltip from '../../Tooltip';
import Fab from '@material-ui/core/Fab';
import InfoIcon from '@material-ui/icons/Info';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { findBulletinsData, removeAlert } from 'reducers/BulletinReducer';
import { notify } from 'reducers/NotifierReducer';
import EditIcon from '@material-ui/icons/Edit';
const columns = [
  { field: 'AlertType', headerName: 'Alert Type', width: 150 },
  { field: 'AgencyID', headerName: 'AgencyID', width: 150 },

  {
    field: 'Beginning',
    valueFormatter: params => displayDateTime(params.value),
    width: 200
  },
  {
    field: 'Ending',
    valueFormatter: params => displayDateTime(params.value),
    width: 200
  },
  { field: 'Person', headerName: 'Person', width: 150 },
  { field: 'OLN', headerName: 'OLN', width: 150 },
  { field: 'OLNState', headerName: 'OLN State', width: 150 },
  { field: 'Plate', headerName: 'Plate', width: 150 },
  { field: 'PlateState', headerName: 'Plate State', width: 150 },
  { field: 'Address', headerName: 'Address', width: 200 },
  { field: 'Ranges', headerName: 'Ranges', width: 200 },
  { field: 'Notification', headerName: 'Notification', width: 150 },
  { field: 'Priority', headerName: 'Priority', width: 150 }
];

const useStyles = makeStyles(theme => ({
  wrap: {
    padding: theme.spacing(3)
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1)
    }
  }
}));

function Bulletins(props) {
  const { vehicle, dataUpdate, vehicleDetailsData } = props;
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selection, setSelection] = useState(null);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const mountedRef = useRef(true);

  /* get Bulletins for first time */
  useEffect(() => {
    getBulletins();
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
    //eslint-disable-next-line
  }, []);

  /* get bulletins when adding new bulletin */
  useEffect(() => {
    if (!dataUpdate || dataUpdate.type !== 'Bulletin') return;
    getBulletins();
    //eslint-disable-next-line
  }, [dataUpdate]);

  const getBulletins = async () => {
    try {
      const data = await findBulletinsData({
        ptsVehicleID: vehicle.ptsVehicleID
      });
      if (!mountedRef.current) return;
      setData(processData(data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err, 'Error receiving address bulletins data.');
    }
  };

  const processData = data => {
    return data.map(row => ({ ...row, id: row.ptsAlertID }));
  };

  const viewDetails = () => {
    const item = data.find(row => row.ptsAlertID === selection);
    if (!item) return;
    props.showDisplayDataWindow({
      data: filterData(item),
      title: item.AlertType
    });
  };

  const filterData = rawData => {
    const {
      AlertType,
      AgencyID,
      Beginning,
      Ending,
      Person,
      OLN,
      OLNState,
      Plate,
      PlateState,
      Address,
      Ranges,
      Notification,
      Priority
    } = rawData;
    return {
      AlertType,
      AgencyID,
      Beginning,
      Ending,
      Person,
      OLN,
      OLNState,
      Plate,
      PlateState,
      Address,
      Ranges,
      Notification,
      Priority
    };
  };

  const add = () => {
    props.addBulletin({
      type: 'vehicle',
      object: vehicleDetailsData ? vehicleDetailsData[0] : vehicle
    });
  };
  const edit = () => props.editBulletin(selection);

  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete selected bulletin?'))
      return;
    try {
      await removeAlert(selection);
      //search();
      await getBulletins();
      props.notify('Bulletin removed', 'success');
    } catch (err) {
      props.handleError(err, 'Error, Unable to remove bulletin');
    }
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.actions}>
        <PrintsSearch
          title="SOP Records"
          data={data}
          cols={columns}
          hiddenCols={hiddenColumns}
        />
        <Tooltip title="Edit Attachment">
          <Fab size="small" color="secondary" onClick={add}>
            <AddIcon fontSize="small" />
          </Fab>
        </Tooltip>
        {selection !== null && (
          <>
            <Tooltip title="Edit Bulletin">
              <Fab onClick={edit} size="small" color="secondary">
                <EditIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Delete SOP">
              <Fab size="small" color="secondary" onClick={handleDelete}>
                <DeleteIcon />
              </Fab>
            </Tooltip>

            <Tooltip title="View details">
              <Fab size="small" color="secondary" onClick={viewDetails}>
                <InfoIcon />
              </Fab>
            </Tooltip>
          </>
        )}
      </div>

      <div className={classes.gridWrap}>
        <XGrid
          columns={columns}
          rows={data}
          loading={!loaded}
          rowHeight={38}
          disableMultipleSelection={true}
          showToolbar
          disableColumnFilter
          onSelectionModelChange={newSelection => {
            setSelection(newSelection[0]);
          }}
          onColumnVisibilityChange={col =>
            setHiddenColumns([...hiddenColumns, col.field])
          }
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    addAddressSOP: state.dialogs.addAddressSOP,
    dataUpdate: state.dataUpdate
  };
};

export default connect(mapStateToProps, {
  closeAddressDialog,
  handleError,
  showDisplayDataWindow,
  addBulletin,
  notify,
  editBulletin
})(Bulletins);
