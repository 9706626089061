import React, { useState, createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import moment from 'moment';

import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Grid
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {
  sendRequest,
  clearPersonForm,
  handlePersonDateChange,
  handlePersonFilterChange,
  handlePersonCheckboxChange,
  setDefaultEnvFORNCIC
} from '../../../../../reducers/NcicReducer';
import { clearResults } from '../../../../../reducers/PersonSearchReducer';
import { Autocomplete } from '@material-ui/lab';
import { useLocation } from 'react-router';
import saveSearchSuggestions from 'utils/saveSearchSuggestions';
import SearchHistory from 'global-components/SearchHistory';

const useStyles = makeStyles(theme => ({
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    marginBottom: '1em'
  },
  filters: {
    padding: 20,
    marginBottom: 20
  },
  filterAction: {
    textAlign: 'right',
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  filter: {
    margin: '0 10px 10px',
    width: '200px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  datePicker: {
    marginTop: 10,
    width: 200,
    margin: '16px 10px 10px',
    paddingTop: 6,
    '& input::-webkit-input-placeholder': {
      fontSize: 14,
      opacity: 0.6
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  select: {
    margin: '0 10px 10px',
    width: '200px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  selectInput: {
    fontSize: '16px'
  },
  iconButton: {
    padding: 10
  },
  reset: {
    marginRight: 20
  },
  searchBtnWrap: {
    display: 'inline-block',
    position: 'relative',
    paddingRight: '1em'
  },
  buttonProgress: {
    color: '#B00927',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const SearchFormNcic = props => {
  const classes = useStyles();

  const [filters, setFilters] = useState(props.formdefinition.fields);
  const [validationRules, setValidationRules] = useState(
    props.formdefinition.combinations
  );
  const filterRefs = {};

  const {
    wsClient,
    appInfo,

    sendRequest,
    clearPersonForm,
    clearResults,
    handlePersonDateChange,
    handlePersonFilterChange,
    handlePersonCheckboxChange,
    setDefaultEnvFORNCIC,
    pickerValues,
    filterValues,
    name,
    ncicCheckbox,
    rid,
    ori
  } = props;
  const location = useLocation();
  const { searchType } = location?.state || {};
  const quickSearchValue = filterValues['OperatorLicenseNumber']?.value || '';
  const codes = {
    State: props.statesCodes,
    SexCode: props.sexCodes,
    ReasonCode: [
      { Code: 'C', Description: 'C' },
      { Code: 'D', Description: 'D' },
      { Code: 'F', Description: 'F' },
      { Code: 'N', Description: 'N' }
    ]
  };

  const [isSubmited, setIsSubmited] = useState(false);
  Object.values(filters).forEach(({ name, components }) => {
    // eslint-disable-next-line
    if (JSON.stringify(components) !== '{}') {
      Object.values(components).map((f, i) => {
        filterRefs[f.name] = createRef();
      });
    } else {
      filterRefs[name] = createRef();
    }
  });

  React.useEffect(() => {
    if (wsClient.websocket && appInfo.appEnvInfo) {
      setDefaultEnvFORNCIC(appInfo.appEnvInfo);
    }
  }, [wsClient, appInfo]);

  const buildMessage = rules => {
    let message = '';
    for (let x = 0; x < rules.length; x++) {
      const _fields = Object.entries(rules[x][1]['required']);
      for (let y = 0; y < _fields.length; y++) {
        if (y === _fields.length - 1) {
          message += `"${_fields[y][0]}"`;
        } else {
          message += `"${_fields[y][0]}" and `;
        }
      }
      if (x !== rules.length - 1) {
        message += ' or ';
      } else if (x === rules.length - 1) {
        message += ' are required fields';
      }
    }
    return message;
  };

  const checkValidation = data => {
    const values = { ...data };
    delete values['user'];
    delete values['queryType'];
    const _values = Object.entries(values);
    const _rules = Object.entries(validationRules);
    let isValid = null;
    const message = buildMessage(_rules);
    if (_values.length === 0) return message;

    for (let x = 0; x < _rules.length; x++) {
      if (isValid === true) break;
      const _fields = Object.entries(_rules[x][1]['required']);
      for (let y = 0; y < _fields.length; y++) {
        if (values[_fields[y][0]]) {
          isValid = true;
        } else {
          if (_fields[y][0] === 'Name') {
            if (values['Last']) {
              isValid = true;
            } else {
              isValid = message;
              break;
            }
          } else {
            isValid = message;
            break;
          }
        }
      }
    }
    return isValid;
  };

  const submitForm = e => {
    e.preventDefault();
    let newFilterValues = {};
    const queryDefinition = {
      filterValues: filterValues,
      pickerValues: pickerValues
    };
    newFilterValues.query = { ...filterValues };
    Object.entries(pickerValues).forEach(([key, val]) => {
      newFilterValues.query[key] = {
        value: moment(val.from).format('MMDDYYYY'),
        from: moment(val.from).format('YYYY-MM-DD') + ' 00:00:00.0',
        to: moment(val.to).format('YYYY-MM-DD') + ' 23:59:59.0',
        name: key,
        ncic: true
      };
    });

    saveSearchSuggestions('personNCIC', newFilterValues, true);

    newFilterValues['queryType'] = props.formdefinition.name;
    newFilterValues['user'] = props.user;
    newFilterValues['queryDefinition'] = queryDefinition;
    const validCheck = checkValidation(newFilterValues.query);
    if (validCheck === true) {
      sendRequest(newFilterValues);
      clearForm();
    } else {
      alert(validCheck);
    }
    setIsSubmited(!isSubmited);
  };

  const getTextFilter = (filter, idx, type) => {
    switch (type) {
      case 'Name':
        return (
          <TextField
            type="search"
            size="small"
            variant="outlined"
            label={`${filter.name} ${type}`}
            key={idx}
            className={classes.filter}
            value={
              filterValues[filter.name] ? filterValues[filter.name].value : ''
            }
            onChange={e => handlePersonFilterChange(e, filter)}
            inputRef={filterRefs[filter.name]}
          />
        );
      default:
        return (
          <TextField
            type="search"
            size="small"
            variant="outlined"
            autoFocus={
              filter.name === 'OperatorLicenseNumber' &&
              (searchType !== 'quickPerson' || !quickSearchValue)
                ? true
                : false
            }
            label={filter.description || filter.name}
            key={idx}
            className={classes.filter}
            value={
              filterValues[filter.name] ? filterValues[filter.name].value : ''
            }
            onChange={e => handlePersonFilterChange(e, filter)}
            inputRef={filterRefs[filter.name]}
          />
        );
    }
  };

  const getDateSingleFilter = (filter, idx) => {
    const value = pickerValues[filter.name]
      ? pickerValues[filter.name]
      : { from: null, to: null };
    return (
      <FormControl key={idx}>
        <KeyboardDatePicker
          style={{ marginTop: 0, paddingTop: 0 }}
          key={idx}
          variant="inline"
          inputVariant="outlined"
          size="small"
          className={classes.datePicker}
          value={value.from}
          placeholder={filter.description}
          onChange={date => handlePersonDateChange(date, filter.name, 'single')}
          format="MM/DD/yyyy"
        />
      </FormControl>
    );
  };

  const getSelectFilter = (filter, idx) => (
    <Autocomplete
      variant="outlined"
      size="small"
      className={classes.select}
      key={idx}
      autoHighlight
      autoSelect
      getOptionSelected={() => true}
      value={{
        Code: filterValues[filter.name] ? filterValues[filter.name].value : ''
      }}
      onChange={(event, newValue) => {
        handlePersonFilterChange(newValue?.Code || '', filter);
      }}
      options={[{ Code: '', Description: '' }, ...codes[filter.name]]}
      getOptionLabel={option => option.Code || ''}
      renderOption={option =>
        filter.name == 'State' ? (
          <>
            <b>{option.Code || ''} </b> - {option.Description || ''}
          </>
        ) : (
          option.Code || '-'
        )
      }
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          autoFocus={
            filter.name === 'State' &&
            searchType === 'quickPerson' &&
            quickSearchValue
              ? true
              : false
          }
          label={filter.description}
        />
      )}
    />
    // <FormControl
    //   variant="outlined"
    //   size="small"
    //   className={classes.select}
    //   key={idx}>
    //   <InputLabel id={`filter-${filter.name}`}>{filter.description}</InputLabel>
    //   <Select
    //     className={classes.selectInput}
    //     labelId={`filter-${filter.name}`}
    //     value={filterValues[filter.name] ? filterValues[filter.name].value : ''}
    //     onChange={e => handlePersonFilterChange(e, filter)}>
    //     <MenuItem value=""> </MenuItem>
    //     {codes[filter.name].map((code, idx) => (
    //       <MenuItem value={code.Code} key={idx}>
    //         {code.Code}
    //       </MenuItem>
    //     ))}
    //   </Select>
    // </FormControl>
  );

  const clearForm = () => {
    Object.values(filterRefs).forEach(({ current }) => {
      if (current) current.value = '';
    });
    clearPersonForm();
  };

  return (
    <div className={classes.searchWrap}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <form onSubmit={submitForm}>
          <Grid container spacing={2}>
            {Object.values(filters).map((filter, idx) => {
              switch (filter.type) {
                case 'Date':
                  return getDateSingleFilter(filter, idx);
                case 'Alphanumeric':
                  if (filter.name === 'BirthDate')
                    return getDateSingleFilter(filter, idx);
                  if (filter.name === 'ImageIndicator') break;
                  if (
                    filter.name === 'State' ||
                    filter.name === 'SexCode' ||
                    filter.name === 'ReasonCode'
                  )
                    return getSelectFilter(filter, idx);
                  if (JSON.stringify(filter.components) !== '{}') {
                    return (
                      <div style={{ display: 'flex' }} key={idx}>
                        {Object.values(filter.components).map((f, i) => {
                          return getTextFilter(f, i, filter.description);
                        })}
                      </div>
                    );
                  } else {
                    return getTextFilter(filter, idx, filter.description);
                  }
                case 'Alphabetic':
                  if (filter.name === 'ImageIndicator') break;
                  if (
                    filter.description === 'State' ||
                    filter.description === 'Sex'
                  )
                    return getSelectFilter(filter, idx);
                  if (JSON.stringify(filter.components) !== '{}') {
                    return (
                      <div style={{ display: 'flex' }} key={idx}>
                        {Object.values(filter.components).map((f, i) => {
                          return getTextFilter(f, i, filter.description);
                        })}
                      </div>
                    );
                  } else {
                    return getTextFilter(filter, idx, filter.description);
                  }
                default:
                  return getTextFilter(filter, idx);
              }
            })}
          </Grid>
          <div className={classes.filterAction}>
            <SearchHistory loading={isSubmited} name="personNCIC" />
            <FormControlLabel
              style={{ verticalAlign: 'top', fontSize: 22 }}
              control={
                <Checkbox
                  disabled={ori === ''}
                  checked={ncicCheckbox}
                  onChange={e => handlePersonCheckboxChange(e)}
                  name="ncic"
                  color="primary"
                  style={{ verticalAlign: 'top', fontSize: 22 }}
                />
              }
              label="NCIC/State"
            />
            <Button
              size="large"
              startIcon={<DeleteOutlineIcon />}
              onClick={() => clearResults()}
              className={classes.reset}>
              Clear Results
            </Button>
            <Button
              size="large"
              startIcon={<DeleteOutlineIcon />}
              onClick={clearForm}
              className={classes.reset}>
              Clear Form
            </Button>
            <div className={classes.searchBtnWrap}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<SearchIcon />}
                type="submit">
                Search
              </Button>
            </div>
          </div>
        </form>
      </MuiPickersUtilsProvider>
    </div>
  );
};

const mapStateToProps = state => ({
  wsClient: state.websocket,
  appInfo: state.appInfo,
  user: state.user,
  themeMode: state.theme.mode,
  statesCodes: state.codes.dictionary.statesCodes || [],
  sexCodes: state.codes.dictionary.sexCodes || [],
  pickerValues: state.ncic.personForm.pickers,
  filterValues: state.ncic.personForm.filters,
  ncicCheckbox: state.ncic.personForm.ncic,
  rid: state.ncic.rid,
  ori: state.ncic.ori
});

export default connect(mapStateToProps, {
  sendRequest,
  clearPersonForm,
  clearResults,
  handlePersonDateChange,
  handlePersonFilterChange,
  handlePersonCheckboxChange,
  setDefaultEnvFORNCIC
})(SearchFormNcic);
