import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  formSection: {
    paddingBottom: '10px'
  }
}));

const OlnStateFilter = props => {
  const classes = useStyles();

  const handleFilterOlnStateChange = (event, value) => {
    props.setSelectedOlnState(value?.Code || '');
  };

  const statusArray = props.states.map(state => state.Code);

  return (
    <FormControl fullWidth variant="outlined" className={classes.formSection}>
      <Autocomplete
        label="plate-state"
        value={{ Code: props.selectedOlnState || '' }}
        onChange={handleFilterOlnStateChange}
        options={[{ Code: '', Description: '' }, ...props.states] || []}
        getOptionLabel={option => option.Code || ''}
        getOptionSelected={() => true}
        renderOption={option => (
          <>
            <b>{option.Code || ''} </b> - {option.Description || ''}
          </>
        )}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            label="OLN State"
            variant="outlined"
          />
        )}
      />
    </FormControl>
  );
};

export default OlnStateFilter;
