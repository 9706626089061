import React, { useEffect, useState, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash.throttle';
import { RiMapPin2Line } from 'react-icons/ri';
import { GrMap } from 'react-icons/gr';
import { GrMapLocation } from 'react-icons/gr';
import { getService } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { getAddressDetails } from 'reducers/AddressReducer';
import { addCoordsToLocation } from 'utils/mapFunctions';
import { personAddressLookup } from 'reducers/PersonReducer';
import { IconButton } from '@material-ui/core';
import { AddIcon } from 'evergreen-ui';
import { showAddressDialog } from 'reducers/DialogsReducer';
import { searchFilter, filterObj, sortObjArr } from 'utils/functions';

const autocompleteService = { current: null, details: null };

const btnStyle = {
  margin: '0 8px 0 2px',
  padding: 0
};

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  }
}));

export const getPlace = ptsPlaceID => {
  const service = getService('cad');
  return service.get({ type: 'get-place', data: { ptsPlaceID } });
};

function AddressLookup(props) {
  const {
    wsClient,
    ptsPlaces,
    ptsPlaceID,
    ptsAddresses,
    googleAddresses,
    map,
    onReset,
    dictionary,
    setPlace,
    personAddress = false,
    size = 'small',
    label = 'search',
    error,
    compact,
    dataUpdate,
    dialogs,
    inputRef
  } = props;

  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [autocompleteOptions, setAutocompleteOptions] = useState([]); // list of search results
  const [addressType, setAddressType] = useState('none');
  const [extAddressUpdated, setExtAddressUpdated] = useState(false);
  const activeRef = useRef(false);
  const throttleRef = useRef(0);
  const throttleRef2 = useRef(0);
  const addEnabled = dialogs.address === null;

  useEffect(() => {
    clearTimeout(throttleRef2.current);
    throttleRef2.current = setTimeout(() => {
      if (inputValue.length > 2) {
        search();
      } else {
        setAutocompleteOptions([]);
      }
    }, 500);
    // eslint-disable-next-line
  }, [inputValue]);

  useEffect(() => {
    if (!dataUpdate || dataUpdate.type !== 'Address') return;
    handlePlaceSet(dataUpdate.data);
    // eslint-disable-next-line
  }, [dataUpdate]);

  useEffect(() => {
    handlePlaceSet();
    // eslint-disable-next-line
  }, [setPlace]);

  useEffect(() => {
    if (!ptsPlaceID) return;
    setPlaceByID(ptsPlaceID);
    // eslint-disable-next-line
  }, [ptsPlaceID]);

  useEffect(() => {
    activeRef.current = true;
    if (googleAddresses && !setGoogleService()) return;
    if (inputValue === '') return setAutocompleteOptions(value ? [value] : []);
    return () => {
      activeRef.current = false;
    };
    // eslint-disable-next-line
  }, [
    value,
    inputValue,
    wsClient.websocket,
    googleAddresses,
    ptsAddresses,
    ptsPlaces,
    map
  ]);

  const search = () => {
    if (!inputValue) {
      setAutocompleteOptions([]);
      return;
    }
    const { searchTerm, filters } = searchFilter(inputValue);
    findAddress(searchTerm, filters);
  };

  const setPlaceByID = async ptsPlaceID => {
    try {
      const place = await getPlace(ptsPlaceID);
      handlePlaceSet(place);
    } catch (err) {
      props.handleError(err);
    }
  };

  const setGoogleService = () => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      autocompleteService.details = new window.google.maps.places.PlacesService(
        map
      );
    }
    if (!autocompleteService.current || !autocompleteService.details)
      return false;
    return true;
  };

  const getGooglePlace = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
        return () => {
          clearTimeout(throttleRef.current);
        };
      }, 500),
    []
  );

  const searchGoogleAddress = () => {
    if (!googleAddresses || inputValue.length < 3)
      return setAutocompleteOptions([]);
    setAddressType('google');
    getGooglePlace({ input: inputValue }, results => {
      if (activeRef.current) {
        let newOptions = [];
        if (value) newOptions = [value];
        if (results) newOptions = [...newOptions, ...results];
        setAutocompleteOptions(newOptions);
      }
    });
  };

  const findAddress = async (searchTerm, filters) => {
    if (!ptsAddresses && !ptsPlaces) return searchGoogleAddress();
    let PlaceID;
    if (ptsAddresses && ptsPlaces && extAddressUpdated)
      return setExtAddressUpdated(false);
    if (ptsAddresses && !ptsPlaces) PlaceID = null;
    if (!ptsAddresses && ptsPlaces) PlaceID = 'notnull';
    try {
      const service = getService('ptsaddresslistliteview');
      let addresses = await service.find({
        query: { FullAddressText: searchTerm, PlaceID }
      });
      addresses = filterObj(
        addresses,
        ['FullAddressText', 'PlaceName'],
        filters
      );
      addresses = sortObjArr(addresses, 'FullAddressText');
      if (personAddress) {
        const personAddresses = await personAddressLookup(searchTerm);
        let addrWithNames = personAddresses.map(a => {
          const addressText = a.FullAddressText ? a.FullAddressText.trim() : '';
          return { ...a, FullAddressText: `${addressText} - ${a.FullName}` };
        });
        addrWithNames = filterObj(
          addrWithNames,
          ['FullAddressText', 'FullName'],
          filters
        );
        addresses = [...addresses, ...addrWithNames];
        addresses = sortObjArr(addresses, 'FullAddressText');
      }
      if (addresses.length > 0) {
        setAddressType('pts');
        setAutocompleteOptions(addresses);
      } else {
        searchGoogleAddress();
      }
    } catch (error) {
      props.handleError(error);
    }
  };

  // If place set by parent component
  const handlePlaceSet = (place = setPlace) => {
    if (!place) {
      if (value || inputValue) {
        setAutocompleteOptions([]);
        setValue(null);
        setExtAddressUpdated(true);
      }
      return;
    } else if (!place.ptsAddressID) return;
    const autocompleteOptions = [{ ...place }];
    setAutocompleteOptions(autocompleteOptions);
    setValue(autocompleteOptions[0]);
    setExtAddressUpdated(true);
    if (place !== setPlace) {
      props.onAddressValueSet(place);
    }
  };

  const getCityName = ptsCityID => {
    const city = dictionary.codeCities.find(
      city => city.ptsCityID === ptsCityID
    );
    return city ? city.Code : '';
  };

  const getOptionLabel = option => {
    if (option.FullAddressText) {
      if (option.PlaceName) {
        return `${option.PlaceName} - ${option.FullAddressText}`;
      } else {
        return option.FullAddressText;
      }
    } else if (option.formatted_address) {
      return option.formatted_address;
    } else {
      let label = '';
      label += option.AddressNumber ? option.AddressNumber : '';
      label += option.PreDirection ? ` ${option.PreDirection}` : '';
      label += option.StreetName ? ` ${option.StreetName}` : '';
      label += option.StreetType ? ` ${option.StreetType}` : '';
      label += option.PostDirection ? ` ${option.PostDirection}` : '';
      label += option.ptsCityID ? ` ${getCityName(option.ptsCityID)}` : '';
      label += option.State ? `, ${option.State}` : '';
      label += option.PostalCode ? ` ${option.PostalCode}` : '';
      return label;
    }
  };

  const onAddressSelect = async (ev, newValue) => {
    setAutocompleteOptions(
      newValue ? [newValue, ...autocompleteOptions] : autocompleteOptions
    );
    const isGooglePlace = Boolean(newValue?.place_id);
    if (isGooglePlace) {
      const service = autocompleteService.details;
      service.getDetails({ placeId: newValue.place_id }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK)
          setValue(place);
      });
    } else {
      setValue(newValue);
      if (!newValue) return onReset && onReset();
      const addressDetails = await getAddress(newValue.ptsAddressID);
      const address = { ...newValue, ...addressDetails };
      if (address.ptsPlaceID !== null) {
        props.onPlaceValueSet && props.onPlaceValueSet(address);
      } else {
        props.onAddressValueSet && props.onAddressValueSet(address);
      }
    }
  };

  const getAddress = async ptsAddressID => {
    let address = null;
    try {
      const result = await getAddressDetails(ptsAddressID);
      if (!result?.data?.length) return null;
      address = await addCoordsToLocation(result.data[0]);
    } catch (err) {
      props.handleError(err);
    }
    return address;
  };

  const onInputChange = (ev, newInputValue) => {
    setInputValue(newInputValue);
  };

  // const renderInput = (params) => (
  //   <TextField {...params} label={label} variant="outlined" fullWidth size={size} />
  // );

  const renderOption = option => {
    if (addressType === 'google' && option.structured_formatting) {
      const matches = option.structured_formatting.main_text_matched_substrings;
      const parts = parse(
        option.structured_formatting.main_text,
        matches.map(match => [match.offset, match.offset + match.length])
      );
      return (
        <Grid container alignItems="center">
          <Grid item>
            <RiMapPin2Line
              className={classes.icon}
              style={{ color: 'black', fontSize: '24px' }}
            />
          </Grid>
          <Grid item xs>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{ fontWeight: part.highlight ? 700 : 400 }}>
                {part.text}
              </span>
            ))}

            <Typography variant="body2" color="textSecondary">
              {option.structured_formatting.secondary_text}
            </Typography>
          </Grid>
        </Grid>
      );
    } else {
      if (option.PlaceName === null) {
        return (
          <Grid container alignItems="center">
            <Grid item>
              <GrMap
                className={classes.icon}
                style={{ color: 'green', fontSize: '24px' }}
              />
            </Grid>
            <Grid item xs>
              <span style={{ fontWeight: 400 }}>{option.FullAddressText}</span>

              <Typography variant="body2" color="textSecondary">
                {option.PlaceName}
              </Typography>
            </Grid>
          </Grid>
        );
      } else {
        return (
          <Grid container alignItems="center">
            <Grid item>
              <GrMapLocation
                className={classes.icon}
                style={{ color: 'red', fontSize: '24px' }}
              />
            </Grid>
            <Grid item xs>
              <span style={{ fontWeight: 400 }}>{option.FullAddressText}</span>
              <Typography variant="body2" color="textSecondary">
                {option.PlaceName}
              </Typography>
            </Grid>
          </Grid>
        );
      }
    }
  };

  const getOptionSelected = (option, value) => {
    return (
      option.ptsLocationAddressID === value.ptsLocationAddressID ||
      option.ptsAddressID === value.ptsAddressID
    );
  };

  const onAdd = () => {
    props.showAddressDialog();
  };

  const helperText = compact
    ? undefined
    : typeof error === 'string'
    ? error
    : ' ';
  const startAdornment = addEnabled ? (
    <IconButton style={btnStyle} onClick={onAdd}>
      <AddIcon fontSize="small" />
    </IconButton>
  ) : (
    undefined
  );

  return (
    <Autocomplete
      getOptionLabel={getOptionLabel}
      options={autocompleteOptions}
      autoComplete
      includeInputInList
      filterSelectedOptions
      className={clsx(classes.autocomplete, props.className)}
      style={props.style}
      value={value}
      onChange={onAddressSelect}
      onInputChange={onInputChange}
      // renderInput={renderInput}
      renderOption={renderOption}
      getOptionSelected={getOptionSelected}
      filterOptions={option => option}
      renderInput={params => (
        <TextField
          {...params}
          error={!!error}
          helperText={helperText}
          label={label}
          variant="outlined"
          size="small"
          inputRef={inputRef}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password'
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password'
          }}
        />
      )}
    />
  );
}

AddressLookup.defaultProps = {
  ptsPlaces: true,
  ptsAddresses: true,
  googleAddresses: true
};

const mapStateToProps = state => ({
  wsClient: state.websocket,
  map: state.map.map,
  dictionary: state.codes.dictionary,
  dataUpdate: state.dataUpdate,
  dialogs: state.dialogs
});

export default connect(mapStateToProps, {
  handleError,
  showAddressDialog
})(AddressLookup);
