import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  Hidden,
  Drawer,
  List,
  IconButton,
  Badge,
  Zoom,
  Fab,
  Card,
  TextField,
  useTheme
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';

import { makeStyles } from '@material-ui/core/styles';

import { setUnitsDrawerMobileState } from '../../reducers/UiReducer';
import { setUnitInfowindow } from '../../reducers/MapReducer';
import {
  unitsSortBy,
  unitsSortOrder,
  unitsDragReorder,
  unitsFilterKeywordChange,
  setUnitGroupBy,
  makeUnitGroup,
  toggleAccrodinExpanded
} from '../../reducers/UnitsReducer';
import { toggleDialog } from '../../reducers/SettingsReducer';

import UnitItem from '../DrawerUnitsDesktop/Unit';
import UnitItemSimple from '../DrawerUnitsDesktopSimple/Unit';
import FilterButton from 'layout-components/DrawerEventsDesktop/FilterButton';
import { withStyles } from '@material-ui/styles';

const drawerWidth = 300;
const drawerHeight = 80;

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    },
    zIndex: 98
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.bgElements,
    padding: '0px 6px',
    overflow: 'hidden'
  },
  toolbar: {
    height: drawerHeight,
    backgroundColor: theme.palette.bgElements
  },
  topHeaderBar: {
    position: 'fixed',
    backgroundColor: theme.palette.bgElements,
    zIndex: 99,
    width: drawerWidth - 10
  },
  select: {
    margin: '10px'
  }
}));
const Accordion = withStyles(theme => ({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto',
      backgroundColor: theme.palette.bgElements
    }
  },
  expanded: {}
}))(MuiAccordion);

const AccordionSummary = withStyles(theme => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
}))(MuiAccordionSummary);
const DrawerUnitsMobile = props => {
  const {
    state,
    setUnitsDrawerMobileState,
    units,
    sortBy,
    sortOrder,
    unitsSortBy,
    unitsSortOrder,
    unitsDragReorder,
    stateAgencies,
    isEmpty,
    toggleDialog,
    unitsFilterKeywordChange,
    filterKeyword,
    viewType,
    groupBy,
    groupedUnit,
    accrodinExpand
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const topRef = useRef();
  const [isButtonVisible, setButtonVisible] = React.useState(false);
  const [showIcon, setShowIcon] = useState(false);

  useEffect(() => {
    dispatch(makeUnitGroup(groupBy));
  }, [groupBy]);
  // useEffect(() => {
  //   dispatch(makeUnitGroupForPin());
  // }, [sortBy, sortOrder]);
  useEffect(() => {
    unitsFilterKeywordChange(filterKeyword);
  }, []);
  const handleScroll = () => {
    topRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  const handleListScroll = e => {
    let element = e.target;
    // At the bootom of the list
    // if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    //   console.log('show button');
    // }
    // After start scrolling
    if (element.scrollTop > 100) {
      setButtonVisible(true);
    } else {
      setButtonVisible(false);
    }
  };
  const handleChange = panel => (event, isExpanded) => {
    const a = isExpanded ? panel : -1;

    dispatch(toggleAccrodinExpanded(a));
  };
  const renderUnits = () => {
    if (stateAgencies?.length == 0 && units.length == 0) {
      return (
        <div style={{ paddingTop: `${showIcon ? 180 : 70}px` }}>
          <Card className="card-box bg-neutral-light border-1 border-dark text-center">
            <div className="py-2 px-2">
              <p className="opacity-6 font-size-md mb-2 mt-2">
                <b>No agencies selected</b>
                <br />
                You can select agencies from <b>Agency Filter</b> Option
              </p>
            </div>
          </Card>
        </div>
      );
    } else if (units && units.length > 0) {
      let ui =
        groupBy != 'none' ? (
          <div
            style={{
              maxHeight: `${
                showIcon ? window.innerHeight - 240 : window.innerHeight - 140
              }px`,
              overflowY: 'auto',
              overflowX: 'hidden',
              scrollbarWidth: 'thin',
              paddingRight: '10px',
              marginTop: `${showIcon ? -9 : -10}px`
            }}
            onScroll={handleListScroll}>
            <p ref={topRef}></p>
            {groupedUnit &&
              Object.entries(groupedUnit).map(([key, value]) => {
                return (
                  <Accordion
                    style={{ borderLeft: 'none', borderRight: 'none' }}
                    expanded={accrodinExpand == key}
                    onChange={handleChange(key)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <Typography
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}>
                        {key}
                        {/* <span
                        style={{
                          float: 'right',
                          backgroundColor: theme.palette.primary,
                          padding: '2px 7px',
                          borderRadius: '50%',
                          color: 'white'
                        }}>
                        {value.length}
                      </span> */}
                        <Badge
                          overlap="rectangular"
                          style={{ marginTop: '12px', marginRight: '10px' }}
                          badgeContent={value.length}
                          color="primary"></Badge>
                      </Typography>
                    </AccordionSummary>

                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppableUnits">
                        {provided => (
                          <List
                            {...provided.droppableProps}
                            ref={provided.innerRef}>
                            {value.map((unit, index) => {
                              return viewType == 'complex' ? (
                                <UnitItem
                                  key={index}
                                  unit={unit}
                                  index={index}
                                  pined={unit.Pined}
                                  expanded={unit.expanded}
                                />
                              ) : (
                                <UnitItemSimple
                                  key={index}
                                  unit={unit}
                                  index={index}
                                  pined={unit.Pined}
                                  expanded={unit.expanded}
                                />
                              );
                            })}
                            {provided.placeholder}
                            <Zoom in={isButtonVisible}>
                              <Fab
                                style={{
                                  position: 'fixed',
                                  bottom: '10px',
                                  left: '10px',
                                  zIndex: '100'
                                }}
                                onClick={handleScroll}
                                color="primary"
                                size="large"
                                aria-label="scroll back to top">
                                <KeyboardArrowUp />
                              </Fab>
                            </Zoom>
                          </List>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Accordion>
                );
              })}
          </div>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppableUnits">
              {provided => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    maxHeight: `${
                      showIcon
                        ? window.innerHeight - 220
                        : window.innerHeight - 110
                    }px`,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    scrollbarWidth: 'thin',
                    paddingRight: '10px',
                    marginTop: `${showIcon ? -15 : -20}px`
                  }}
                  onScroll={handleListScroll}>
                  <p ref={topRef}></p>
                  {units.map((unit, index) => {
                    if (filterKeyword !== '') {
                      if (
                        unit.UnitResources?.toLowerCase().includes(
                          filterKeyword
                        ) ||
                        unit.Unit?.toLowerCase().includes(filterKeyword) ||
                        unit.UnitStatus?.toLowerCase().includes(
                          filterKeyword
                        ) ||
                        unit.Division?.toLowerCase().includes(filterKeyword) ||
                        unit.Zone?.toLowerCase().includes(filterKeyword)
                      ) {
                        return viewType == 'complex' ? (
                          <UnitItem
                            key={index}
                            unit={unit}
                            index={index}
                            pined={unit.Pined}
                            expanded={unit.expanded}
                          />
                        ) : (
                          <UnitItemSimple
                            key={index}
                            unit={unit}
                            index={index}
                            pined={unit.Pined}
                            expanded={unit.expanded}
                          />
                        );
                      } else {
                        return null;
                      }
                    } else {
                      return (
                        <UnitItem
                          key={index}
                          unit={unit}
                          index={index}
                          pined={unit.Pined}
                          expanded={unit.expanded}
                        />
                      );
                    }
                  })}
                  {provided.placeholder}
                  <Zoom in={isButtonVisible}>
                    <Fab
                      style={{
                        position: 'fixed',
                        bottom: '10px',
                        left: '10px',
                        zIndex: '100'
                      }}
                      onClick={handleScroll}
                      color="primary"
                      size="large"
                      aria-label="scroll back to top">
                      <KeyboardArrowUp />
                    </Fab>
                  </Zoom>
                </List>
              )}
            </Droppable>
          </DragDropContext>
        );
      return ui;
    } else {
      return (
        <div style={{ paddingTop: `${showIcon ? 180 : 70}px` }}>
          {isEmpty ? (
            <Card className="card-box bg-neutral-light border-1 border-dark text-center">
              <div className="py-2 px-2">
                <p className="opacity-6 font-size-md mb-2 mt-2">
                  <b>No units found</b>
                </p>
              </div>
            </Card>
          ) : (
            <Fragment>
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
            </Fragment>
          )}
        </div>
      );
    }
  };

  const onDragEnd = result => {
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;
    unitsDragReorder(destination, source, draggableId);
  };

  const drawer = (
    <div>
      <div className={classes.topHeaderBar} style={{ position: 'relative' }}>
        <div className="divider my-1" style={{ background: '#0b55a1' }} />
        <div
          className="text-center"
          style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              alignSelf: 'center',
              marginLeft: '10px',
              color: '#0b55a1',
              fontSize: '1.1rem'
            }}
            className="font-weight-bold font-size-md mb-0">
            {units !== null ? (
              <Badge
                overlap="rectangular"
                style={{ marginRight: '10px' }}
                badgeContent={units.length}
                color="primary"></Badge>
            ) : (
              <Badge
                overlap="rectangular"
                style={{ marginRight: '10px' }}
                badgeContent={0}
                color="primary"></Badge>
            )}{' '}
            Units {'  '}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* <Autocomplete
              id="units-group"
              size="small"
              value={{ label: groupBy }}
              onChange={(event, newValue) => {
                dispatch(setUnitGroupBy(newValue.value || ''));
              }}
              autoHighlight
              autoSelect
              options={[
                { label: 'None', value: 'none' },
                { label: 'Agency', value: 'agency' },
                { label: 'Status', value: 'status' },
                { label: 'Zone', value: 'zone' }
              ]}
              getOptionLabel={option => option.label || ''}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label="Group By"
                />
              )}
            /> */}
            <FormControl
              size="small"
              style={{ minWidth: '110px', paddingRight: '10px' }}>
              <Select
                variant="outlined"
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null,
                  size: 'small'
                }}
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                size="small"
                value={groupBy}
                onChange={ev => dispatch(setUnitGroupBy(ev.target.value))}>
                <MenuItem value="none">None</MenuItem>
                <MenuItem value="agency">Agency</MenuItem>
                <MenuItem value="division">Division</MenuItem>
                <MenuItem value="status">Status</MenuItem>
                <MenuItem value="zone">Zone</MenuItem>
              </Select>
            </FormControl>
            <FilterButton type="unit" />

            <Badge
              variant="dot"
              badgeContent={1}
              invisible={filterKeyword == ''}
              color="error"
              overlap="circular">
              <IconButton
                size="small"
                style={{ color: '#0b55a1' }}
                onClick={() => setShowIcon(!showIcon)}>
                {showIcon ? (
                  <ExpandLessIcon fontSize="medium" color="primary" />
                ) : (
                  <ExpandMoreIcon fontSize="medium" color="primary" />
                )}
              </IconButton>
            </Badge>
            <IconButton
              style={{ color: '#0b55a1' }}
              onClick={() => setUnitsDrawerMobileState()}
              size="small"
              className="btn-inverse">
              <MenuOpenRoundedIcon />
            </IconButton>

            {/* <FormControl variant="outlined" size="small">
              <InputLabel>Group By</InputLabel>
              <Select
                size="small"
                native
                value={groupBy}
                onChange={ev => dispatch(setUnitGroupBy(ev.target.value))}
                label="Group By">
                <option value="none">None</option>
                <option value="agency">Agency</option>
                <option value="status">Status</option>
                <option value="zone">Zone</option>
              </Select>
            </FormControl> */}
          </div>
        </div>
        <div className="divider my-1" style={{ background: '#0b55a1' }} />
        {showIcon ? (
          <div style={{ textAlign: 'center' }}>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.select}>
              <InputLabel
                htmlFor="unit-sort-options"
                className={classes.selectLabel}>
                Sort By
              </InputLabel>
              <Select
                native
                value={sortBy}
                onChange={ev => unitsSortBy(ev.target.value)}
                label="Sort By"
                inputProps={{ id: 'unit-sort-options' }}>
                <option value="Unit">Name</option>
                <option value="UnitStatus">Status</option>
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.select}>
              <InputLabel
                htmlFor="unit-sort-options"
                className={classes.selectLabel}>
                Order
              </InputLabel>
              <Select
                native
                value={sortOrder}
                onChange={ev => unitsSortOrder(ev.target.value)}
                label="Order"
                inputProps={{ id: 'unit-sort-options' }}>
                <option value="DESC">DESC</option>
                <option value="ASC">ASC</option>
              </Select>
            </FormControl>
            <FormControl>
              <TextField
                style={{ width: '201px' }}
                variant="outlined"
                size="small"
                label="Filter units"
                placeholder="Filer units"
                size="small"
                type="search"
                value={filterKeyword}
                onChange={unitsFilterKeywordChange}
                // onBlur={() => setShowIcon(!showIcon)}
              />
            </FormControl>
          </div>
        ) : null}
      </div>
      {renderUnits()}
    </div>
  );

  return (
    <Fragment>
      <Hidden mdUp>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          open={state}
          anchor="left"
          classes={{
            paper: classes.drawerPaper
          }}>
          <div>
            <div className={classes.toolbar} />
            {drawer}
          </div>
        </Drawer>
      </Hidden>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  state: state.ui.unitsDrawerMobileState,
  units: state.units.units,
  sortBy: state.units.sortBy,
  sortOrder: state.units.sortOrder,
  filterKeyword: state.units.filterKeyword,
  themeMode: state.theme.mode,
  unitInfowindow: state.map.unitInfowindow,
  user: state.user,
  accrodinExpand: state.units.accrodinExpand,
  stateAgencies: state.settings.agenciesUnit,
  isEmpty: state.units.isEmpty,
  viewType: state.settings.viewType,
  groupBy: state.units.groupBy,
  groupedUnit: state.units.groupedUnit
});

export default connect(mapStateToProps, {
  setUnitsDrawerMobileState,
  setUnitInfowindow,
  unitsSortBy,
  unitsSortOrder,
  unitsDragReorder,
  toggleDialog,
  unitsFilterKeywordChange
})(DrawerUnitsMobile);
