import { createTheme } from '@material-ui/core';
import typography from './typography';

/* eslint-disable import/no-unresolved, import/no-webpack-loader-syntax */
import vars from '!!sass-vars-to-js-loader!../assets/core/_variables-mui.scss';

export const DayTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: vars.primary
    },
    grey: {
      300: vars.inheritDefault1,
      A100: vars.inheritDefault2
    },
    grey1: '#dedfe3',
    orange1: '#FFA500',
    secondary: {
      main: vars.secondary
    },
    error: {
      main: vars.red
    },
    success: {
      main: vars.green
    },
    warning: {
      main: vars.orange
    },
    helpers: {
      primary: vars.blue,
      main: 'rgba(25, 46, 91, .035)'
    },
    AlertButton: {
      main: 'rgb(235, 173, 26)'
    },
    bg: vars.bgLight,
    bgElements: vars.bgElementsLight,
    colorFont: '#000',
    contrastThreshold: 3,
    tonalOffset: 0.1
  },
  shape: {
    borderRadius: '0.5rem'
  },
  card: {
    color: vars.text1,
    bg: vars.paper,
    bg1: vars.grey1,
    bg2: vars.grey2,
    bgHover: vars.grey4,
    hr: vars.grey1
  },
  overrides: {
    MuiButton: {
      text: {
        paddingLeft: '14px',
        paddingRight: '14px'
      },
      containedSizeSmall: {
        paddingLeft: '14px',
        paddingRight: '14px'
      },
      root: {
        textTransform: 'none',
        fontWeight: 'normal'
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: vars.second,
        padding: '8px 16px',
        fontSize: '13px'
      },
      arrow: {
        color: vars.second
      }
    }
  },
  typography,
  map: {
    labelColor: '#000',
    labelBorder: '#fff',
    iconColor: '#242f3e',
    avatarBg: '#dedfe3'
  }
});

export const NightTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: vars.primary
    },
    grey: {
      300: vars.inheritDefault1,
      A100: vars.inheritDefault2
    },

    grey1: '#343434',

    orange1: '#FFA500',
    secondary: {
      main: vars.secondary
    },
    error: {
      main: vars.red
    },
    success: {
      main: vars.green
    },
    warning: {
      main: vars.orange
    },
    helpers: {
      primary: vars.blue,
      main: 'rgba(25, 46, 91, .035)'
    },
    AlertButton: {
      main: 'rgb(135, 173, 26)'
    },
    bg: vars.bgDark,
    bgElements: vars.bgElementsDark,
    colorFont: '#fff',
    contrastThreshold: 3,
    tonalOffset: 0.1
  },

  shape: {
    borderRadius: '0.5rem'
  },
  overrides: {
    MuiButton: {
      text: {
        paddingLeft: '14px',
        paddingRight: '14px'
      },
      containedSizeSmall: {
        paddingLeft: '14px',
        paddingRight: '14px'
      },
      root: {
        textTransform: 'none',
        fontWeight: 'normal'
      }
    },
    MuiOutlinedInput: {
      root: {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #778eeb !important'
        }
      }
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: '#778eeb !important'
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: vars.second,
        padding: '8px 16px',
        fontSize: '13px'
      },
      arrow: {
        color: vars.second
      }
    },
    MuiTabs: {
      root: {
        '& .MuiTabs-indicator': {
          backgroundColor: '#778eeb !important'
        }
      }
    },
    MuiTab: {
      root: {
        '&.Mui-selected': {
          color: '#778eeb !important'
        }
      }
    },

    MuiToolbar: {
      regular: {
        minHeight: '80px '
      }
    }
  },
  typography,
  map: {
    labelColor: '#fff',
    labelBorder: '#000',
    iconColor: '#242f3e',
    avatarBg: '#5b5b5b'
  }
});
