export function parseXmlSOP(sop) {
  const { Actions, ptsSOPID, SOPID } = sop;
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(Actions, 'text/xml');
  const getContent = name =>
    xmlDoc.getElementsByTagName(name)[0]
      ? xmlDoc.getElementsByTagName(name)[0].textContent
      : null;
  const getCheckboxVal = val => (val === 'True' ? true : false);
  const DispatcherMessage = getContent('Message');
  const ParentType = getContent('ParentType');
  const ParentName = getContent('ParentName');
  const Notified = {};
  const NotifiedObj = xmlDoc.getElementsByTagName('Notified')[0];
  if (NotifiedObj) {
    Notified.Name = NotifiedObj.textContent;
    Notified.Time = NotifiedObj.getAttribute('Time');
  }
  const DispatcherQuestions = [];
  const DispatcherQuestionsNodes = xmlDoc.getElementsByTagName(
    'DispatcherQuestions'
  )[0]
    ? xmlDoc.getElementsByTagName('DispatcherQuestions')[0].childNodes
    : null;
  if (DispatcherQuestionsNodes !== null) {
    for (let i = 0; i < DispatcherQuestionsNodes.length; i++) {
      const node = DispatcherQuestionsNodes[i];
      if (!node.tagName) continue;
      // node.tagName === 'CheckBox' && console.log(node.tagName, node.textContent);
      DispatcherQuestions.push({
        type: node.tagName,
        name: node.getAttribute('Name'),
        text: node.getAttribute('Text'),
        value:
          node.tagName === 'CheckBox'
            ? getCheckboxVal(node.textContent)
            : node.textContent
      });
    }
  }
  return {
    DispatcherMessage,
    ParentType,
    ParentName,
    Notified,
    DispatcherQuestions,
    ptsSOPID,
    SOPID
  };
}

export const decodeDispatcherQuestions = xml => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, 'text/xml');
  const getCheckboxVal = val => (val === 'True' ? true : false);
  const DispatcherQuestions = [];
  const DispatcherQuestionsNodes = xmlDoc.getElementsByTagName(
    'DispatcherQuestions'
  )[0].childNodes;
  for (let i = 0; i < DispatcherQuestionsNodes.length; i++) {
    const node = DispatcherQuestionsNodes[i];
    if (!node.tagName) continue;
    DispatcherQuestions.push({
      type: node.tagName,
      name: node.getAttribute('Name'),
      text: node.getAttribute('Text'),
      value:
        node.tagName === 'CheckBox'
          ? getCheckboxVal(node.textContent)
          : node.textContent
    });
  }
  return DispatcherQuestions;
};

/* Encode during updating answers */
export function encodeSOPs(rawSops) {
  return rawSops.map(sop => {
    const { ptsEventID, ptsSOPID } = sop;
    const Actions = encodeSOP(sop);
    return { Actions, ptsEventID, ptsSOPID };
  });
}

/* Encode new SOP */
export function encodeSOP(sop) {
  const { DispatcherMessage, Notified, ParentName, ParentType } = sop;
  const notified =
    Notified && Notified.Name
      ? `<Notified Time="${Notified.Time}">${Notified.Name}</Notified>\n`
      : '';
  const DispatcherQuestions = parseDispatcherQuestions(sop);
  const Actions = `<Actions>
  <DispatcherMessage>
    <Message>${DispatcherMessage ? DispatcherMessage : ''}</Message>
  </DispatcherMessage>
  <DispatcherQuestions>
${DispatcherQuestions}
  </DispatcherQuestions>
  <ParentType>${ParentName ? ParentName : ''}</ParentType>
  <ParentName>${ParentType ? ParentType : ''}</ParentName>
  ${notified}
</Actions>`;
  return Actions;
}

const parseDispatcherQuestions = sop => {
  const getTextStr = q => `    <Text Name="${q.name}" Text="${q.text}"/>\n`;
  const getCheckboxStr = q =>
    `    <CheckBox Name="${q.name}" Text="${q.text}">${
      q.value ? 'True' : ''
    }</CheckBox>\n`;
  const getQuestionStr = q =>
    `    <Question Name="${q.name}" Text="${q.text}">${q.value}</Question>\n`;
  const DispatcherQuestions = sop.DispatcherQuestions.map(item => {
    switch (item.type) {
      case 'Text':
        return getTextStr(item);
      case 'Question':
        return getQuestionStr(item);
      case 'CheckBox':
        return getCheckboxStr(item);
      default:
        return '';
    }
  }).reduce((res, val) => res + val, '');
  return DispatcherQuestions;
};
