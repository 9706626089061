import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getEventColor } from '../../../utils/uiUtils';

const useStyles = makeStyles(theme => ({
  formSection: {
    paddingBottom: '10px'
  }
}));

const EventFilter = props => {
  const { userUnit, eventList } = props;
  const classes = useStyles();
  const [selectedEvent, setSelectedEvent] = useState('');

  const handleFilterEventChange = (event, value) => {
    if (value !== null) {
      let selectedEventInfo = props.eventList.filter(
        event => event.EventID === value.EventID
      );
      setSelectedEvent(selectedEventInfo[0]);
      props.setSelectedEvent(selectedEventInfo[0].ptsEventID);
    } else {
      setSelectedEvent('');
      props.setSelectedEvent(null);
    }
  };

  const statusArray = props.eventList.map(event => event);
  // console.log('props.eventList', props.eventList);

  useEffect(() => {
    let myEvents = [];

    if (props.selectedEvent) {
      myEvents = eventList.filter(ev => ev.EventID === props.selectedEvent);
      if (myEvents.length > 0) {
        setSelectedEvent(myEvents[0]);
        props.setSelectedEvent(myEvents[0].ptsEventID);
      }
    } else {
      const ptsUnitID = userUnit.ptsUnitID;
      eventList.forEach(event => {
        const unitList = event.assignedUnits;
        if (unitList.length > 0) {
          const myUnits = unitList.find(un => un.ptsUnitID == ptsUnitID);
          if (myUnits) {
            myEvents.push(event);
          }
        }
      });
    }
  }, [eventList, props.selectedEvent]);
  return (
    <FormControl fullWidth variant="outlined" className={classes.formSection}>
      <Autocomplete
        label="event-autocomplete"
        value={selectedEvent}
        onChange={handleFilterEventChange}
        getOptionSelected={() => true}
        options={statusArray}
        getOptionLabel={option =>
          option.EventID
            ? `${option.EventID} - ${option.FullLocationAddress}`
            : ''
        }
        renderOption={option => (
          <Fragment>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
              }}>
              <div style={{ paddingRight: '10px' }}>
                <b>
                  {option.CallType} - {option.EventID}
                </b>{' '}
                - {option.FullLocationAddress}
              </div>
              <div
                className="badge"
                style={{
                  background: getEventColor(option, props.colors),
                  width: '90px'
                }}>
                {option.Status}
              </div>
            </div>
          </Fragment>
        )}
        renderInput={params => (
          <TextField {...params} fullWidth label="Event" variant="outlined" />
        )}
      />
    </FormControl>
  );
};

const mapStateToProps = state => ({
  colors: state.codes.colorsCodes,
  userUnit: state.user.userUnit
});

export default connect(mapStateToProps)(EventFilter);
