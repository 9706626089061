import React from 'react';
import { ClimbingBoxLoader } from 'react-spinners';

const style = {
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}

function Loading() {
  return (
    <div style={style}>
      <ClimbingBoxLoader color={'#0b55a1'} loading={true} />
      <div style={{ marginBottom: 8, fontSize: 22 }}>Loading...</div>
    </div>
  );
}

export default Loading;
