import { enqueueSnackbar } from '../reducers/NotifierReducer';
import store from '../config/configureStore';
import tableConfig from '../config/table-config-closed-events.json';

import { showSpinner, hideSpinner } from './UiReducer';

export const CLEAR_RESULTS = 'CLEAR_RESULTS';

export const SET_FORM_CLOSE_EVENT_PICKERS = 'SET_FORM_CLOSE_EVENT_PICKERS';
export const SET_FORM_CLOSE_EVENT_FILTERS = 'SET_FORM_CLOSE_EVENT_FILTERS';
export const FIND_CLOSED_EVENTS = 'FIND_CLOSED_EVENTS';
export const LOADING_CLOSE_EVENTS = 'LOADING_CLOSE_EVENTS';
export const LOADING_CLOSE_EVENTS_ERROR = 'LOADING_CLOSE_EVENTS_ERROR';
export const OPEN_EVENT_DETAILS = 'OPEN_EVENT_DETAILS';
export const CLOSE_EVENT_DETAILS = 'CLOSE_EVENT_DETAILS';
export const SET_SETECTED_SEARCH_HISTORY = 'SET_SETECTED_SEARCH_HISTORY';

export const findClosedEvents = params => {
  return async dispatch => {
    const state = store.store.getState();
    const client = state.websocket.websocket;

    try {
      const service = client.service('search-event');
      service.timeout = 500000;
      dispatch({ type: LOADING_CLOSE_EVENTS, payload: true });
      const closeEvents = await service.find({ query: params });
      // zconst closeEvents = data;

      dispatch({ type: FIND_CLOSED_EVENTS, payload: { params, closeEvents } });
      dispatch({ type: LOADING_CLOSE_EVENTS, payload: false });
    } catch (error) {
      dispatch({ type: LOADING_CLOSE_EVENTS_ERROR });
    }
  };
};
/* New ncic implementation */
export const handleDateChange = (date, dbName, param) => {
  const pickerValues = store.store.getState().closeEvents.closeEventsForm
    .pickers;
  return async dispatch => {
    const value = pickerValues[dbName]
      ? pickerValues[dbName]
      : { from: null, to: null };
    value[param] = date;
    if (param === 'from' && !value.to) value.to = new Date();
    if (param === 'to' && !value.from) value.from = date;

    const currentPickerValue = { ...pickerValues };
    if (value.from !== null && value.to !== null) {
      currentPickerValue[dbName] = value;
    } else {
      delete currentPickerValue[dbName];
    }
    dispatch({
      type: SET_FORM_CLOSE_EVENT_PICKERS,
      payload: currentPickerValue
    });
  };
};

export const handleFilterChange = (ev, filter) => {
  const values = store.store.getState().closeEvents.closeEventsForm.filters;

  return async dispatch => {
    values[filter.dbName] = {
      type: filter.type,
      search: ev.target ? ev.target.value.toUpperCase() : ev.toUpperCase()
    };
    dispatch({
      type: SET_FORM_CLOSE_EVENT_FILTERS,
      payload: cleanFilters(values)
    });
  };
};
export const handleClosedEventFilltersAutoFill = values => {
  return dispatch => {
    dispatch({
      type: SET_SETECTED_SEARCH_HISTORY,
      payload: JSON.parse(JSON.stringify(values))
    });
    delete values.personOptionLabel;

    if (values['e.Created']) {
      dispatch({
        type: SET_FORM_CLOSE_EVENT_PICKERS,
        payload: { 'e.Created': values['e.Created'] }
      });
      delete values['e.Created'];
    } else {
      dispatch({
        type: SET_FORM_CLOSE_EVENT_PICKERS,
        payload: {}
      });
    }

    dispatch({
      type: SET_FORM_CLOSE_EVENT_FILTERS,
      payload: typeof values === 'object' ? values : {}
    });
  };
};
const cleanFilters = obj => {
  const result = {};
  Object.entries(obj).forEach(([key, val]) => {
    if (val.search !== '') result[key] = val;
  });
  return result;
};

export const clearFilters = () => {
  return async dispatch => {
    dispatch({
      type: SET_FORM_CLOSE_EVENT_FILTERS,
      payload: {}
    });
    dispatch({
      type: SET_FORM_CLOSE_EVENT_PICKERS,
      payload: {}
    });
  };
};
export const clearResults = () => {
  return async dispatch => {
    dispatch({
      type: CLEAR_RESULTS,
      payload: null
    });
  };
};
export const openShowDetails = params => {
  return async dispatch => {
    dispatch({ type: OPEN_EVENT_DETAILS, payload: params });
  };
};
export const closeShowDetails = () => {
  return async dispatch => {
    dispatch({ type: CLOSE_EVENT_DETAILS });
  };
};
export default function reducer(
  state = {
    // Gun
    closeEvents: {
      rows: [],
      count: 5000,
      pageNo: 0,
      search: '',
      rowsPerPage: tableConfig.rowsPerPage,
      orderBy: tableConfig.orderBy,
      orderDirection: tableConfig.orderDirection,
      loading: false,
      loaded: false,
      error: false
    },
    closeEventsForm: {
      filters: {},
      pickers: {}
    },
    closedEventsDetails: {
      isShow: false,
      event: null
    },
    selectedSearchHistory: null
  },
  action
) {
  switch (action.type) {
    // Gun
    case LOADING_CLOSE_EVENTS:
      return {
        ...state,
        closeEvents: {
          ...state.closeEvents,
          loading: action.payload,
          loaded: action.payload ? false : state.closeEvents.loaded,
          error: false
        }
      };
    case LOADING_CLOSE_EVENTS_ERROR:
      return {
        ...state,
        closeEvents: {
          ...state.closeEvents,
          loading: false,
          loaded: true,
          error: true
        }
      };
    case FIND_CLOSED_EVENTS:
      return {
        ...state,
        closeEvents: {
          rows: action.payload.closeEvents.data,
          ...action.payload.params,
          count: action.payload.closeEvents.count,
          loaded: true,
          error: false
        }
      };
    case OPEN_EVENT_DETAILS:
      return {
        ...state,
        closedEventsDetails: {
          ...state.closedEventsDetails,
          isShow: true,
          event: action.payload
        }
      };
    case CLOSE_EVENT_DETAILS:
      return {
        ...state,
        closedEventsDetails: {
          ...state.closedEventsDetails,
          isShow: false,
          event: null
        }
      };
    case SET_FORM_CLOSE_EVENT_PICKERS:
      return {
        ...state,
        closeEventsForm: {
          ...state.closeEventsForm,
          pickers: action.payload
        }
      };
    case SET_FORM_CLOSE_EVENT_FILTERS:
      return {
        ...state,
        closeEventsForm: {
          ...state.closeEventsForm,
          filters: action.payload
        }
      };
    case CLEAR_RESULTS:
      return {
        ...state,
        closeEvents: {
          rows: [],
          count: 5000,
          pageNo: 0,
          search: '',
          rowsPerPage: tableConfig.rowsPerPage,
          orderBy: tableConfig.orderBy,
          orderDirection: tableConfig.orderDirection,
          loading: false,
          loaded: false,
          error: false
        }
      };
    case SET_SETECTED_SEARCH_HISTORY:
      return {
        ...state,
        selectedSearchHistory: action.payload
      };
    default:
      break;
  }
  return state;
}
