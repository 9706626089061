import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment-timezone';
import ErrorIcon from '@material-ui/icons/Error';
import { Tooltip, IconButton } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';

import { Draggable } from 'react-beautiful-dnd';
import ClickNHold from 'react-click-n-hold';

// importing the icon
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import DirectionsCarOutlinedIcon from '@material-ui/icons/DirectionsCarOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { makeStyles } from '@material-ui/core/styles';

import { IconContext } from 'react-icons/lib';
import { AiFillPushpin } from 'react-icons/ai';
import { AiOutlinePushpin } from 'react-icons/ai';

import EventIcon from '../../components/Map/EventIcon';
import AgencyIcon from '../../components/Map/AgencyIcon';

import { setUnitsDrawerDesktopState } from '../../reducers/UiReducer';
import { setUnitInfowindow, actualEvent } from '../../reducers/MapReducer';
import { toggleDialog as ToggleUnitDialog } from '../../reducers/UnitStatusReducer';
import {
  unitsSortBy,
  unitsSortOrder,
  pinUnit,
  toggleExpanded
} from '../../reducers/UnitsReducer';

import { getUnitColor } from '../../utils/uiUtils';
import { getEventColor } from '../../utils/uiUtils';

import { displayDateTime } from 'reducers/TimeReducer';
import { CalculateLastTimeDif } from 'utils/functions';

const avatarWidth = 44;
const iconWidth = 35;

const useStyles = makeStyles(theme => ({
  eventCard: {
    borderWidth: '5px',
    cursor: 'pointer'
  },
  listItem: {
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  btop: {
    borderTop: 'solid 1px rgba(7, 9, 25, 0.125)'
  },
  avatar: {
    flex: `0 0 ${avatarWidth}px`,
    width: avatarWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    '& > div': {
      boxSizing: 'border-box',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      background: '#dedfe3',
      borderRadius: '15%',
      width: 35,
      height: 35,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.2em',
      color: '#000',
      fontWeight: 500,
      textTransform: 'uppercase',
      padding: 4
    }
  },
  icon: {
    flex: `0 0 ${iconWidth}px`,
    textAlign: 'center',
    '& > div': {
      height: 20,
      width: 20,
      display: 'inline-block'
    }
  }
}));

const UnitItem = props => {
  const {
    unit,
    index,
    setUnitInfowindow,
    unitInfowindow,
    pinUnit,
    colors,
    expanded,
    toggleExpanded,
    actualEvent
  } = props;

  const classes = useStyles();

  const zoneSimplifier = zone => {
    if (!zone) return '';
    let zoneToReturn = zone.replace(/_/g, ' ');
    return zoneToReturn;
  };

  const onUnitClick = unit => {
    setUnitInfowindow(true, unit);
  };

  const onIconClick = (e, unit) => {
    e.stopPropagation();
    pinUnit(unit);
  };

  const onUnitHold = (e, unit) => {
    // console.log(event);
  };

  const handleEventClick = (e, event) => {
    e.stopPropagation();
    actualEvent(event);
  };

  const onExpandClick = (e, unit) => {
    e.stopPropagation();
    toggleExpanded(unit);
  };

  let boxShadow =
    'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px';
  if (
    unitInfowindow.open === true &&
    unitInfowindow.data &&
    unitInfowindow.data.ptsUnitID === unit.ptsUnitID
  ) {
    boxShadow = '-4px 0 0 -1px #0153a3';
  }
  const mobile = unit.Flags && unit.Flags.trim() === 'M' ? true : false;
  const details =
    unit.Location !== null ||
    unit.LastActionOLN !== null ||
    unit.LastActionPlateState !== null ||
    unit.LastActionNotes
      ? true
      : false;
  if (!unit.ptsUnitID) {
    console.log('unit for test', unit);
  }
  return (
    <Draggable
      key={unit.ptsUnitID}
      draggableId={unit?.ptsUnitID?.toString()}
      index={index}
      isDragDisabled={!unit.Pined}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <ClickNHold time={1} onClickNHold={e => onUnitHold(e, unit)}>
            <div
              onClick={() => onUnitClick(unit)}
              className={clsx(
                'MuiPaper-root',
                'MuiPaper-rounded',
                'card-box',
                'card-border-top',
                'card-border-bottom',
                'mb-2',
                'pb-1',
                'badge-wrapper',
                classes.eventCard
              )}
              style={{
                borderColor: getUnitColor(unit, colors),
                boxShadow: boxShadow
              }}>
              <div
                style={{
                  alignItems: 'end',
                  marginBottom: '2px',
                  borderBottom: 'none'
                }}
                className="card-header MuiPaper-root p-2">
                <div>
                  <Tooltip arrow title={unit.AgencyID}>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={e => onIconClick(e, unit)}
                      className={classes.avatar}>
                      <div>
                        <AgencyIcon
                          color={getUnitColor(unit, colors)}
                          AgencyID={unit.AgencyID}
                        />
                      </div>
                    </div>
                  </Tooltip>
                  <div
                    style={{
                      // maxWidth: '45px',
                      // marginRight: '10px',
                      textAlign: 'center',
                      fontSize: '10px',
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      marginTop: '5px'
                    }}
                    className={classes.avatar}>
                    <div style={{ alignItems: 'center', background: 'none' }}>
                      <IconButton
                        style={{ padding: '2px' }}
                        onClick={ev => {
                          onExpandClick(ev, unit);
                        }}
                        aria-label="delete">
                        {expanded ? (
                          <ExpandLessIcon fontSize="medium" color="primary" />
                        ) : (
                          <ExpandMoreIcon fontSize="medium" color="primary" />
                        )}
                      </IconButton>
                    </div>
                  </div>
                </div>
                <div className="card-header--title">
                  <div
                    {...provided.dragHandleProps}
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      alignContent: 'flex-end',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}>
                    <div style={{ alignSelf: 'start' }}>
                      <div
                        style={{
                          lineHeight: '15px'
                        }}>
                        <b>{unit.Unit}</b>
                        {unit.Division && <b> | {unit.Division}</b>}
                      </div>
                      <div
                        style={{
                          lineHeight: '10px',
                          fontSize: '1rem',
                          fontWeight: '500'
                          // color: getUnitColor(unit, colors)
                        }}
                        className="pb-1 lead">
                        <small style={{ fontWeight: '500' }}>
                          {unit.UnitResources}
                        </small>
                      </div>
                      <div style={{ lineHeight: '10px' }}>
                        <div>
                          <small>
                            {displayDateTime(unit.LastActionOccurred)}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div>
                      {CalculateLastTimeDif(
                        unit.LastChangedLocationTime,
                        unit.Flags
                      ) && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '3px',
                            right: '27px',
                            zIndex: '2'
                          }}>
                          <Tooltip arrow title="Idle">
                            <ErrorIcon color="error" />
                          </Tooltip>
                        </div>
                      )}

                      <div
                        style={{
                          position: 'absolute',
                          top: '20px',
                          right: '7px',
                          zIndex: '2'
                        }}>
                        {mobile && (
                          <div
                            style={{
                              alignItems: 'center',
                              background: 'none'
                            }}>
                            <DirectionsCarIcon
                              style={{
                                color: getUnitColor(unit, colors),
                                fontSize: '16px'
                              }}
                            />
                          </div>
                        )}
                        {!mobile && (
                          <div
                            style={{
                              alignItems: 'center',
                              background: 'none',
                              opacity: '0.5'
                            }}>
                            <DirectionsCarOutlinedIcon
                              style={{
                                color: getUnitColor(unit, colors),
                                fontSize: '16px'
                              }}
                            />
                          </div>
                        )}
                      </div>
                      {unit.Pined && (
                        <div
                          onClick={e => {
                            e.stopPropagation();
                            pinUnit(unit);
                          }}
                          style={{
                            zIndex: '2',
                            right: '-2px',
                            top: '1px',
                            backgroundColor: 'transparent',
                            cursor: 'pointer'
                          }}
                          className="badge badge-first badge-position badge-position--top-right"
                          title="Badge top left">
                          <IconContext.Provider
                            value={{
                              color: getUnitColor(unit, colors),
                              size: '2em'
                            }}>
                            <AiFillPushpin />
                          </IconContext.Provider>
                        </div>
                      )}
                      {!unit.Pined && (
                        <div
                          onClick={e => {
                            e.stopPropagation();
                            pinUnit(unit);
                          }}
                          style={{
                            zIndex: '2',
                            right: '-2px',
                            top: '1px',
                            backgroundColor: 'transparent',
                            cursor: 'pointer',
                            opacity: '0.5'
                          }}
                          className="badge badge-first badge-position badge-position--top-right"
                          title="Badge top right">
                          <IconContext.Provider
                            value={{
                              color: getUnitColor(unit, colors),
                              size: '2em'
                            }}>
                            <AiOutlinePushpin />
                          </IconContext.Provider>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}>
                    <div style={{ lineHeight: '10px', marginTop: '10px' }}>
                      <small>
                        <b>{zoneSimplifier(unit.Zone).toString()}</b>
                      </small>
                    </div>
                    <div
                      className="badge"
                      style={{
                        background: getUnitColor(unit, colors),
                        width: '90px'
                      }}>
                      {unit.UnitStatus}
                    </div>
                  </div>
                </div>
              </div>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                {details === true && (
                  <div
                    className="px-2 py-1"
                    style={{
                      borderTop: 'solid 1px rgba(7, 9, 25, 0.125)',
                      borderBottom: 'solid 1px rgba(7, 9, 25, 0.125)'
                    }}>
                    {unit.Location !== null && (
                      <div style={{ lineHeight: '12px' }}>
                        <small>
                          <b
                            style={{
                              height: '10px',
                              lineHeight: '12px',
                              width: '50px'
                            }}
                            className="m-1 badge badge-secondary">
                            Location
                          </b>
                          {unit.Location}
                        </small>
                      </div>
                    )}
                    {unit.LastActionOLN !== null && (
                      <div style={{ lineHeight: '12px' }}>
                        <small>
                          <b
                            style={{
                              height: '10px',
                              lineHeight: '12px',
                              width: '50px'
                            }}
                            className="m-1 badge badge-secondary">
                            OLN
                          </b>
                          {unit.LastActionOLN}{' '}
                          {unit.LastActionOLNState !== null
                            ? unit.LastActionOLNState
                            : ''}
                        </small>
                      </div>
                    )}
                    {unit.LastActionPlateState !== null && (
                      <div style={{ lineHeight: '12px' }}>
                        <small>
                          <b
                            style={{
                              height: '10px',
                              lineHeight: '12px',
                              width: '50px'
                            }}
                            className="m-1 badge badge-secondary">
                            Plate
                          </b>
                          {unit.LastActionPlate}{' '}
                          {unit.LastActionPlateState !== null
                            ? unit.LastActionPlateState
                            : ''}
                        </small>
                      </div>
                    )}
                    {unit.LastActionNotes !== null && (
                      <div style={{ lineHeight: '12px' }}>
                        <small>
                          <b
                            style={{
                              height: '10px',
                              lineHeight: '12px',
                              width: '50px'
                            }}
                            className="m-1 badge badge-secondary">
                            Notes
                          </b>
                          {unit.LastActionNotes.substring(0, 50)}
                        </small>
                      </div>
                    )}
                  </div>
                )}
                {unit.Events.length > 0 && (
                  <div className={clsx(!details && 'btop')}>
                    {unit.Events.map((event, index) => (
                      <div
                        style={{
                          backgroundColor:
                            index % 2 == 0
                              ? 'rgba(0, 0, 0, 0.02)'
                              : 'transparent'
                        }}
                        key={event.ptsEventID}
                        className="px-2 pb-1 pt-1">
                        <div
                          onClick={ev => handleEventClick(ev, event)}
                          className="d-flex justify-content-between"
                          style={{ alignItems: 'center' }}>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                              style={{ cursor: 'pointer' }}
                              className={classes.icon}>
                              <div>
                                <EventIcon
                                  CallType={event.CallType}
                                  CallTypeDescription={event.CallType}
                                  color={getEventColor(event, colors)}
                                />
                              </div>
                            </div>
                            <div>
                              <div
                                className="font-weight-bold"
                                style={{
                                  lineHeight: '10px',
                                  minWidth: '125px'
                                }}>
                                <div>
                                  <span>{event.EventID}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div
                              className="badge"
                              style={{
                                background: getEventColor(event, colors),
                                width: '90px'
                              }}>
                              {event.Status}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Collapse>
            </div>
          </ClickNHold>
        </div>
      )}
    </Draggable>
  );
};

const mapStateToProps = state => ({
  unitInfowindow: state.map.unitInfowindow,
  colors: state.codes.colorsCodes
});

export default connect(mapStateToProps, {
  setUnitsDrawerDesktopState,
  setUnitInfowindow,
  ToggleUnitDialog,
  unitsSortBy,
  unitsSortOrder,
  pinUnit,
  toggleExpanded,
  actualEvent
})(UnitItem);
