import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';

import { Badge } from '@material-ui/core';

import { toggleDetails } from '../../../../reducers/NcicReducer';

const stylesDay = {
  spinner: {
    background: 'rgba(255, 255, 255, 0.5)'
  }
};

const stylesNight = {
  spinner: {
    background: 'rgba(0, 0, 0, 0.5)'
  }
};

const styles = themeMode => {
  return themeMode === 'day' ? stylesDay : stylesNight;
};

const useStyles = makeStyles(theme => ({
  tableWrap: {
    overflow: 'auto',
    margin: '0 auto',
    position: 'relative'
  },
  header: {
    cursor: 'pointer'
  },
  spinner: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      color: '#999'
    }
  },
  warrantRow: {
    backgroundColor: theme.palette.error.main
  }
}));

const headCells = {
  NormalizedName: {
    dbName: 'NormalizedName',
    isDisplayed: true,
    label: 'Full Name',
    name: 'FullName',
    seq: 1,
    title: 'Full Name',
    width: '22.47%'
  },
  SexCode: {
    dbName: 'SexCode',
    isDisplayed: true,
    label: 'Sex',
    name: 'SexCode',
    seq: 2,
    title: 'Sex',
    width: '10%'
  },
  BirthDate: {
    dbName: 'BirthDate',
    isDisplayed: true,
    label: 'DOB',
    name: 'BirtDate',
    seq: 3,
    title: 'DOB',
    width: '10%'
  }
};

const SearchList = props => {
  // const classes = useStyles();
  // const { list, themeMode } = props;
  return null;
  // if (list.length > 0) {
  //   return (
  //     <Fragment>
  //       <div className="table-responsive">
  //         <table
  //           className={clsx(
  //             'table',
  //             'table-hover',
  //             themeMode === 'night' && 'table-pts-night',
  //             themeMode === 'day' && 'table-pts-day',
  //             'text-nowrap',
  //             'mb-0'
  //           )}>
  //           <thead>
  //             <tr>
  //               {Object.values(headCells).map((row, idx) => {
  //                 if (row.isDisplayed === true) {
  //                   return (
  //                     <Fragment key={row.label}>
  //                       <th
  //                         className="MuiTableCell-sizeSmall"
  //                         title={row.title}>
  //                         <span>{row.label}</span>
  //                       </th>
  //                     </Fragment>
  //                   );
  //                 }
  //               })}
  //             </tr>
  //           </thead>
  //           <tbody>
  //             {list.map((row, idx) => {
  //               let cols = [];
  //               Object.entries(
  //                 row['LawEnforcementTransaction'][0]['Transaction'][0][
  //                   'Response'
  //                 ][4]
  //               ).forEach(([key, val]) => {
  //                 if (key !== 'key') {
  //                   if (headCells[key]) {
  //                     const dataType = headCells[key].type;
  //                     const dataName = headCells[key].dbName;
  //                     const isDisplayed = headCells[key].isDisplayed;
  //                     if (isDisplayed === true) {
  //                       if (
  //                         val &&
  //                         dataType === 'date' &&
  //                         moment(val).isValid()
  //                       ) {
  //                         const editedDate = val.slice(0, -1);
  //                         cols.push({
  //                           text: moment(editedDate).format('MM/DD/YYYY'),
  //                           seq: headCells[key].seq,
  //                           name: dataName
  //                         });
  //                       } else {
  //                         cols.push({
  //                           text: val,
  //                           seq: headCells[key].seq,
  //                           name: dataName
  //                         });
  //                       }
  //                     }
  //                   }
  //                 }
  //               });
  //               if (cols.length > 0 && cols[0].seq !== undefined) {
  //                 cols = cols.sort((a, b) => a.seq - b.seq);
  //               }
  //               if (
  //                 row['LawEnforcementTransaction'][0]['Transaction'][0][
  //                   'Response'
  //                 ][4]['Hit'][0]
  //               ) {
  //                 return (
  //                   <tr
  //                     key={idx}
  //                     onClick={() => console.log(row)}
  //                     className={classes.warrantRow}>
  //                     {cols.map((obj, idx) => {
  //                       if (obj.name === 'NormalizedName') {
  //                         return (
  //                           <Fragment key={idx}>
  //                             <td
  //                               className="MuiTableCell-sizeSmall"
  //                               title={obj.text}>
  //                               <span style={{ display: 'block' }}>
  //                                 {obj.text}
  //                               </span>
  //                               <Badge overlap="rectangular"
  //                                 style={{ left: '100px' }}
  //                                 badgeContent={
  //                                   row['LawEnforcementTransaction'][0][
  //                                     'Transaction'
  //                                   ][0]['Response'][4]['Hit'][0]['Banner']
  //                                 }
  //                                 color="error"></Badge>
  //                             </td>
  //                           </Fragment>
  //                         );
  //                       } else {
  //                         return (
  //                           <Fragment key={idx}>
  //                             <td
  //                               className="MuiTableCell-sizeSmall"
  //                               title={obj.text}>
  //                               <span>{obj.text}</span>
  //                             </td>
  //                           </Fragment>
  //                         );
  //                       }
  //                     })}
  //                   </tr>
  //                 );
  //               } else {
  //                 return (
  //                   <tr key={idx} onClick={() => console.log(row)}>
  //                     {cols.map((obj, idx) => {
  //                       return (
  //                         <Fragment key={idx}>
  //                           <td
  //                             className="MuiTableCell-sizeSmall"
  //                             title={obj.text}>
  //                             <span>{obj.text}</span>
  //                           </td>
  //                         </Fragment>
  //                       );
  //                     })}
  //                   </tr>
  //                 );
  //               }
  //             })}
  //           </tbody>
  //         </table>
  //       </div>
  //     </Fragment>
  //   );
  // } else {
  //   return null;
  // }
};

const mapStateToProps = state => ({
  themeMode: state.theme.mode
});

export default connect(mapStateToProps, {})(SearchList);
