import React, { Suspense, Fragment, useState, useEffect } from 'react';
import store from './config/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import Spinner from './global-components/Spinner';
import { AnimatePresence, motion } from 'framer-motion';
import { SnackbarProvider } from 'notistack';
import Notifier from 'global-components/Notifier';
import Notifications from 'react-notification-system-redux';
import { LicenseInfo } from '@material-ui/x-grid';

import * as serviceWorker from './serviceWorker';

import AddToHomescreen from 'react-add-to-homescreen';

import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import auth from '@feathersjs/authentication-client';

import { setWebsocketConnection } from './reducers/WebsocketReducer';
import {
  setUserAuthenticated,
  setUserData,
  newUserLocation,
  setIsPasswordValid,
  newUserLocationForImprovedChannel
} from './reducers/UserReducer';
import { getServerTimeSettings } from './reducers/TimeReducer';

import {
  clearFilter as clearAlertFilters,
  getAlerts
} from './reducers/AlertsReducer';
import {
  getZones,
  getUnitActionCodes,
  getAddresses
} from './reducers/DataReducer';
import { getCodeColors, getDictionay } from './reducers/CodeReducer';
import { getEvents } from './reducers/EventsReducer';
import { getUnits } from './reducers/UnitsReducer';

import { ThemeProvider } from '@material-ui/styles';

import IdleTimerContainer from 'components/IdleTimerContainer';

import { ClimbingBoxLoader } from 'react-spinners';

import { DayTheme, NightTheme } from './theme';

// Layout Blueprints
import { MinimalLayout, MainLayout, PagesLayout } from './layout-blueprints';
import { checkPassExpiration } from 'reducers/UserReducer';

// Pages
import PagesMain from './pages/PagesMain';
import PagesLogin from './pages/PagesLogin';
import PagesSearch from './pages/PagesSearch';
import PagesCitation from './pages/PagesCitation';
import PagesWarning from './pages/PagesWarning';
import PagesAlert from './pages/PagesAlert';
import ResetPassword from 'pages/ResetPassword';
import ForcePasswordReset from 'pages/ForcePasswordReset';
import Loading from 'pages/Loading';
import PageDebugInfo from './pages/PageDebugInfo';

import { getTimeFormatted } from 'reducers/SettingsReducer';
import {
  subscribePermissions,
  unsubscribePermissions,
  getUserPermissions
} from 'reducers/PermissionsReducer';
import { setEnvInfo } from 'reducers/AppInfoReducer';
import { saveNcicCredentials } from 'reducers/NcicReducer';
import Dialogs from 'global-components/CadComponents/Dialogs';
import { useLoadScript } from '@react-google-maps/api';
import {
  setMapError,
  setMapLoaded,
  setUnitsCoordinates
} from 'reducers/MapReducer';
import { getService, joinChannel, leaveChannel } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { useRef } from 'react';
const libraries = (process.env.REACT_APP_GOOGLE_LIBRARIES || '').split(',');

const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const myUnit = store.store.getState().user.userUnit;
      const isUnitSeleteted = store.store.getState().user.isUnitSeleteted;
      if (isAuthenticated === true && myUnit && isUnitSeleteted) {
        return <Component {...props} />;
      } else {
        return (
          <Redirect
            to={{
              pathname: '/Login',
              state: { from: props.location }
            }}
          />
        );
      }
    }}
  />
);

const LoginRoute = ({
  setIsPasswordValid: setIsPasswordValid,
  component: Component,
  isAuthenticated,
  isWarningAccepted,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (
        isAuthenticated === true &&
        store.store.getState().user.userUnit &&
        store.store.getState().user.isUnitSeleteted
      ) {
        return (
          <Redirect
            to={{
              pathname: '/Main',
              state: { from: props.location }
            }}
          />
        );
      } else if (
        isAuthenticated === true &&
        !store.store.getState().user.userUnit
      ) {
        setIsPasswordValid(false);
        return <Component {...props} />;
      } else if (isWarningAccepted) {
        return <Component {...props} />;
      } else {
        return (
          <Redirect
            to={{
              pathname: '/Warning',
              state: { from: props.location }
            }}
          />
        );
      }
    }}
  />
);

const WarningRoute = ({
  component: Component,
  isAuthenticated,
  isWarningAccepted,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated === true ? (
        <Redirect
          to={{
            pathname: '/Main',
            state: { from: props.location }
          }}
        />
      ) : !isWarningAccepted ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/Login',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const Routes = props => {
  const location = useLocation();
  const {
    isWarningAccepted,
    isAuthenticated,
    setWebsocketConnection,
    setUserAuthenticated,
    setUserData,
    setLoggedInUserData,
    notifications,
    newUserLocation,
    clearAlertFilters,
    setIsPasswordValid,
    passExpirationDays,
    showPassChange,
    wsClient,

    getZones,
    getUnitActionCodes,
    getAddresses,
    getAlerts,
    getCodeColors,

    getDictionay,
    getEvents,
    getUnits,
    userUnit,
    isUnitSeleteted,
    userLocation
  } = props;

  const { showSpinner } = props.ui;

  const [getoWatchState, setState] = useState(false);
  const dispatch = useDispatch();
  const coordsServiceRef = useRef(null);
  // console.log('env');
  const envInfo = useSelector(state => state.appInfo.appEnvInfo);
  const { FEATURES_CITATIONS, APP_DEFAULT_STATE, AVL, APP_XGRID_KEY } = envInfo;
  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    // window.indexedDB.databases().then(db => {
    //   console.log('db', db);
    // });

    const updateApp = registration => {
      const ncic = window.localStorage.getItem('ncic');
      window.localStorage.clear();
      if (APP_DEFAULT_STATE === 'MS') {
        window.localStorage.setItem('ncic', ncic);
      }

      window.indexedDB.deleteDatabase('localforage');
      const waitingServiceWorker = registration.waiting;

      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener('statechange', event => {
          if (event.target.state === 'activated') {
            window.location.reload();
          }
        });
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
      }
    };
    const openNotification = registration => {
      const notification = {
        title: `Application Update Available!`,
        message: `New release of CAD MOBILE Application has been released.It is highly recomended to launch the new version!!`,
        position: 'tc',
        level: 'warning',
        autoDismiss: 0,
        action: {
          label: 'LAUNCH!',
          callback: () => updateApp(registration)
        }
      };
      store.store.dispatch(Notifications.success(notification));
    };
    serviceWorker.register({
      onSuccess: () => console.log('service worker registered!'),
      onUpdate: reg => openNotification(reg)
    });
  }, []);
  useEffect(() => {
    if (APP_DEFAULT_STATE === 'MS') {
      if (window.localStorage.getItem('ncic') !== null) {
        const ncicData = JSON.parse(window.localStorage.getItem('ncic'));
        dispatch(saveNcicCredentials(ncicData.rid, ncicData.ori));
      }
    }
  }, [APP_DEFAULT_STATE]);
  React.useEffect(() => {
    if (!wsClient.websocket || !isAuthenticated) return;
    props.checkPassExpiration();
    props.subscribePermissions();
    props.getUserPermissions();
    props.getServerTimeSettings();
    return () => {
      props.unsubscribePermissions();
    };
    // eslint-disable-next-line
  }, [wsClient, isAuthenticated]);

  useEffect(() => {
    dispatch(getTimeFormatted());
  }, [wsClient]);

  useEffect(() => {
    if (isAuthenticated === true) {
      if (AVL) {
        const hasGeolocationSupport = 'geolocation' in navigator;
        const hasWatchPositionSupport =
          'watchPosition' in navigator.geolocation;
        if (hasGeolocationSupport && hasWatchPositionSupport) {
          navigator.geolocation.watchPosition(
            position => {
              const location = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                altitude: position.coords.altitude,
                accuracy: position.coords.accuracy,
                timestamp: new Date()
              };
              newUserLocation(location);
            },
            err => {
              console.log('error', err);
            },
            { enableHighAccuracy: true }
          );

          // if (geoWatch) {
          //   setState(true);
          //   navigator.geolocation.clearWatch(geoWatch);
          // }
        }
      }
    }
  }, [isAuthenticated]);
  React.useEffect(() => {
    async function establishConnection() {
      const client = feathers();
      client.configure(
        socketio(
          io(process.env.REACT_APP_API_URL, {
            transports: ['websocket', 'polling'],
            perMessageDeflate: false,
            timeout: 20000,
            requestTimeout: 20000
          })
        )
      );
      getAppBuildInfoData(client);
      client.configure(auth({ storageKey: 'auth' }));
      try {
        const user = await client.reAuthenticate();
        setWebsocketConnection(client);
        setUserData(user);
        setUserAuthenticated(true);
      } catch (error) {
        if (error.code === 401) {
          setUserAuthenticated(false);
          setUserData(null);
          setWebsocketConnection(client);
        } else {
          console.log(error);
        }
        setWebsocketConnection(client);
      }
      client.io.on('connect', async reason => {
        // console.log('connect');
        // console.log('connect', reason);
        // console.log('reauthenticate');
        getAppBuildInfoData(client);
        try {
          const user = await client.reAuthenticate();
          setWebsocketConnection(client);
          setUserData(user);
          setUserAuthenticated(true);
        } catch (error) {
          if (error.code === 401) {
            setUserAuthenticated(false);
            setUserData(null);
            setWebsocketConnection(client);
          } else {
            console.log(error);
          }
          setWebsocketConnection(client);
        }
      });
      client.io.on('disconnect', reason => {
        //console.log('disconnect');
      });
    }
    establishConnection();
  }, [
    setWebsocketConnection,
    setUserAuthenticated,
    setLoggedInUserData,
    setUserData
  ]);
  React.useEffect(() => {
    if (location.pathname !== '/Alerts') {
      clearAlertFilters();
    }
  }, [clearAlertFilters, location]);
  useEffect(() => {
    if (!userUnit || !userUnit.ptsUnitID) {
      setUserAuthenticated(false);
    }
  }, [userUnit]);

  React.useEffect(() => {
    if (!wsClient.websocket || !isAuthenticated) return;
    getDictionay();
    getCodeColors();
    initCoords();
    getEvents();
    getUnits();
    getAlerts();
    getZones();
    getUnitActionCodes();

    getAddresses();

    // eslint-disable-next-line
  }, [wsClient, isAuthenticated]);

  const initCoords = async () => {
    coordsServiceRef.current = getService('unit-coordinates');
    try {
      const coords = await coordsServiceRef.current.find();
      dispatch(setUnitsCoordinates(coords));
    } catch (err) {
      dispatch(handleError(err));
    }
    joinChannel('unit-coordinates');
    coordsServiceRef.current.on('updated', coords => {
      const userCoords =
        userUnit.ptsUnitID && coords ? coords[userUnit.ptsUnitID] : null;
      if (
        userCoords.lat !== userLocation?.latitude ||
        userCoords.lng !== userLocation?.longitude
      ) {
        dispatch(newUserLocationForImprovedChannel(userCoords));
      }
      dispatch(setUnitsCoordinates(coords));
    });
  };

  const deactivateCoords = () => {
    leaveChannel('unit-coordinates');
    coordsServiceRef.current.off('updated');
  };
  React.useEffect(() => {
    if (APP_XGRID_KEY) {
      LicenseInfo.setLicenseKey(APP_XGRID_KEY);
      // console.log(store.store.getState().appinfo);
    }
  }, [APP_XGRID_KEY]);
  const getAppBuildInfoData = async client => {
    const service = client.service('cdr');
    service.timeout = 30000;
    try {
      const data = await service.find({
        query: {
          Path: 'MOBILE.env'
        }
        //
      });
      dispatch(setEnvInfo(data));
      // console.log('data', data);
      // console.log('window', window);
    } catch (error) {
      console.log(error);
    }
  };
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99,
      width: '100%',
      height: '100%'
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={'#0b55a1'} loading={true} />
          </div>
        </div>
      </Fragment>
    );
  };

  const themeMode = () => {
    let theme = DayTheme;
    if (props.mode === 'night') {
      theme = NightTheme;
    }
    return theme;
  };

  const handleAddToHomescreenClick = () => {
    alert(`
    1. Open Share menu
    2. Tap on "Add to Home Screen" button`);
  };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    libraries
  });
  dispatch(setMapLoaded(isLoaded));
  dispatch(setMapError(loadError));
  return (
    <ThemeProvider theme={themeMode()}>
      {showSpinner && <Spinner />}
      <Dialogs />
      <SnackbarProvider>
        <AddToHomescreen onAddToHomescreenClick={handleAddToHomescreenClick} />
        <Notifier />
        <Notifications notifications={notifications} />
        <IdleTimerContainer />
        <AnimatePresence>
          <Suspense fallback={<SuspenseLoading />}>
            <Switch>
              <Redirect exact from="/" to="/Warning" />
              <Route path={['/Warning']}>
                <MinimalLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <WarningRoute
                        path="/Warning"
                        component={PagesWarning}
                        isAuthenticated={isAuthenticated}
                        isWarningAccepted={isWarningAccepted}
                      />
                    </motion.div>
                  </Switch>
                </MinimalLayout>
              </Route>

              <Route path={['/Login']}>
                <MinimalLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <LoginRoute
                        path="/Login"
                        component={PagesLogin}
                        isAuthenticated={isAuthenticated}
                        isWarningAccepted={isWarningAccepted}
                        setIsPasswordValid={setIsPasswordValid}
                      />
                    </motion.div>
                  </Switch>
                </MinimalLayout>
              </Route>

              <Route path={['/Reset-password/:hash']}>
                <MinimalLayout>
                  <ResetPassword isAuthenticated={isAuthenticated} />
                </MinimalLayout>
              </Route>

              {showPassChange && (
                <Route path={['/']}>
                  <MinimalLayout>
                    <ForcePasswordReset
                      passExpirationDays={passExpirationDays}
                    />
                  </MinimalLayout>
                </Route>
              )}
              {passExpirationDays === null && (
                <Route path={['/']}>
                  <MinimalLayout>
                    <ProtectedRoute
                      component={Loading}
                      isAuthenticated={isAuthenticated}
                    />
                  </MinimalLayout>
                </Route>
              )}
              <Route path={['/debug-info']}>
                <MinimalLayout>
                  <PageDebugInfo
                    component={Loading}
                    isAuthenticated={isAuthenticated}
                  />
                </MinimalLayout>
              </Route>
              <Route path={['/Main']}>
                <MainLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ProtectedRoute
                        path="/Main"
                        component={PagesMain}
                        isAuthenticated={isAuthenticated}
                      />
                    </motion.div>
                  </Switch>
                </MainLayout>
              </Route>

              <Route path={['/Search']}>
                <PagesLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ProtectedRoute
                        path="/Search"
                        component={PagesSearch}
                        isAuthenticated={isAuthenticated}
                      />
                    </motion.div>
                  </Switch>
                </PagesLayout>
              </Route>

              <Route path={['/Alerts']}>
                <PagesLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ProtectedRoute
                        path="/Alerts"
                        component={PagesAlert}
                        isAuthenticated={isAuthenticated}
                      />
                    </motion.div>
                  </Switch>
                </PagesLayout>
              </Route>
              {FEATURES_CITATIONS && FEATURES_CITATIONS == true && (
                <Route path={['/Ecitations']}>
                  <PagesLayout>
                    <Switch location={location} key={location.pathname}>
                      <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        transition={pageTransition}>
                        <ProtectedRoute
                          path="/Ecitations/:type"
                          component={PagesCitation}
                          isAuthenticated={isAuthenticated}
                        />
                      </motion.div>
                    </Switch>
                  </PagesLayout>
                </Route>
              )}
            </Switch>
          </Suspense>
        </AnimatePresence>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = state => ({
  network: state.offline,
  mode: state.theme.mode,
  notifications: state.notifications,
  isAuthenticated: state.user.isAuthenticated,
  userUnit: state.user.userUnit,
  isWarningAccepted: state.user.isWarningAccepted,
  passExpirationDays: state.user.passExpirationDays,
  showPassChange: state.user.showPassChange,
  isUnitSeleteted: state.user.isUnitSeleteted,
  ui: state.ui,
  wsClient: state.websocket,
  userLocation: state.user.location
});

export default connect(mapStateToProps, {
  setWebsocketConnection,
  setUserAuthenticated,
  setUserData,
  newUserLocation,
  clearAlertFilters,
  setIsPasswordValid,
  checkPassExpiration,
  subscribePermissions,
  unsubscribePermissions,
  getUserPermissions,
  getServerTimeSettings,

  getZones,
  getUnitActionCodes,
  getAddresses,
  getAlerts,
  getCodeColors,

  getEvents,
  getUnits,
  getDictionay
})(Routes);
