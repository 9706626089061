import React, { useState, useEffect, useRef, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useDispatch, useSelector } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Grid
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {
  clearPersonForm,
  clearResults,
  handleDateChange,
  handleFilterChange,
  handleCheckboxChange
} from '../../../../../reducers/PersonSearchReducer';
import { Autocomplete } from '@material-ui/lab';
import { getAccessPermission } from 'reducers/PermissionsReducer';
import saveSearchSuggestions from 'utils/saveSearchSuggestions';
import SearchHistory from 'global-components/SearchHistory';
import { showAddPerson } from 'reducers/DialogsReducer';

const useStyles = makeStyles(theme => ({
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    marginBottom: '1em'
  },
  filters: {
    padding: 20,
    marginBottom: 20
  },
  filterAction: {
    textAlign: 'right',
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  filter: {
    margin: '0 10px 10px',
    width: '200px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  datePicker: {
    marginTop: 10,
    width: 200,
    margin: '16px 10px 10px',
    paddingTop: 6,
    '& input::-webkit-input-placeholder': {
      fontSize: 14,
      opacity: 0.6
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  select: {
    margin: '0 10px 10px',
    width: '200px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  selectInput: {
    fontSize: '16px'
  },
  iconButton: {
    padding: 10
  },
  reset: {
    marginRight: 20
  },
  searchBtnWrap: {
    display: 'inline-block',
    position: 'relative',
    paddingRight: '1em'
  },
  buttonProgress: {
    color: '#B00927',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const SearchFormLocal = props => {
  const classes = useStyles();
  const [filters, setFilters] = useState(props.formdefinition.display);
  const { loaded } = props.data;
  const [loading, setLoading] = React.useState(false);
  const filterRefs = {};
  const {
    clearPersonForm,
    clearResults,
    handleDateChange,
    handleFilterChange,
    wsClient,
    pickerValues,
    filterValues,
    rid,
    ori
  } = props;
  const persmissions = useSelector(state => state.permissions.globals);
  const [canExecNcic, setCanExecNcic] = useState('');
  const codes = {
    sex: props.sexCodes,
    race: props.raceCodes,
    state: props.statesCodes
  };
  const dispatch = useDispatch();
  Object.values(filters).forEach(({ dbName }) => {
    // eslint-disable-next-line
    filterRefs[dbName] = useRef();
  });

  React.useEffect(() => {
    setCanExecNcic(getAccessPermission('globals', 'Execute NCIC Queries'));
  }, [wsClient, persmissions]);
  useEffect(() => {
    loaded && loading && setLoading(false);
  }, [loaded, loading]);

  const submitForm = e => {
    e.preventDefault();
    const { orderBy, rowsPerPage, orderDirection } = props.data;
    const newFilterValues = { ...filterValues };
    Object.entries(pickerValues).forEach(([key, val]) => {
      newFilterValues[key] = {
        type: 'date',
        from: moment(val.from).format('YYYY-MM-DD') + ' 00:00:00.0',
        to: moment(val.to).format('YYYY-MM-DD') + ' 23:59:59.0'
      };
    });

    // process suggestion
    saveSearchSuggestions('personLocal', newFilterValues);

    const options = {
      pageNo: 0,
      orderBy,
      rowsPerPage,
      orderDirection,
      filters: newFilterValues
    };
    props.getPersonsData(options);
    setLoading(true);
  };

  const getTextFilter = (filter, idx) => (
    <TextField
      type="search"
      size="small"
      variant="outlined"
      autoFocus={filter.dbName == 'OLN.Number' ? true : false}
      label={filter.label}
      key={idx}
      className={classes.filter}
      value={
        filterValues[filter.dbName] ? filterValues[filter.dbName].search : ''
      }
      onChange={e => handleFilterChange(e, filter)}
      inputRef={filterRefs[filter.dbName]}
    />
  );

  const getDateFilter = (filter, idx) => {
    const value = pickerValues[filter.dbName]
      ? pickerValues[filter.dbName]
      : { from: null, to: null };
    const minToValue = value.from ? value.from : undefined;
    const maxFromValue = value.to ? value.to : new Date();
    return (
      <Fragment key={idx}>
        <KeyboardDatePicker
          className={classes.datePicker}
          clearable
          value={value.from}
          placeholder={`${filter.label} From`}
          onChange={date => handleDateChange(date, filter.dbName, 'from')}
          maxDate={maxFromValue}
          format="MM/DD/yyyy"
        />
        <KeyboardDatePicker
          className={classes.datePicker}
          clearable
          value={value.to}
          placeholder={`${filter.label} To`}
          onChange={date => handleDateChange(date, filter.dbName, 'to')}
          minDate={minToValue}
          maxDate={new Date()}
          format="MM/DD/yyyy"
        />
      </Fragment>
    );
  };

  const getDateSingleFilter = (filter, idx) => {
    const value = pickerValues[filter.dbName]
      ? pickerValues[filter.dbName]
      : { from: null, to: null };
    return (
      <span key={idx}>
        <KeyboardDatePicker
          style={{ marginTop: 0, paddingTop: 0 }}
          key={idx}
          variant="inline"
          inputVariant="outlined"
          size="small"
          className={classes.datePicker}
          value={value.from}
          placeholder={filter.label}
          onChange={date => handleDateChange(date, filter.dbName, 'single')}
          format="MM/DD/yyyy"
        />
      </span>
    );
  };

  const getSelectFilter = (filter, idx) => (
    <Autocomplete
      variant="outlined"
      size="small"
      className={classes.select}
      key={idx}
      autoHighlight
      autoSelect
      getOptionSelected={() => true}
      size="small"
      value={{
        Code: filterValues[filter.dbName]
          ? filterValues[filter.dbName].search
          : ''
      }}
      onChange={(event, newValue) => {
        handleFilterChange(newValue?.Code || '', filter);
      }}
      options={[
        { Code: '', Description: '' },
        ...codes[filter.label.toLowerCase()]
      ]}
      getOptionLabel={option => option.Code || ''}
      renderOption={option =>
        filter.label.toLowerCase() == 'state' ? (
          <>
            <b>{option.Code || ''} </b> - {option.Description || ''}
          </>
        ) : (
          option.Code || '-'
        )
      }
      renderInput={params => (
        <TextField {...params} variant="outlined" label={filter.label} />
      )}
    />
    // <FormControl
    //   variant="outlined"
    //   size="small"
    //   className={classes.select}
    //   key={idx}>
    //   <InputLabel id={`filter-${filter.dbName}`}>{filter.label}</InputLabel>
    //   <Select
    //     className={classes.selectInput}
    //     labelId={`filter-${filter.dbName}`}
    //     value={
    //       filterValues[filter.dbName] ? filterValues[filter.dbName].search : ''
    //     }
    //     onChange={e => handleFilterChange(e, filter)}>
    //     <MenuItem value=""> </MenuItem>

    //     {codes[filter.label.toLowerCase()].map((code, idx) => (
    //       <MenuItem value={code.Code} key={idx}>
    //         {code.Code}
    //       </MenuItem>
    //     ))}
    //   </Select>
    // </FormControl>
  );

  const clearForm = () => {
    Object.values(filterRefs).forEach(({ current }) => {
      if (current) current.value = '';
    });
    clearPersonForm();
  };

  return (
    <div className={classes.searchWrap}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <form onSubmit={submitForm}>
          <Grid container spacing={2}>
            {Object.values(filters).map((filter, idx) => {
              switch (filter.type) {
                case 'date':
                  return getDateFilter(filter, idx);
                case 'date-single':
                  return getDateSingleFilter(filter, idx);
                case 'select':
                  return getSelectFilter(filter, idx);
                default:
                  return getTextFilter(filter, idx);
              }
            })}
          </Grid>
          <div className={classes.filterAction}>
            <SearchHistory name="personLocal" loading={loading} />

            {canExecNcic && (
              <FormControlLabel
                style={{ verticalAlign: 'top', fontSize: 22 }}
                control={
                  <Checkbox
                    disabled={ori === ''}
                    checked={props.ncicCheckbox}
                    onChange={e => props.handleCheckboxChange(e)}
                    name="ncic"
                    color="primary"
                    style={{ verticalAlign: 'top', fontSize: 22 }}
                  />
                }
                label="NCIC/State"
              />
            )}
            <Button
              size="large"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => {
                dispatch(showAddPerson(true));
              }}
              className={classes.button}>
              Add Person
            </Button>
            <Button
              size="large"
              startIcon={<DeleteOutlineIcon />}
              onClick={() => clearResults()}
              className={classes.reset}>
              Clear Results
            </Button>
            <Button
              size="large"
              startIcon={<DeleteOutlineIcon />}
              onClick={clearForm}
              className={classes.reset}
              disabled={loading}>
              Clear Form
            </Button>
            <div className={classes.searchBtnWrap}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<SearchIcon />}
                type="submit"
                disabled={loading}>
                Search
              </Button>
              {loading && (
                <CircularProgress
                  color="primary"
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
        </form>
      </MuiPickersUtilsProvider>
    </div>
  );
};

const mapStateToProps = state => ({
  wsClient: state.websocket,
  themeMode: state.theme.mode,
  sexCodes: state.codes.dictionary.sexCodes || [],
  raceCodes: state.codes.dictionary.raceCodes || [],
  statesCodes: state.codes.dictionary.statesCodes || [],
  pickerValues: state.personSearch.personForm.pickers,
  filterValues: state.personSearch.personForm.filters,
  ncicCheckbox: state.personSearch.personForm.ncic,
  rid: state.ncic.rid,
  ori: state.ncic.ori
});

export default connect(mapStateToProps, {
  clearPersonForm,
  clearResults,
  handleDateChange,
  handleFilterChange,
  handleCheckboxChange
})(SearchFormLocal);
