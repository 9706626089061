import store from '../config/configureStore';
import { setUserUnit } from './UserReducer';
import { getUnits } from './UnitsReducer';
import { getEvents, handleCaseIdCreation } from './EventsReducer';

const IS_OPEN = 'UNIT_STATUS/IS_DIALOG_OPEN';
const SET_NOTIFY = 'UNIT_STATUS/SET_NOTIFY';
const SET_NOTIFICATION = 'UNIT_STATUS/SET_NOTIFICATION';

export const toggleDialog = () => {
  const _open = store.store.getState().unitStatus.isOpen;
  return dispatch => {
    dispatch({ type: IS_OPEN, payload: !_open });
  };
};

export const setNotify = notify => {
  return dispatch => {
    dispatch({ type: SET_NOTIFY, payload: notify });
  };
};

export const setNotification = notification => {
  return dispatch => {
    dispatch({ type: SET_NOTIFICATION, payload: notification });
  };
};

export const unitStatusChange = (
  status,
  event,
  location,
  _plate,
  _plateState,
  _oln,
  _olnState,
  _notes,
  tripNumber = null,
  modifiers = null,
  mileage = null,
  inserviceModeClick = false,
  destinationID = null
) => {
  const client = store.store.getState().websocket.websocket;
  const myUnit = store.store.getState().user.userUnit;
  const _open = store.store.getState().unitStatus.isOpen;
  return async dispatch => {
    const service = client.service('cmplx-units');
    service.timeout = 20000;
    if (event) {
      handleCaseIdCreation(event, status);
    }
    await service.update(myUnit.ptsUnitID, {
      status: status,
      event: event || '',
      unit: myUnit,
      location: location || '',
      plate: _plate,
      plateState: _plateState,
      oln: _oln,
      olnState: _olnState,
      statusNotes: _notes,
      tripNumber: tripNumber,
      modifiers: modifiers,
      mileage: mileage === '' ? null : mileage,
      destination: destinationID ? destinationID : null
    });

    const alertsService = client.service('cadalerts');
    let notify = false;
    let notification = {};
    alertsService.timeout = 20000;

    if (_plate !== null) {
      let data = await alertsService.find({
        query: {
          Plate: _plate,
          $select: [
            'AgencyID',
            'AlertType',
            'Beginning',
            'Ending',
            'OLN',
            'OLNState',
            'Plate',
            'PlateState',
            'Notification'
          ]
        }
      });

      if (data.data.length > 0) {
        notify = true;
        notification = data.data[0];
      }
    }
    if (_oln !== null && notify === false) {
      let data = await alertsService.find({
        query: {
          OLN: _oln,
          $select: [
            'AgencyID',
            'AlertType',
            'Beginning',
            'Ending',
            'OLN',
            'OLNState',
            'Plate',
            'PlateState',
            'Notification'
          ]
        }
      });

      if (data.data.length > 0) {
        notify = true;
        notification = data.data[0];
      }
    }

    if (notify) {
      dispatch(setNotification(notification));
      dispatch(setNotify(true));
    } else {
      dispatch(setNotify(false));
    }

    dispatch(getEvents());
    dispatch(getUnits());
    dispatch(setUserUnit());
    if (inserviceModeClick) dispatch({ type: IS_OPEN, payload: false });
    else dispatch({ type: IS_OPEN, payload: !_open });
  };
};

export default function reducer(
  state = {
    isOpen: false,
    notify: false,
    notification: {}
  },
  action
) {
  switch (action.type) {
    case IS_OPEN:
      return {
        ...state,
        isOpen: action.payload
      };
    case SET_NOTIFY:
      return {
        ...state,
        notify: action.payload
      };
    case SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload
      };
    default:
      break;
  }
  return state;
}
