import store from '../config/configureStore';
import moment from 'moment';
import { filter } from 'underscore';

export const SET_ALERTS = 'ALERTS/SET_ALERTS';
export const SET_ALERT_TYPES = 'ALERTS/SET_ALERT_TYPES';
export const SET_ALERTS_READ = 'ALERTS/SET_ALERTS_READ';
export const SET_NEW_ALERT = 'ALERTS/SET_NEW_ALERT';
export const UPDATE_ALERT = 'ALERTS/UPDATE_ALERT';
export const DELETE_ALERT = 'ALERTS/DELETE_ALERT';
export const GET_PAGINATED_ALERTS = 'ALERTS/GET_PAGINATED_ALERTS';
export const SET_FILTERS = 'ALERTS/SET_FILTERS';

export const getAlerts = () => {
  const client = store.store.getState().websocket.websocket;
  const userData = store.store.getState().user.userData;
  let ptsUserID = userData?.user?.ptsUserID;
  return async dispatch => {
    try {
      const service = client.service('alerts');
      service.timeout = 90000;
      const alerts = await service.find({
        query: {
          ptsUserID: ptsUserID
        }
      });

      dispatch({ type: SET_ALERTS, payload: alerts });
    } catch (error) {
      console.log(error);
    }
  };
};

// not needed, for debug purpose
export const getUserAlerts = () => {
  const client = store.store.getState().websocket.websocket;

  return async dispatch => {
    try {
      const service = client.service('caduseralerts');
      service.timeout = 20000;
      const userAlerts = await service.find({});
      // here we need to dispatch if needed
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAlertTypes = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codealerttypes');
      service.timeout = 20000;
      const alertTypes = await service.find({
        query: {
          isActive: {
            $ne: false
          },
          $select: ['Code', 'Description', 'Flags']
        }
      });
      dispatch({ type: SET_ALERT_TYPES, payload: alertTypes.data });
    } catch (error) {
      console.log(error);
    }
  };
};

export const setAlerts = alerts => {
  return async dispatch => {
    dispatch({ type: SET_ALERTS, payload: alerts });
  };
};

export const setNewAlert = alert => {
  return async dispatch => {
    dispatch({ type: SET_NEW_ALERT, payload: alert });
  };
};

export const updateAlert = alert => {
  return async dispatch => {
    dispatch({ type: UPDATE_ALERT, payload: alert });
  };
};

export const deleteAlert = alert => {
  return async dispatch => {
    dispatch({ type: DELETE_ALERT, payload: alert });
  };
};

export const setAllAlertsRead = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('cad-user-alerts-read');
      service.timeout = 90000;
      const alerts = await service.create({ ptsAlertID: 'all' });
      dispatch(getAlerts());
    } catch (error) {
      console.log(error);
    }
  };
};

export const setAlertRead = id => {
  const client = store.store.getState().websocket.websocket;
  const userData = store.store.getState().user.userData;
  let ptsUserID = userData.user.ptsUserID;
  let Username = userData.user.Username;
  return async dispatch => {
    try {
      const service = client.service('caduseralerts');
      service.timeout = 90000;
      let data = await service.create({
        ptsUserID: ptsUserID,
        ptsAlertID: id,
        IsSealed: 0,
        Flags: 'CAD' // this line needed to be deleted at prod
      });
      dispatch(getAlerts());
      // dispatch({ type: SET_ALERTS_READ, payload: id });
    } catch (error) {
      console.log(error);
    }
  };
};

export const getPaginatedAlerts = (currentPage, rowsperPage) => {
  const option = { currentPage, rowsperPage };
  return async dispatch => {
    dispatch({ type: GET_PAGINATED_ALERTS, payload: option });
  };
};

function filterData(arr, filterOptions, notificationAlertID) {
  var newFilter = false;
  var filteredArray = [];

  for (var key in filterOptions)
    if (filterOptions[key] !== '') newFilter = true;

  if (newFilter) {
    filteredArray = arr.filter(alert => {
      var flag = true;
      for (var key in filterOptions) {
        if (filterOptions[key] !== '') {
          if (
            key === 'Person' ||
            key === 'Vehicle' ||
            key === 'Plate' ||
            key === 'OLN'
          ) {
            if (alert[key]) {
              if (
                alert[key]
                  .toLowerCase()
                  .indexOf(filterOptions[key].toLowerCase()) < 0
              ) {
                flag = false;
              }
            } else {
              flag = false;
            }
          } else if (key === 'read') {
            if (filterOptions[key] === 'read' && !alert['ReadDate'])
              // chanded 'read' to 'ReadDate'
              flag = false;
            if (filterOptions[key] === 'unread' && alert['ReadDate'])
              flag = false;
          } else if (key === 'notification') {
            if (filterOptions[key] === true) {
              if (alert['ptsAlertID'] !== notificationAlertID) {
                flag = false;
              }
            }
          } else {
            if (alert[key] !== filterOptions[key]) {
              flag = false;
            }
          }
        }
      }
      return flag;
    });
  }

  if (!newFilter) return arr;
  return filteredArray;
}

export const setFilter = filters => {
  return async dispatch => {
    dispatch({
      type: SET_FILTERS,
      payload: filters
    });
  };
};

export const clearFilter = (status = null) => {
  var filters = {};
  filters.Plate = '';
  filters.PlateState = '';
  filters.OLN = '';
  filters.OLNState = '';
  filters.Person = '';
  filters.Vehicle = '';
  filters.AlertType = '';
  filters.read = 'unread';
  filters.notification = status ? status : false;

  return async dispatch => {
    dispatch({
      type: SET_FILTERS,
      payload: filters
    });
  };
};

export default function reducer(
  state = {
    filters: {
      Plate: '',
      PlateState: '',
      OLN: '',
      OLNState: '',
      Person: '',
      Vehicle: '',
      AlertType: '',
      read: 'unread',
      notification: false
    },
    alerts: [],
    total: 0,
    loading: true,
    paginatedAlerts: [],
    rowsperPage: 9,
    currentPage: 0,
    alertTypes: [],
    unreadAlertsCount: 0,
    notificationAlertID: '',
    isNewNotificationAvailable: false
  },
  action
) {
  switch (action.type) {
    case SET_ALERTS:
      action.payload.forEach(element => {
        if (state.total !== 0) {
          for (var i = 0; i < state.alerts.length; ++i) {
            if (element.ptsAlertID === state.alerts[i].ptsAlertID) {
              element.read = state.alerts[i].read;
              break;
            } else {
              element.read = false;
            }
          }
        } else {
          element.read = false;
        }
      });

      if (state.currentPage === 0) {
        const upperLimit = (state.currentPage + 1) * state.rowsperPage;
        state.paginatedAlerts = filterData(
          action.payload,
          state.filters,
          state.notificationAlertID
        ).slice(upperLimit - state.rowsperPage, upperLimit);
      }

      return {
        ...state,
        unreadAlertsCount: action.payload.filter(alert => !alert.ReadDate)
          .length,
        total: action.payload.length,
        loading: false,
        alerts: action.payload
      };

    case SET_ALERTS_READ:
      let markReadPaginatedAlerts = state.paginatedAlerts;
      const markReadUpperLimit = (state.currentPage + 1) * state.rowsperPage;
      state.alerts.forEach(element => {
        if (element.ptsAlertID === action.payload) element.read = true;
      });

      markReadPaginatedAlerts = filterData(
        state.alerts,
        state.filters,
        state.notificationAlertID
      ).slice(0, markReadUpperLimit);

      return {
        ...state,
        unreadAlertsCount: state.unreadAlertsCount - 1,
        paginatedAlerts: markReadPaginatedAlerts
      };
    case SET_NEW_ALERT:
      // eslint-disable-next-line no-case-declarations
      let newPaginatedAlerts = state.paginatedAlerts;
      // eslint-disable-next-line no-case-declarations
      const upperLimit = (state.currentPage + 1) * state.rowsperPage;
      action.payload.read = false;
      state.alerts.unshift(action.payload);

      newPaginatedAlerts = filterData(
        state.alerts,
        state.filters,
        action.payload.ptsAlertID
      ).slice(0, upperLimit);

      return {
        ...state,
        unreadAlertsCount: newPaginatedAlerts.length,
        total: state.total + 1,
        paginatedAlerts: newPaginatedAlerts,
        notificationAlertID: action.payload.ptsAlertID
      };
    case UPDATE_ALERT:
      // eslint-disable-next-line no-case-declarations
      let updatePaginatedAlerts = state.paginatedAlerts;
      // eslint-disable-next-line no-case-declarations
      const updateUpperLimit = (state.currentPage + 1) * state.rowsperPage;
      action.payload.read = false;
      // eslint-disable-next-line no-case-declarations
      let updateAlerts = state.alerts.map(alert =>
        alert.ptsAlertID === action.payload.ptsAlertID ? action.payload : alert
      );

      updatePaginatedAlerts = filterData(
        updateAlerts,
        state.filters,
        action.payload.ptsAlertID
      ).slice(0, updateUpperLimit);

      return {
        ...state,
        unreadAlertsCount: updatePaginatedAlerts.length,
        paginatedAlerts: updatePaginatedAlerts,
        alerts: updateAlerts,
        notificationAlertID: action.payload.ptsAlertID
      };
    case DELETE_ALERT:
      // eslint-disable-next-line no-case-declarations
      let deletePaginatedAlerts = state.paginatedAlerts;
      // eslint-disable-next-line no-case-declarations
      const deleteUpperLimit = (state.currentPage + 1) * state.rowsperPage;
      // eslint-disable-next-line no-case-declarations
      let deleteAlerts = state.alerts.filter(
        alert => alert.ptsAlertID !== action.payload.ptsAlertID
      );
      deletePaginatedAlerts = filterData(
        deleteAlerts,
        state.filters,
        action.payload.ptsAlertID
      ).slice(0, deleteUpperLimit);

      return {
        ...state,
        total: state.total - 1,
        unreadAlertsCount: deleteAlerts.filter(alert => alert.read === false)
          .length,
        paginatedAlerts: deletePaginatedAlerts,
        alerts: deleteAlerts
      };
    case GET_PAGINATED_ALERTS:
      // eslint-disable-next-line no-case-declarations
      let nextPaginatedData;
      // eslint-disable-next-line no-case-declarations
      const upperLimitCard =
        (action.payload.currentPage + 1) * action.payload.rowsperPage;

      if (
        state.filters.notification === true &&
        state.isNewNotificationAvailable
      ) {
        state.paginatedAlerts = filterData(
          state.alerts,
          state.filters,
          state.notificationAlertID
        );
      } else {
        nextPaginatedData = filterData(
          state.alerts,
          state.filters,
          state.notificationAlertID
        ).slice(upperLimitCard - action.payload.rowsperPage, upperLimitCard);

        if (!state.paginatedAlerts || action.payload.currentPage === 0) {
          state.paginatedAlerts = nextPaginatedData;
        } else {
          state.paginatedAlerts = [
            ...state.paginatedAlerts,
            ...nextPaginatedData
          ];
        }
      }

      return {
        ...state,
        currentPage: action.payload.currentPage,
        rowsperPage: action.payload.rowsperPage
      };
    case SET_FILTERS:
      return {
        ...state,
        isNewNotificationAvailable: action.payload.notification,
        filters: action.payload
      };
    case SET_ALERT_TYPES: {
      const types = [...state.alertTypes];

      action.payload.forEach((item, index) => {
        const itemIndex = types.findIndex(type => type.Code === item.Code);

        if (itemIndex !== -1) {
          types[itemIndex] = { ...types[itemIndex], ...item };
        } else {
          types.push(item);
        }
      });

      return {
        ...state,
        alertTypes: [...types]
      };
    }

    default:
      break;
  }
  return state;
}
