import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import AgencyIcon from '../../../Map/AgencyIcon';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  makeStyles,
  TablePagination,
  Tooltip,
  useTheme
} from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setEventsDrawerMobileState } from 'reducers/UiReducer';
import { setEventInfowindow } from '../../../../reducers/MapReducer';
import {
  findClosedEvents,
  openShowDetails
} from 'reducers/ClosedEventSearchReducer';
import Spinner from 'global-components/Spinner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Warrants from '..';

import { findWarrants } from 'reducers/WarrantsReducer';
import { displayDate } from 'reducers/TimeReducer';
const stylesDay = {
  spinner: {
    background: 'rgba(255, 255, 255, 0.5)'
  }
};

const stylesNight = {
  spinner: {
    background: 'rgba(0, 0, 0, 0.5)'
  }
};

const styles = themeMode => {
  return themeMode === 'day' ? stylesDay : stylesNight;
};

const useStyles = makeStyles(theme => ({
  tableWrap: {
    overflow: 'auto',
    margin: '0 auto',
    position: 'relative'
  },
  header: {
    cursor: 'pointer',
    fontSize: '2em'
  },
  headerItem: {
    fontSize: '1em !important',
    background: '#0b55a1 !important',
    color: '#fff !important'
  },
  tableCell: {},
  tableRow: {
    color: '#000 !important',
    '&:hover': {
      color: '#0b55a1 !important',
      backgroundColor: '#B6C4D2 !important'
    }
  },
  spinner: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      color: '#999'
    }
  },
  warrantRow: {
    backgroundColor: theme.palette.error.main
  }
}));
const WarrantCard = props => {
  const classes = useStyles();
  const theme = useTheme();
  const { data, config, themeMode } = props;
  const { orderBy, rowsPerPage, orderDirection, loading, filters, rows } = data;
  const [numberOfRows, setNumberOfRows] = useState(rowsPerPage);
  const dispatch = useDispatch();

  const handleOnEventClick = event => {
    dispatch(openShowDetails(event));
    // dispatch(setEventsDrawerMobileState(false));
    // dispatch(setEventInfowindow(true, event));
  };
  const handlePageChange = (ev, pageNo) => {
    let options = {
      pageNo,
      filters,
      orderBy,
      rowsPerPage,
      orderDirection
    };
    dispatch(findWarrants(options));
  };

  const handleChangeRowsPerPage = (ev, info) => {
    setNumberOfRows(info.props.value);
    let options = {
      pageNo: 0,
      filters,
      orderBy,
      rowsPerPage: info.props.value,
      orderDirection
    };
    dispatch(findWarrants(options));
  };
  // console.log('warrants', rows);
  return (
    <Fragment>
      <Grid
        container
        spacing={2}
        className="p-1"
        style={{ backgroundColor: 'none' }}>
        {rows.length > 0 &&
          rows.map((row, idx) => (
            <Grid item xs={12} md={6} lg={4} key={idx}>
              <Card
                className="card-box mb-4    py-3 px-1"
                style={{ height: '400px' }}>
                <div className="card-tr-actions">
                  <div>
                    {row.IsActive ? (
                      <div className="badge badge-success badge-pill">
                        <span>ACTIVE</span>
                      </div>
                    ) : (
                      <div
                        sx={{ bgcolor: 'text.secondary' }}
                        className="badge  badge-pill">
                        <span>DEACTIVE</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex  px-3 mb-1">
                  <div
                    className="avatar-icon-wrapper rounded mr-3"
                    style={{ textAlign: 'center' }}>
                    <Tooltip arrow title={row.IssueAgencyID || 'Unknown'}>
                      <div
                        style={{
                          cursor: 'pointer',

                          padding: '10px'
                        }}>
                        <div>
                          <AssignmentIcon fontSize="large" />
                        </div>
                      </div>
                    </Tooltip>
                    <h6>{row.IssueAgencyID}</h6>
                  </div>

                  <div className="w-100">
                    <p
                      className="font-weight-bold font-size-lg mb-0"
                      title="...">
                      {row.FullName || ''}
                    </p>
                    <p className="text-black-70 mb-0">
                      {row.FullAddress || ''}
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}>
                      <p className="text-black-70 mb-1">
                        DOB:{displayDate(row.DOB) || ''}
                      </p>
                      <p className="text-black-70 mb-1">SSN:{row.SSN || ''}</p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}>
                      <p className="text-black-70 align-left">
                        OLN State: : {row.OLNState || ''}
                      </p>
                      <p className="text-black-70 align-left">
                        OLN: : {row.OLNNumber || ''}
                      </p>
                    </div>
                  </div>
                </div>
                <Divider className="my-2" />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px 8px'
                  }}>
                  <p>
                    <b>NUMBER:</b> {row.WarrantID || ''}
                  </p>
                  <p>
                    <b>ISSUED:</b> {displayDate(row.IssuedDate) || ''}
                  </p>
                  <p>
                    <b>BOND:</b> {row.BondAmount || ''}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px 8px'
                  }}>
                  <p>
                    <b>Disposition:</b> {row.DispositionDate || ''}
                  </p>
                  <p>
                    <b>Disposition Date:</b>
                    {displayDate(row.DispositionDate) || ''}
                  </p>
                  <p></p>
                </div>
                <p
                  className="font-weight-bold font-size-md mb-0 px-2"
                  title="...">
                  Offenses
                </p>
                <Divider className="my-1" />
                <div
                  className="font-size-sm px-2 rounded-sm"
                  style={{ maxHeight: '200px', overflow: 'auto' }}>
                  <List className="py-0">
                    <ListItem button className="border-0 align-box-row px-0">
                      <div className="align-box-row w-100">
                        <div className="d-flex justify-content-between w-100">
                          <div className=" d-block">
                            {row.OffenseDescription}
                          </div>
                          <div className="mr-3">
                            <div className="badge badge-warning badge-pill">
                              Main
                            </div>
                          </div>
                        </div>
                      </div>
                    </ListItem>
                    <div className="divider mt-1 mb-1" />
                    {row.AdditionalOffenses &&
                      JSON.parse(row.AdditionalOffenses).map((offese, idxx) => (
                        <Fragment key={idxx}>
                          <ListItem className="px-0">
                            {offese.Description}
                          </ListItem>
                          <Divider />
                        </Fragment>
                      ))}
                  </List>
                </div>
              </Card>
            </Grid>
          ))}
      </Grid>

      <TablePagination
        rowsPerPageOptions={config.pageSizeOptions}
        component="div"
        count={data.count}
        rowsPerPage={numberOfRows}
        page={data.pageNo}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {loading && <Spinner />}
    </Fragment>
  );
};

export default WarrantCard;
