import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import Spinner from '../../../../global-components/Spinner';

import clsx from 'clsx';

import { Badge, Avatar } from '@material-ui/core';

import {
  findResultPerson,
  findResultVehicle
} from '../../../../reducers/NcicReducer';

const stylesDay = {
  spinner: {
    background: 'rgba(255, 255, 255, 0.5)'
  }
};

const stylesNight = {
  spinner: {
    background: 'rgba(0, 0, 0, 0.5)'
  }
};

const styles = themeMode => {
  return themeMode === 'day' ? stylesDay : stylesNight;
};

const useStyles = makeStyles(theme => ({
  tableWrap: {
    overflow: 'auto',
    margin: '0 auto',
    position: 'relative'
  },
  header: {
    cursor: 'pointer',
    fontSize: '2em'
  },
  headerItem: {
    fontSize: '1em !important'
  },
  tableCell: {},
  spinner: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      color: '#999'
    }
  },
  warrantRow: {
    backgroundColor: theme.palette.error.main
  }
}));

const Badges = props => {
  if (props.ncicData !== null && props.ncicData.warnings.length > 0) {
    return props.ncicData.warnings.map((item, id) => {
      if (item.Detected > 0) {
        return (
          <div style={{ marginBottom: '10px' }}>
            <Badge
              overlap="rectangular"
              key={id}
              style={{ left: '45px' }}
              className="customBagde"
              badgeContent={item.Banner ? item.Banner.toUpperCase() : ''}
              color="error"></Badge>
          </div>
        );
      } else {
        return null;
      }
    });
  } else {
    return null;
  }
};

const Table = props => {
  const classes = useStyles();
  const { data, config, sort, themeMode, origin } = props;

  const { orderBy, rowsPerPage, orderDirection, filters, loading } = data;
  const [headCells, setHeadCells] = useState(getHeadCells(data.rows, config));

  useEffect(() => {
    setHeadCells(getHeadCells(data.rows, config));
  }, [config, data]);

  const handlePageChange = (ev, pageNo) => {
    let options = {
      pageNo,
      filters,
      orderBy,
      rowsPerPage,
      orderDirection
    };
    if (props.ncicQueryId === null) {
      props.getData(options);
    } else {
      options.QueryID = props.ncicQueryId;
      props.getResultsData(options);
    }
  };

  const handleChangeRowsPerPage = (ev, info) => {
    const rowsPerPage = parseInt(info.props.value);
    let options = {
      pageNo: 0,
      filters,
      orderBy,
      rowsPerPage,
      orderDirection
    };
    if (props.ncicQueryId === null) {
      props.getData(options);
    } else {
      options.QueryID = props.ncicQueryId;
      props.getResultsData(options);
    }
  };

  const handleRowClick = row => {
    if (row.QueryId) {
      if (origin == 'vehicleSearch' && props.vehicleInfo.QueryId) {
        props.findResultVehicle(props.vehicleInfo);
      }
      props.findResultPerson(row);
    } else {
      props.rowClick(row);
    }
  };

  const formatDate = (date, origin = '') => {
    if (date.length < 8) {
      return date.split('-')[1];
    }
    if (date?.toString().includes('-') || date?.toString().includes('/')) {
      return moment(date).format('MM/DD/YYYY');
    }
    return moment(date, 'YYYYMMDD').format('MM/DD/YYYY');
  };

  return (
    <Fragment>
      <div className="table-responsive">
        <table
          className={clsx(
            'table',
            'table-hover',
            themeMode === 'night' && 'table-pts-night',
            themeMode === 'day' && 'table-pts-day',
            'text-nowrap',
            'mb-0'
          )}>
          <thead>
            <tr>
              {headCells.map((row, idx) => {
                if (row.isDisplayed === true) {
                  return (
                    <Fragment key={row.label}>
                      <th
                        className={classes.headerItem}
                        // onClick={() => sort(row.dbName)}
                        title={row.title}>
                        <span>
                          {row.label}
                          {row.dbName === orderBy &&
                            orderDirection === 'ASC' && <ArrowDownwardIcon />}
                          {row.dbName === orderBy &&
                            orderDirection === 'DESC' && <ArrowUpwardIcon />}
                        </span>
                      </th>
                    </Fragment>
                  );
                }
              })}
            </tr>
          </thead>
          <tbody>
            {data.rows.map((row, idx) => {
              let cols = [];
              Object.entries(row).forEach(([key, val]) => {
                if (key !== 'key') {
                  if (config.display[key]) {
                    const dataType = config.display[key].type;
                    const dataName = config.display[key].dbName;
                    const isDisplayed = config.display[key].isDisplayed;
                    if (isDisplayed === true) {
                      if (val && dataType === 'date' && moment(val).isValid()) {
                        if (val.toString().substr(val.length - 1) === 'Z') {
                          const editedDate = val.slice(0, -1);
                          cols.push({
                            text: moment(editedDate).format('MM/DD/YYYY'),
                            seq: config.display[key].seq,
                            name: dataName
                          });
                        } else {
                          cols.push({
                            text: formatDate(val),
                            seq: config.display[key].seq,
                            name: dataName
                          });
                        }
                      } else {
                        cols.push({
                          text: val,
                          seq: config.display[key].seq,
                          name: dataName
                        });
                      }
                    }
                  }
                }
              });
              if (cols.length > 0 && cols[0].seq !== undefined) {
                cols = cols.sort((a, b) => a.seq - b.seq);
              }
              const _parsedNcicData =
                row.NcicResponseData && row.NcicResponseData !== null
                  ? JSON.parse(row.NcicResponseData)
                  : null;
              const rawData = _parsedNcicData?.rawData;
              if (row.ptsPersonID === null) {
                row['_parsedNcicData'] = _parsedNcicData;
                row['DOB'] = _parsedNcicData.data.BirthDate;
                row['OLNNumber'] = _parsedNcicData.data.OperatorLicenseNumber;
                row['OLNState'] =
                  _parsedNcicData.data.ImpliedOperatorLicenseStateCode;
                row['SSN'] = _parsedNcicData.data.SocialSecurityNumber;
              }
              let searchType = 'L';
              if (!row.ptsPersonID && _parsedNcicData !== null) {
                searchType = 'N';
              } else if (row.ptsPersonID && _parsedNcicData !== null) {
                searchType = 'LN';
              }
              if (row.ptsPersonID !== null) {
                return (
                  <tr key={idx} onClick={() => handleRowClick(row)}>
                    {cols.map((obj, idx) => {
                      if (obj.name === 'FullName') {
                        return (
                          <Fragment key={idx}>
                            <td className={classes.tableCell} title={obj.text}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}>
                                <div style={{ paddingRight: '15px' }}>
                                  <Avatar alt="Avatar">{searchType}</Avatar>
                                </div>
                                <div>
                                  <div>
                                    <span
                                      style={{
                                        fontWeight: '500',
                                        fontSize: '18px'
                                      }}>
                                      {obj.text.toUpperCase()}
                                    </span>
                                  </div>
                                  <div style={{ color: '#83848b' }}>
                                    <span style={{ fontWeight: '500' }}>
                                      Hair color:
                                    </span>
                                    <span> {row.HairColor}</span>
                                  </div>
                                  <div style={{ color: '#83848b' }}>
                                    <span style={{ fontWeight: '500' }}>
                                      Eye color:
                                    </span>
                                    <span> {row.EyeColor}</span>
                                  </div>
                                  <div style={{ color: '#83848b' }}>
                                    <span style={{ fontWeight: '500' }}>
                                      Height:
                                    </span>
                                    <span> {row.Height}</span>
                                  </div>
                                  <div style={{ color: '#83848b' }}>
                                    <span style={{ fontWeight: '500' }}>
                                      Weight:
                                    </span>
                                    <span> {row.Weight}</span>
                                  </div>
                                  <div style={{ color: '#83848b' }}>
                                    <span style={{ fontWeight: '500' }}>
                                      Sex:
                                    </span>
                                    <span> {row.Sex}</span>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </Fragment>
                        );
                      } else if (obj.name === 'ActiveWarrant') {
                        if (row.ActiveWarrant === true) {
                          return (
                            <Fragment key={idx}>
                              <td className={classes.tableCell}>
                                <div style={{ marginBottom: '10px' }}>
                                  <Badge
                                    overlap="rectangular"
                                    style={{ left: '45px' }}
                                    className="customBagde"
                                    badgeContent="ACTIVE WARRANT"
                                    color="error"></Badge>
                                  <Badges ncicData={_parsedNcicData} />
                                </div>
                              </td>
                            </Fragment>
                          );
                        } else if (
                          row.ActiveWarrant === false &&
                          row.OLNNumber === '820507071999'
                        ) {
                          return (
                            <Fragment key={idx}>
                              <td className={classes.tableCell}>
                                <div style={{ marginBottom: '10px' }}>
                                  <Badge
                                    overlap="rectangular"
                                    style={{ left: '45px' }}
                                    className="customBagde"
                                    badgeContent="ALERT"
                                    color="error"></Badge>
                                </div>
                              </td>
                            </Fragment>
                          );
                        } else {
                          return (
                            <Fragment key={idx}>
                              <td className={classes.tableCell}></td>
                            </Fragment>
                          );
                        }
                      } else {
                        return (
                          <Fragment key={idx}>
                            <td className={classes.tableCell} title={obj.text}>
                              <span>{obj.text}</span>
                            </td>
                          </Fragment>
                        );
                      }
                    })}
                  </tr>
                );
              } else {
                return (
                  <tr key={idx} onClick={() => handleRowClick(row)}>
                    {cols.map((obj, idx) => {
                      if (obj.name === 'FullName') {
                        let _name = '';
                        if (row._parsedNcicData.data.Name) {
                          _name = row._parsedNcicData.data.Name.toUpperCase();
                        } else {
                          _name = `${row._parsedNcicData.data?.NameLast?.toUpperCase()}, ${row._parsedNcicData.data?.NameFirst?.toUpperCase()} ${row._parsedNcicData.data?.NameMiddle?.toUpperCase()}`;
                        }
                        return (
                          <Fragment key={idx}>
                            <td className={classes.tableCell} title={_name}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}>
                                <div style={{ paddingRight: '15px' }}>
                                  <Avatar alt="Avatar">{searchType}</Avatar>
                                </div>
                                <div>
                                  <div>
                                    <span
                                      style={{
                                        fontWeight: '500',
                                        fontSize: '18px'
                                      }}>
                                      {_name}
                                    </span>
                                  </div>
                                  <div style={{ color: '#83848b' }}>
                                    <span style={{ fontWeight: '500' }}>
                                      Hair color:
                                    </span>
                                    <span>
                                      {' '}
                                      {row._parsedNcicData.data.HairColorCode}
                                    </span>
                                  </div>
                                  <div style={{ color: '#83848b' }}>
                                    <span style={{ fontWeight: '500' }}>
                                      Eye color:
                                    </span>
                                    <span>
                                      {' '}
                                      {row._parsedNcicData.data.EyeColorCode}
                                    </span>
                                  </div>
                                  <div style={{ color: '#83848b' }}>
                                    <span style={{ fontWeight: '500' }}>
                                      Height:
                                    </span>
                                    <span>
                                      {' '}
                                      {row._parsedNcicData.data.Height}
                                    </span>
                                  </div>
                                  <div style={{ color: '#83848b' }}>
                                    <span style={{ fontWeight: '500' }}>
                                      Weight:
                                    </span>
                                    <span>
                                      {' '}
                                      {row._parsedNcicData.data.Weight}
                                    </span>
                                  </div>
                                  <div style={{ color: '#83848b' }}>
                                    <span style={{ fontWeight: '500' }}>
                                      Sex:
                                    </span>
                                    <span>
                                      {' '}
                                      {row._parsedNcicData.data.SexCode}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </Fragment>
                        );
                      } else if (obj.name === 'ActiveWarrant') {
                        if (row._parsedNcicData.warnings.length > 0) {
                          {
                            return row._parsedNcicData.warnings.map(w => {
                              if (w.Banner) {
                                return (
                                  <Fragment key={w.Banner}>
                                    <td className={classes.tableCell}>
                                      <Badge
                                        overlap="rectangular"
                                        className="customBagde"
                                        style={{ left: '45px' }}
                                        badgeContent={w.Banner.toUpperCase()}
                                        color="error"></Badge>
                                    </td>
                                  </Fragment>
                                );
                              } else {
                                return (
                                  <Fragment key={idx}>
                                    <td className={classes.tableCell}></td>
                                  </Fragment>
                                );
                              }
                            });
                          }
                        } else {
                          return (
                            <Fragment key={idx}>
                              <td className={classes.tableCell}></td>
                            </Fragment>
                          );
                        }
                      } else {
                        return (
                          <Fragment key={idx}>
                            <td className={classes.tableCell} title={obj.text}>
                              <span>{obj.text}</span>
                            </td>
                          </Fragment>
                        );
                      }
                    })}
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
      {!origin && (
        <TablePagination
          rowsPerPageOptions={config.pageSizeOptions}
          component="div"
          count={data.count}
          rowsPerPage={data.rowsPerPage}
          page={data.pageNo}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      {loading && <Spinner />}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  themeMode: state.theme.mode,
  ncicQueryId: state.ncic.personsQueryId
});

export default connect(mapStateToProps, {
  findResultVehicle,
  findResultPerson
})(Table);

// ======================= HELPER FUNCTIONS ====================

function getHeadCells(dataObj, config) {
  const result = [];
  if (dataObj.length === 0) return [];
  const row = dataObj[0];
  Object.keys(row).forEach(key => {
    const option = config.display[key];
    if (option) {
      if (key !== 'key') {
        result.push({
          name: key,
          dbName: option.dbName,
          label: option ? option.label : key,
          width: getColWidth(key, config),
          title: option.title ? option.title : option.label,
          seq: option.seq,
          isDisplayed: option.isDisplayed
        });
      }
    }
  });

  if (result.length > 0 && result[0].seq !== undefined) {
    return result.sort((a, b) => a.seq - b.seq);
  }

  return result;
}

function getTableWidth(config) {
  return Object.values(config.display).reduce((sum, val) => val.width + sum, 0);
}

function getColWidth(key, config) {
  return `${Math.round(
    (config.display[key].width / getTableWidth(config)) * 10000
  ) / 100}%`;
}
