import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { sendRequest } from '../../../../reducers/NcicReducer';

const useStyles = makeStyles(theme => ({
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    marginBottom: '1em'
  },
  filters: {
    padding: 20,
    marginBottom: 20
  },
  filterAction: {
    textAlign: 'right',
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  filter: {
    margin: '0 10px 10px',
    width: '200px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  datePicker: {
    marginTop: 10,
    width: 200,
    margin: '16px 10px 10px',
    paddingTop: 6,
    '& input::-webkit-input-placeholder': {
      fontSize: 14,
      opacity: 0.6
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  select: {
    margin: '0 10px 10px',
    width: '200px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  selectInput: {
    fontSize: '16px'
  },
  iconButton: {
    padding: 10
  },
  reset: {
    marginRight: 20
  },
  searchBtnWrap: {
    display: 'inline-block',
    position: 'relative',
    paddingRight: '1em'
  },
  buttonProgress: {
    color: '#B00927',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const SearchForm = props => {
  const classes = useStyles();
  // const [filters, setFilters] = useState(props.formdefinition.fields);
  // const [validationRules, setValidationRules] = useState(
  //   props.formdefinition.combinations
  // );
  // const [filterValues, setFilterValues] = useState({});
  // const filterRefs = {};
  // const [pickerValues, setPickerValues] = useState({});
  // const { getCodeState, getCodeSex, sendRequest, wsClient } = props;
  // const codes = {
  //   State: props.statesCodes,
  //   SexCode: props.sexCodes
  // };

  // Object.values(filters).forEach(({ name, components }) => {
  //   // eslint-disable-next-line
  //   if (JSON.stringify(components) !== '{}') {
  //     Object.values(components).map((f, i) => {
  //       filterRefs[f.name] = createRef();
  //     });
  //   } else {
  //     filterRefs[name] = createRef();
  //   }
  // });

  // React.useEffect(() => {
  //   if (wsClient.websocket) {
  //     getCodeState();
  //     getCodeSex();
  //   }
  // }, [getCodeState, getCodeSex, wsClient]);

  // const buildMessage = rules => {
  //   let message = '';
  //   for (let x = 0; x < rules.length; x++) {
  //     const _fields = Object.entries(rules[x][1]['required']);
  //     for (let y = 0; y < _fields.length; y++) {
  //       if (y === _fields.length - 1) {
  //         message += `"${_fields[y][0]}"`;
  //       } else {
  //         message += `"${_fields[y][0]}" and `;
  //       }
  //     }
  //     if (x !== rules.length - 1) {
  //       message += ' or ';
  //     } else if (x === rules.length - 1) {
  //       message += ' are required fields';
  //     }
  //   }
  //   return message;
  // };

  // const checkValidation = data => {
  //   const values = { ...data };
  //   delete values['user'];
  //   delete values['queryType'];
  //   const _values = Object.entries(values);
  //   const _rules = Object.entries(validationRules);
  //   let isValid = null;
  //   const message = buildMessage(_rules);
  //   if (_values.length === 0) return message;

  //   loop1: for (let x = 0; x < _rules.length; x++) {
  //     if (isValid === true) break loop1;
  //     const _fields = Object.entries(_rules[x][1]['required']);
  //     loop2: for (let y = 0; y < _fields.length; y++) {
  //       if (values[_fields[y][0]]) {
  //         isValid = true;
  //       } else {
  //         if (_fields[y][0] === 'Name') {
  //           if (values['Last']) {
  //             isValid = true;
  //           } else {
  //             isValid = message;
  //             break loop2;
  //           }
  //         } else {
  //           isValid = message;
  //           break loop2;
  //         }
  //       }
  //     }
  //   }
  //   return isValid;
  // };

  // const submitForm = e => {
  //   e.preventDefault();
  //   const newFilterValues = { ...filterValues };
  //   Object.entries(pickerValues).forEach(([key, val]) => {
  //     newFilterValues[key] = {
  //       value: moment(val.from).format('MMDDYYYY'),
  //       name: key,
  //       ncic: true
  //     };
  //   });
  //   newFilterValues['queryType'] = props.formdefinition.name;
  //   newFilterValues['user'] = props.user;
  //   const validCheck = checkValidation(newFilterValues);
  //   if (validCheck === true) {
  //     sendRequest(newFilterValues);
  //     clearForm();
  //   } else {
  //     alert(validCheck);
  //   }
  // };

  // const handleFilterChange = filter => ev => {
  //   const values = { ...filterValues };
  //   values[filter.name] = {
  //     value: ev.target.value,
  //     name: filter.name,
  //     ncic: true
  //   };
  //   setFilterValues(cleanFilters(values));
  // };

  // const handleDateChange = (date, name, param) => {
  //   const value = pickerValues[name]
  //     ? pickerValues[name]
  //     : { from: null, to: null };
  //   value[param] = date;
  //   if (param === 'from' && !value.to) value.to = date;
  //   if (param === 'to' && !value.from) value.from = date;
  //   if (param === 'single') {
  //     value.from = date;
  //     value.to = date;
  //   }
  //   const currentPickerValue = { ...pickerValues };
  //   if (value.from !== null && value.to !== null) {
  //     currentPickerValue[name] = value;
  //   } else {
  //     delete currentPickerValue[name];
  //   }

  //   setPickerValues(currentPickerValue);
  // };

  // const getTextFilter = (filter, idx) => {
  //   return (
  //     <TextField
  //       type="search"
  //       size="small"
  //       variant="outlined"
  //       label={filter.description || filter.name}
  //       key={idx}
  //       className={classes.filter}
  //       onChange={handleFilterChange(filter)}
  //       inputRef={filterRefs[filter.name]}
  //     />
  //   );
  // };

  // const getDateSingleFilter = (filter, idx) => {
  //   const value = pickerValues[filter.name]
  //     ? pickerValues[filter.name]
  //     : { from: null, to: null };
  //   return (
  //     <KeyboardDatePicker
  //       style={{ marginTop: 0, paddingTop: 0 }}
  //       key={idx}
  //       variant="inline"
  //       inputVariant="outlined"
  //       size="small"
  //       className={classes.datePicker}
  //       value={value.from}
  //       placeholder={filter.description}
  //       onChange={date => handleDateChange(date, filter.name, 'single')}
  //       format="MM/DD/yyyy"
  //     />
  //   );
  // };

  // const getSelectFilter = (filter, idx) => (
  //   <FormControl
  //     variant="outlined"
  //     size="small"
  //     className={classes.select}
  //     key={idx}>
  //     <InputLabel id={`filter-${filter.name}`}>{filter.description}</InputLabel>
  //     <Select
  //       className={classes.selectInput}
  //       labelId={`filter-${filter.name}`}
  //       value={filterValues[filter.name] ? filterValues[filter.name].value : ''}
  //       onChange={handleFilterChange(filter)}>
  //       <MenuItem value=""> </MenuItem>
  //       {codes[filter.name].map((code, idx) => (
  //         <MenuItem value={code.Code} key={idx}>
  //           {code.Code}
  //         </MenuItem>
  //       ))}
  //     </Select>
  //   </FormControl>
  // );

  // const clearForm = () => {
  //   Object.values(filterRefs).forEach(({ current }) => {
  //     if (current) current.value = '';
  //   });
  //   setFilterValues({});
  //   setPickerValues({});
  // };

  return (
    <div className={classes.searchWrap}>
      {/* <MuiPickersUtilsProvider utils={MomentUtils}>
        <form onSubmit={submitForm}>
          <div>
            {Object.values(filters).map((filter, idx) => {
              switch (filter.type) {
                case 'Date':
                  return getDateSingleFilter(filter, idx);
                case 'Alphanumeric':
                  if (filter.name === 'ImageIndicator') break;
                  if (filter.name === 'State' || filter.name === 'SexCode')
                    return getSelectFilter(filter, idx);
                  if (JSON.stringify(filter.components) !== '{}') {
                    return Object.values(filter.components).map((f, i) => {
                      return getTextFilter(f, i);
                    });
                  } else {
                    return getTextFilter(filter, idx);
                  }
                default:
                  return getTextFilter(filter, idx);
              }
            })}
          </div>
          <div className={classes.filterAction}>
            <Button
              size="large"
              startIcon={<DeleteOutlineIcon />}
              onClick={() => console.log('clear responses')}
              className={classes.reset}>
              Clear List
            </Button>
            <Button
              size="large"
              startIcon={<DeleteOutlineIcon />}
              onClick={clearForm}
              className={classes.reset}>
              Clear Form
            </Button>
            <div className={classes.searchBtnWrap}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<SearchIcon />}
                type="submit">
                Search
              </Button>
            </div>
          </div>
        </form>
      </MuiPickersUtilsProvider> */}
    </div>
  );
};

const mapStateToProps = state => ({
  wsClient: state.websocket,
  user: state.user,
  themeMode: state.theme.mode,
  statesCodes: state.codes.dictionary.statesCodes || [],
  sexCodes: state.codes.dictionary.sexCodes || []
});

export default connect(mapStateToProps, {
  sendRequest
})(SearchForm);

function cleanFilters(obj) {
  const result = {};
  Object.entries(obj).forEach(([key, val]) => {
    if (val.value !== '') result[key] = val;
  });
  return result;
}
