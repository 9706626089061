import { getService } from './service';
import { handleError } from './ErrorReducer';
import store from 'config/configureStore';

export const GET_SETTINGS = 'CONFIG/GET_SETTINGS';
export const CLEAR_SETTINGS = 'CONFIG/CLEAR_SETTINGS';
export const SET_TIME_DIFF = 'SET_TIME_DIFF';
export const SET_SETTINGS_VAL = 'SET_SETTINGS_VAL';

const multipleScreens = process.env.REACT_APP_MULTIPLE_SCREENS === 'true';

// ================  ACTIONS  ======================

export const getSettings = () => {
  return async dispatch => {
    try {
      const service = getService();
      const settings = await service.find({ query: { type: 'settings' } });
      dispatch({ type: GET_SETTINGS, settings });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const clearSettings = () => dispatch => {
  dispatch({ type: CLEAR_SETTINGS });
};

export const setSettingsVal = (key, value) => dispatch => {
  dispatch({ type: SET_SETTINGS_VAL, key, value });
};

export const saveSettings = (type, data) => {
  const service = getService();
  return service.patch(type, { type: 'settings', data });
};

export const setMasterMode = () => {
  window.localStorage.setItem('masterMode', 'true');
};

export const removeMasterMode = () => {
  window.localStorage.removeItem('masterMode');
};

export const isMasterMode = () => {
  return Boolean(window.localStorage.getItem('masterMode'));
};

/** Check if master instance really exists if not clean the data and reload */

let testMasterTimeout = 0;

const testingLevel = parseInt(process.env.REACT_APP_TESTING_LEVEL) || 0;

// ==================  REDUCERS  ===============

// show911 :
// 0 - reject calls on this screen (grey)
// 1 - show - accepts all calls (blue)
// 2 - accept calls - ommit recent callers (red)

const defaultConfig = {
  loaded: false,
  options: {},
  colorPalette: {
    events: {},
    units: {}
  },
  timeDiff: 0,
  testingLevel,
  show911: 1,
  mode: 'standalone', // standalone, master, slave
  eventDataPresent: false, // is event data present in local storage
  unitDataPresent: false // is event data present in local storage
};

export default function reducer(state = defaultConfig, action) {
  switch (action.type) {
    case GET_SETTINGS:
      return { ...state, ...action.settings, loaded: true };
    case CLEAR_SETTINGS:
      return { ...defaultConfig };
    case SET_TIME_DIFF:
      return { ...state, timeDiff: action.timeDiff };
    case SET_SETTINGS_VAL:
      return { ...state, [action.key]: action.value };
    default:
      return state;
  }
}

export function getWorkstationID() {
  const settings = getLocalSettings();
  return settings.workstationID;
}

export function saveWorkstationID(workstationID) {
  const settings = getLocalSettings();
  settings.workstationID = workstationID;
  saveLocalSettings(settings);
}

export function createWorkstationID() {
  return String(Math.random()).substr(2, 10);
}

export const defaultLocalSettings = {
  workstationID: '',
  mapLat: 38.8937091,
  mapLng: -77.0846157,
  mapZoom: 14,
  mapEventOpen: false,
  mapEventWidth: 580,
  mapUnitOpen: false,
  mapUnitWidth: 580,
  changePassReminder: {}
};

export function getLocalSettings(setting) {
  const savedSettings = localStorage.getItem('localSettings');
  let settings;
  if (savedSettings) {
    const parsedSettings = JSON.parse(savedSettings);
    settings = { ...defaultLocalSettings, ...parsedSettings };
  } else {
    const newSettings = { ...defaultLocalSettings };
    newSettings.workstationID = createWorkstationID();
    localStorage.setItem('localSettings', JSON.stringify(newSettings));
    settings = newSettings;
  }
  if (setting) return settings[setting];
  return settings;
}

export function saveLocalSettings(settings) {
  const currentSettings = getLocalSettings();
  const newSettings = { ...currentSettings, ...settings };
  localStorage.setItem('localSettings', JSON.stringify(newSettings));
}
