import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import RenderMap from '../../components/Map';
import Loader from '../../global-components/Loader';
import { handleError } from 'reducers/ErrorReducer';
// const libraries = (process.env.REACT_APP_GOOGLE_LIBRARIES || '').split(',');

const PagesMain = props => {
  const { units, events, isLoaded, loadError, unitsCoordinates } = props;
  // const { APP_GOOGLE_LIBRARIES, APP_GOOGLE_KEY } = useSelector(
  //   state => state.appInfo.appEnvInfo
  // );

  if (loadError) {
    return (
      <Fragment>
        <Loader />
      </Fragment>
    );
  }

  return isLoaded && units && events && unitsCoordinates ? (
    <RenderMap />
  ) : (
    <Loader />
  );
};

const mapStateToProps = state => ({
  wsClient: state.websocket,
  themeMode: state.theme.mode,
  units: state.units.units,
  events: state.events.events,
  loadError: state.map.mapError,
  isLoaded: state.map.mapLoaded,
  unitsCoordinates: state.map.unitsCoordinates
});

export default connect(mapStateToProps, {
  handleError
})(PagesMain);
