import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing, Integrations } from '@sentry/tracing';
import App from './App';
import packageJson from '../package.json';
const isProduction = process.env.NODE_ENV !== 'development';
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
if (isProduction && sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    release: packageJson.version,
    tracesSampler: context => {
      if (context.exception) return 1;
      return 0; // block all but exceptions
    }
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
