import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { GrMap } from 'react-icons/gr';
import { GrMapLocation } from 'react-icons/gr';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  }
}));

function AddressSearch(props) {
  const {
    wsClient,
    ptsPlaces,
    ptsAddresses,
    googleAddresses,
    codeCities,
    searchAddressID,
    setSearchAddressID,
    addressOptionLable,
    setAddressOptionLable
  } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (ptsAddresses === true && ptsPlaces === true) {
      (async () => {
        try {
          const service = wsClient.websocket.service('ptsaddresslistliteview');
          service.timeout = 20000;
          const addresses = await service.find({
            query: { FullAddressText: inputValue }
          });
          if (addresses.length > 0) {
            setOptions(addresses);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    value,
    inputValue,
    wsClient.websocket,
    googleAddresses,
    ptsAddresses,
    ptsPlaces
  ]);

  const getCityName = ptsCityID => {
    const city = codeCities.find(city => city.ptsCityID === ptsCityID);
    return city ? city.Code : '';
  };

  const getOptionLabel = option => {
    if (!option) {
      return;
    }
    if (typeof option === 'string') {
      return option;
    }
    if (option.FullAddressText) {
      if (option.PlaceName) {
        return `${option.PlaceName} - ${option.FullAddressText}`;
      } else {
        return option.FullAddressText;
      }
    } else if (option.formatted_address) {
      return option.formatted_address;
    } else {
      let label = '';
      label += option.AddressNumber ? option.AddressNumber : '';
      label += option.PreDirection ? ` ${option.PreDirection}` : '';
      label += option.StreetName ? ` ${option.StreetName}` : '';
      label += option.StreetType ? ` ${option.StreetType}` : '';
      label += option.PostDirection ? ` ${option.PostDirection}` : '';
      label += option.ptsCityID ? ` ${getCityName(option.ptsCityID)}` : '';
      label += option.State ? `, ${option.State}` : '';
      label += option.PostalCode ? ` ${option.PostalCode}` : '';
      return label;
    }
  };

  const onChange = (ev, newValue) => {
    if (newValue) {
      setSearchAddressID(newValue);
      setAddressOptionLable(getOptionLabel(newValue));
    } else {
      setSearchAddressID(null);
      setAddressOptionLable(null);
    }
  };

  const onInputChange = (ev, newInputValue) => {
    setInputValue(newInputValue);
  };

  const renderInput = params => (
    <TextField
      autoComplete="hidden"
      size="small"
      {...params}
      label="Address"
      variant="outlined"
      fullWidth
    />
  );

  const renderOption = option => {
    if (option.PlaceName === null) {
      return (
        <Grid container alignItems="center">
          <Grid item>
            <GrMap
              className={classes.icon}
              style={{ color: 'green', fontSize: '24px' }}
            />
          </Grid>
          <Grid item xs>
            <span style={{ fontWeight: 400 }}>{option.FullAddressText}</span>

            <Typography variant="body2" color="textSecondary">
              {option.PlaceName}
            </Typography>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container alignItems="center">
          <Grid item>
            <GrMapLocation
              className={classes.icon}
              style={{ color: 'red', fontSize: '24px' }}
            />
          </Grid>
          <Grid item xs>
            <span style={{ fontWeight: 400 }}>{option.FullAddressText}</span>

            <Typography variant="body2" color="textSecondary">
              {option.PlaceName}
            </Typography>
          </Grid>
        </Grid>
      );
    }
  };

  const getOptionSelected = (option, value) => {
    return (
      value &&
      (option.ptsLocationAddressID === value.ptsLocationAddressID ||
        option.ptsAddressID === value.ptsAddressID)
    );
  };

  return (
    <Autocomplete
      size="small"
      id="address-search"
      autoComplete
      autoSelect
      autoHighlight
      value={addressOptionLable || ''}
      getOptionLabel={getOptionLabel}
      options={options}
      includeInputInList
      className={classes.autocomplete}
      onChange={onChange}
      onInputChange={onInputChange}
      renderInput={renderInput}
      renderOption={renderOption}
      getOptionSelected={getOptionSelected}
    />
  );
}

AddressSearch.defaultProps = {
  ptsPlaces: true,
  ptsAddresses: true,
  googleAddresses: false
};

const mapStateToProps = state => ({
  wsClient: state.websocket,
  codeCities: state.codes.dictionary.codeCities || []
});

export default connect(mapStateToProps)(AddressSearch);
