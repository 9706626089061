import React from 'react';
import PoliceIcon from './iconagencytypepoliceIcon';
import MedicalIcon from './iconsagencytypemedicalIcon';
import SheriffIcon from './iconsagencytypesheriffIcon';
import FireIcon from './fireDEPIcon';
import Police1Icon from './police1Icon';
import Police2Icon from './police2Icon';

function EmptyIcon() {
  return <span>-</span>
}

export default {
  0: EmptyIcon,
  1: PoliceIcon,
  2: MedicalIcon,
  3: SheriffIcon,
  4: Police1Icon,
  5: Police2Icon,
  6: FireIcon,
}