import React from 'react';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';

const maskStyle = {
  lineHeight: 1,
  boxSizing: 'border-box',
  position: 'absolute',
  inset: 13,
  overflow: 'hidden',
  pointerEvents: 'none',
  color: '#333'
};

function TextField2(props) {
  const {
    disabled,
    error,
    className,
    label,
    multiline,
    rows,
    min,
    max,
    compact,
    toFixed,
    onClick,
    style,
    autoFocus,
    onKeyDown
  } = props;
  const value =
    props.value !== null && props.value !== undefined ? props.value : '';
  const inputType = props.type;
  let inputClass = '';
  let type = inputType ? inputType.toLowerCase() : 'text';
  let InputProps = {
    ...props.inputProps,
    autoComplete: 'new-password'
  };
  let passValue = '';
  const helperText = compact
    ? undefined
    : typeof error === 'string'
    ? error
    : ' ';

  if (type === 'mask') {
    const len = value.length;
    type = 'text';
    inputClass = 'login-pass';
    passValue = ''.padStart(len, '●');
  }

  if (type === 'number' && (max !== undefined || min !== undefined)) {
    InputProps = { inputProps: { min, max } };
  }

  const onChange = ev => {
    const val = ev.target.value;
    if (val === '') return props.onChange(ev, val);
    switch (type) {
      case 'number':
        onNumberChange(ev, val);
        break;
      case 'postcode':
        onPostcodeChange(ev, val);
        break;
      case 'phone':
        onPhoneChange(ev, val);
        break;
      default:
        onInputChange(ev, val);
        break;
    }
  };

  const onNumberChange = (ev, val) => {
    if (val === '-') return props.onChange(ev, val);
    const intVal = parseFloat(val);
    if (max !== undefined && intVal > max) return;
    if (min !== undefined && intVal < min) return;
    if (toFixed !== undefined) {
      val = '' + parseFloat(val).toFixed(toFixed);
    }
    return props.onChange(ev, val);
  };

  const onPostcodeChange = (ev, val) => {
    let out = val;
    const delText = ev.nativeEvent.inputType === 'deleteContentBackward';
    const len = val.length;
    if (len > 12) return;
    if (len === 6 && !delText) {
      out = val.substr(0, len - 1) + ' - ' + val[len - 1];
    }
    if (len === 8 && delText) {
      out = out.substr(0, 5);
    }
    const ascii = out.charCodeAt(out.length - 1);
    if (ascii < 48 || ascii > 57) return;
    return props.onChange(ev, out);
  };

  const onPhoneChange = (ev, val) => {
    const maxLength = 24;
    let digits = '';
    val.split('').forEach(ch => {
      const reg = /^\d+$/;
      if (ch.match(reg)) digits += ch;
    });
    const digitsArr = digits.split('');
    const len = digitsArr.length;
    len > 0 && digitsArr.splice(0, 0, '(');
    len > 3 && digitsArr.splice(4, 0, ') ');
    len > 6 && digitsArr.splice(8, 0, '-');
    len > 10 && digitsArr.splice(13, 0, ' ext ');
    const out = digitsArr.join('').substr(0, maxLength);
    props.onChange(ev, out);
  };

  const onInputChange = (ev, val) => {
    if (max !== undefined && val.length > max)
      return props.onChange(ev, val.substr(0, max));
    props.onChange(ev, val);
  };

  const input = (
    <TextField
      {...props}
      onClick={onClick}
      error={!!error}
      helperText={helperText}
      onChange={onChange}
      className={type !== 'mask' ? clsx(className, inputClass) : null}
      label={label}
      value={value}
      // onChange={onChange}
      variant="outlined"
      size="small"
      multiline={multiline}
      minRows={rows}
      disabled={disabled}
      type={type}
      InputProps={InputProps}
      style={style}
      autoFocus={autoFocus}
      onKeyDown={onKeyDown}
    />
  );

  if (inputType === 'mask') {
    return (
      <div
        style={{ position: 'relative' }}
        className={clsx(className, inputClass)}>
        {input}
        <div style={maskStyle}>{passValue}</div>
      </div>
    );
  }

  return input;
}

export default TextField2;
