import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getPersonContactInfo, getPartyPerson } from 'reducers/EventsReducer';
import { handleError } from 'reducers/ErrorReducer';
import { showAddPerson } from 'reducers/DialogsReducer';
import { displayDate } from 'reducers/TimeReducer';
import { IconButton } from '@material-ui/core';
import { AddIcon } from 'evergreen-ui';
import TextFieldPlus from './TextFieldPlus';
import { searchPeople2 } from 'reducers/SearchReducer';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from './Tooltip';
import { showWarrants } from 'reducers/DialogsReducer';
import { getPersonWarrantCount } from 'reducers/WarrantsReducer';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import { showEditPerson } from 'reducers/DialogsReducer';

const useStyles = makeStyles(theme => ({
  wrap: {
    position: 'relative',
    '& .MuiAutocomplete-clearIndicator': {
      visibility: 'visible',
      opacity: 0.2,
      transition: 'all 0.1s'
    },
    '&:hover, & .Mui-focused': {
      '& $personInfo': {
        opacity: 1
      },
      '& $warrants': {
        opacity: 1
      },
      '& .MuiAutocomplete-clearIndicator': {
        opacity: 1
      }
    }
  },
  textField: {
    margin: '0 4px 8px'
  },
  activeWarrant: {
    background: theme.palette.error.main,
    color: theme.palette.background,
    width: 'calc(100% + 32px)',
    display: 'inline-block',
    padding: '8px 16px',
    margin: '-8px -16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 14,
    '& em': {
      fontStyle: 'normal',
      fontSize: 12
    }
  },
  option: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& em': {
      fontStyle: 'normal',
      fontSize: 12
    }
  },
  personInfo: {
    position: 'absolute',
    right: 60,
    top: 5,
    opacity: 0.2,
    transition: 'all 0.1s'
  },
  warrants: {
    position: 'absolute',
    right: 90,
    top: 5,
    opacity: 0.2,
    transition: 'all 0.1s'
  },
  iconButtonError: {
    color: theme.palette.error.main
  },
  iconButton: {
    color: theme.palette.secondary.main
  }
}));

const btnStyle = {
  margin: '0 8px 0 2px',
  padding: 0
};

function PersonLookup2(props) {
  const {
    label = 'Search Person',
    defaultOptions,
    dictionary,
    dialogs,
    ptsPersonID,
    setPerson
  } = props;
  const classes = useStyles();
  const [text, setText] = useState('');
  const [searchMatch, setSearchMatch] = useState([]);
  const [personData, setPersonData] = useState(null);
  const [warrantsNo, setWarrantsNo] = useState(0);
  const mountedRef = useRef(true);
  const throttleRef = useRef(0);
  const addEnabled = dialogs.addPerson === null;

  useEffect(() => {
    searchPerson();
    // eslint-disable-next-line
  }, [text]);

  useEffect(() => {
    if (personData) {
      getWarrants(personData.ptsPersonID);
    } else {
      setWarrantsNo(0);
    }
    // eslint-disable-next-line
  }, [personData]);

  useEffect(() => {
    if (!defaultOptions) return;
    setSearchMatch(defaultOptions);
    // eslint-disable-next-line
  }, [defaultOptions]);

  useEffect(() => {
    if (ptsPersonID !== undefined) getPerson(ptsPersonID);
    // eslint-disable-next-line
  }, [ptsPersonID]);

  useEffect(() => {
    handlePersonSet();
  }, [setPerson]);
  const getPerson = async ptsPersonID => {
    if (!ptsPersonID) return;
    try {
      const personData = await getPartyPerson(ptsPersonID);
      if (!mountedRef.current) return;
      setSearchMatch([personData]);
      setPersonData(personData);
      onSearchSelect(null, personData);
    } catch (err) {
      handleError(err);
    }
  };

  const searchPerson = () => {
    if (text.length < 3) {
      setSearchMatch([]);
      return;
    }
    clearTimeout(throttleRef.current);
    throttleRef.current = setTimeout(() => {
      searchPeople2(text, false, 100)
        .then(result => {
          result = result.map(p => ({
            ...p,
            BirthDate: displayDate(p.BirthDate)
          }));
          setSearchMatch(result);
        })
        .catch(props.handleError);
    }, 500);
  };

  const onPersonChange = data => {
    if (props.onPersonChange) return props.onPersonChange(data); // ToDo: should be refactored
    if (props.onChange) return props.onChange(data);
  };

  const onSearchSelect = (ev, selection) => {
    if (!selection) {
      setPersonData(null);
      onPersonChange(null);
      return;
    }
    setPersonData(selection);
    getPersonContactInfo(selection.ptsPersonID)
      .then(result => {
        if (!mountedRef.current) return;
        onPersonChange({ ...selection, ContactInfo: result });
      })
      .catch(err => {
        props.handleError(err, 'Cannot contact API');
      });
  };

  const getOptionSelected = (option, value) => {
    return option.ptsPersonID === value.ptsPersonID;
  };

  const getWarrants = async ptsPersonID => {
    try {
      const warrantsNo = await getPersonWarrantCount(ptsPersonID);
      setWarrantsNo(warrantsNo);
    } catch (err) {
      props.handleError(err);
    }
  };

  // If person set by parent component
  const handlePersonSet = (person = setPerson) => {
    if (!person) {
      if (personData) {
        setSearchMatch([]);
        setPersonData(null);
      }
      return;
    } else if (!person.ptsPersonID) return;
    const autocompleteOptions = [{ ...person }];
    setSearchMatch(autocompleteOptions);
    setPersonData(autocompleteOptions[0]);
    if (person !== setPerson) {
      props.onChange(person);
    }
  };
  const renderOption = option => {
    if (!option) return;

    let label = '';
    if (option.LastName) label += option.LastName + ',';
    if (option.FirstName) label += ` ${option.FirstName} `;
    if (option.MiddleName) label += ` ${option.MiddleName} `;
    label += ' | ';
    if (option.Race) {
      const desc = dictionary.raceCodes?.find(s => s.Code === option.Race);
      if (desc) label += ' Race: ' + desc.Description + ' | ';
    }
    if (option.Sex) {
      const desc = dictionary.sexCodes?.find(s => s.Code === option.Sex);
      if (desc) label += ' Sex: ' + desc.Description + ' | ';
    }
    if (option.BirthDate) {
      label += ' DOB: ' + option.BirthDate + ' | ';
    }
    if (option.OLN) label += ' OLN: ' + option.OLN + ' | ';
    if (option.SSN) label += ' SSN: ' + option.SSN + ' | ';
    if (option.PersonID) label += ' ' + option.PersonID;

    return (
      <span
        className={
          option.ActiveWarrant ? classes.activeWarrant : classes.option
        }>
        {label}
      </span>
    );
  };

  const startAdornment = addEnabled ? (
    <IconButton style={btnStyle} onClick={() => props.showAddPerson()}>
      <AddIcon fontSize="small" />
    </IconButton>
  ) : (
    undefined
  );

  const renderPersonInfo = () => {
    const onClick = () => {
      props.showEditPerson({ ptsPersonID: personData.ptsPersonID });
    };

    return (
      <div className={classes.personInfo}>
        <Tooltip title="Person info">
          <span>
            <IconButton
              color="secondary"
              size="small"
              onClick={onClick}
              className={classes.iconButton}>
              <InfoIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    );
  };

  const renderWarrants = () => {
    const onClick = () => {
      const { ptsPersonID, FirstName, LastName } = personData;
      const Name = `${FirstName} ${LastName}`;
      props.showWarrants({ Name, ptsPersonID });
    };
    return (
      <div className={classes.warrants}>
        <Tooltip title="Active warrants">
          <span>
            <IconButton
              color="secondary"
              size="small"
              onClick={onClick}
              className={classes.iconButtonError}>
              <ErrorIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className={clsx(classes.wrap, props.className)}>
      <Autocomplete
        disabled={props.disabled}
        fullWidth
        autoHighlight
        options={searchMatch}
        value={personData}
        getOptionLabel={option => option.FullName}
        size="small"
        onChange={onSearchSelect}
        onInputChange={(ev, val) => setText(val)}
        renderInput={params => {
          params.label = label;
          params.variant = 'outlined';
          return (
            <>
              <TextFieldPlus
                {...params}
                InputProps={{
                  ...params.InputProps,
                  autoComplete: 'new-password',
                  startAdornment
                }}
              />
              {Boolean(personData) && renderPersonInfo()}
              {warrantsNo > 0 && renderWarrants()}
            </>
          );
        }}
        getOptionSelected={getOptionSelected}
        renderOption={renderOption}
        filterOptions={option => option}
      />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    dictionary: state.codes.dictionary,
    dialogs: state.dialogs,
    dataUpdate: state.dataUpdate
  };
};

export default connect(mapStateToProps, {
  handleError,
  showAddPerson,
  showWarrants,
  showEditPerson
})(PersonLookup2);
