import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Card, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  body: {
    background: theme.palette.bgElements,
    minHeight: '50vh',
    padding: '10px 10px'
  },
  itemWrap: {
    fontWeight: 500
  },
  itemLabel: {
    fontWeight: 200
  },
  headerItem: {
    fontSize: '1em !important'
  }
}));

function Alerts(props) {
  const { data, themeMode, close } = props;
  const classes = useStyles();

  if (data.label === 'Alerts') {
    if (data.data !== null) {
      return (
        <Card className="p-4 mt-3">
          <div className="card-header-alt d-flex justify-content-between">
            <div></div>
            <IconButton onClick={() => close()} aria-label="close">
              <CloseIcon variant="contained" />
            </IconButton>
          </div>
          <div
            className="divider"
            style={{ marginLeft: '0', marginRight: '0' }}
          />
          <div className="table-responsive">
            <table
              className={clsx(
                'table',
                themeMode === 'night' && 'table-pts-night',
                themeMode === 'day' && 'table-pts-day',
                'text-nowrap',
                'mb-0'
              )}>
              <thead>
                <tr>
                  <th className={classes.headerItem}>AlertID</th>
                  <th className={classes.headerItem}>Type</th>
                  <th className={classes.headerItem}>Beginning</th>
                  <th className={classes.headerItem}>Ending</th>
                  <th className={classes.headerItem}>Priority</th>
                </tr>
              </thead>
              <tbody>
                {data.data.map((row, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{row.AlertID}</td>
                      <td>{row.AlertType}</td>
                      <td>
                        {' '}
                        {row.Beginning
                          ? moment(row.Beginning).format('MM/DD/YYYY')
                          : ''}
                      </td>
                      <td>
                        {' '}
                        {row.Ending
                          ? moment(row.Ending).format('MM/DD/YYYY')
                          : ''}
                      </td>
                      <td>{row.Priority}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Card>
      );
    } else {
      return (
        <Card className="card-box bg-neutral-warning border-1 card-border-top border-warning text-center p-3">
          <h4 style={{ marginBottom: 0, color: 'black' }}>
            Sorry, no alerts found.
          </h4>
        </Card>
      );
    }
  } else {
    return null;
  }
}

export default Alerts;
