import React, { Fragment, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { makeStyles } from '@material-ui/core/styles';

import MessageIcon from '@material-ui/icons/Message';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Draggable from 'react-draggable';

import {
  Card,
  CardContent,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Fab,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  Tooltip,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  IconButton,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import DirectionsIcon from '@material-ui/icons/Directions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AttachmentList from './AttachmentList';
import AlertDialog from '../../../global-components/AlertDialog';

import EventIcon from '../EventIcon';
import AgencyIcon from '../AgencyIcon';

import {
  setDirectionInfoWindo,
  setEventInfowindow,
  setEventInfowindowTab
} from '../../../reducers/MapReducer';
import {
  setEventStatus,
  setEventNote,
  setEventDispositions,
  setEventAttachment,
  removeEventDisposition,
  getEventUnitHistory
} from '../../../reducers/EventsReducer';

import { parseXmlSOP } from '../../../reducers/SopsReducer';

import { getEventColor } from '../../../utils/uiUtils';
import { getUnitColor } from '../../../utils/uiUtils';
import { displayDateTime } from 'reducers/TimeReducer';
import { useState } from 'react';
import { sortObjArr } from 'utils/functions';
import { useRef } from 'react';
import { handleError } from 'reducers/ErrorReducer';

const avatarWidth = 44;
const iconWidth = 35;

const useStyles = makeStyles(theme => ({
  eventCard: {
    borderWidth: '5px'
  },
  listItem: {
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  avatar: {
    flex: `0 0 ${avatarWidth}px`,
    width: avatarWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    '& > div': {
      boxSizing: 'border-box',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      background: '#dedfe3',
      borderRadius: '15%',
      width: 35,
      height: 35,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.2em',
      color: '#000',
      fontWeight: 500,
      textTransform: 'uppercase',
      padding: 4
    }
  },
  icon: {
    flex: `0 0 ${iconWidth}px`,
    textAlign: 'center',
    '& > div': {
      height: 20,
      width: 20,
      display: 'inline-block'
    }
  },
  callerItem: {
    flexGrow: 0,
    flexShrink: 0,

    textAlign: 'left'
  },
  button: {
    border: '1px solid #000',
    borderRadius: '7px',
    width: '60px',
    minWidth: '40px',
    height: '40px',
    marginRight: '10px'
  }
}));

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
};

const DialogEvent = props => {
  const {
    network,
    dispositions,
    eventInfowindow,
    activeTab,
    setEventInfowindow,
    setEventInfowindowTab,
    setEventStatus,
    setEventNote,
    setEventDispositions,
    setEventAttachment,
    removeEventDisposition,
    user,
    colors,
    unitActionCodes
  } = props;

  const unitInserviceStatus =
    unitActionCodes.length === 0
      ? null
      : unitActionCodes['Inservice']
      ? unitActionCodes['Inservice'].Code
      : unitActionCodes['InService'].Code;

  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
  let unselectedDispositons;
  const [rawData, setRawData] = useState([]);
  const [unitHistory, setUnitHistory] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(false);

  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    updateEventHistory();
    // eslint-disable-next-line
  }, [eventInfowindow.data && eventInfowindow.data.ptsEventID]);

  useEffect(() => {
    setUnitHistory(sortObjArr(rawData, 'Created'));
    // eslint-disable-next-line
  }, [rawData]);

  const updateEventHistory = async () => {
    if (historyLoading || !eventInfowindow.data) return;
    setHistoryLoading(true);
    try {
      const rawData = await getEventUnitHistory(
        eventInfowindow.data.ptsEventID
      );
      if (!mountedRef.current) return;
      setRawData(rawData);
    } catch (err) {
      dispatch(handleError(err));
    }
    setHistoryLoading(false);
  };
  const renderText = text => {
    return (
      <tr key={text.name}>
        <td colSpan={2} className={classes.text}>
          <MessageIcon /> &nbsp;{text.text}
        </td>
      </tr>
    );
  };

  const renderQuestion = (question, idx) => {
    return (
      <tr key={question.name}>
        <td>
          <QuestionAnswerIcon /> &nbsp;{question.text}
        </td>
        <td>
          {
            <TextField
              disabled
              variant="outlined"
              size="small"
              style={{ width: '100%' }}
              value={question.value}
            />
          }
        </td>
      </tr>
    );
  };

  const renderCheckbox = (checkbox, idx) => {
    const value = checkbox.value === true || checkbox.value === 'True';
    return (
      <tr key={checkbox.name} className={classes.checkbox}>
        <td>
          <label>{checkbox.text}</label>
        </td>
        <td>
          <Checkbox checked={value} disabled />
        </td>
      </tr>
    );
  };

  if (dispositions && eventInfowindow.data && user) {
    function findDifference(eventDispostions) {
      return function(allDisposition) {
        return (
          eventDispostions.filter(function(eventDispostion) {
            return (
              eventDispostion.Disposition === allDisposition.Disposition &&
              eventDispostion.AgencyID === allDisposition.AgencyID
            );
          }).length == 0
        );
      };
    }

    unselectedDispositons = dispositions
      .filter(findDifference(eventInfowindow.data.dispositions))
      .map(disposition =>
        Object.assign(disposition, {
          ptsEventID: eventInfowindow.data.ptsEventID
        })
      )
      .filter(disposition => disposition.AgencyID === user.userUnit.AgencyID);
  }

  let event = eventInfowindow.data;
  let cases = '';
  let googlePath = '';
  let eventLatitude = '';
  let eventLongitude;
  if (event) {
    cases = JSON.parse(event.CaseIds);
    if (user && user.location && user.location !== null) {
      if (
        event.LatitudeSign &&
        event.LongitudeSign &&
        event.LatitudeDegree &&
        event.LongitudeDegree
      ) {
        eventLatitude = `${
          event.LatitudeSign === '-' ? event.LatitudeSign : ''
        }${event.LatitudeDegree}`;
        eventLongitude = `${
          event.LongitudeSign === '-' ? event.LongitudeSign : ''
        }${event.LongitudeDegree}`;
        googlePath = `https://www.google.com/maps/dir/${user.location.latitude},${user.location.longitude}/${eventLatitude},${eventLongitude}/`;
      }
    }
  }
  const handleShowDirection = () => {
    dispatch(
      setDirectionInfoWindo(true, {
        origin: {
          lat: user.location.latitude,
          lng: user.location.longitude
        },
        destination: {
          lat: parseFloat(eventLatitude),
          lng: parseFloat(eventLongitude)
        }
      })
    );
  };
  const historyData =
    eventInfowindow.data && eventInfowindow.data.UnitStatuses
      ? JSON.parse(eventInfowindow.data.UnitStatuses)
      : [];

  const attachementsData =
    eventInfowindow.data && eventInfowindow.data.Attachments
      ? JSON.parse(eventInfowindow.data.Attachments)
      : [];

  const eventStatuses = [
    { status: 'Dispatch', value: 'Dispatched', disabled: true },
    { status: 'Enroute', value: 'Enroute', disabled: true },
    { status: 'Arrived', value: 'Arrived', disabled: true },
    { status: 'Completed', value: 'Completed', disabled: true },
    { status: 'Leave event', value: 'Close', disabled: true }
  ];

  const [filterMyHistory, setFilterMyHistory] = React.useState(false);
  const [history, setHistory] = React.useState([]);
  const [isEventsFormVisible, setIsEventsFormVisible] = React.useState(true);
  const [notesFieldValue, setNotesFieldValue] = React.useState('');
  const [dispositionFieldValue, setDispositionsFieldValue] = React.useState([]);
  const [eventStatusValue, setEventStatusValue] = React.useState('Dispatched');
  const [eventStatusesList, setEventStatusesList] = React.useState(
    eventStatuses
  );
  const [attachmentFileName, setAttachmentFileName] = React.useState('');
  const [attachmentModal, setAttachmentModal] = React.useState(false);
  const [attachmentFileInfo, setAttachmentFileInfo] = React.useState({
    file: null,
    Description: ''
  });
  const [alertBox, setAlertBox] = React.useState(false);
  const [dispositionToDelete, setDispositionToDelete] = React.useState({});
  const [
    dispositionEventIdToDelete,
    setDispositionEventIdToDelete
  ] = React.useState(false);
  const [confirmDisposition, setConfirmDisposition] = React.useState(false);
  const [opneDispositionDropdown, setOpneDispositionDropdown] = React.useState(
    false
  );
  const handleDispositonsDeleteClick = () => {
    setAlertBox(true);
  };

  const handleFilterMyHistory = event => {
    setFilterMyHistory(event.target.checked);

    event.target.checked
      ? setHistory(unitHistory.filter(hist => hist.Unit == user.userUnit.Unit))
      : setHistory(unitHistory);
  };

  const deleteDisposition = () => {
    removeEventDisposition(dispositionEventIdToDelete, dispositionToDelete);
    setAlertBox(false);
  };

  const closeAlertBox = () => {
    setAlertBox(false);
  };

  //here is the accordian handlers
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // here ends the accordian handlers

  const handleEventInfoWindowClose = (state, infowindow) => {
    setNotesFieldValue('');
    setEventInfowindow(state, infowindow);
  };

  const onButtonClick = (event, newValue) => {
    setEventInfowindowTab(newValue);
  };

  const handleNotesChange = event => {
    setNotesFieldValue(event.target.value);
  };

  const handleDispositionChange = event => {
    setDispositionsFieldValue(event.target.value);
  };

  const submitNotesForm = (e, prevent = true) => {
    if (prevent) {
      e.preventDefault();
    }
    setEventNote(eventInfowindow.data, notesFieldValue);
    setNotesFieldValue('');
  };

  const submitDispositionsForm = e => {
    e.preventDefault();
    setEventDispositions(eventInfowindow.data, dispositionFieldValue);
    setDispositionsFieldValue([]);
  };

  const submitEventStatusForm = async e => {
    e.preventDefault();
    let showAlert = false;
    let closeEventWindow = false;

    const agency = user.userUnit.AgencyID;
    if (eventStatusValue === unitInserviceStatus) {
      closeEventWindow = true;
      showAlert = true;
      for (let i = 0; i < eventInfowindow.data.dispositions.length; i++) {
        if (eventInfowindow.data.dispositions[i].AgencyID === agency)
          showAlert = false;
      }
    }
    if (showAlert === true) {
      setConfirmDisposition(true);
    } else {
      if (closeEventWindow) {
        await setEventStatus(eventInfowindow.data, eventStatusValue);
        handleEventInfoWindowClose(false, null);
      } else {
        await setEventStatus(eventInfowindow.data, eventStatusValue);
      }
    }
  };
  const handleDispositionDialogClose = async () => {
    setConfirmDisposition(false);
    await setEventStatus(eventInfowindow.data, eventStatusValue);
    handleEventInfoWindowClose(false, null);
  };

  const handleEventStatusChange = event => {
    setEventStatusValue(event.target.value);
  };

  const handleEventAttachmentChange = event => {
    setAttachmentFileName(event.target.files[0].name);

    setAttachmentFileInfo({
      ...attachmentFileInfo,
      file: event.target.files[0]
    });

    setAttachmentModal(true);
  };

  const handleNotes = e => {
    const keyCode = e.which || e.keyCode;
    // 13 represents the Enter key
    if (keyCode === 13 && !e.shiftKey) {
      // Don't generate a new line
      e.preventDefault();
      submitNotesForm('e', false);
      // Do something else such as send the message to back-end ...
    }
  };
  const handleAttatchmentModalClose = () => {
    setAttachmentFileName('');
    setAttachmentFileInfo({ file: null, Description: '' });
    setAttachmentModal(false);
  };

  React.useEffect(() => {
    if (network.online === false) {
      // need to disable form submition
    } else {
      // console.log(eventInfowindow);

      if (eventInfowindow.open === true && eventInfowindow.data) {
        const { Status, myStatus = null, ptsEventID } = eventInfowindow.data;
        // console.log('user', user);
        const messageForSentry = JSON.stringify({
          Status,
          myStatus,
          ptsEventID,
          userID: user?.userData?.user?.ptsUserID || null
        });
        Sentry.captureMessage('Status Log: ' + messageForSentry);
        if (eventInfowindow.data.myStatus) {
          if (
            eventInfowindow.data.myStatus ===
              'CAD.DefaultCodes.UnitActions.Dispatch' ||
            eventInfowindow.data.myStatus ===
              'CAD.DefaultCodes.UnitActions.Queued'
          ) {
            const list = [
              {
                status: 'Dispatch',
                value: unitActionCodes['Dispatch'].Code,
                disabled: true
              },
              {
                status: 'Enroute',
                value: unitActionCodes['Enroute'].Code,
                disabled: false
              },
              {
                status: 'Arrived',
                value: unitActionCodes['Arrived'].Code,
                disabled: false
              },
              {
                status: 'Completed',
                value: unitActionCodes['Completed'].Code,
                disabled: false
              },
              {
                status: 'Leave event',
                value: unitInserviceStatus,
                disabled: false
              }
            ];
            setEventStatusesList(list);
            setEventStatusValue(unitActionCodes['Enroute'].Code);
            setIsEventsFormVisible(true);
          } else if (
            eventInfowindow.data.myStatus ===
            'CAD.DefaultCodes.UnitActions.Enroute'
          ) {
            const list = [
              {
                status: 'Dispatch',
                value: unitActionCodes['Dispatch'].Code,
                disabled: true
              },
              {
                status: 'Enroute',
                value: unitActionCodes['Enroute'].Code,
                disabled: true
              },
              {
                status: 'Arrived',
                value: unitActionCodes['Arrived'].Code,
                disabled: false
              },
              {
                status: 'Completed',
                value: unitActionCodes['Completed'].Code,
                disabled: false
              },
              {
                status: 'Leave event',
                value: unitInserviceStatus,
                disabled: false
              }
            ];
            setEventStatusesList(list);
            setEventStatusValue(unitActionCodes['Arrived'].Code);
            setIsEventsFormVisible(true);
          } else if (
            eventInfowindow.data.myStatus ===
            'CAD.DefaultCodes.UnitActions.Arrived'
          ) {
            const list = [
              {
                status: 'Dispatch',
                value: unitActionCodes['Dispatch'].Code,
                disabled: true
              },
              {
                status: 'Enroute',
                value: unitActionCodes['Enroute'].Code,
                disabled: true
              },
              {
                status: 'Arrived',
                value: unitActionCodes['Arrived'].Code,
                disabled: true
              },
              {
                status: 'Completed',
                value: unitActionCodes['Completed'].Code,
                disabled: false
              },
              {
                status: 'Leave event',
                value: unitInserviceStatus,
                disabled: false
              }
            ];
            setEventStatusesList(list);
            setEventStatusValue(unitActionCodes['Completed'].Code);
            setIsEventsFormVisible(true);
          } else if (
            eventInfowindow.data.myStatus ===
            'CAD.DefaultCodes.UnitActions.Completed'
          ) {
            const list = [
              {
                status: 'Dispatch',
                value: unitActionCodes['Dispatch'].Code,
                disabled: true
              },
              {
                status: 'Enroute',
                value: unitActionCodes['Enroute'].Code,
                disabled: true
              },
              {
                status: 'Arrived',
                value: unitActionCodes['Arrived'].Code,
                disabled: true
              },
              {
                status: 'Completed',
                value: unitActionCodes['Completed'].Code,
                disabled: true
              },
              {
                status: 'Leave event',
                value: unitInserviceStatus,
                disabled: false
              }
            ];
            setEventStatusesList(list);
            setEventStatusValue(unitInserviceStatus);
            setIsEventsFormVisible(true);
          }
        } else {
          if (
            eventInfowindow.data.Status !== unitActionCodes['Completed'].Code
          ) {
            const list = [
              {
                status: 'Dispatch',
                value: unitActionCodes['Dispatch'].Code,
                disabled: false
              },
              {
                status: 'Enroute',
                value: unitActionCodes['Enroute'].Code,
                disabled: false
              },
              {
                status: 'Arrived',
                value: unitActionCodes['Arrived'].Code,
                disabled: false
              },
              {
                status: 'Completed',
                value: unitActionCodes['Completed'].Code,
                disabled: false
              },
              {
                status: 'Leave event',
                value: unitInserviceStatus,
                disabled: false
              }
            ];
            setEventStatusesList(list);
            setEventStatusValue(unitActionCodes['Dispatch'].Code);
            setIsEventsFormVisible(true);
          } else {
            const list = [
              {
                status: 'Dispatch',
                value: unitActionCodes['Dispatch'].Code,
                disabled: true
              },
              {
                status: 'Enroute',
                value: unitActionCodes['Enroute'].Code,
                disabled: true
              },
              {
                status: 'Arrived',
                value: unitActionCodes['Arrived'].Code,
                disabled: true
              },
              {
                status: 'Completed',
                value: unitActionCodes['Completed'].Code,
                disabled: true
              },
              {
                status: 'Leave event',
                value: unitInserviceStatus,
                disabled: false
              }
            ];
            setEventStatusesList(list);
            setEventStatusValue('');
            setIsEventsFormVisible(false);
          }
        }
      }
    }
  }, [network, eventInfowindow]);
  useEffect(() => {
    filterMyHistory
      ? setHistory(unitHistory.filter(hist => hist.Unit == user.userUnit.Unit))
      : setHistory(unitHistory);
  }, [unitHistory, unitHistory.length]);
  // console.log('eventInfowindow', eventInfowindow);
  if (eventInfowindow.open === true && eventInfowindow.data) {
    return (
      <Draggable handle=".handle" positionOffset={{ x: '-50%', y: '-50%' }}>
        <div
          className="pts-event-draggable"
          style={{
            position: 'absolute',
            zIndex: 999,
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            padding: '0 5px'
          }}>
          <Card className="card-box pts-event-box">
            <IconButton
              style={{
                position: 'absolute',
                right: '-10px',
                top: '-10px'
              }}
              onClick={() => handleEventInfoWindowClose(false, null)}
              aria-label="close">
              <CloseIcon variant="contained" />
            </IconButton>
            <CardContent>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'end',
                  alignContent: 'flex-end',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}>
                <Tooltip arrow title={event.CallTypeDescription}>
                  <div className={classes.avatar}>
                    <div>
                      <EventIcon
                        CallType={event.CallType}
                        CallTypeDescription={event.CallTypeDescription}
                        color={getEventColor(event, colors)}
                      />
                    </div>
                  </div>
                </Tooltip>
                <div className="card-header--title">
                  <div
                    className="handle"
                    style={{
                      display: 'flex',
                      alignItems: 'end',
                      alignContent: 'flex-end',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      cursor: 'grab'
                    }}>
                    <div>
                      <div style={{ lineHeight: '21px' }}>
                        <b>{event.EventID}</b>
                      </div>{' '}
                      <div
                        style={{
                          lineHeight: '9px',
                          // color: getEventColor(event, colors)
                          fontWeight: '500'
                        }}
                        className="pb-2 lead">
                        <small>{event.CallTypeDescription}</small>
                      </div>
                      <div style={{ lineHeight: '10px' }}>
                        {displayDateTime(event.ReceiveDate)}
                      </div>
                    </div>
                    <div>
                      <div
                        className="badge"
                        style={{
                          background: getEventColor(event, colors),
                          width: '90px'
                        }}>
                        {event.Status}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: '10px',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}>
                    <div style={{ lineHeight: '15px' }}>
                      <div>
                        <b>{event.FullLocationAddress}</b>
                      </div>
                      <div>
                        <b>{event.LocationDescription}</b>
                      </div>
                      <div style={{ paddingTop: '2px' }}>Address Notes:</div>
                      <div
                        className="py-1"
                        style={{
                          maxHeight: '50px',
                          maxWidth: '320px',
                          padding: '2px',
                          overflowY: 'auto',
                          overflowWrap: 'break-word'
                        }}>
                        {event.AddressNotes}
                      </div>
                    </div>
                    {googlePath && (
                      <div style={{ alignSelf: 'center' }}>
                        <Button onClick={handleShowDirection}>
                          <IconButton
                            style={{
                              bottom: '3px',
                              padding: '0px',
                              margin: '0px 6px'
                            }}
                            aria-label="navigation">
                            <DirectionsIcon />
                          </IconButton>
                        </Button>
                      </div>
                    )}
                    <div>
                      {event.distance && <div>{event.distance.toFixed(2)}</div>}
                    </div>
                  </div>
                </div>
              </div>
              {isEventsFormVisible === true && (
                <div
                  style={{
                    position: 'fixed',
                    width: '100%',
                    bottom: '0px',
                    paddingRight: '55px'
                  }}>
                  <Typography
                    variant="h5"
                    component="h5"
                    style={{
                      backgroundColor: theme.palette.background
                    }}>
                    Next Status
                  </Typography>
                  <form
                    id="event-statuses-form"
                    onSubmit={submitEventStatusForm}
                    style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <FormControl required fullWidth size="small">
                      <Select
                        labelId="sevent-statuses-label"
                        id="event-statuses-select"
                        required
                        variant="outlined"
                        fullWidth
                        disabled={false}
                        style={{ paddingLeft: '60px' }}
                        value={eventStatusValue}
                        onChange={handleEventStatusChange}>
                        {eventStatusesList.map(e => {
                          return (
                            <MenuItem
                              key={e.status}
                              value={e.value}
                              disabled={e.disabled}>
                              {e.status}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <Fab
                        disabled={network.online === false ? true : false}
                        type="submit"
                        size="small"
                        style={{
                          position: 'absolute',
                          top: '-1px',
                          left: '10px'
                        }}
                        color="primary">
                        <FontAwesomeIcon icon={['fas', 'plus']} />
                      </Fab>
                    </FormControl>
                  </form>
                </div>
              )}

              <div
                style={{
                  marginTop: '5px',
                  padding: '5px 0px',
                  justifyContent: 'center',
                  alignContent: 'flex-start',
                  borderBottom: '1px solid rgba(7, 9, 25, 0.125)',
                  borderBottomRightRadius: '0',
                  borderBottomLeftRadius: '0'
                }}
                className="card-footer d-flex align-items-center bg-transparent p-2">
                <Button
                  onClick={ev => onButtonClick(ev, 0)}
                  className={classes.button}>
                  <div className="text-center my-1 p-1">
                    <div className="line-height-sm">
                      <b className="font-size-s">
                        {eventInfowindow.data.notes.length}
                      </b>
                      <span
                        style={{ fontWeight: '200', fontSize: '11px' }}
                        className="d-block">
                        Notes
                      </span>
                    </div>
                  </div>
                </Button>
                <Button
                  onClick={ev => onButtonClick(ev, 1)}
                  className={classes.button}>
                  <div className="text-center my-1 p-1">
                    <div className="line-height-sm">
                      <b className="font-size-s">0</b>
                      <span
                        style={{ fontWeight: '200', fontSize: '11px' }}
                        className="d-block">
                        Dets
                      </span>
                    </div>
                  </div>
                </Button>
                <Button
                  onClick={ev => onButtonClick(ev, 2)}
                  className={classes.button}>
                  <div className="text-center my-1 p-1">
                    <div className="line-height-sm">
                      <b className="font-size-s">
                        {eventInfowindow.data.dispositions.length}
                      </b>
                      <span
                        style={{ fontWeight: '200', fontSize: '11px' }}
                        className="d-block">
                        Disp
                      </span>
                    </div>
                  </div>
                </Button>
                <Button
                  onClick={ev => onButtonClick(ev, 3)}
                  className={classes.button}>
                  <div className="text-center my-1 p-1">
                    <div className="line-height-sm">
                      <b className="font-size-s">
                        {event.Attachments !== null
                          ? JSON.parse(eventInfowindow.data.Attachments).length
                          : 0}
                      </b>
                      <span
                        style={{ fontWeight: '200', fontSize: '11px' }}
                        className="d-block">
                        Attch
                      </span>
                    </div>
                  </div>
                </Button>
                <Button
                  onClick={ev => onButtonClick(ev, 4)}
                  className={classes.button}>
                  <div className="text-center my-1 p-1">
                    <div className="line-height-sm">
                      <b className="font-size-s">
                        {historyLoading ? (
                          <CircularProgress
                            color="primary"
                            size={15}
                            className={classes.buttonProgress}
                          />
                        ) : history ? (
                          history.length
                        ) : (
                          0
                        )}
                      </b>
                      <span
                        style={{ fontWeight: '200', fontSize: '11px' }}
                        className="d-block">
                        Hist
                      </span>
                    </div>
                  </div>
                </Button>
                <Button
                  onClick={ev => onButtonClick(ev, 5)}
                  className={classes.button}>
                  <div className="text-center my-1 p-1">
                    <div className="line-height-sm">
                      <b className="font-size-s">{cases ? cases.length : 0}</b>
                      <span
                        style={{ fontWeight: '200', fontSize: '11px' }}
                        className="d-block">
                        Cases
                      </span>
                    </div>
                  </div>
                </Button>
                <Button
                  onClick={ev => onButtonClick(ev, 6)}
                  className={classes.button}>
                  <div className="text-center my-1 p-1">
                    <div className="line-height-sm">
                      <b className="font-size-s">
                        {eventInfowindow.data.sops.length}
                      </b>
                      <span
                        style={{ fontWeight: '200', fontSize: '11px' }}
                        className="d-block">
                        Sops
                      </span>
                    </div>
                  </div>
                </Button>
              </div>
              <div
                className="card-indicator"
                style={{
                  backgroundColor: getEventColor(eventInfowindow.data, colors)
                }}
              />
              <Dialog
                fullWidth
                maxWidth="sm"
                open={confirmDisposition}
                onClose={() => handleDispositionDialogClose}
                aria-labelledby="responsive-dialog-title">
                <DialogTitle
                  id="responsive-dialog-title"
                  style={{ height: '100px' }}>
                  {`There are no dispositions for ${user?.userUnit?.AgencyID} agency!. Do You Want To Add ?`}
                </DialogTitle>

                <DialogActions>
                  <Button
                    autoFocus
                    onClick={handleDispositionDialogClose}
                    variant="outlined"
                    color="primary">
                    No
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setConfirmDisposition(false);
                      onButtonClick('', 2);
                      setOpneDispositionDropdown(true);
                    }}
                    autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
              <TabPanel value={activeTab} index={0}>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    minHeight: 200,
                    maxHeight: 200,
                    overflow: 'auto'
                  }}>
                  <form id="notes-form" onSubmit={submitNotesForm}>
                    <TextField
                      className="mb-2 mt-2"
                      id="notes-input"
                      label="Notes"
                      fullWidth
                      required
                      multiline
                      maxRows="3"
                      onKeyDown={handleNotes}
                      value={notesFieldValue}
                      onChange={handleNotesChange}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Fab
                              disabled={network.online === false ? true : false}
                              type="submit"
                              size="small"
                              color="primary">
                              <FontAwesomeIcon icon={['fas', 'plus']} />
                            </Fab>
                          </InputAdornment>
                        )
                      }}
                    />
                  </form>
                  <ul style={{ listStyle: 'none', padding: 0 }}>
                    {eventInfowindow.data.notes.map((note, index) => (
                      <Fragment key={note.ptsCommentID}>
                        <li>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              fontSize: '0.8rem'
                            }}>
                            <div
                              style={{
                                alignSelf: 'center',
                                fontStyle: 'italic',
                                fontWeight: 'bold'
                              }}>
                              {displayDateTime(note.Created)}
                            </div>
                            <div>
                              <span
                                className="badge"
                                style={{ fontSize: '100%' }}>
                                {note.CreatedBy}
                              </span>
                            </div>
                          </div>
                          <div style={{ fontSize: '0.8rem' }}>
                            {note.Comment}
                          </div>
                        </li>
                        <div className="divider my-1" />
                      </Fragment>
                    ))}
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column'
                  }}>
                  <div
                    style={{
                      maxHeight: '195px',
                      overflow: 'auto',
                      paddingBottom: '5px'
                    }}>
                    <div>
                      <div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                        Caller
                      </div>
                      {eventInfowindow.data.callers.map((caller, idx) => {
                        let callerPhone;
                        let callerName = '';
                        if (caller.FullName) {
                          callerName = caller.FullName;
                        } else {
                          callerName = `${caller.LastName ||
                            ''}, ${caller.FirstName ||
                            ''} ${caller.MiddleName || ''}`;
                        }
                        if (caller.Info != null)
                          callerPhone = `tel:${caller.Info}`;
                        return (
                          <Fragment key={idx}>
                            <div
                              style={{
                                fontSize: '0.8rem',

                                display: 'flex',
                                justifyContent: 'space-between'
                              }}>
                              <div
                                className={classes.callerItem}
                                style={{ flexBasis: '40%' }}>
                                {callerName}
                              </div>
                              <div
                                className={classes.callerItem}
                                style={{ flexBasis: '15%' }}>
                                <b>{caller.Relationship}</b>
                              </div>
                              <div
                                className={classes.callerItem}
                                style={{ flexBasis: '45%' }}>
                                <a href={callerPhone}>{caller.Info}</a>
                              </div>
                            </div>
                          </Fragment>
                        );
                      })}
                    </div>
                    <div className="divider my-1" />
                    {eventInfowindow.data.assignedUnits.length > 0 && (
                      <div>
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '0.8rem'
                          }}>
                          Units on Event
                        </span>
                        {eventInfowindow.data.assignedUnits.map(
                          (unit, index) => (
                            <div key={unit.ptsUnitID}>
                              <div
                                className="d-flex justify-content-between"
                                style={{
                                  alignItems: 'center',
                                  paddingTop: '5px'
                                }}>
                                <div style={{ display: 'flex' }}>
                                  <div className={classes.icon}>
                                    <div>
                                      <AgencyIcon
                                        color={getUnitColor(unit, colors)}
                                        AgencyID={unit.AgencyID}
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <div
                                      className="font-weight-bold"
                                      style={{
                                        lineHeight: '10px',
                                        minWidth: '125px'
                                      }}>
                                      <div>
                                        <span>
                                          {unit.Unit !== null
                                            ? unit.Unit
                                            : unit.ptsUnitID}
                                        </span>
                                      </div>
                                      <div>
                                        <small>
                                          {displayDateTime(unit.Occurred)}
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div
                                    className="badge"
                                    style={{
                                      background: getUnitColor(unit, colors),
                                      width: '90px'
                                    }}>
                                    {unit.UnitStatus}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={activeTab} index={2}>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    minHeight: 200,
                    maxHeight: 200,
                    overflow: 'auto'
                  }}>
                  <form
                    id="dispositions-form"
                    onSubmit={submitDispositionsForm}
                    style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <FormControl required fullWidth size="small">
                      <Select
                        labelId="dispositions-label"
                        id="dispositions-select"
                        multiple={false}
                        required
                        open={opneDispositionDropdown}
                        onOpen={() => setOpneDispositionDropdown(true)}
                        onClose={() => setOpneDispositionDropdown(false)}
                        autoFocus
                        variant="outlined"
                        fullWidth
                        style={{ paddingLeft: '60px' }}
                        value={dispositionFieldValue}
                        onChange={handleDispositionChange}>
                        {unselectedDispositons.map(d => (
                          <MenuItem key={d.id} value={d}>
                            {`${d.Disposition} (${d.Description}) - ${d.AgencyID}`}
                          </MenuItem>
                        ))}
                      </Select>
                      <Fab
                        disabled={network.online === false ? true : false}
                        type="submit"
                        size="small"
                        style={{
                          position: 'absolute',
                          top: '-1px',
                          left: '10px'
                        }}
                        color="primary">
                        <FontAwesomeIcon icon={['fas', 'plus']} />
                      </Fab>
                    </FormControl>
                  </form>
                  <ul style={{ listStyle: 'none', padding: 0 }}>
                    {eventInfowindow.data.dispositions.map(
                      (disposition, index) => (
                        <Fragment key={index}>
                          <li>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                fontSize: '0.8rem'
                              }}>
                              <div
                                style={{
                                  alignSelf: 'center',
                                  fontStyle: 'italic',
                                  fontWeight: 'bold'
                                }}>
                                {displayDateTime(disposition.Created)}
                              </div>
                              <div>
                                <span
                                  className="badge"
                                  style={{ fontSize: '100%' }}>
                                  {disposition.CreatedBy}
                                </span>
                              </div>
                              <div>
                                <span style={{ fontSize: '100%' }}>
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      setDispositionEventIdToDelete(
                                        eventInfowindow.data.ptsEventID
                                      );
                                      setDispositionToDelete({
                                        Disposition: disposition.Disposition,
                                        AgencyID: disposition.AgencyID
                                      });
                                      handleDispositonsDeleteClick();
                                    }}>
                                    <Delete
                                      fontSize="inherit"
                                      color="primary"
                                    />
                                  </IconButton>

                                  <AlertDialog
                                    key={index}
                                    alertBox={alertBox}
                                    change={() => deleteDisposition()}
                                    alertTitle={
                                      'Are you sure you want to remove disposition ?'
                                    }
                                    alertDescription={
                                      "If you press 'Yes, this disposition will be deleted."
                                    }
                                    closeAlertBox={closeAlertBox}
                                  />
                                </span>
                              </div>
                            </div>
                            <div style={{ fontSize: '0.8rem' }}>
                              {disposition.Disposition} (
                              {disposition.Description}) -{' '}
                              {disposition.AgencyID}
                            </div>
                          </li>
                          <div className="divider my-1" />
                        </Fragment>
                      )
                    )}
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value={activeTab} index={3}>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    minHeight: 200,
                    maxHeight: 200,
                    overflow: 'auto'
                  }}>
                  <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={attachmentModal}
                    // onClose={() => handleAttatchmentModalClose()}
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">
                      Attatchment Description
                    </DialogTitle>
                    <DialogContent>
                      <TextField
                        className="mb-2 mt-2"
                        id="attachment-description"
                        label="Description"
                        fullWidth
                        autoFocus
                        value={attachmentFileInfo.Description || ''}
                        variant="outlined"
                        onChange={e =>
                          setAttachmentFileInfo({
                            ...attachmentFileInfo,
                            Description: e.target.value
                          })
                        }
                        size="small"
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleAttatchmentModalClose}
                        variant="outlined"
                        color="primary">
                        Cancel
                      </Button>
                      <Button
                        disabled={attachmentFileInfo.Description ? false : true}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setEventAttachment(
                            eventInfowindow.data.ptsEventID,
                            attachmentFileInfo
                          );
                          handleAttatchmentModalClose();
                        }}>
                        Save
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <TextField
                    className="mb-2 mt-2"
                    id="attachment-input"
                    label="Attachment"
                    fullWidth
                    disabled
                    value={attachmentFileName}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <input
                            onClick={e => (e.target.value = null)}
                            onChange={e => handleEventAttachmentChange(e)}
                            style={{
                              display: 'none'
                            }}
                            id="icon-button-file"
                            type="file"
                            capture
                          />
                          <label htmlFor="icon-button-file">
                            <Fab
                              style={{
                                position: 'absolute',
                                top: 0,
                                right: 10
                              }}
                              className="MuiButtonBase-root MuiFab-root MuiFab-sizeSmall MuiFab-primary"
                              color="primary"
                              aria-label="upload picture"
                              component="span">
                              <FontAwesomeIcon icon={['fas', 'plus']} />
                            </Fab>
                          </label>
                        </InputAdornment>
                      )
                    }}
                  />
                  {eventInfowindow.data.Attachments && (
                    <ul style={{ listStyle: 'none', padding: 0 }}>
                      {JSON.parse(eventInfowindow.data.Attachments).map(
                        (attachment, i) => (
                          <AttachmentList info={attachment} key={i} />
                        )
                      )}
                    </ul>
                  )}
                </div>
              </TabPanel>
              <TabPanel value={activeTab} index={4}>
                <Checkbox
                  style={{ padding: '10px 0px' }}
                  checked={filterMyHistory}
                  onChange={handleFilterMyHistory}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />{' '}
                My Units's History
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    minHeight: 150,
                    maxHeight: 150,
                    overflow: 'auto'
                  }}>
                  {historyLoading ? (
                    <p style={{ width: '100%', textAlign: 'center' }}>
                      Loading...
                    </p>
                  ) : (
                    <ul style={{ listStyle: 'none', padding: 0 }}>
                      {history.map((unit, index) => (
                        <li key={index}>
                          <div
                            className="d-flex justify-content-between"
                            style={{
                              alignItems: 'center',
                              paddingTop: '5px',
                              paddingBottom: '5px',
                              backgroundColor:
                                index % 2 == 0
                                  ? 'rgba(0, 0, 0, 0.02)'
                                  : 'transparent'
                            }}>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}>
                              <div className={classes.icon}>
                                <div>
                                  <AgencyIcon
                                    color={getUnitColor(unit, colors)}
                                    AgencyID={unit.AgencyID}
                                  />
                                </div>
                              </div>
                              <div>
                                <div
                                  className="font-weight-bold"
                                  style={{
                                    lineHeight: '10px',
                                    minWidth: '260px'
                                  }}>
                                  <div>
                                    <span>
                                      {unit.Unit !== null
                                        ? unit.Unit
                                        : unit.ptsUnitID}
                                    </span>
                                  </div>
                                  <div>
                                    <small>
                                      {displayDateTime(unit.Occurred)}
                                    </small>
                                  </div>
                                  {unit.Location !== null && (
                                    <div>
                                      <small>
                                        <b>Location: </b>
                                        {unit.Location}
                                      </small>
                                    </div>
                                  )}
                                  {unit.OLN !== null && (
                                    <div>
                                      <small>
                                        <b>OLN: </b>
                                        {unit.OLN}{' '}
                                        {unit.OLNState !== null
                                          ? unit.OLNState
                                          : ''}
                                      </small>
                                    </div>
                                  )}
                                  {unit.Plate !== null && (
                                    <div>
                                      <small>
                                        <b>Plate: </b>
                                        {unit.Plate}{' '}
                                        {unit.PlateState !== null
                                          ? unit.PlateState
                                          : ''}
                                      </small>
                                    </div>
                                  )}
                                  {unit.Notes !== null && (
                                    <div>
                                      <small>
                                        <b>Notes: </b>
                                        {unit.Notes}
                                      </small>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div>
                              <div
                                className="badge"
                                style={{
                                  background: getUnitColor(unit, colors),
                                  width: '90px'
                                }}>
                                {unit.UnitStatus}
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </TabPanel>
              <TabPanel value={activeTab} index={5}>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    minHeight: 200,
                    maxHeight: 200,
                    overflow: 'auto'
                  }}>
                  {cases && (
                    <ul style={{ listStyle: 'none', padding: 0 }}>
                      {cases.map((c, index) => (
                        <Fragment key={index}>
                          <li style={{ marginBottom: '10px' }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                fontSize: '0.8rem',
                                lineHeight: '26px'
                              }}>
                              <div
                                style={{
                                  alignSelf: 'center',
                                  lineHeight: '26px',
                                  fontWeight: 'bold'
                                }}>
                                {c.CaseId ? c.CaseId : ''}
                              </div>
                            </div>
                            <div style={{ lineHeight: '1px' }}>
                              <span
                                style={{
                                  fontStyle: 'italic',
                                  fontSize: '0.7rem',
                                  paddingRight: '3px'
                                }}>
                                {c.AgencyId ? c.AgencyId : ''}
                              </span>
                            </div>
                          </li>
                          <div className="divider my-1" />
                        </Fragment>
                      ))}
                    </ul>
                  )}
                </div>
              </TabPanel>
              <TabPanel value={activeTab} index={6}>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    minHeight: 200,
                    maxHeight: 200,
                    overflow: 'auto'
                    // overflow: 'hidden'
                  }}>
                  <ul style={{ listStyle: 'none', padding: 0 }}>
                    {eventInfowindow.data.sops.map((sop, index) => {
                      const parsedSop = parseXmlSOP(sop);
                      return (
                        <Fragment key={index}>
                          <li>
                            <Fragment>
                              <Accordion
                                style={{
                                  marginBottom: '5px',
                                  backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                  boxShadow: 'none'
                                }}
                                expanded={expanded === sop.SOPID}
                                onChange={handleChange(sop.SOPID)}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header">
                                  <Typography>
                                    <b className="m-1 badge badge-secondary">
                                      {sop.SOPID}
                                    </b>{' '}
                                    ({parsedSop.ParentType} -{' '}
                                    <span>{parsedSop.ParentName})</span>
                                    <br />
                                    {parsedSop.DispatcherMessage}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className="d-flex align-items-center flex-column flex-sm-row">
                                    <table className={classes.questions}>
                                      <tbody>
                                        {parsedSop.DispatcherQuestions.map(
                                          (q, idx) => {
                                            switch (q.type) {
                                              case 'Text':
                                                return renderText(q);
                                              case 'Question':
                                                return renderQuestion(q, idx);
                                              case 'CheckBox':
                                                return renderCheckbox(q, idx);
                                              default:
                                                return '';
                                            }
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </Fragment>
                          </li>
                        </Fragment>
                      );
                    })}
                  </ul>
                </div>
              </TabPanel>
            </CardContent>
          </Card>
        </div>
      </Draggable>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => ({
  network: state.offline,
  dispositions: state.codes.dictionary.dispositions || [],
  unitActionCodes: state.data.unitActionCodes,
  normalizedUnitActionCodes: state.data.normalizedUnitActionCodes,
  eventInfowindow: state.map.eventInfowindow,
  activeTab: state.map.eventInfowindowTab,
  user: state.user,
  colors: state.codes.colorsCodes,
  unitActionCodes: state.data.normalizedUnitActionCodesCategory
});

export default connect(mapStateToProps, {
  setEventInfowindow,
  setEventInfowindowTab,
  setEventStatus,
  setEventNote,
  setEventDispositions,
  setEventAttachment,
  removeEventDisposition
})(DialogEvent);
