import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import formStyles, {
  gridStyle,
  Row,
  RowInner,
  ColCard,
  Fills,
  validatePostcode,
  parsePostcode
} from 'utils/formStyles';
import TextField from '@material-ui/core/TextField';
import FormDivider from '../../FormDivider';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { getPtsPerson, addOrEditPerson } from 'reducers/SearchReducer';
import {
  dateTimePicker,
  formatSaveDate,
  setDateOnly
} from 'reducers/TimeReducer';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import AddressLookup from '../../AddressLookup';
import {
  decodePhoneNo,
  validatePhoneNo,
  validateEmail
} from 'utils/formStyles';
import TextField2 from '../../TextField2';
import { useError } from 'utils/formStyles';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import {
  closeAddPerson,
  closeEditPerson,
  showEditPerson
} from 'reducers/DialogsReducer';
import Dictionary from '../../Dictionary';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { setFullAddressText } from 'utils/functions';

const useStyles = makeStyles(theme => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3)
  },
  item: gridStyle(200, 800),
  addressFiled: gridStyle('100%', '100%'),
  field: gridStyle(210, 400),
  actions: {
    textAlign: 'right',
    marginTop: 8,
    '& button': {
      marginLeft: 8,
      '& svg': {
        marginRight: theme.spacing(1)
      }
    }
  }
}));

function PersonTab(props) {
  const classes = useStyles();
  const { personId, close, ptsAddressID, personDetailsData } = props;
  const {
    NamePrefixes,
    NameSuffixes,

    ethnicityCodes: Ethnicity,
    eyeColorCodes: EyeColor,

    hairColorCodes: HairColor,

    sexCodes: Sex,
    raceCodes: Race,
    Counties,
    Countries,
    codeCities: Cities,
    statesCodes: States,
    ContactMethodTypes,
    ContactMethods,
    copyAddress
  } = props.dictionary;
  const [firstname, setFirstname] = useState('');
  const [middlename, setMiddlename] = useState('');
  const [lastname, setLastname] = useState('');
  const [nickname, setNickname] = useState('');
  const [prefix, setPrefix] = useState(null);
  const [suffix, setSuffix] = useState(null);

  const [race, setRace] = useState(null);
  const [sex, setSex] = useState(null);
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [birthdate, setBirthdate] = useState(null);

  // const [gang, setGang] = useState(null);
  // const [maritalStatus, setMaritalStatus] = useState(null);
  // const [educationLevel, setEducationLevel] = useState('');
  // const [allergies, setAllergies] = useState('');
  // const [dna, setDna] = useState('');
  const [notes, setNotes] = useState('');

  const [ethnicity, setEthnicity] = useState(null);
  const [eyecolor, setEyecolor] = useState(null);

  const [haircolor, setHaircolor] = useState(null);

  const [ssn, setSsn] = useState('');
  const [oln, setOln] = useState('');
  const [state, setState] = useState(null);
  const [address, setAddress] = useState('');
  const [isPrimary, setIsPrimary] = useState(false);
  const [contactType, setContactType] = useState(null);
  const [contactMethodType, setContactMethodType] = useState(null);
  const [info, setInfo] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [contactNotes, setContactNotes] = useState('');
  const [errors, setErrors] = useState();

  const [AddressCategory, setAddressCategory] = useState(null);

  const [AddressNumber, setAddressNumber] = useState('');
  const [AddressNumberPrefix, setAddressNumberPrefix] = useState('');
  const [AddressNumberSuffix, setAddressNumberSuffix] = useState('');
  const [PreDirection, setPreDirection] = useState(null);
  const [PostDirection, setPostDirection] = useState(null);
  const [StreetName, setStreetName] = useState('');
  const [StreetType, setStreetType] = useState(null);
  const [City, setCity] = useState(null);
  const [AddressNotes, setAddressNotes] = useState();
  const [Postal, setPostal] = useState('');
  const [County, setCounty] = useState(null);
  const [Country, setCountry] = useState(null);
  const [AddressState, setAddressState] = useState();
  const [UnitType, setUnitType] = useState(null);
  const [UnitIdentifier, setUnitIdentifier] = useState('');
  const [FullAddressText, setFullAddressTextLocal] = useState('');
  const { err } = useError(errors, setErrors);
  const dispatch = useDispatch();
  // const perms = getFullPermissions('globals', 'People', 'any');
  const isNewRecord = !personId;
  // const saveAllowed = isNewRecord ? perms.Create : perms.Edit;

  useEffect(() => {
    if (address && address.ptsAddressID) {
      setAddressCategory(null);
      setAddressNumber('');
      setAddressNumberPrefix('');
      setAddressNumberSuffix('');
      setPreDirection(null);
      setPostDirection(null);
      setStreetName('');
      setStreetType(null);
      setCity(null);
      setState(null);
      setPostal('');
      setCounty(null);
      setCountry(null);

      setUnitType(null);
      setUnitIdentifier('');
    }
  }, [address]);
  useEffect(() => {
    if (personDetailsData && personDetailsData.ptsPersonID) {
      const {
        Age,

        Born,

        Ethnicity: fetchedEthnicity,

        EyeColor: fetchedEyeColor,

        FirstName,

        HairColor: fetchedHairColor,

        Height,

        LastName,

        MiddleName,

        Notes,

        Prefix,

        Race: fetchedRace,

        Sex: fetchedSex,

        Suffix,
        Weight
      } = personDetailsData;
      setFirstname(FirstName || '');
      setMiddlename(MiddleName || '');
      setLastname(LastName || '');

      if (Prefix) {
        const prefix = NamePrefixes?.find(p => p.Code === Prefix);
        setPrefix(prefix);
      }

      if (Suffix) {
        const suffix = NameSuffixes?.find(s => s.Code === Suffix);
        setSuffix(suffix);
      }

      const race = Race?.find(r => r.Code === fetchedRace);
      setRace(race || null);
      const sex = Sex?.find(s => s.Code === fetchedSex);
      setSex(sex || null);
      setHeight(Height || '');
      setWeight(Weight || '');
      setBirthdate(dateTimePicker(Born) || null);
      // setAge(Age);

      /* set value of cause of death depends on  person deceased or not */

      setNotes(Notes || '');

      const ethnicity = Ethnicity?.find(e => e.Code === fetchedEthnicity);
      setEthnicity(ethnicity || null);
      const eyeColor = EyeColor?.find(e => e.Code === fetchedEyeColor);
      setEyecolor(eyeColor || null);

      const hairColor = HairColor?.find(h => h.Code == fetchedHairColor);
      setHaircolor(hairColor || null);
    }
  }, [personId, personDetailsData]);

  const renderExistingPostal = (code, ext) => {
    if (!code) return;
    if (!ext) setPostal(code);
    else setPostal(`${code} - ${ext}`);
  };
  useEffect(() => {
    const form = processAddressObject();
    const fullAddressText = setFullAddressText(form);

    setFullAddressTextLocal(fullAddressText);
    // eslint-disable-next-line
  }, [
    AddressNumber,
    AddressNumberPrefix,
    AddressNumberSuffix,
    PreDirection,
    PostDirection,
    StreetName,
    StreetType,
    City,
    AddressState,
    Postal,
    UnitType,
    UnitIdentifier
  ]);

  const save = async () => {
    const FullName =
      `${firstname} ` + (middlename ? `${middlename} ` : '') + lastname;

    let data = {
      person: {
        FirstName: firstname || null,
        MiddleName: middlename || null,
        LastName: lastname || null,
        FullName,

        Prefix: prefix ? prefix.Code : null,
        Suffix: suffix ? suffix.Code : null,

        Race: race ? race.Code : null,
        Sex: sex ? sex.Code : null,
        Height: height || null,
        Weight: weight || null,
        Born: formatSaveDate(birthdate),

        Notes: notes || null,

        Ethnicity: ethnicity ? ethnicity.Code : null,
        EyeColor: eyecolor ? eyecolor.Code : null,

        HairColor: haircolor ? haircolor.Code : null
      }
    };
    if (!personId) {
      const contactCreds = {
        contact: {
          ContactMethod: contactMethodType ? contactMethodType.Code : null, // mail, phone
          MethodType: contactType ? contactType.Code : null,
          Info: info || null,
          IsDefault: isDefault,
          Notes: contactNotes || null,

          IsPrimary: isPrimary,
          PartyType: 'Person'
        },
        credentials: {
          SSN: ssn,
          OLN: oln,
          State: state ? state.Code : null
        },
        addressDetails: {
          ptsAddressID: address
            ? address.ptsAddressID
            : ptsAddressID
            ? ptsAddressID
            : null,
          ...processAddressObject()
        }
      };
      delete contactCreds.addressDetails.CityName;
      Object.assign(data, contactCreds);
    }

    if (
      contactMethodType &&
      contactMethodType.Code === 'Phone' &&
      info.length > 10
    ) {
      data = { ...data, contact: { ...data.contact, ...decodePhoneNo(info) } };
    }

    try {
      const ptsPersonID = await addOrEditPerson(personId, data);
      dispatch(
        notifyDataUpdate({
          type: 'Person',
          data: { action: personId ? 'Update' : 'Create', ptsPersonID }
        })
      );
      props.notify('Person data saved', 'success');

      if (isNewRecord) {
        props.closeAddPerson();
        props.showEditPerson({ ptsPersonID });
      }
    } catch (err) {
      props.handleError(err);
    }
  };
  const processAddressObject = () => {
    const { PostalCode, PostalCodeExtension } = parsePostcode(Postal);
    return {
      AddressCategory: AddressCategory?.Code || null,
      AddressNumber: AddressNumber || null,
      AddressNumberPrefix: AddressNumberPrefix || null,
      AddressNumberSuffix: AddressNumberSuffix || null,
      PreDirection: PreDirection?.Code || null,
      PostDirection: PostDirection?.Code || null,
      StreetName: StreetName || null,
      StreetType: StreetType?.Code || null,
      ptsCityID: City?.ptsCityID || null,
      State: AddressState?.Code || null,
      CityName: City?.Code || null,
      PostalCode,
      PostalCodeExtension,
      County: County?.Code || null,
      Country: Country?.Code || null,
      Notes: AddressNotes || null,
      UnitType: UnitType?.Code || null,
      UnitIdentifier: UnitIdentifier,

      FullAddressText
    };
  };
  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={save}
          disabled={!firstname || !lastname}>
          <SaveIcon /> Save
        </Button>
        <Button color="inherit" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderPrefix = () => {
    const onChange = (ev, val) => setPrefix(val);
    return (
      <Dictionary
        options="NamePrefixes"
        className={classes.item}
        onChange={onChange}
        value={prefix}
        label="Prefix"
      />
    );
  };

  const renderSuffix = () => {
    const onChange = (ev, val) => setSuffix(val);
    return (
      <Dictionary
        options="NameSuffixes"
        className={classes.item}
        onChange={onChange}
        value={suffix}
        label="Suffix"
      />
    );
  };

  const renderFirstname = () => {
    return (
      <TextField
        label="First Name"
        variant="outlined"
        onChange={e => setFirstname(e.target.value)}
        value={firstname}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderMiddleName = () => {
    return (
      <TextField
        label="Middle Name"
        variant="outlined"
        onChange={e => setMiddlename(e.target.value)}
        value={middlename}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderLastName = () => {
    return (
      <TextField
        label="Last Name"
        variant="outlined"
        onChange={e => setLastname(e.target.value)}
        value={lastname}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderHeight = () => {
    const handleHeight = e => setHeight(e.target.value);
    return (
      <TextField
        label="Height"
        variant="outlined"
        onChange={handleHeight}
        value={height}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderWeight = () => {
    return (
      <TextField
        label="Weight"
        variant="outlined"
        onChange={e => setWeight(e.target.value)}
        value={weight}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderSex = () => {
    const onChange = (ev, val) => setSex(val);
    return (
      <Dictionary
        options="sexCodes"
        className={classes.item}
        onChange={onChange}
        value={sex}
        label="Sex"
      />
    );
  };

  const renderRace = () => {
    const onChange = (ev, val) => setRace(val);
    return (
      <Dictionary
        options="raceCodes"
        className={classes.item}
        onChange={onChange}
        value={race}
        label="Race"
      />
    );
  };

  const renderBirthDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Birth Date"
          value={birthdate}
          onChange={date => setBirthdate(date)}
          autoOk
          size="small"
          className={classes.item}
        />
        {/* <KeyboardDatePicker
          style={{ marginTop: 0, paddingTop: 0 }}
          key="person_dob"
          variant="inline"
          inputVariant="outlined"
          size="small"
          className={classes.item}
          value={birthdate}
          placeholder="Birth Date"
          onChange={date => setBirthdate(date)}
          format="MM/DD/yyyy"
        /> */}
      </MuiPickersUtilsProvider>
    );
  };

  const renderNotes = () => {
    return (
      <TextField
        label="Person Notes"
        variant="outlined"
        onChange={e => setNotes(e.target.value)}
        value={notes}
        size="small"
        className={classes.item}
        multiline
        minRows={5}
      />
    );
  };

  const renderEthnicity = () => {
    const onChange = (ev, val) => setEthnicity(val);
    return (
      <Dictionary
        options="ethnicityCodes"
        className={classes.item}
        onChange={onChange}
        value={ethnicity}
        label="Ethnicity"
      />
    );
  };

  const renderEyeColor = () => {
    const onChange = (ev, val) => setEyecolor(val);
    return (
      <Dictionary
        options="eyeColorCodes"
        className={classes.item}
        onChange={onChange}
        value={eyecolor}
        label="Eye Color"
      />
    );
  };

  const renderHairColor = () => {
    const onChange = (ev, val) => setHaircolor(val);
    return (
      <Dictionary
        options="hairColorCodes"
        className={classes.item}
        onChange={onChange}
        value={haircolor}
        label="Hair Color"
      />
    );
  };

  const renderSsn = () => {
    return (
      <TextField
        label="SSN"
        variant="outlined"
        onChange={e => setSsn(e.target.value)}
        value={ssn}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderOln = () => {
    return (
      <TextField
        label="OLN"
        variant="outlined"
        onChange={e => setOln(e.target.value)}
        value={oln}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderState = () => {
    const onChange = (ev, val) => setState(val);
    return (
      <Dictionary
        options="statesCodes"
        className={classes.item}
        onChange={onChange}
        value={state}
        label="State"
      />
    );
  };

  const renderPrimary = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={isPrimary}
            onChange={() => setIsPrimary(!isPrimary)}
            color="primary"
          />
        }
        label="Primary"
        className={classes.item}
      />
    );
  };

  const renderContactType = () => {
    const onChange = (ev, val) => setContactType(val);
    return (
      <Dictionary
        options="ContactMethodTypes"
        className={classes.item}
        onChange={onChange}
        value={contactType}
        label="Contact Type"
      />
    );
  };

  const renderMethodType = () => {
    const onChange = (ev, val) => setContactMethodType(val);
    return (
      <Dictionary
        options="ContactMethods"
        className={classes.item}
        onChange={onChange}
        value={contactMethodType}
        label="Method Type"
      />
    );
  };

  const renderInfo = () => {
    const onChange = (ev, val) => {
      setInfo(val);
    };
    const type = contactMethodType?.Code;
    let error;
    if (type === 'Phone') {
      error = err(validatePhoneNo(info), 'Info', 'Invalid phone number');
    }
    if (type === 'Email') {
      error = err(validateEmail(info), 'Info', 'Invalid email address');
    }

    return (
      <TextField2
        className={classes.item}
        label="Info"
        value={info}
        onChange={onChange}
        max={100}
        error={error}
        type={type}
        disabled={!contactMethodType}
      />
    );
  };

  const renderDefault = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={isDefault}
            onChange={() => setIsDefault(!isDefault)}
            color="primary"
          />
        }
        label="Default"
        className={classes.item}
      />
    );
  };

  const renderContactNotes = () => {
    return (
      <TextField
        label="Notes"
        variant="outlined"
        onChange={e => setContactNotes(e.target.value)}
        value={contactNotes}
        size="small"
        className={classes.item}
      />
    );
  };
  const renderAddressNumberPrefix = () => {
    const onChange = (ev, val) => setAddressNumberPrefix(val);
    return (
      <TextField2
        className={classes.item}
        label="Prefix"
        value={AddressNumberPrefix}
        onChange={onChange}
        max={20}
        disabled={address && address.ptsAddressID ? true : false}
      />
    );
  };

  const renderAddressNumberSuffix = () => {
    const onChange = (ev, val) => setAddressNumberSuffix(val);
    return (
      <TextField2
        className={classes.item}
        label="Suffix"
        value={AddressNumberSuffix}
        onChange={onChange}
        max={20}
        disabled={address && address.ptsAddressID ? true : false}
      />
    );
  };

  const renderAddressNumber = () => {
    const onChange = (ev, val) => setAddressNumber(val);
    // const error = err(AddressNumber, 'AddressNumber');
    return (
      <TextField2
        className={classes.item}
        label="Number"
        value={AddressNumber}
        onChange={onChange}
        type="number"
        disabled={address && address.ptsAddressID ? true : false}
        // error={error}
      />
    );
  };

  const renderPreDirection = () => {
    const onChange = (ev, val) => setPreDirection(val);
    return (
      <Dictionary
        options="StreetDirections"
        className={classes.item}
        onChange={onChange}
        value={PreDirection}
        label="Predirectional"
        disabled={address && address.ptsAddressID ? true : false}
      />
    );
  };

  const renderStreetName = () => {
    const onChange = (ev, val) => setStreetName(val);
    const error = err(StreetName.length > 1, 'StreetName');
    return (
      <TextField2
        className={classes.item}
        label="Street Name"
        value={StreetName}
        onChange={onChange}
        disabled={address && address.ptsAddressID ? true : false}
        error={error}
        max={100}
      />
    );
  };

  const renderStreetType = () => {
    const onChange = (ev, val) => setStreetType(val);
    return (
      <Dictionary
        options="StreetTypes"
        className={classes.item}
        onChange={onChange}
        value={StreetType}
        label="Street Type"
        disabled={address && address.ptsAddressID ? true : false}
      />
    );
  };

  const renderPostDirection = () => {
    const onChange = (ev, val) => setPostDirection(val);
    return (
      <Dictionary
        options="StreetDirections"
        className={classes.item}
        onChange={onChange}
        value={PostDirection}
        label="Postdirectional"
        disabled={address && address.ptsAddressID ? true : false}
      />
    );
  };

  const renderCities = () => {
    const onChange = (ev, val) => {
      setCity(val);
    };
    const error = err(City, 'City');
    return (
      <Dictionary
        options="codeCities"
        className={classes.item}
        onChange={onChange}
        value={City}
        disabled={address && address.ptsAddressID ? true : false}
        label="City"
        error={error}
      />
    );
  };
  const renderAddressState = () => {
    const onChange = (ev, val) => setAddressState(val);
    return (
      <Dictionary
        options="statesCodes"
        className={classes.item}
        onChange={onChange}
        value={AddressState}
        label="Address State"
        disabled={address && address.ptsAddressID ? true : false}
      />
    );
  };
  const renderAddressLookup = () => {
    const clearPlace = () => setAddress(null);
    return (
      <AddressLookup
        className={classes.addressFiled}
        ptsPlaces={false}
        ptsAddresses={true}
        googleAddresses={false}
        onAddressValueSet={setAddress}
        defaultLocation={null}
        onReset={clearPlace}
        label="Address Lookup"
      />
    );
  };
  const renderPostal = () => {
    const onChange = (ev, val) => setPostal(val);
    const isValid = !Postal || validatePostcode(Postal);
    const error = err(isValid, 'Postal', 'Must consist of 5 or 9 digits');
    return (
      <TextField2
        className={classes.item}
        label="Postal Code - Ext."
        value={Postal}
        onChange={onChange}
        error={error}
        type="postcode"
        disabled={address && address.ptsAddressID ? true : false}
      />
    );
  };
  const renderUnitType = () => {
    const onChange = (ev, val) => setUnitType(val);
    const error = !UnitType && UnitIdentifier ? 'Unit Type is missing' : null;
    return (
      <Dictionary
        className={classes.item}
        options="AddressUnitTypes"
        value={UnitType}
        onChange={onChange}
        label="Unit Type"
        error={error}
        disabled={address && address.ptsAddressID ? true : false}
      />
    );
  };

  const renderUnitIdentifier = () => {
    const onChange = (ev, val) => setUnitIdentifier(val);
    const error =
      UnitType && !UnitIdentifier ? 'Unit Identifier is missing' : null;
    return (
      <TextField2
        className={classes.item}
        label="Unit Identifier"
        value={UnitIdentifier}
        onChange={onChange}
        max={20}
        error={error}
        autoFocus={copyAddress}
        disabled={address && address.ptsAddressID ? true : false}
      />
    );
  };

  return (
    <>
      <Row>
        <ColCard minWidth={500}>
          <FormDivider title="Name" />
          <RowInner>
            {renderFirstname()}
            {renderMiddleName()}
            {renderLastName()}
            {renderSuffix()}
            {renderRace()}
            {renderSex()}
            {renderBirthDate()}
            {renderEthnicity()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
      </Row>
      <Row>
        <ColCard minWidth={500}>
          <FormDivider title="Features" />
          <RowInner>
            {renderHeight()}
            {renderWeight()}
            {renderEyeColor()}

            {renderHairColor()}

            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
        {!personId && (
          <ColCard minWidth={500}>
            <FormDivider title="Credentials" />
            <RowInner>
              {renderSsn()}
              {renderOln()}
              {renderState()}
              <Fills className={classes.item} />
            </RowInner>
          </ColCard>
        )}
      </Row>
      {!personId && (
        <Row>
          <ColCard minWidth={500}>
            <FormDivider title="Address Details" />
            <RowInner>
              {renderAddressLookup()}

              {renderAddressNumberPrefix()}
              {renderAddressNumber()}
              {renderAddressNumberSuffix()}
              {renderPreDirection()}
              {renderStreetName()}
              {renderStreetType()}
              {renderPostDirection()}
              {renderCities()}
              {renderAddressState()}
              {renderPostal()}
              {renderUnitType()}
              {renderUnitIdentifier()}
              <Fills className={classes.item} />
            </RowInner>
          </ColCard>
        </Row>
      )}

      <Row>
        {!personId && (
          <ColCard minWidth={500}>
            <FormDivider title="Contact" />
            <RowInner>
              {renderContactType()}
              {renderMethodType()}
              {renderInfo()}
              {renderDefault()}
              {renderContactNotes()}
              <Fills className={classes.item} />
            </RowInner>
          </ColCard>
        )}
        <ColCard minWidth={500}>
          <FormDivider title="Notes" />
          <RowInner>{renderNotes()}</RowInner>
        </ColCard>
      </Row>

      <div className={classes.actions}>{renderActions()}</div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    dictionary: state.codes.dictionary
  };
};

export default connect(mapStateToProps, {
  closeAddPerson,
  closeEditPerson,
  notify,
  handleError,
  showEditPerson
})(PersonTab);
