// Map
import store from '../config/configureStore';
import { enqueueSnackbar } from './NotifierReducer';
import { saveUserSettings } from './UserReducer';

export const SET_MAP = 'MAP/SET_MAP';
export const SET_MAP_LOADED = 'MAP/SET_MAP_LOADED';
export const SET_MAP_ERROR = 'MAP/SET_MAP_ERROR';
export const SET_MAP_CENTER = 'MAP/SET_MAP_CENTER';
export const SET_MAP_ZOOM = 'MAP/SET_MAP_ZOOM';
export const SET_MAP_BOUNDS = 'MAP/SET_MAP_BOUNDS';
export const SET_EVENT_INFOWINDOW = 'MAP/SET_EVENT_INFOWINDOW';
export const SET_EVENT_INFOWINDOW_TAB = 'MAP/SET_EVENT_INFOWINDOW_TAB';
export const SET_UNIT_INFOWINDOW = 'MAP/SET_UNIT_INFOWINDOW';
export const SET_DIRECTION_INFOWINDOW = 'MAP/SET_DIRECTION_INFOWINDOW';
export const SET_MAP_DEFAULT_VIEW = 'SETTINGS/SET_MAP_DEFAULT_VIEW';
export const SET_UNITS_COORDINATES = 'MAP/SET_UNITS_COORDINATES';
export const setMap = (map, units = [], events = []) => {
  const state = store.store.getState();
  const mapDefaultView = state.settings.mapDefaultView;
  const unitsCoords = state.map.unitsCoordinates;

  return async dispatch => {
    if (mapDefaultView === null) {
      var bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(1, -180),
        new window.google.maps.LatLng(-1, 180)
      );

      if (units.length !== 0) {
        units.map(unit => {
          const position = unitsCoords[unit.ptsUnitID];
          if (position) {
            bounds.extend(position);
          }
        });

        map.fitBounds(bounds);
      }

      if (events.length !== 0) {
        events.map(event => {
          if (
            event.LatitudeSign &&
            event.LongitudeSign &&
            event.LatitudeDegree &&
            event.LongitudeDegree
          ) {
            let latitude = `${
              event.LatitudeSign === '-' ? event.LatitudeSign : ''
            }${event.LatitudeDegree}`;
            let longitude = `${
              event.LongitudeSign === '-' ? event.LongitudeSign : ''
            }${event.LongitudeDegree}`;

            let position = {
              lat: parseFloat(latitude),
              lng: parseFloat(longitude)
            };
            bounds.extend(position);
          }
        });

        map.fitBounds(bounds);
      }
      if (events.length === 0 && units.length === 0) map.setZoom(2);
    } else {
      map.setZoom(mapDefaultView.zoom);
      map.setCenter(mapDefaultView.center);
    }

    dispatch({ type: SET_MAP_BOUNDS, payload: bounds });
    dispatch({ type: SET_MAP, payload: map });
  };
};

export const saveMapPositionReducer = () => {
  const map = store.store.getState().map.map;
  const zoom = map.getZoom();
  const center = map.getCenter().toJSON();
  return async dispatch => {
    dispatch({ type: SET_MAP_DEFAULT_VIEW, payload: { center, zoom } });
    dispatch(saveUserSettings());
    // dispatch(
    //   enqueueSnackbar({
    //     message: 'Default map view has been saved.',
    //     options: {
    //       key: new Date().getTime() + Math.random(),
    //       variant: 'success',
    //       anchorOrigin: {
    //         vertical: 'top',
    //         horizontal: 'center'
    //       }
    //     }
    //   })
    // );
  };
};

export const restoreMapPositionReducer = () => {
  const map = store.store.getState().map.map;
  const mapDefaultView = store.store.getState().settings.mapDefaultView;
  return async dispatch => {
    if (mapDefaultView !== null) {
      map.setZoom(mapDefaultView.zoom);
      map.setCenter(mapDefaultView.center);
      // dispatch(
      //   enqueueSnackbar({
      //     message: 'Default map view has been restored.',
      //     options: {
      //       key: new Date().getTime() + Math.random(),
      //       variant: 'success',
      //       anchorOrigin: {
      //         vertical: 'top',
      //         horizontal: 'center'
      //       }
      //     }
      //   })
      // );
    } else {
      dispatch(
        enqueueSnackbar({
          message: 'There is no default map view saved.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          }
        })
      );
    }
  };
};
export const setMapLoaded = payload => {
  return dispatch => {
    dispatch({ type: SET_MAP_LOADED, payload });
  };
};
export const setMapError = payload => {
  return dispatch => {
    dispatch({ type: SET_MAP_ERROR, payload });
  };
};
export const setEventInfowindow = (state, event, isInitited = false) => {
  const events = store.store.getState().events.events;

  return async dispatch => {
    dispatch({
      type: SET_UNIT_INFOWINDOW,
      payload: { open: false, data: null }
    });
    if (isInitited) {
      const ptsEventID = event;
      event = null;
      if (events?.length > 0) {
        const updatedEvent = events.find(e => e.ptsEventID === ptsEventID);
        if (updatedEvent) {
          event = updatedEvent;
        }
      }
    }

    if (event !== null) {
      if (event.LatitudeDegree !== null && event.LongitudeDegree !== null) {
        const latitude = `${
          event.LatitudeSign === '-' ? event.LatitudeSign : ''
        }${event.LatitudeDegree}`;
        const longitude = `${
          event.LongitudeSign === '-' ? event.LongitudeSign : ''
        }${event.LongitudeDegree}`;
        const position = {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude)
        };
        dispatch({
          type: SET_EVENT_INFOWINDOW,
          payload: { open: state, data: event }
        });
        dispatch({ type: SET_MAP_CENTER, payload: position });
        dispatch({ type: SET_MAP_ZOOM, payload: 15 });
      } else {
        dispatch({
          type: SET_EVENT_INFOWINDOW,
          payload: { open: state, data: event }
        });
      }
    } else {
      dispatch({
        type: SET_EVENT_INFOWINDOW,
        payload: { open: state, data: event }
      });
    }
  };
};

export const setEventInfowindowTab = tab => {
  return async dispatch => {
    dispatch({ type: SET_EVENT_INFOWINDOW_TAB, payload: tab });
  };
};

export const actualUnit = unit => {
  const units = [...store.store.getState().units.units];
  let _unit = units.filter(u => u.ptsUnitID == unit.ptsUnitID);
  return async dispatch => {
    dispatch(setUnitInfowindow(true, _unit[0]));
  };
};

export const actualEvent = event => {
  let events = [...store.store.getState().events.events];
  let _event = events.filter(e => e.ptsEventID == event.ptsEventID);
  return async dispatch => {
    dispatch(setEventInfowindow(true, _event[0]));
  };
};

export const setUnitInfowindow = (state, unit) => {
  const rootSate = store.store.getState();
  const unitsCoords = rootSate.map.unitsCoordinates;
  return async dispatch => {
    dispatch({
      type: SET_EVENT_INFOWINDOW,
      payload: { open: false, data: null }
    });
    if (unit !== null) {
      const position = unitsCoords[unit.ptsUnitID];
      if (position) {
        dispatch({
          type: SET_UNIT_INFOWINDOW,
          payload: { open: state, data: unit }
        });
        dispatch({ type: SET_MAP_CENTER, payload: position });
        dispatch({ type: SET_MAP_ZOOM, payload: 15 });
      } else {
        dispatch({
          type: SET_UNIT_INFOWINDOW,
          payload: { open: state, data: unit }
        });
      }
    } else {
      dispatch({
        type: SET_UNIT_INFOWINDOW,
        payload: { open: state, data: unit }
      });
    }
  };
};
export const setDirectionInfoWindo = (open, data = null) => {
  return dispatch => {
    dispatch({ type: SET_DIRECTION_INFOWINDOW, payload: { open, data } });
  };
};

export const setUnitsCoordinates = data => {
  return dispatch => {
    dispatch({ type: SET_UNITS_COORDINATES, payload: data });
  };
};
export default function reducer(
  state = {
    // Map
    map: null,
    mapLoaded: false,
    mapError: false,
    mapcenter: { lat: 0, lng: 0 },
    mapzoom: 7,
    mapbounds: null,
    eventInfowindow: { open: false, data: null },
    eventInfowindowTab: 0,
    unitInfowindow: { open: false, data: null },
    directionInfoWindow: { open: false, data: null },
    unitsCoordinates: {}
  },
  action
) {
  switch (action.type) {
    // Map
    case SET_MAP:
      return {
        ...state,
        map: action.payload
      };
    case SET_MAP_CENTER:
      return {
        ...state,
        mapcenter: action.payload
      };
    case SET_MAP_ZOOM:
      return {
        ...state,
        mapzoom: action.payload
      };
    case SET_MAP_BOUNDS:
      return {
        ...state,
        mapbounds: action.payload
      };
    case SET_EVENT_INFOWINDOW:
      return {
        ...state,
        eventInfowindow: action.payload
      };
    case SET_EVENT_INFOWINDOW_TAB:
      return {
        ...state,
        eventInfowindowTab: action.payload
      };
    case SET_UNIT_INFOWINDOW:
      return {
        ...state,
        unitInfowindow: action.payload
      };
    case SET_DIRECTION_INFOWINDOW:
      return {
        ...state,
        directionInfoWindow: action.payload
      };
    case SET_MAP_LOADED:
      return {
        ...state,
        mapLoaded: action.payload
      };
    case SET_MAP_ERROR:
      return {
        ...state,
        mapError: action.payload
      };
    case SET_UNITS_COORDINATES:
      return {
        ...state,
        unitsCoordinates: action.payload
      };
    default:
      break;
  }
  return state;
}
