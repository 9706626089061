import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  makeStyles,
  useTheme
} from '@material-ui/core';
import React from 'react';
import { displayDateTime } from 'reducers/TimeReducer';
import { IoMdAlert } from 'react-icons/io';
import { useSelector } from 'react-redux';
const stylesDay = {
  spinner: {
    background: 'rgba(255, 255, 255, 0.5)'
  }
};

const stylesNight = {
  spinner: {
    background: 'rgba(0, 0, 0, 0.5)'
  }
};

const styles = themeMode => {
  return themeMode === 'day' ? stylesDay : stylesNight;
};

const useStyles = makeStyles(theme => ({
  unreadDay: {
    backgroundColor: '#b3b3b3'
  },
  unreadNight: {
    backgroundColor: '#343434'
  },
  unreadChip: {
    backgroundColor: '#ddd'
  },
  readChip: {
    backgroundColor: '#bbb'
  },
  alertIcon: {
    textAlign: 'center',
    display: 'block',
    '@media (max-width:413px)': {
      display: 'none !important'
    }
  },
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    marginBottom: '1em'
  },
  filters: {
    padding: 20,
    marginBottom: 20
  },
  filterAction: {
    textAlign: 'right',
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  filter: {
    margin: '0 10px 10px',
    width: '200px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  select: {
    margin: '0 10px 10px',
    width: '200px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  selectInput: {
    fontSize: '16px'
  },
  iconButton: {
    padding: 10
  },
  reset: {
    marginRight: 20
  },
  searchBtnWrap: {
    display: 'inline-block',
    position: 'relative',
    paddingRight: '1em'
  },
  buttonProgress: {
    color: '#B00927',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const AlertCard = props => {
  const classes = useStyles();
  const { alert, idx, handleRowClick } = props;
  const themeMode = useSelector(state => state.theme.mode);
  const theme = useTheme();
  return (
    <Card
      className={` ${
        themeMode == 'day' ? classes.unreadDay : classes.unreadNight
      } card-box mb-4 p-4`}>
      <Box className="card-tr-actions">
        {alert.ReadDate != null || idx == 'update' ? (
          <Chip
            style={{ backgroundColor: themeMode == 'day' ? '#ddd' : '#212325' }}
            label="Read"
          />
        ) : (
          <Chip
            style={{ backgroundColor: themeMode == 'day' ? '#ddd' : '#212325' }}
            label={'Unread'}
          />
        )}
      </Box>

      <div className="d-flex align-items-center mb-3">
        <div
          className={`d-block p-0 avatar-icon-wrapper m-2 d-100 ${classes.alertIcon}`}>
          <div className="rounded-circle overflow-hidden">
            <IoMdAlert
              size={35}
              color={themeMode !== 'day' ? '#778eeb' : '#1E61A6'}
            />
          </div>
        </div>
        <div className="w-100">
          <a
            href="#/"
            onClick={e => e.preventDefault()}
            className="font-weight-bold font-size-lg"
            title="..."
            style={{ color: themeMode !== 'day' ? '#778eeb' : '' }}>
            {alert.AlertType}
          </a>
          <span className="text-50 d-block">{alert.AgencyID}</span>
          <div className="pt-1">
            <div className="justify-content-between">
              <span>Begin:</span>
              <span className=" p-2">{displayDateTime(alert.Beginning)}</span>
            </div>
          </div>
          <div className="pt-1">
            <div className="justify-content-between">
              <span>End:</span>
              <span className="p-2">{displayDateTime(alert.Ending)}</span>
            </div>
          </div>
        </div>
      </div>
      <p className="8 font-size-md mb-0" style={{ minHeight: '25px' }}>
        {alert.Notification}
      </p>
      <div
        className="my-4 font-size-sm p-3 rounded-sm"
        style={{
          backgroundColor: themeMode == 'day' ? '#d4d4d4' : '#404040'
        }}>
        <div className="d-flex justify-content-between">
          <span className="font-weight-bold">Priority:</span>
          <span className="text-50">{alert.Priority}</span>
        </div>
        <div className="d-flex justify-content-between py-2">
          <span className="font-weight-bold">Person:</span>
          <span className="text-50">{alert.Person}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span className="font-weight-bold">Vehicle:</span>
          <span className="text-50">{alert.Vehicle}</span>
        </div>
        <div className="d-flex justify-content-between py-2">
          <span className="font-weight-bold">OLN:</span>
          <span className="text-50">{alert.OLN}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span className="font-weight-bold">OLN State</span>
          <span className="text-50">{alert.OLNState}</span>
        </div>
        <div className="d-flex justify-content-between py-2">
          <span className="font-weight-bold">Plate:</span>
          <span className="text-50">{alert.Plate}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span className="font-weight-bold">Plate State:</span>
          <span className="text-50">{alert.PlateState}</span>
        </div>
        <div className="d-flex justify-content-between py-2">
          <span className="font-weight-bold">Ranges:</span>
          <span className="text-50">{alert.Ranges}</span>
        </div>
      </div>
      {idx !== 'update' && (
        <Button
          disabled={alert.ReadDate ? true : false}
          onClick={() => handleRowClick(alert.ptsAlertID)}
          variant="outlined"
          style={{ color: themeMode !== 'day' ? '#778eeb' : '#1E61A6' }}
          className="text-uppercase w-100 font-size-xs">
          <small className="font-weight-bold">Mark as Read</small>
        </Button>
      )}
    </Card>
  );
};

export default AlertCard;
