import { Card, CardHeader, IconButton } from '@material-ui/core';

import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Draggable from 'react-draggable';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setDirectionInfoWindo } from 'reducers/MapReducer';
import { DirectionsRenderer, GoogleMap, Marker } from '@react-google-maps/api';

const DialogDirection = () => {
  const directionInfoWindow = useSelector(
    state => state.map.directionInfoWindow
  );
  const { origin, destination } = directionInfoWindow.data || {};

  const [directions, setDirections] = useState(null);
  const dispatch = useDispatch();
  const [map, setMap] = React.useState(null);

  useEffect(() => {
    if (destination) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          destination,
          origin,
          travelMode: 'DRIVING'
        },
        handleDirections
      );
    }
  }, [destination, origin]);
  const handleDirections = (result, status) => {
    if (status === 'OK') {
      setDirections(result);
    } else if (status === 'ZERO_RESULTS') {
      alert('No Routes Found Between These Two Locations');
    } else {
      console.error(`error fetching directions `, result);
    }
  };

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!

    const bounds = new window.google.maps.LatLngBounds({
      lat: origin.lat,
      lng: origin.lng
    });
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const options = {
    fillColor: 'lightblue',
    fillOpacity: 1,
    strokeColor: 'red',
    strokeOpacity: 1,
    strokeWeight: 6,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
  };
  const containerStyle = {
    width: '100%',
    height: '100%'
  };
  // console.log('directions', directions);
  if (directionInfoWindow.open) {
    return (
      <Draggable handle=".handle" positionOffset={{ x: '-55%', y: '-40%' }}>
        <div
          className="pts-event-draggable"
          style={{
            position: 'absolute',
            zIndex: 999,
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-40%, -40%)',
            width: '100%',
            padding: '0 5px'
          }}>
          <Card className="card-box pts-event-box" style={{ height: '450px' }}>
            <IconButton
              style={{
                position: 'absolute',
                right: '-10px',
                top: '-10px',
                color: 'white'
              }}
              onClick={() => dispatch(setDirectionInfoWindo(false, null))}
              aria-label="close">
              <CloseIcon variant="contained" />
            </IconButton>
            <CardHeader
              className="handle"
              style={{
                cursor: 'move',
                height: '30px',
                padding: '5px',
                background: '#0b55a1'
              }}>
              <h2>Map</h2>
            </CardHeader>
            {/* <iframe
              src={directionInfoWindow.data + '&embedded=true' || ''}
              width="100%"
              height="100%"
            /> */}
            <GoogleMap
              mapContainerStyle={containerStyle}
              zoom={0}
              onLoad={onLoad}
              onUnmount={onUnmount}>
              {directions && (
                <>
                  <DirectionsRenderer
                    directions={directions}
                    options={{
                      polylineOptions: options,
                      suppressMarkers: true
                    }}
                  />
                  <Marker
                    position={directions.routes[0].legs[0].end_location}
                  />
                </>
              )}

              {/* <Polygon paths={paths} options={options} /> */}
            </GoogleMap>
          </Card>
        </div>
      </Draggable>
    );
  } else {
    return <></>;
  }
};
const mapStateToProps = state => ({
  themeMode: state.theme.mode,
  websocket: state.websocket,
  map: state.map.map,
  mapcenter: state.map.mapcenter,
  mapzoom: state.map.mapzoom,
  mapbounds: state.map.mapbounds
});

export default connect(mapStateToProps, {})(DialogDirection);
