import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { Avatar, makeStyles, TablePagination } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setEventsDrawerMobileState } from 'reducers/UiReducer';
import { setEventInfowindow } from '../../../../reducers/MapReducer';
import {
  findClosedEvents,
  openShowDetails
} from 'reducers/ClosedEventSearchReducer';
import Spinner from 'global-components/Spinner';

import { displayDateTime } from 'reducers/TimeReducer';
const stylesDay = {
  spinner: {
    background: 'rgba(255, 255, 255, 0.5)'
  }
};

const stylesNight = {
  spinner: {
    background: 'rgba(0, 0, 0, 0.5)'
  }
};

const styles = themeMode => {
  return themeMode === 'day' ? stylesDay : stylesNight;
};

const useStyles = makeStyles(theme => ({
  tableWrap: {
    overflow: 'auto',
    margin: '0 auto',
    position: 'relative'
  },
  header: {
    cursor: 'pointer',
    fontSize: '2em'
  },
  headerItem: {
    fontSize: '1em !important',
    background: '#0b55a1 !important',
    color: '#fff !important'
  },
  tableCell: {},
  tableRow: {
    color: `${theme.palette.text.primary} !important`,
    '&:hover': {
      color: ` ${theme.palette.text.primary}!important`,
      backgroundColor:
        theme.palette.type == 'dark'
          ? 'rgba(255, 255, 255, 0.16) !important'
          : ''
    }
  },
  spinner: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      color: '#999'
    }
  },
  warrantRow: {
    backgroundColor: theme.palette.error.main
  }
}));
const Table = props => {
  const classes = useStyles();

  const { data, config, themeMode } = props;
  const { orderBy, rowsPerPage, orderDirection, loading, filters } = data;
  const [numberOfRows, setNumberOfRows] = useState(rowsPerPage);
  const dispatch = useDispatch();
  const [headCells, setHeadCells] = useState(getHeadCells(data.rows, config));
  useEffect(() => {
    setHeadCells(getHeadCells(data.rows, config));
  }, [config, data]);

  const handleOnEventClick = event => {
    dispatch(openShowDetails(event));
    // dispatch(setEventsDrawerMobileState(false));
    // dispatch(setEventInfowindow(true, event));
  };
  const handlePageChange = (ev, pageNo) => {
    let options = {
      pageNo,
      filters,
      orderBy,
      rowsPerPage,
      orderDirection
    };
    dispatch(findClosedEvents(options));
  };

  const handleChangeRowsPerPage = (ev, info) => {
    setNumberOfRows(info.props.value);
    let options = {
      pageNo: 0,
      filters,
      orderBy,
      rowsPerPage: info.props.value,
      orderDirection
    };
    dispatch(findClosedEvents(options));
  };
  return (
    <Fragment>
      <div className="table-responsive">
        <table
          className={clsx(
            'table',
            'table-hover',
            themeMode === 'night' && 'table-pts-night',
            themeMode === 'day' && 'table-pts-day',
            'text-nowrap',
            'mb-0'
          )}>
          <thead>
            <tr>
              {headCells.map((row, idx) => {
                if (row.isDisplayed === true) {
                  return (
                    <Fragment key={row.label}>
                      <th className={classes.headerItem} title={row.title}>
                        <span>
                          {row.label}
                          {row.dbName === orderBy &&
                            orderDirection === 'ASC' && <ArrowDownwardIcon />}
                          {row.dbName === orderBy &&
                            orderDirection === 'DESC' && <ArrowUpwardIcon />}
                        </span>
                      </th>
                    </Fragment>
                  );
                }
              })}
            </tr>
          </thead>
          <tbody>
            {data.rows.map((row, idx) => {
              let cols = [];
              const agenciesId = [];
              let agencyString = '';
              if (row.CaseIds) {
                const ids = JSON.parse(row.CaseIds);
                ids.forEach(obj => agenciesId.push(obj.AgencyId));
                const uniqueId = [...new Set(agenciesId)];
                uniqueId.forEach((id, index) => {
                  if (index == 0) {
                    agencyString += `${id}`;
                  } else {
                    agencyString += `, ${id}`;
                  }
                });
                row = { ...row, AgencyID: agencyString };
              }
              Object.entries(row).forEach(([key, val]) => {
                if (key !== 'key') {
                  if (config.display[key]) {
                    const dataType = config.display[key].type;
                    const dataName = config.display[key].dbName;
                    const isDisplayed = config.display[key].isDisplayed;
                    if (isDisplayed === true) {
                      if (val && dataType === 'date' && moment(val).isValid()) {
                        // if (val.substr(val.length - 1) === 'Z') {
                        //   const editedDate = val.slice(0, -1);
                        //   cols.push({
                        //     text: displayDateTime(editedDate),
                        //     seq: config.display[key].seq,
                        //     name: dataName
                        //   });
                        // } else {
                        cols.push({
                          text: displayDateTime(val),
                          seq: config.display[key].seq,
                          name: dataName
                        });
                        // }
                      } else {
                        cols.push({
                          text: val,
                          seq: config.display[key].seq,
                          name: dataName
                        });
                      }
                    }
                  }
                }
              });
              if (row.ptsEventID !== null) {
                return (
                  <tr
                    key={idx}
                    onClick={() => handleOnEventClick(row)}
                    className={classes.tableRow}>
                    {cols.map((obj, idx) => {
                      if (obj.name === 'FullName') {
                        return (
                          <Fragment key={idx}>
                            <td className={classes.tableCell} title={obj.text}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}>
                                <div style={{ paddingRight: '15px' }}>
                                  <Avatar alt="Avatar"></Avatar>
                                </div>
                                <div>
                                  <div>
                                    <span
                                      style={{
                                        fontWeight: '500',
                                        fontSize: '18px'
                                      }}>
                                      {obj.text.toUpperCase()}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </Fragment>
                        );
                      } else {
                        return (
                          <Fragment key={idx}>
                            <td className={classes.tableCell} title={obj.text}>
                              <span>{obj.text}</span>
                            </td>
                          </Fragment>
                        );
                      }
                    })}
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        rowsPerPageOptions={config.pageSizeOptions}
        component="div"
        count={data.count}
        rowsPerPage={numberOfRows}
        page={data.pageNo}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {loading && <Spinner />}
    </Fragment>
  );
};

export default Table;

function getHeadCells(dataObj, config) {
  const result = [];
  if (dataObj.length === 0) return [];
  const row = { ...dataObj[0], AgencyID: '' };
  Object.keys(row).forEach(key => {
    const option = config.display[key];
    if (option) {
      if (key !== 'key') {
        result.push({
          name: key,
          dbName: option.dbName,
          label: option ? option.label : key,
          width: getColWidth(key, config),
          title: option.title ? option.title : option.label,
          seq: option.seq,
          isDisplayed: option.isDisplayed
        });
      }
    }
  });

  return result;
}

function getTableWidth(config) {
  return Object.values(config.display).reduce((sum, val) => val.width + sum, 0);
}

function getColWidth(key, config) {
  return `${Math.round(
    (config.display[key].width / getTableWidth(config)) * 10000
  ) / 100}%`;
}
