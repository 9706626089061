import React from 'react';
import { connect } from 'react-redux';

import { OverlayView } from '@react-google-maps/api';

// import AgencyIcon from '../AgencyIcon';
import UnitMarker from './UnitMarker';
import { getUnitColor } from '../../../utils/uiUtils';
import { setUnitInfowindow } from '../../../reducers/MapReducer';

const positionOverlayView = (width, height) => ({ x: -width / 2, y: -height });

const MarkersUnits = props => {
  const {
    units,
    colors,
    setUnitInfowindow,
    filterKeyword,
    unitsCoords
  } = props;

  const onClick = (unit, coords) => {
    setUnitInfowindow(true, unit, coords);
  };

  const renderMarker = unit => {
    const coords = unitsCoords[unit.ptsUnitID];

    if (coords) {
      const position = {
        lat: parseFloat(coords.lat),
        lng: parseFloat(coords.lng)
      };
      return (
        <OverlayView
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          key={unit.ptsUnitID}
          position={position}
          getPixelPositionOffset={positionOverlayView}>
          <UnitMarker
            unit={unit}
            color={getUnitColor(unit, colors)}
            onUnitClick={() => onClick(unit)}
          />
        </OverlayView>
      );
    } else {
      return null;
    }
  };

  if (units.length > 0) {
    return units.map((unit, index) => {
      if (filterKeyword !== '') {
        if (
          unit.UnitResources?.toLowerCase().includes(filterKeyword) ||
          unit.Unit?.toLowerCase().includes(filterKeyword) ||
          unit.UnitStatus?.toLowerCase().includes(filterKeyword) ||
          unit.Division?.toLowerCase().includes(filterKeyword) ||
          unit.Zone?.toLowerCase().includes(filterKeyword)
        ) {
          return renderMarker(unit);
        } else {
          return null;
        }
      } else {
        return renderMarker(unit);
      }
    });
  } else {
    return null;
  }
};

const mapStateToProps = state => ({
  themeMode: state.theme.mode,
  units: state.units.units,
  colors: state.codes.colorsCodes,
  filterKeyword: state.units.filterKeyword
});

export default connect(mapStateToProps, { setUnitInfowindow })(MarkersUnits);
