import React, { useState } from 'react';

import Tooltip from '@material-ui/core/Tooltip';

export default function MyTooltip(props) {
  const [disabled, setDisabled] = useState(false);
  const dragging = !!localStorage.getItem('dragNdrop');

  if (dragging) return props.children;

  return (
    <Tooltip
      {...props}
      enterDelay={1500}
      enterNextDelay={250}
      leaveDelay={2}
      disableHoverListener={disabled}
      disableFocusListener={disabled}
      disableTouchListener={disabled}
    />
  );
}
