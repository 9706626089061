import React, { Fragment } from 'react';
import { connect, useSelector } from 'react-redux';

import TopBar from '../../layout-components/TopBar';
//import BottomBar from '../../layout-components/BottomBar';
import DrawerNavigation from '../../layout-components/DrawerNavigation';
import DrawerEventsDesktop from '../../layout-components/DrawerEventsDesktop';
import DrawerUnitsDesktop from '../../layout-components/DrawerUnitsDesktop';
import DrawerEventsMobile from '../../layout-components/DrawerEventsMobile';
import DrawerUnitsMobile from '../../layout-components/DrawerUnitsMobile';

import DrawerEventsDesktopSimple from '../../layout-components/DrawerEventsDesktopSimple';
import DrawerUnitsDesktopSimple from '../../layout-components/DrawerUnitsDesktopSimple';

import DialogEvent from '../../components/Map/DialogEvent';
import DialogUnit from '../../components/Map/DialogUnit';

import DialogSettings from '../../layout-components/DialogSettings';
import DialogUnitStatus from '../../layout-components/DialogUnitStatus';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import DialogDirection from 'components/Map/DialogDirection';

const drawerWidth = 240;
const drawerHeight = 80;

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.bgElements
  },
  appBarBottom: {
    backgroundColor: theme.palette.bgElements,
    zIndex: theme.zIndex.drawer + 1,
    top: 'auto',
    bottom: 0
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  toolbar: {
    height: drawerHeight,
    backgroundColor: theme.palette.bgElements
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.bgElements,
    padding: '0px 5px'
  },
  content: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    backgroundColor: theme.palette.bg
  },
  contentInner: {
    flex: 1,
    position: 'relative',
    marginTop: drawerHeight
    //marginBottom: drawerHeight
  }
}));

const MainLayout = props => {
  const { children, contentBackground } = props;
  const directionInfoWindow = useSelector(
    state => state.map.directionInfoWindow
  );
  const classes = useStyles();

  return (
    <div className={clsx('app-wrapper', contentBackground)}>
      <DialogEvent />
      <DialogUnit />
      {directionInfoWindow.data && <DialogDirection />}
      <DialogSettings />
      <DialogUnitStatus />
      <TopBar />
      <DrawerNavigation />
      {props.viewType === 'complex' && (
        <Fragment>
          <DrawerEventsDesktop />
          <DrawerUnitsDesktop />
        </Fragment>
      )}
      {props.viewType === 'simple' && (
        <Fragment>
          <DrawerEventsDesktopSimple />
          <DrawerUnitsDesktopSimple />
        </Fragment>
      )}
      <DrawerEventsMobile />
      <DrawerUnitsMobile />
      {/*<BottomBar />*/}
      <div className={classes.content}>
        <div className={classes.contentInner}>{children}</div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  sidebarToggle: state.theme.sidebarToggle,
  sidebarToggleMobile: state.theme.sidebarToggleMobile,
  sidebarFixed: state.theme.sidebarFixed,

  headerFixed: state.theme.headerFixed,
  headerSearchHover: state.theme.headerSearchHover,
  headerDrawerToggle: state.theme.headerDrawerToggle,

  footerFixed: state.theme.footerFixed,

  contentBackground: state.theme.contentBackground,
  viewType: state.settings.viewType
});

export default connect(mapStateToProps)(MainLayout);
