import { getService } from './service';

export const personLookup = search => {
  if (!search) return Promise.resolve([]);
  const service = getService('person-lookup');
  return service.find({ query: { search } });
};

export const personAddressLookup = search => {
  if (!search) return Promise.resolve([]);
  const service = getService('person-address-lookup');
  return service.find({ query: { search } });
};

export const savePeopleSOP = async data => {
  const service = getService('record-person-sops');
  return await service.create(data);
};

export const deletePeopleSOP = async query => {
  const service = getService('rms-assigned-sops');
  return await service.remove(query);
};
