import { getService } from './service';

export const addVehicleTowing = data => {
  const service = getService('cad-vehicle-towing');
  return service.create(data);
};

export const getVehicleTowing = ptsTowingID => {
  const service = getService('cad-vehicle-towing');
  return service.get(ptsTowingID);
};

export const findVehicleTowing = ptsVehicleID => {
  const service = getService('cad-vehicle-towing');
  return service.find({ query: { ptsVehicleID } });
};

export const updateVehicleTowing = data => {
  const service = getService('cad-vehicle-towing');
  return service.patch(data.ptsTowingID, data);
};
