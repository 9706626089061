import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { hideSpinner, showSpinner } from 'reducers/UiReducer';
import {
  CircularProgress,
  Fab,
  IconButton,
  InputAdornment,
  makeStyles
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { MdLocationSearching } from 'react-icons/md';
const useStyles = makeStyles(theme => ({
  searchBtnWrap: {
    display: 'inline-block',
    position: 'relative',
    paddingRight: '1em'
  },
  buttonProgress: {
    color: '#B00927',
    position: 'absolute',
    top: '50%',
    left: '30%',
    marginTop: -12,
    marginLeft: -5
  }
}));
const filter = createFilterOptions();
const FavouriteLocation = props => {
  const {
    locationString,
    setLocationString,
    handleLocationAutocomplete,
    network,
    locationLoading,
    locationButtonVisible
  } = props;
  const classes = useStyles();
  const [locationMode, setLocationMode] = React.useState({ name: 'save' });
  const [open, toggleOpen] = React.useState(false);
  const wsClient = useSelector(state => state.websocket);
  const user = useSelector(state => state.user);
  const [locationValue, setLocationValue] = React.useState();
  const [locations, setLocation] = React.useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (wsClient.websocket) {
      getLocation();
    }
    // sec-user-location
  }, [wsClient]);
  const getLocation = async () => {
    try {
      const userId = user.userData.user.ptsUserID;
      const service = wsClient?.websocket.service('sec-user-location');
      service.timeout = 20000;
      const data = await service.find({
        query: {
          IsDeleted: false,
          ptsUserID: userId,
          $limit: 100,
          $sort: {
            ptsUserLocationID: -1
          }
        }
      });

      setLocation(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    toggleOpen(false);
    setLocationMode({ name: 'save' });
    setLocationValue('');
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      dispatch(showSpinner());
      handleClose();

      const userId = user.userData.user.ptsUserID;
      const service = wsClient?.websocket.service('sec-user-location');
      service.timeout = 20000;
      const newLocation = { ptsUserID: userId, Location: locationValue };

      let data;
      if (locationMode.name == 'edit') {
        data = await service.patch(locationMode.locationId, {
          Location: locationValue
        });
      } else {
        data = await service.create(newLocation);
      }

      getLocation();

      setLocationString(data.Location);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(hideSpinner());
    }
  };
  const handleActionCLick = async (e, option, type) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      if (type == 'edit') {
        toggleOpen('true');
        setLocationValue(option.Location);
        setLocationMode({ name: 'edit', locationId: option.ptsUserLocationID });
      } else if (type == 'delete') {
        dispatch(showSpinner());
        const service = wsClient?.websocket.service('sec-user-location');
        service.timeout = 20000;
        const data = await service.remove(option.ptsUserLocationID);

        const newList = locations.filter(
          l => l.ptsUserLocationID !== data.ptsUserLocationID
        );
        setLocation(newList);
        getLocation();
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(hideSpinner());
    }
  };
  // console.log('last location', locationString);
  return (
    <div>
      <Autocomplete
        clearOnBlur
        freeSolo
        style={{ width: '100%', paddingBottom: '10px' }}
        value={{ Location: locationString }}
        loading
        loadingText="Loading Or Empty List"
        onChange={(event, newValue) => {
          // console.log('newValue', newValue);
          if (typeof newValue === 'string') {
            setLocationString(newValue || '');
          } else if (newValue && newValue.Location) {
            // Create a new value from the user input
            setLocationString(newValue ? newValue.Location : '');
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setLocationString(newValue.inputValue || '');
          } else {
            setLocationString(newValue);
          }
        }}
        id="favourite_location"
        options={locations}
        onInputChange={(event, newInputValue) => {
          setLocationString(newInputValue);
        }}
        getOptionSelected={() => true}
        getOptionLabel={option => option.Location}
        renderOption={option => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}>
            <p>{option.Location}</p>
            <div>
              <IconButton
                // hidden={!optionsVisibility}
                variant="contained"
                style={{ margin: 0, padding: '10px' }}
                aria-label="delete"
                onClick={e => handleActionCLick(e, option, 'edit')}>
                <EditIcon style={{ fontSize: '17px' }} color="primary" />
              </IconButton>
              <span style={{ marginLeft: '5px', padding: '10px' }}></span>
              <IconButton
                style={{ margin: 0, padding: '10px' }}
                aria-label="delete"
                // hidden={!optionsVisibility}
                onClick={e => handleActionCLick(e, option, 'delete')}>
                <DeleteIcon style={{ fontSize: '17px' }} color="primary" />
              </IconButton>
            </div>
          </div>
        )}
        renderInput={params => (
          <div ref={params.InputProps.ref}>
            <TextField
              {...params}
              InputProps={{
                ...props.InputProps,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setLocationValue(locationString);
                      toggleOpen(true);
                    }}>
                    <AddCircleIcon color="primary" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <div>
                    {/* {params.InputProps.endAdornment} */}
                    <InputAdornment>
                      <div className={classes.searchBtnWrap}>
                        <Fab
                          disabled={
                            locationLoading || !locationButtonVisible
                              ? true
                              : false
                          }
                          onClick={handleLocationAutocomplete}
                          size="small"
                          color="primary">
                          <MdLocationSearching style={{ fontSize: '1.3rem' }} />
                        </Fab>
                        {locationLoading && (
                          <CircularProgress
                            color="primary"
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </InputAdornment>
                  </div>
                )
              }}
              label="Location"
              placeholder="Favorites"
              variant="outlined"
            />
          </div>
        )}
        // inputValue={locationInputValue}
        // onInputChange={(event, newInputValue) => {
        //   setLocationInputValue(newInputValue);
        // }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <form style={{ minWidth: '400px' }}>
          <DialogTitle id="form-dialog-title">Add a new location</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={locationValue}
              onChange={event => setLocationValue(event.target.value)}
              label="Location"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default FavouriteLocation;
