import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import IconsAgency from '../IconsAgency';

function Icon(props) {
  const { AgencyID, color, Agencies, size } = props;

  const agency = Agencies?.find(a => a.AgencyID === AgencyID);
  const iconIndex = agency ? agency.Icon : 0;
  const Icon = IconsAgency[iconIndex] || IconsAgency[0];

  return <Icon style={{ color: color, fontSize: size ? '40px' : '' }} />;
}

const mapStateToProps = state => {
  return {
    Agencies: state.codes.dictionary.Agencies || []
  };
};

export default connect(mapStateToProps)(Icon);
