import React, { useState, useRef, Fragment } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Badge, Card, Button, Box, Avatar } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AlertsComponent from '../Alerts';
import AddressComponent from '../Address';
import BookingComponent from '../Booking';
import CadIncidentComponent from '../CadIncident';
import CitationComponent from '../Citation';
import ContactComponent from '../Contact';
import RmsIncidentComponent from '../RmsIncident';
import WarrantComponent from '../Warrant';

import avatarPlaceholder from '../../../../../assets/images/avatars/avatar-526x526.png';
import { useHistory } from 'react-router';
import { setCitaionFlag } from 'reducers/PersonSearchReducer';
import { useDispatch } from 'react-redux';
import { showEditPerson } from 'reducers/DialogsReducer';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  body: {
    background: theme.palette.bgElements,
    minHeight: '50vh',
    padding: '10px 10px'
  },
  itemWrap: {
    fontWeight: 500,
    fontSize: '0.95rem'
  },
  itemLabel: {
    fontWeight: 200
  },
  customBadge: {
    'MuiBadge-badge': {
      width: '200px'
    }
  }
}));

function Info(props) {
  const { data, images, themeMode } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const dataArr = Object.keys(data).map(key => {
    return {
      key: key,
      value: data[key]
    };
  });

  let parsedImages;
  if (images && images.length > 0) {
    parsedImages = JSON.parse(images);
  }

  let displayType = 'local';
  const [details, setDetails] = useState(false);
  const [mainMugshot, setMainMugshot] = useState(() => {
    if (data.localMugshots !== null && data.localMugshots.length > 0)
      return `data:image/png;base64,${data.localMugshots[0].FileObject}`;
    if (data.ncicMugshots !== null && data.ncicMugshots.length > 0)
      return `data:image/png;base64,${data.ncicMugshots[0].ImageData}`;
    if (data.stateMugshots !== null)
      return `data:image/png;base64,${data.stateMugshots.ImageData}`;
    if (parsedImages.length > 0)
      return `data:image/png;base64,${parsedImages[0].ImageData}`;
    return avatarPlaceholder;
  });

  if (data.localData === null && data.ncicData !== null) displayType = 'ncic';
  if (data.localData !== null && data.ncicData !== null)
    displayType = 'combined';

  // Universal Person data
  let FullName = '';
  if (displayType === 'ncic') {
    if (data.ncicData.data.Name && data.ncicData.data.Name !== '') {
      FullName = data.ncicData.data.Name;
    } else {
      FullName = `${data.ncicData.data?.NameLast?.toUpperCase()}, ${data.ncicData.data?.NameFirst?.toUpperCase()} ${data.ncicData.data?.NameMiddle?.toUpperCase()}`;
    }
  } else {
    FullName = data.localData.FullName;
  }

  let Address =
    displayType === 'ncic' ? data.ncicData.data.NormalizedAddress : '';
  if (data.localAddresses !== null)
    Address = data.localAddresses.data[0].FullAddressText;

  function RenderImages() {
    const images = [];
    if (
      data.localMugshots &&
      data.localMugshots !== null &&
      data.localMugshots.length > 0
    ) {
      for (let i = 0; i < data.localMugshots.length; i++) {
        images.push(data.localMugshots[i].FileObject);
      }
    }
    if (
      data.ncicMugshots &&
      data.ncicMugshots !== null &&
      data.ncicMugshots.length > 0
    ) {
      for (let i = 0; i < data.ncicMugshots.length; i++) {
        images.push(data.ncicMugshots[i].ImageData);
      }
    }
    if (data.stateMugshots && data.stateMugshots !== null) {
      images.push(data.stateMugshots.ImageData);
    }
    const handleImageClick = image => {
      setMainMugshot(`data:image/png;base64,${image}`);
    };

    return (
      <Card className="mb-4">
        <div className="card-img-wrapper">
          <img alt="..." className="card-img-top" src={mainMugshot} />
        </div>
        <div className="card-body text-center card-body-avatar">
          {images.map((image, idx) => {
            return (
              <div
                onClick={() => handleImageClick(image)}
                key={idx}
                className="avatar-icon-wrapper avatar-icon-xl">
                <div className="avatar-icon rounded">
                  <img alt="..." src={`data:image/png;base64,${image}`} />
                </div>
              </div>
            );
          })}
          <h3 className="card-title mt-4 mb-1">{FullName.toUpperCase()}</h3>
          <p className="card-text mb-3">{Address}</p>
          <div>
            {data.warnings.map((row, idx) => {
              if (row.Banner === true) {
                return (
                  <div key={idx} style={{ marginBottom: '10px' }}>
                    <Badge
                      overlap="rectangular"
                      key={idx}
                      className="customBagde"
                      badgeContent="ACTIVE WARRANT"
                      color="error"></Badge>
                  </div>
                );
              } else {
                if (row.Banner) {
                  return (
                    <div key={idx} style={{ marginBottom: '10px' }}>
                      <Badge
                        overlap="rectangular"
                        key={idx}
                        className="customBagde"
                        badgeContent={row.Banner.toUpperCase()}
                        color="error"></Badge>
                    </div>
                  );
                }
              }
            })}
          </div>
        </div>
      </Card>
    );
  }

  function RenderHistoryButton() {
    if (
      (data.localData !== null && data.localData.OLNNumber !== null) ||
      (data.ncicData !== null &&
        data.ncicData.data.OperatorLicenseNumber !== null)
    ) {
      return (
        // <Button
        //   size="medium"
        //   variant="contained"
        //   color="primary"
        //   fullWidth
        //   className={classes.button}>
        //   Driver History
        // </Button>
        null
      );
    } else {
      return null;
    }
  }

  function RenderLocalCard() {
    if (displayType === 'local' || displayType === 'combined') {
      // Person data
      let OLN = data.localData.OLNNumber;
      let OLNState = data.localData.OLNState;
      let OLNType = data.localData.OLNType ? data.localData.OLNType : 'Unknown';
      let OLNStatus = data.localData.OLNStatus
        ? data.localData.OLNStatus
        : 'Unknown';
      let SSN = data.localData.SSN ? data.localData.SSN : 'Unknown';
      let FirstName = data.localData.FirstName
        ? data.localData.FirstName
        : 'Unknown';
      let MiddleName = data.localData.MiddleName
        ? data.localData.MiddleName
        : 'Unknown';
      let LastName = data.localData.LastName
        ? data.localData.LastName
        : 'Unknown';
      let Sex = data.localData.Sex ? data.localData.Sex : 'Unknown';
      let Race = data.localData.Race ? data.localData.Race : 'Unknown';
      let DOB = data.localData.DOB
        ? moment(data.localData.DOB).format('MM/DD/YYYY')
        : 'Unknown';
      let Height = data.localData.Height ? data.localData.Height : 'Unknown';
      let Weight = data.localData.Weight ? data.localData.Weight : 'Unknown';
      let HairColor = data.localData.HairColor
        ? data.localData.HairColor
        : 'Unknown';
      let EyeColor = data.localData.EyeColor
        ? data.localData.EyeColor
        : 'Unknown';
      let Address =
        data.localAddresses !== null
          ? data.localAddresses.data[0].FullAddressText
          : 'Unknown';
      let Probation = data.localData.Probation
        ? data.localData.Probation
        : 'Unknown';
      let Parole = data.localData.Parole ? data.localData.Parole : 'Unknown';
      let GunLicense = data.localData.GunLicense
        ? data.localData.GunLicense
        : 'Unknown';

      return (
        <Card className="mb-4 p-4">
          <div className="card-header-alt d-flex justify-content-between">
            <div>
              <h6 className="font-weight-bold font-size-lg mb-1 ">
                {FullName.toUpperCase()}
              </h6>
              <p className="  mb-0">{Address}</p>
            </div>
            <Box className="d-flex align-items-center">
              <Avatar alt="Avatar">L</Avatar>
            </Box>
          </div>
          <div
            className="divider"
            style={{ marginLeft: '0', marginRight: '0' }}
          />
          <div
            style={{ justifyContent: 'space-between' }}
            className="d-block d-md-flex align-items-center my-2">
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">DOB</span>
              <div className="m-1">{DOB}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Sex</span>
              <div className="m-1">{Sex}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Race</span>
              <div className="m-1">{Race}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Height</span>
              <div className="m-1">{Height}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Weight</span>
              <div className="m-1">{Weight}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Hair</span>
              <div className="m-1">{HairColor}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Eyes</span>
              <div className="m-1">{EyeColor}</div>
            </span>
          </div>
          <div
            className="divider"
            style={{ marginLeft: '0', marginRight: '0' }}
          />
          <div
            style={{ justifyContent: 'space-between' }}
            className="d-block d-md-flex align-items-center my-2">
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">DL Number</span>
              <div className="m-1">{OLN}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">DL State</span>
              <div className="m-1">{OLNState}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">DL Type</span>
              <div className="m-1">{OLNType}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">DL Status</span>
              <div className="m-1">{OLNStatus}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">SSN</span>
              <div className="m-1">{SSN}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Probation</span>
              <div className="m-1">{Probation}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Parole</span>
              <div className="m-1">{Parole}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Gun License</span>
              <div className="m-1">{GunLicense}</div>
            </span>
          </div>
        </Card>
      );
    } else {
      return null;
    }
  }

  function RenderNcicCard() {
    if (displayType === 'ncic' || displayType === 'combined') {
      // Person data
      let FullName =
        data.ncicData.data && data.ncicData.data.NameLast
          ? `${data.ncicData.data?.NameLast?.toUpperCase()}, ${data.ncicData.data?.NameFirst?.toUpperCase()} ${data.ncicData.data?.NameMiddle?.toUpperCase()}`
          : data.ncicData.data.Name;
      let OLN =
        data.ncicData.data && data.ncicData.data.OperatorLicenseNumber
          ? data.ncicData.data.OperatorLicenseNumber
          : 'Unknown';
      let OLNState =
        data.ncicData.data && data.ncicData.data.ImpliedOperatorLicenseStateCode
          ? data.ncicData.data.ImpliedOperatorLicenseStateCode
          : 'Unknown';
      let OLNType =
        data.ncicData.data && data.ncicData.data.OperatorLicenseClassCode
          ? data.ncicData.data.OperatorLicenseClassCode
          : 'Unknown';
      let OLNStatus =
        data.ncicData.data && data.ncicData.data.OperatorLicenseStatus
          ? data.ncicData.data.OperatorLicenseStatus
          : 'Unknown';
      let SSN =
        data.ncicData.data && data.ncicData.data.SocialSecurityNumber
          ? data.ncicData.data.SocialSecurityNumber
          : 'Unknown';
      let FirstName =
        data.ncicData.data && data.ncicData.data.NormalizedNameFirst
          ? data.ncicData.data.NormalizedNameFirst
          : 'Unknown';
      let MiddleName =
        data.ncicData.data && data.ncicData.data.NormalizedNameMiddle
          ? data.ncicData.data.NormalizedNameMiddle
          : 'Unknown';
      let LastName =
        data.ncicData.data && data.ncicData.data.NormalizedNameLast
          ? data.ncicData.data.NormalizedNameLast
          : 'Unknown';
      let Sex =
        data.ncicData.data && data.ncicData.data.SexCode
          ? data.ncicData.data.SexCode
          : 'Unknown';
      let Race =
        data.ncicData.data && data.ncicData.data.RaceCode
          ? data.ncicData.data.RaceCode
          : 'Unknown';
      let DOB =
        data.ncicData.data && data.ncicData.data.BirthDate
          ? data.ncicData.data.BirthDate
          : 'Unknown';
      let Height =
        data.ncicData.data && data.ncicData.data.Height
          ? data.ncicData.data.Height
          : 'Unknown';
      let Weight =
        data.ncicData.data && data.ncicData.data.Weight
          ? data.ncicData.data.Weight
          : 'Unknown';
      let HairColor =
        data.ncicData.data && data.ncicData.data.HairColorCode
          ? data.ncicData.data.HairColorCode
          : 'Unknown';
      let EyeColor =
        data.ncicData.data && data.ncicData.data.EyeColorCode
          ? data.ncicData.data.EyeColorCode
          : 'Unknown';
      let Address = data.ncicData.data.Address
        ? data.ncicData.data.Address
        : '';
      let Probation =
        data.ncicData.data && data.ncicData.data.Probation
          ? data.ncicData.data.Probation
          : 'Unknown';
      let Parole =
        data.ncicData.data && data.ncicData.data.Parole
          ? data.ncicData.data.Parole
          : 'Unknown';
      let GunLicense =
        data.ncicData.data && data.ncicData.data.GunLicense
          ? data.ncicData.data.GunLicense
          : 'Unknown';
      let SocialSecurityNumber =
        data.ncicData.data && data.ncicData.data.SocialSecurityNumber
          ? data.ncicData.data.SocialSecurityNumber
          : 'Unknown';
      let IssueDate =
        data.ncicData.data && data.ncicData.data.IssueDate
          ? data.ncicData.data.IssueDate
          : 'Unknown';
      let ExpirationDate =
        data.ncicData.data && data.ncicData.data.ExpirationDate
          ? data.ncicData.data.ExpirationDate
          : 'Unknown';
      let OperatorLicenseRestrictions =
        data.ncicData.data && data.ncicData.data.OperatorLicenseRestrictions
          ? data.ncicData.data.OperatorLicenseRestrictions
          : 'Unknown';
      let OperatorLicenseEndorsments =
        data.ncicData.data && data.ncicData.data.OperatorLicenseEndorsments
          ? data.ncicData.data.OperatorLicenseEndorsments
          : 'Unknown';
      let OperatorLicenseStatusCode =
        data.ncicData.data && data.ncicData.data.OperatorLicenseStatusCode
          ? data.ncicData.data.OperatorLicenseStatusCode
          : 'Unknown';
      let CommericalOperatorLicenseStatusCode =
        data.ncicData.data &&
        data.ncicData.data.CommericalOperatorLicenseStatusCode
          ? data.ncicData.data.CommericalOperatorLicenseStatusCode
          : 'Unknown';
      let AddressStreet =
        data.ncicData.data && data.ncicData.data.AddressStreet
          ? data.ncicData.data.AddressStreet
          : '';
      let AddressStateCode =
        data.ncicData.data && data.ncicData.data.AddressStateCode
          ? data.ncicData.data.AddressStateCode
          : '';
      let AddressZipCode =
        data.ncicData.data && data.ncicData.data.AddressZipCode
          ? data.ncicData.data.AddressZipCode
          : '';
      let AddressCity =
        data.ncicData.data && data.ncicData.data.AddressCity
          ? data.ncicData.data.AddressCity
          : '';
      let AddressCounty =
        data.ncicData.data && data.ncicData.data.AddressCounty
          ? data.ncicData.data.AddressCounty
          : '';
      if (!Address) {
        Address = `${AddressStreet || ''} ${
          AddressCity ? ',' + AddressCity : ''
        } ${AddressStateCode ? ', ' + AddressStateCode : ''} ${AddressZipCode ||
          ''}
             `;
      }
      if (!Address.trim()) Address = 'Address Unknown';
      return (
        <Card className="mb-4 p-4">
          <div className="card-header-alt d-flex justify-content-between">
            <div>
              <h6 className="font-weight-bold font-size-lg mb-1  ">
                {FullName.toUpperCase()}
              </h6>
              <p className="  mb-0">{Address}</p>
            </div>
            <Box className="d-flex align-items-center">
              <Avatar alt="Avatar">N</Avatar>
            </Box>
          </div>
          <div
            className="divider"
            style={{ marginLeft: '0', marginRight: '0' }}
          />
          <div>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">OLN</span>
              <span className="m-1">{OLN}</span>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">CLASS</span>
              <span className="m-1">{OLNType}</span>
            </span>{' '}
            <br />
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">RAC</span>
              <span className="m-1">{Race}</span>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">SEX</span>
              <span className="m-1">{Sex}</span>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">EYE</span>
              <span className="m-1">{EyeColor}</span>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">HAI</span>
              <span className="m-1">{HairColor}</span>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">HGT</span>
              <span className="m-1">{Height}</span>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">WGT</span>
              <span className="m-1">{Weight}</span>
            </span>{' '}
            <br />
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">SOC</span>
              <span className="m-1">{SocialSecurityNumber}</span>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">DOB</span>
              <span className="m-1">{DOB}</span>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">ISSU</span>
              <span className="m-1">{IssueDate}</span>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">EXP</span>
              <span className="m-1">{ExpirationDate}</span>
            </span>{' '}
            <br />
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">RESTRICTIONS</span>
              <span className="m-1">{OperatorLicenseRestrictions}</span>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">ENDORSEMENTS</span>
              <span className="m-1">{OperatorLicenseEndorsments}</span>
            </span>{' '}
            <br />
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">STATUS NON-CDL</span>
              <span className="m-1">{OperatorLicenseStatusCode}</span>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">STATUS CDL</span>
              <span className="m-1">{CommericalOperatorLicenseStatusCode}</span>
            </span>
            <br />
            <br />
            <span className="align-items-center">
              {AddressStreet}
              <br />
              {AddressCity} {AddressStateCode} {AddressZipCode}
            </span>
            <br />
          </div>
        </Card>
      );
    } else {
      return null;
    }
  }

  function RenderPersonItem(value, idx) {
    const handleCardclick = (e, card) => {
      setDetails(card);
    };

    if (value.value !== null && value.value.label) {
      if (details === false) {
        return (
          <Grid item xs={12} md={6} key={idx}>
            <Card
              className="card card-box card-box-hover-rise card-box-hover align-box-row align-items-start p-2"
              onClick={e => handleCardclick(e, value)}>
              <div>
                <div className="display-3">{value.value.data.length}</div>
                <div className="divider mt-2 mb-3 border-2 w-25 rounded" />
                <div className="font-weight-bold font-size-sm text-uppercase">
                  {value.value.label}
                </div>
              </div>
              <div className="ml-auto card-hover-indicator align-self-center">
                <FontAwesomeIcon
                  icon={['fas', 'chevron-right']}
                  className="font-size-xl"
                />
              </div>
            </Card>
          </Grid>
        );
      } else {
        return null;
      }
    }
  }

  function cardClose() {
    setDetails(false);
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} sm={3}>
        {RenderImages()}
        {RenderHistoryButton()}
      </Grid>
      <Grid item xs={12} sm={9}>
        {RenderLocalCard()}
        {RenderNcicCard()}
        <Grid container className={classes.root} spacing={2}>
          {dataArr.map((data, idx) => {
            return RenderPersonItem(data, idx);
          })}
        </Grid>
        {details !== false && (
          <Fragment>
            <AlertsComponent
              data={details}
              themeMode={themeMode}
              close={cardClose}
            />
            <AddressComponent
              data={details}
              themeMode={themeMode}
              close={cardClose}
            />
            <BookingComponent
              data={details}
              themeMode={themeMode}
              close={cardClose}
            />
            <CadIncidentComponent
              data={details}
              themeMode={themeMode}
              close={cardClose}
            />
            <CitationComponent
              data={details}
              themeMode={themeMode}
              close={cardClose}
            />
            <ContactComponent
              data={details}
              themeMode={themeMode}
              close={cardClose}
            />
            <RmsIncidentComponent
              data={details}
              themeMode={themeMode}
              close={cardClose}
            />
            <WarrantComponent
              data={details}
              themeMode={themeMode}
              close={cardClose}
            />
          </Fragment>
        )}
      </Grid>
      {data?.localData?.ptsPersonID && (
        <Button
          variant="contained"
          color="primary"
          style={{
            position: 'absolute',
            bottom: '8px',
            background: 'blue !important',
            right: '120px'
          }}
          onClick={() => {
            dispatch(
              showEditPerson({ ptsPersonID: data.localData.ptsPersonID })
            );
          }}>
          Edit Person
        </Button>
      )}

      {displayType !== 'local' && props.origin !== 'VehicleRegistration' && (
        <Button
          variant="contained"
          color="primary"
          style={{
            position: 'absolute',
            bottom: '8px',
            background: 'blue !important',
            right: '120px'
          }}
          onClick={() => {
            dispatch(setCitaionFlag(true));
            history.push('/Ecitations/traffic');
          }}>
          E-Citation
        </Button>
      )}
    </Grid>
  );
}

export default Info;
