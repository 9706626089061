import store from '../config/configureStore';
import { setUserAuthenticated, setUserData } from './UserReducer';

export const SET_DICTIONARY = 'CODE/SET_DICTIONARYCODE';
export const SET_CODE_COLORS = 'CODE/SET_CODE_COLORS';

export const getDictionay = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('mobile-dictionary');
      service.timeout = 20000;
      const data = await service.find({
        query: {
          $limit: 100,
          IsDeleted: false,
          options: 'mobile'
        }
      });

      dispatch({
        type: SET_DICTIONARY,
        payload: data.dictionary
      });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getCodeColors = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codecolors');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false
        }
      });
      let _codes = { default: {}, categories: {}, units: {}, events: {} };
      for (let i = 0; i < codes.data.length; i++) {
        switch (codes.data[i].Category) {
          case 'DEFAULT':
            _codes.default[codes.data[i]['ColorKey']] =
              codes.data[i]['ColorValue'];
            break;
          case 'ACTION_CATEGORY':
            _codes.categories[codes.data[i]['ColorKey']] =
              codes.data[i]['ColorValue'];
            break;
          case 'EVENT':
            _codes.events[codes.data[i]['ColorKey']] =
              codes.data[i]['ColorValue'];
            break;
          case 'UNIT_ACTION':
            _codes.units[codes.data[i]['ColorKey']] =
              codes.data[i]['ColorValue'];
            break;
          default:
            console.log(`there is no handler for ${codes.data[i].Category} `);
        }
      }
      dispatch({ type: SET_CODE_COLORS, payload: _codes });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export default function reducer(
  state = {
    colorsCodes: { default: {}, categories: {}, units: {}, events: {} },

    dictionary: {}
  },
  action
) {
  // console.log('action: ',action)
  switch (action.type) {
    case SET_DICTIONARY: {
      return {
        ...state,
        dictionary: { ...action.payload }
      };
    }

    case SET_CODE_COLORS:
      return {
        ...state,
        colorsCodes: action.payload
      };

    default:
      break;
  }
  return state;
}
