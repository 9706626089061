import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  Menu,
  Switch,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import PageviewIcon from '@material-ui/icons/Pageview';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import Hotkeys from 'react-hot-keys';
import { AiFillCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import { BsPencilSquare } from 'react-icons/bs';
import { FaBell, FaChevronRight, FaSearch } from 'react-icons/fa';
import { GiPoliceOfficerHead } from 'react-icons/gi';
import { MdLocationSearching } from 'react-icons/md';
import { TiHome } from 'react-icons/ti';
import { success, warning } from 'react-notification-system-redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import {
  Link,
  NavLink,
  useHistory,
  useLocation,
  withRouter
} from 'react-router-dom';
import useSound from 'use-sound';
import notificationSound from '../../assets/sound/notificationSound.wav';
import messageSound from '../../assets/sound/pulse_notification.mp3';
import AlertDialog from '../../global-components/AlertDialog';
import {
  clearFilter,
  deleteAlert,
  getAlertTypes,
  setNewAlert,
  updateAlert
} from '../../reducers/AlertsReducer';
import {
  pinEvent,
  setEventDispatchedNotification,
  setEvents,
  toggleExpanded
} from '../../reducers/EventsReducer';
import { setEventInfowindow } from '../../reducers/MapReducer';
import {
  processResponse,
  responseNotification
} from '../../reducers/NcicReducer';
import {
  setAlertTone,
  setHelpBar,
  setSearchView,
  setViewType,
  toggleDialog
} from '../../reducers/SettingsReducer';
import { setMode } from '../../reducers/ThemeReducer';
import {
  hideMapQuickSearchBox,
  hideMapSearchBox,
  setEventsDrawerMobileState,
  setNavigationDrawerMobileState,
  setSearchActiveTab,
  setUnitsDrawerMobileState,
  showMapQuickSearchBox,
  showMapSearchBox
} from '../../reducers/UiReducer';
import { setUnits } from '../../reducers/UnitsReducer';
import {
  toggleDialog as ToggleUnitDialog,
  unitStatusChange
} from '../../reducers/UnitStatusReducer';
import {
  saveUserSettings,
  setAllPermission,
  setUserAuthenticated
} from '../../reducers/UserReducer';
import { getUnitColor } from '../../utils/uiUtils';
import AboutDialog from '../AboutDialog';
import AgenciesFilter from './agenciesFilter';
import { getAccessPermission } from 'reducers/PermissionsReducer';
import * as Sentry from '@sentry/react';
import { getApiVer } from 'reducers/AppInfoReducer';
import { getCitationFormDefinition } from 'reducers/CitationsReducer';
import AlertCard from 'pages/PagesAlert/AlertCard';
import { updateZones } from 'reducers/ZonesReducer';

const drawerHeight = 80;

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: 99,
    backgroundColor: theme.palette.bgElements
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  toolbar: {
    height: drawerHeight,
    backgroundColor: theme.palette.bgElements
  },
  activeNav: {
    '& > button': {
      background: '#90ABC7',
      fontWeight: 100
    }
  },
  username: {
    position: 'absolute',
    bottom: '-13px',
    left: '-5px',
    width: '100%',
    textAlign: 'center',
    fontSize: '0.7rem',
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  notificationDesc: {
    fontStyle: 'italic'
  },
  EventNotificationDesc: {
    fontStyle: 'bold'
  },
  notificationContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '0',
    margin: '0',
    listStyle: 'none'
  },
  notificationContentItem: {
    flex: '1 50%',
    paddingBottom: '2px'
  },
  notificationActionWrapper: {
    margin: '0px',
    padding: '0px',
    cursor: 'pointer',
    color: 'rgb(90, 83, 67)',
    fontSize: '13px'
  },
  notificationActionBtn: {
    background: 'rgb(235, 173, 26)',
    '&:hover': {
      backgroundColor: 'rgb(235, 173, 26)'
    },
    borderRadius: '2px',
    padding: '6px 20px',
    fontWeight: 'bold',
    margin: '10px 0px 0px',
    border: '0px',
    color: 'rgb(255, 255, 255)',
    fontSize: '13px'
  },
  notificationContentTitle: {
    fontWeight: 'bold'
  },
  notificationContentValue: {
    textAlign: 'right'
  },
  Callers: {
    padding: '0px',
    margin: '0px',
    listStyle: 'none'
  },
  eventDispatchedNotificationContentItem: {
    flex: '1 30%',
    paddingBottom: '2px'
  },
  eventDispatchedActionButton: {
    background: 'rgb(94, 164, 0)',
    '&:hover': {
      backgroundColor: 'rgb(94, 164, 0)'
    }
  }
}));

const TopBar = props => {
  const {
    setNavigationDrawerMobileState,
    setEventsDrawerMobileState,
    setUnitsDrawerMobileState,
    setUserAuthenticated,
    unreadAlertsCount,
    setNewAlert,
    updateAlert,
    deleteAlert,
    setUnits,
    setEvents,
    setEventDispatchedNotification,
    setEventInfowindow,
    eventDispatchedNotification,
    clearFilter,
    wsClient,
    network,
    user,
    toggleDialog,
    unitStatusChange,
    ToggleUnitDialog,
    showMapSearchBox,
    processResponse,
    pendingResponses,
    finishedResponses,
    pinEvent,
    toggleExpanded,
    colors,
    responseNotification,

    hideMapQuickSearchBox,
    themeMode,
    setMode,
    viewType,
    mapQuickSearchBoxVisibility,
    alertTone,
    helpBar,
    searchView,
    isAuthenticated,
    unitActionCodes,
    saveUserSettings,
    appInfo,
    alertTypes
  } = props;
  const unitInserviceStatus =
    unitActionCodes.length === 0
      ? null
      : unitActionCodes['Inservice']
      ? unitActionCodes['Inservice'].Code
      : unitActionCodes['InService'].Code;
  const OutService =
    unitActionCodes.length === 0
      ? null
      : unitActionCodes['OutService']
      ? unitActionCodes['OutService'].Code
      : null;

  const persmissions = useSelector(state => state.permissions.globals);
  const [canExecNcic, setCanExecNcic] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const [messageTone, setMessageTone] = useState(false);
  const [play, { stop }] = useSound(notificationSound, {
    volume: 0.5
  });
  const [play2] = useSound(messageSound);
  const [anchorElAgencies, setAnchorElAgencies] = React.useState(null);
  const theme = useTheme();
  const agencyBasedUnitSetting = useSelector(
    state => state.settings.agencyBasedUnitSetting
  );
  const [AppInfoRegistered, setAppInfoRegistered] = useState(false);
  const { FEATURES_CITATIONS, APP_DEFAULT_STATE } = useSelector(
    state => state.appInfo.appEnvInfo
  );
  const [removeDialog, setRemoveDialog] = useState(false);
  const [alertUpdateDialog, setAlertUpdateDialog] = useState(false);

  const [notifiedAlert, setNotifiedAlert] = useState({});
  React.useEffect(() => {
    setCanExecNcic(getAccessPermission('globals', 'Execute NCIC Queries'));
  }, [persmissions]);
  React.useEffect(() => {
    dispatch(getApiVer());
  }, []);
  React.useEffect(() => {
    setSentryData();
    // eslint-disable-next-line
  }, [appInfo]);
  React.useEffect(() => {
    if (wsClient.websocket) {
      dispatch(getAlertTypes());
    }
  }, [wsClient]);
  const setSentryData = () => {
    const {
      apiVersion,
      buildNo,
      buildTime,
      availBuildNo,
      availBuildTime,
      appVersion
    } = appInfo;

    if (
      AppInfoRegistered ||
      apiVersion === '?' ||
      appVersion === '?' ||
      availBuildNo === '?'
    ) {
      console.log('configuration failed');
      return;
    }
    // console.log('come true');
    setAppInfoRegistered(true);
    const sentryAppInfo = {
      'CAD Mobile Version': appVersion,
      'Build No': buildNo,
      'Build Time': buildTime,
      'Avail. Build No': availBuildNo,
      'Avail. Build Time': availBuildTime,
      'API Version': apiVersion
    };
    delete sentryAppInfo.registration;
    Sentry.setContext('App version', sentryAppInfo);
    const username = user?.userData?.user?.Username || 'unknown';
    Sentry.setUser({ username });
  };
  const handleAgenciesFilterClick = event => {
    setAnchorElAgencies(event.currentTarget);
  };
  const handleAgeciesFilterClose = () => {
    setAnchorElAgencies(null);
  };

  const [anchorElViews, setAnchorElViews] = React.useState(null);
  const [anchorElQuickSearch, setAnchorElQuickSearch] = React.useState(null);
  useEffect(() => {
    if (alertTone && messageTone) {
      play2();
      setMessageTone(false);
    }
  }, [messageTone, alertTone]);

  const handleViewsSettingsClick = event => {
    setAnchorElViews(event.currentTarget);
  };
  const handleViewsSettingsClose = () => {
    setAnchorElViews(null);
  };

  const handleQuickSearchClick = event => {
    dispatch(hideMapSearchBox());
    dispatch(showMapQuickSearchBox());
    // setAnchorElQuickSearch(event.currentTarget);
    // dispatch(showMapQuickSearchBox());
  };
  const handleQuickSearchClose = () => {
    setAnchorElQuickSearch(null);
    // dispatch(setQuickSearchType(''));
  };
  // Notification for New Alert
  const makeNotification = (alert, flag = '') => {
    setMessageTone(true);
    const alertDataArray = Object.entries(alert);
    var itemCount = 0;
    const notificationOpts = {
      title: alertDataArray[2][1]
        ? `${alertDataArray[1][1]} - ${alertDataArray[2][1]}`
        : alertDataArray[1][1],
      position: 'tr',
      autoDismiss: 0,
      children: (
        <div>
          <div className={classes.notificationDesc}>
            {alertDataArray[13][1]}
          </div>
          <div className="divider my-1" />
          <div className={classes.notificationActionWrapper}>
            <div className={classes.notificationContent}>
              {alertDataArray.map((alert, idx) => {
                if (
                  idx > 2 &&
                  idx < 13 &&
                  alert[1] !== null &&
                  alert[1] !== ''
                ) {
                  itemCount = itemCount + 1;
                  if (alert[0] === 'Beginning' || alert[0] === 'Ending')
                    alert[1] = displayDateTime(alert[1]);

                  if (alert[0] === 'OLNState') alert[0] = 'OLN State';
                  if (alert[0] === 'PlateState') alert[0] = 'Plate State';

                  return (
                    <div
                      className={classes.notificationContentItem}
                      style={{
                        textAlign: itemCount % 2 === 0 ? 'right' : 'left'
                      }}
                      key={idx}>
                      <div className={classes.notificationContentTitle}>
                        {alert[0]}
                      </div>
                      <div>{alert[1]}</div>
                    </div>
                  );
                }
              })}
            </div>
            <Button
              onClick={() => {
                if (flag === 'update') {
                  setNotifiedAlert(alert);
                  setAlertUpdateDialog(true);
                } else {
                  clearFilter(true);
                  history.push('/Alerts');
                }
              }}
              className={classes.notificationActionBtn}
              style={{
                background: themeMode == 'night' ? '#000' : 'rgb(235, 173, 26)'
              }}>
              Go to the Bulletin!
            </Button>
          </div>
        </div>
      )
    };

    return notificationOpts;
  };
  // let toneTimer;
  // Notification for Event Dispatched
  const makeNotificationForEventDispatched = event => {
    const notificationOpts = {
      title: `New Event has been dispatched!`,
      position: 'tr',

      onRemove: () => {
        handleStopTimer();
      },
      autoDismiss: 0,
      children: (
        <div>
          <div className={classes.EventNotificationDesc}>{event.EventID}</div>
          <div className="divider my-1" />
          <div className={classes.notificationActionWrapper}>
            <div className={classes.notificationContent}>
              <div
                className={classes.eventDispatchedNotificationContentItem}
                style={{
                  textAlign: 'left'
                }}>
                <div className={classes.notificationContentTitle}>Address</div>
                <div>{event.FullLocationAddress}</div>
              </div>
              <div
                className={classes.eventDispatchedNotificationContentItem}
                style={{
                  textAlign: 'right'
                }}>
                <div className={classes.notificationContentTitle}>Type</div>
                <div>{event.CallType}</div>
              </div>
            </div>
            <div>
              <div className={classes.notificationContentTitle}>Callers</div>
              <ul className={classes.Callers}>
                {event.callers.map((caller, index) => {
                  return (
                    <li key={index}>
                      <FaChevronRight style={{ fontSize: '0.5rem' }} />{' '}
                      {caller.FullName}
                    </li>
                  );
                })}
              </ul>
            </div>
            <Button
              onClick={() => {
                handleStopTimer();
                setEventInfowindow(
                  true,
                  eventDispatchedNotification.data.ptsEventID,
                  true
                );
                pinEvent(eventDispatchedNotification.data);
                toggleExpanded(eventDispatchedNotification.data, true);
                setEventDispatchedNotification(false, null);
                history.push('/Main');
              }}
              className={`${classes.notificationActionBtn} ${classes.eventDispatchedActionButton}`}>
              Go to the Event!
            </Button>
          </div>
        </div>
      )
    };

    return notificationOpts;
  };
  useEffect(() => {
    dispatch(setAllPermission());
    // dispatch(setIsNCICPermission);
  }, [wsClient, isAuthenticated]);
  let toneTimer = 0;
  React.useEffect(() => {
    if (eventDispatchedNotification && eventDispatchedNotification.available) {
      if (alertTone) {
        toneTimer = setInterval(() => {
          play();
        }, 1000);
      }

      dispatch(
        success(
          makeNotificationForEventDispatched(eventDispatchedNotification.data)
        )
      );
      setEventDispatchedNotification(false, null);
    }
  }, [eventDispatchedNotification]);

  React.useEffect(() => {
    if (network.online === false) {
      setUnitStatusBtn(true);
    } else {
      if (user.userUnit) {
        if (
          user.userUnit.Path === 'CAD.DefaultCodes.UnitActions.Inservice' ||
          user.userUnit.Path === 'CAD.DefaultCodes.UnitActions.Outservice'
        ) {
          setUnitStatusBtn(false);
        } else {
          setUnitStatusBtn(true);
        }
      }
    }
  }, [network, user]);
  const checkAllowNotifications = code => {
    dispatch(getAlertTypes());

    if (alertTypes.length === 0) {
      return false;
    }
    const alertItem = alertTypes?.find(item => item.Code === code);

    if (alertItem) {
      const flag = String(alertItem.Flags || '').trim();

      if (flag === 'enable') {
        return true;
      }
    }
    return false;
  };

  React.useEffect(() => {
    if (wsClient.websocket) {
      const onAlertsCreatedListener = alert => {
        const isAllow = checkAllowNotifications(alert.AlertType || '');
        setNewAlert(alert);
        if (isAllow) {
          dispatch(warning(makeNotification(alert)));
        }
      };

      wsClient.websocket
        .service('alerts')
        .on('created', onAlertsCreatedListener);

      return () => {
        wsClient.websocket
          .service('alerts')
          .removeListener('created', onAlertsCreatedListener);
      };
    }
  }, [wsClient, setNewAlert]);

  React.useEffect(() => {
    if (wsClient.websocket) {
      const onAlertsPatchedListener = alert => {
        if (
          moment(alert.Ending).isValid() &&
          moment().format('MM DD YYYY HH:MM:SS') >
            moment(alert.Ending).format('MM DD YYYY HH:MM:SS')
        ) {
          deleteAlert(alert);
        } else {
          // updateAlert(alert);
          const isAllow = checkAllowNotifications(alert.AlertType || '');
          if (isAllow) {
            dispatch(warning(makeNotification(alert, 'update')));
          }
        }
      };

      wsClient.websocket
        .service('alerts')
        .on('updated', alert => onAlertsPatchedListener(alert));

      return () => {
        wsClient.websocket.service('alerts').removeAllListeners('updated');
      };
    }
  }, [wsClient, updateAlert]);

  React.useEffect(() => {
    if (wsClient.websocket) {
      const onAlertsDeleteListener = alert => deleteAlert(alert);

      wsClient.websocket
        .service('alerts')
        .on('deleted', alert => onAlertsDeleteListener(alert));

      return () => {
        wsClient.websocket
          .service('alerts')
          .removeListener('deleted', alert => onAlertsDeleteListener(alert));
      };
    }
  }, [wsClient, deleteAlert]);
  useEffect(() => {
    if (wsClient.websocket && user?.userData && user.userData.user.AgencyID) {
      dispatch(updateZones(user.userData.user.AgencyID));
    }
  }, [user?.userData, user?.userData?.user.AgencyID, wsClient]);
  React.useEffect(() => {
    if (wsClient.websocket) {
      const onEventsListener = events => setEvents(events);

      wsClient.websocket
        .service('cmplx-events')
        .on('newEventsData', onEventsListener);
      wsClient.websocket
        .service('cmplx-events')
        .on('eventChange', onEventsListener);

      const onUnitsListener = units => setUnits(units);
      wsClient.websocket
        .service('cmplx-units')
        .on('newUnitsData', onUnitsListener);

      wsClient.websocket
        .service('cmplx-units')
        .on('unitChange', onUnitsListener);
      return () => {
        wsClient.websocket
          .service('cmplx-events')
          .removeListener('newEventsData', onEventsListener);
        wsClient.websocket
          .service('cmplx-units')
          .removeListener('newUnitsData', onUnitsListener);
        wsClient.websocket
          .service('cmplx-events')
          .removeListener('eventChange', onEventsListener);
        wsClient.websocket
          .service('cmplx-units')
          .removeListener('unitChange', onUnitsListener);
      };
    }
  }, [setEvents, setUnits, wsClient]);

  React.useEffect(() => {
    if (wsClient.websocket) {
      const onCommsysMessageListener = message => processResponse(message);
      wsClient.websocket
        .service('commsys')
        .on('ncicResponse', onCommsysMessageListener);
      return () => {
        wsClient.websocket
          .service('commsys')
          .removeListener('ncicResponse', onCommsysMessageListener);
      };
    }
  }, [wsClient, processResponse]);

  React.useEffect(() => {
    if (wsClient.websocket) {
      const onCommsysMessageNotificationListener = message =>
        responseNotification(message);
      wsClient.websocket
        .service('commsys')
        .on('ncicNotification', onCommsysMessageNotificationListener);
      return () => {
        wsClient.websocket
          .service('commsys')
          .removeListener(
            'ncicNotification',
            onCommsysMessageNotificationListener
          );
      };
    }
  }, [wsClient, processResponse]);

  const [unitStatusBtn, setUnitStatusBtn] = React.useState(false);
  const [displayAbout, setDisplayAbout] = React.useState(false);
  const [alertBox, setAlertBox] = React.useState(false);

  let location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();

  const StyledBadge = withStyles({
    badge: {
      backgroundColor:
        network.online === false ? 'var(--danger)' : 'var(--success)',
      color: network.online === false ? 'var(--danger)' : 'var(--success)',
      boxShadow: '0 0 0 2px #fff',
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""'
      }
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0
      }
    }
  })(Badge);

  const handleUserMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsClick = () => {
    setAnchorEl(null);
    toggleDialog();
  };

  const handleAboutClick = () => {
    setAnchorEl(null);
    setDisplayAbout(!displayAbout);
  };

  const handleUnitStatusClick = () => {
    setAnchorEl(null);
    ToggleUnitDialog();
  };

  const handleInserviceModeClick = () => {
    setAnchorEl(null);
    if (user && user.userUnit.Events && user.userUnit.Events.length > 0) {
      setAlertBox(true);
    } else {
      changeStatusToInservice();
    }
  };

  const closeAlertBox = () => {
    setAnchorEl(null);
    setAlertBox(false);
  };

  const changeStatusToInservice = () => {
    unitStatusChange(
      unitInserviceStatus,
      '',
      '',
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      null,
      true
    );

    setAlertBox(false);
  };
  const changeStatusToOutServce = () => {
    unitStatusChange(
      OutService,
      '',
      '',
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      null,
      true
    );
  };
  document.addEventListener('keydown', function(event) {
    if (
      event.ctrlKey &&
      (event.key === '0' ||
        event.key === '1' ||
        event.key === '2' ||
        event.key === '3' ||
        event.key === '4' ||
        event.key === '5' ||
        event.key === '6' ||
        event.key === '7' ||
        event.key === '8' ||
        event.key === '9')
    ) {
      event.preventDefault();
    }
  });
  const onKeyUp = (keyName, e, handle) => {
    // e.preventDefault();
    if (keyName == 'ctrl+1') {
      dispatch(setSearchActiveTab(0));
      history.push('/Search');
    } else if (keyName == 'ctrl+2') {
      dispatch(setSearchActiveTab(1));
      history.push('/Search');
    } else if (keyName == 'ctrl+3') {
      handleUnitStatusClick();
    } else if (keyName == 'ctrl+4') {
      window.location.reload(false);
    }
  };
  const handleThemeModeChange = event => {
    setMode(event.target.checked == true ? 'night' : 'day');
    saveUserSettings();
  };
  const handleToneChange = event => {
    dispatch(setAlertTone(event.target.checked));
    saveUserSettings();
  };
  const handleViewTypeChange = event => {
    dispatch(setViewType(event.target.checked == true ? 'simple' : 'complex'));
    saveUserSettings();
  };
  const handleSearchViewChange = event => {
    dispatch(setSearchView(event.target.checked == true ? 'ncic' : 'regular'));
    saveUserSettings();
  };
  const handleStopTimer = () => {
    if (alertTone) {
      clearInterval(toneTimer);
      stop();
    }
  };
  // onKeyDown(keyName, e, handle) {
  //   console.log("test:onKeyDown", keyName, e, handle)
  //   this.setState({
  //     output: `onKeyDown ${keyName}`,
  //   });
  // }
  // console.log('userUnit', user.userUnit);
  const handleAssignunitRemove = async value => {
    // console.log(user.userUnit);
    setRemoveDialog(false);
    if (value) {
      try {
        // setLoadingUnit(true);
        const { Username } = user?.userData?.user;
        const service = wsClient?.websocket.service('cad-resources');
        service.timeout = 30000;
        const removedResources = await service.patch(
          user.userUnit.CurrentAssignResourceID,
          {
            Ending: new Date(),
            UpdatedBy: Username
          }
        );
        // console.log('removedResources', removedResources);
      } catch (e) {
        console.log(e);
      } finally {
        // setLoadingUnit(false);
        setUserAuthenticated(false);
      }
    } else {
      setUserAuthenticated(false);
    }
  };

  return (
    <Fragment>
      <AppBar color="primary" position="fixed" className={classes.appBar}>
        <Dialog
          open={removeDialog}
          onClose={() => setRemoveDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth="sm">
          <DialogTitle id="alert-dialog-title">
            Do you want to remove yourself from {user?.userUnit?.Unit} Unit
          </DialogTitle>

          <DialogActions>
            <Button onClick={() => handleAssignunitRemove(false)}>NO</Button>
            <Button onClick={() => handleAssignunitRemove(true)} autoFocus>
              YES
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={alertUpdateDialog}
          onClose={() => setAlertUpdateDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth="sm">
          <DialogContent style={{ width: '100%' }}>
            <AlertCard alert={notifiedAlert} idx="update" />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAlertUpdateDialog(false)}>Close</Button>
            {/* <Button onClick={() => handleAssignunitRemove(true)} autoFocus> */}
            {/* YES
            </Button> */}
          </DialogActions>
        </Dialog>
        <Toolbar className={classes.toolbar}>
          <Hidden mdUp>
            <div
              style={{
                display: 'flex',
                alignSelf: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }}>
              <div style={{ alignSelf: 'center' }}>
                <IconButton
                  color="primary"
                  aria-label="Open drawer"
                  edge="start"
                  onClick={() => setNavigationDrawerMobileState()}>
                  <MenuIcon color="primary" />
                </IconButton>
              </div>
              <div style={{ display: 'flex' }}>
                {location.pathname === '/Main' && (
                  <Fragment>
                    <div>
                      <Tooltip title="Quick Search">
                        <Button
                          style={{
                            width: '60px',
                            height: '60px',
                            border: '3px solid'
                          }}
                          onClick={() => {
                            dispatch(hideMapSearchBox());
                            mapQuickSearchBoxVisibility
                              ? hideMapQuickSearchBox()
                              : dispatch(showMapQuickSearchBox());
                          }}
                          variant="outlined"
                          color="primary"
                          className="p-0 m-2 d-block">
                          <span className="btn-wrapper--icon">
                            <PageviewIcon style={{ fontSize: '1.9rem' }} />
                          </span>
                          <span
                            style={{
                              left: 0,
                              right: 0,
                              bottom: '-3px',
                              position: 'absolute',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}>
                            Search
                          </span>
                        </Button>
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip title="Events">
                        <Button
                          style={{
                            width: '60px',
                            height: '60px',
                            border: '3px solid'
                          }}
                          onClick={() => setEventsDrawerMobileState()}
                          variant="outlined"
                          color="primary"
                          className="p-0 m-2 d-block">
                          <span className="btn-wrapper--icon">
                            <FontAwesomeIcon
                              icon="calendar-alt"
                              className="font-size-xxl"
                            />
                          </span>
                          <span
                            style={{
                              left: 0,
                              right: 0,
                              bottom: '-3px',
                              position: 'absolute',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}>
                            Events
                          </span>
                        </Button>
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip title="Units">
                        <Button
                          style={{
                            width: '60px',
                            height: '60px',
                            border: '3px solid'
                          }}
                          onClick={() => setUnitsDrawerMobileState()}
                          variant="outlined"
                          color="primary"
                          className="p-0 m-2 d-block">
                          <span className="btn-wrapper--icon">
                            <FontAwesomeIcon
                              icon="car"
                              className="font-size-xxl"
                            />
                          </span>
                          <span
                            style={{
                              left: 0,
                              right: 0,
                              bottom: '-3px',
                              position: 'absolute',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}>
                            Units
                          </span>
                        </Button>
                      </Tooltip>
                    </div>
                  </Fragment>
                )}
                <div style={{ alignSelf: 'center' }}>
                  <Button
                    color="primary"
                    onClick={handleUserMenuClick}
                    className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
                    <Box>
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right'
                        }}
                        variant="dot">
                        {user.userUnit && user.userUnit !== null && (
                          <Badge
                            overlap="rectangular"
                            badgeContent={
                              <span
                                className="badge"
                                style={{
                                  backgroundColor: getUnitColor(
                                    user.userUnit,
                                    colors
                                  ),
                                  marginLeft: '5px',
                                  color: '#fff'
                                }}>
                                {user.userUnit.UnitStatus}
                              </span>
                            }>
                            <Avatar
                              sizes="44"
                              alt={
                                user.userData
                                  ? user.userData.user.Username
                                  : null
                              }
                            />
                          </Badge>
                        )}
                      </StyledBadge>
                      <div className={classes.username}>
                        {user.userData ? user.userData.user.Username : null}
                      </div>
                    </Box>
                    <div className="d-none d-xl-block">
                      <div className="font-weight-bold pt-2 line-height-1">
                        {user.userData ? user.userData.user.Username : null}
                      </div>
                      <span className="text-white-50">
                        {user.userUnit !== null ? user.userUnit.Unit : null}
                      </span>
                    </div>
                    <span className="pl-1 pl-xl-3">
                      <FontAwesomeIcon
                        style={{ color: '#ac0a26' }}
                        icon={['fas', 'angle-down']}
                        className="opacity-5"
                      />
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </Hidden>
          <Hidden smDown>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }}>
              <div style={{ display: 'flex', flex: 1 }}>
                <Tooltip title="Home">
                  <NavLink to="/Main" activeClassName={classes.activeNav}>
                    <Button
                      style={{
                        width: '85px',
                        height: '60px',
                        border: '3px solid'
                      }}
                      variant="outlined"
                      color="primary"
                      className="p-0 m-2 d-block">
                      <span className="btn-wrapper--icon">
                        <TiHome style={{ fontSize: '2.5rem' }} />
                      </span>
                      <span
                        style={{
                          left: 0,
                          right: 0,
                          bottom: '-3px',
                          position: 'absolute',
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }}>
                        Home
                      </span>
                    </Button>
                  </NavLink>
                </Tooltip>
                <Tooltip title="Search">
                  <NavLink to="/Search" activeClassName={classes.activeNav}>
                    <Button
                      style={{
                        width: '85px',
                        height: '60px',
                        border: '3px solid'
                      }}
                      variant="outlined"
                      color="primary"
                      className="p-0 m-2 d-block">
                      <Badge
                        overlap="rectangular"
                        badgeContent={finishedResponses}
                        color="error">
                        <span className="btn-wrapper--icon mb-2">
                          <FaSearch style={{ fontSize: '1.9rem' }} />
                        </span>
                      </Badge>
                      <Badge
                        overlap="rectangular"
                        style={{ left: '-37px', top: '-14px' }}
                        badgeContent={pendingResponses}
                        color="primary"></Badge>
                      <span
                        style={{
                          left: 0,
                          right: 0,
                          bottom: '-3px',
                          position: 'absolute',
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }}>
                        Search
                      </span>
                    </Button>
                  </NavLink>
                </Tooltip>
                {FEATURES_CITATIONS && FEATURES_CITATIONS == true && (
                  <Tooltip title="Ecitations">
                    <NavLink
                      to="/Ecitations/traffic"
                      activeClassName={classes.activeNav}>
                      <Button
                        style={{
                          width: '85px',
                          height: '60px',
                          border: '3px solid'
                        }}
                        variant="outlined"
                        color="primary"
                        className="p-0 m-2 d-block">
                        <span className="btn-wrapper--icon">
                          <BsPencilSquare style={{ fontSize: '1.9rem' }} />
                        </span>
                        <span
                          style={{
                            left: 0,
                            right: 0,
                            bottom: '-3px',
                            position: 'absolute',
                            textAlign: 'center',
                            fontWeight: 'bold'
                          }}>
                          Ecitations
                        </span>
                      </Button>
                    </NavLink>
                  </Tooltip>
                )}
                <Tooltip title="Bulletin">
                  <NavLink to="/Alerts" activeClassName={classes.activeNav}>
                    <Button
                      style={{
                        width: '85px',
                        height: '60px',
                        border: '3px solid'
                      }}
                      variant="outlined"
                      color="primary"
                      className="p-0 m-2 d-block">
                      <Badge
                        overlap="rectangular"
                        badgeContent={unreadAlertsCount}
                        color="error">
                        <span className="btn-wrapper--icon mb-2">
                          <FaBell style={{ fontSize: '1.9rem' }} />
                        </span>
                      </Badge>
                      <span
                        style={{
                          left: 0,
                          right: 0,
                          bottom: '-3px',
                          position: 'absolute',
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }}>
                        Bulletin
                      </span>
                    </Button>
                  </NavLink>
                </Tooltip>
              </div>

              <Tooltip title="Quick Search">
                <Link
                  onClick={() => {
                    dispatch(hideMapSearchBox());
                    mapQuickSearchBoxVisibility
                      ? hideMapQuickSearchBox()
                      : dispatch(showMapQuickSearchBox());
                  }}
                  to="/Main">
                  <Button
                    style={{
                      width: '100px',
                      height: '60px',
                      border: '3px solid'
                    }}
                    aria-controls="Quick Search"
                    aria-haspopup="true"
                    // onClick={event => handleQuickSearchClick(event)}
                    variant="outlined"
                    color="primary"
                    className="p-0 m-2 d-block">
                    <span className="btn-wrapper--icon">
                      <PageviewIcon style={{ fontSize: '1.9rem' }} />
                    </span>
                    <span
                      style={{
                        left: 0,
                        right: 0,
                        bottom: '-3px',
                        position: 'absolute',
                        textAlign: 'center',
                        fontWeight: 'bold'
                      }}>
                      Quick Search
                    </span>
                  </Button>
                </Link>
              </Tooltip>

              {/* <QuickSearch
                anchorEl={anchorElQuickSearch}
                handleQuickSearchClose={handleQuickSearchClose}
              /> */}
              {/* <Tooltip title="Views settings">
                <Button
                  style={{
                    width: '70px',
                    height: '60px',
                    border: '0'
                  }}
                  aria-controls="views-settings"
                  aria-haspopup="true"
                  onClick={event => handleViewsSettingsClick(event)}
                  variant="outlined"
                  color="primary"
                  className="p-0 m-2 d-block">
                  <span className="btn-wrapper--icon">
                    <ViewListIcon style={{ fontSize: '1.9rem' }} />
                  </span>
                  <span
                    style={{
                      left: 0,
                      right: 0,
                      bottom: '-3px',
                      position: 'absolute',
                      textAlign: 'center',
                      fontWeight: 'bold'
                    }}>
                    Views
                  </span>
                </Button>
              </Tooltip>
              <ViewsSettings
                anchorEl={anchorElViews}
                handleViewsSettingsClose={handleViewsSettingsClose}
              /> */}
              {/* <Tooltip title="Agencies filter">
                <Button
                  style={{
                    width: '70px',
                    height: '60px',
                    border: '0'
                  }}
                  aria-controls="agencies-filter"
                  aria-haspopup="true"
                  onClick={event => handleAgenciesFilterClick(event)}
                  variant="outlined"
                  color="primary"
                  className="p-0 m-2 d-block">
                  <span className="btn-wrapper--icon">
                    <FilterListIcon style={{ fontSize: '1.9rem' }} />
                  </span>
                  <span
                    style={{
                      left: 0,
                      right: 0,
                      bottom: '-3px',
                      position: 'absolute',
                      textAlign: 'center',
                      fontWeight: 'bold'
                    }}>
                    Agencies
                  </span>
                </Button>
              </Tooltip> */}
              <AgenciesFilter
                anchorEl={anchorElAgencies}
                handleAgeciesFilterClose={handleAgeciesFilterClose}
              />
              <Tooltip title="Search Address">
                <Link
                  onClick={() => {
                    hideMapQuickSearchBox();
                    showMapSearchBox();
                  }}
                  to="/Main">
                  <Button
                    style={{
                      width: '70px',
                      height: '60px',
                      border: '3px solid'
                    }}
                    variant="outlined"
                    color="primary"
                    className="p-0 m-2 d-block">
                    <span className="btn-wrapper--icon">
                      <MdLocationSearching style={{ fontSize: '1.9rem' }} />
                    </span>
                    <span
                      style={{
                        left: 0,
                        right: 0,
                        bottom: '-3px',
                        position: 'absolute',
                        textAlign: 'center',
                        fontWeight: 'bold'
                      }}>
                      Address
                    </span>
                  </Button>
                </Link>
              </Tooltip>
              <Button
                style={{
                  width: '70px',
                  height: '60px',
                  border: '3px solid'
                }}
                onClick={() => handleUnitStatusClick()}
                variant="outlined"
                color="primary"
                className="p-0 m-2 d-block">
                <span className="btn-wrapper--icon">
                  <GiPoliceOfficerHead size={30} />
                </span>
                <span
                  style={{
                    left: 0,
                    right: 0,
                    bottom: '-3px',
                    position: 'absolute',
                    textAlign: 'center',
                    fontWeight: 'bold'
                  }}>
                  Status
                </span>
              </Button>

              {user.userUnit !== null &&
                user.userUnit.UnitStatus !== unitInserviceStatus && (
                  <>
                    <Button
                      style={{
                        width: '70px',
                        height: '60px',
                        border: '3px solid'
                      }}
                      onClick={() => handleInserviceModeClick()}
                      variant="outlined"
                      color="primary"
                      className="p-0 m-2 d-block">
                      <span className="btn-wrapper--icon">
                        <AiFillCheckCircle size={30} />
                      </span>
                      <span
                        style={{
                          left: 0,
                          right: 0,
                          bottom: '-3px',
                          position: 'absolute',
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }}>
                        Inservice
                      </span>
                    </Button>

                    <AlertDialog
                      alertBox={alertBox}
                      change={changeStatusToInservice}
                      alertTitle={
                        "Are you sure you want to change your status to 'INSERVICE' ?"
                      }
                      alertDescription={
                        "If you press 'Yes', you will be unassigned from all events that you are currently assigned!"
                      }
                      closeAlertBox={closeAlertBox}
                    />
                  </>
                )}

              <div style={{ alignSelf: 'center' }}>
                <Button
                  color="inherit"
                  onClick={handleUserMenuClick}
                  className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
                  <Box>
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      variant="dot">
                      {user.userUnit && user.userUnit !== null && (
                        <Badge
                          overlap="rectangular"
                          badgeContent={
                            <span
                              className="badge"
                              style={{
                                backgroundColor: getUnitColor(
                                  user.userUnit,
                                  colors
                                ),
                                marginLeft: '5px'
                              }}>
                              {user.userUnit.UnitStatus}
                            </span>
                          }>
                          <Avatar
                            sizes="44"
                            alt={
                              user.userData ? user.userData.user.Username : null
                            }
                          />
                        </Badge>
                      )}
                    </StyledBadge>
                    <div className={classes.username}>
                      {user.userData ? user.userData.user.Username : null}
                    </div>
                  </Box>
                  {/* <div className="d-none d-xl-block">
                    <div className="font-weight-bold pt-2 line-height-1">
                      {user.userData ? user.userData.user.Username : null}
                    </div>
                    <span className="text-white-50">
                      {user.userUnit !== null ? user.userUnit.Unit : null}
                    </span>
                  </div> */}
                  <span className="pl-1 pl-xl-1">
                    <FontAwesomeIcon
                      style={{ color: '#ac0a26' }}
                      icon={['fas', 'angle-down']}
                      className="opacity-5"
                    />
                  </span>
                </Button>
              </div>
            </div>
          </Hidden>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            getContentAnchorEl={null}
            open={Boolean(anchorEl)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center'
            }}
            onClose={handleClose}
            className="ml-2">
            <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
              <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
                <Box>
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    variant="dot">
                    {user.userUnit && user.userUnit !== null && (
                      <Badge
                        overlap="rectangular"
                        badgeContent={
                          <span
                            className="badge"
                            style={{
                              backgroundColor: getUnitColor(
                                user.userUnit,
                                colors
                              ),
                              marginLeft: '10px',
                              marginTop: '20px',
                              color: '#fff'
                            }}>
                            {user.userUnit.UnitStatus}
                          </span>
                        }>
                        <Avatar
                          sizes="44"
                          alt={
                            user.userData ? user.userData.user.Username : null
                          }
                        />
                      </Badge>
                    )}
                  </StyledBadge>
                </Box>
                <div>
                  <div className="font-weight-bold text-center pt-2 line-height-1">
                    {user.userData ? user.userData.user.Username : null}
                  </div>
                  <span className="text-black-50 text-center">
                    {user.userUnit !== null ? user.userUnit.Unit : null}
                  </span>
                </div>
                <Divider className="w-100 mt-2" />

                <div style={{ display: 'flex', padding: '2px 5px ' }}>
                  <Typography component="div">
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}>
                      <Grid item>Light</Grid>
                      <Grid item>
                        <Switch
                          checked={themeMode == 'day' ? false : true}
                          onChange={handleThemeModeChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Grid>
                      <Grid item>Dark</Grid>
                    </Grid>
                  </Typography>
                </div>
                <div style={{ display: 'flex', padding: '2px 5px ' }}>
                  <Typography component="div">
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}>
                      <Grid item>Standard</Grid>
                      <Grid item>
                        <Switch
                          checked={viewType == 'complex' ? false : true}
                          onChange={handleViewTypeChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Grid>
                      <Grid item>Compact</Grid>
                    </Grid>
                  </Typography>
                </div>
                <div style={{ display: 'flex', padding: '2px 5px ' }}>
                  <Typography component="div">
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}>
                      <Grid item> Tone Off</Grid>
                      <Grid item>
                        <Switch
                          checked={alertTone}
                          onChange={handleToneChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Grid>
                      <Grid item>Tone On</Grid>
                    </Grid>
                  </Typography>
                </div>
                <div style={{ display: 'flex', padding: '2px 5px ' }}>
                  <Typography component="div">
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}>
                      <Grid item> All</Grid>
                      <Grid item>
                        <Switch
                          checked={searchView == 'regular' ? false : true}
                          onChange={handleSearchViewChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Grid>
                      <Grid item>NCIC</Grid>
                    </Grid>
                  </Typography>
                </div>
                <Divider className="w-100 mt-2" />
                <ListItem onClick={() => handleSettingsClick()} button>
                  Settings
                </ListItem>
                <ListItem onClick={() => handleAboutClick()} button>
                  About
                </ListItem>
                <Hidden mdDown>
                  <ListItem
                    onClick={() => {
                      dispatch(setHelpBar(true));
                      saveUserSettings();
                    }}
                    button>
                    Shortcuts
                  </ListItem>
                </Hidden>
                <Divider className="w-100" />
                <ListItem className="d-block rounded-bottom pb-0 text-center">
                  <Hidden smUp>
                    <Button
                      fullWidth
                      onClick={() => handleUnitStatusClick()}
                      variant="contained"
                      size="medium"
                      color="primary">
                      <span className="btn-wrapper--icon">
                        <GiPoliceOfficerHead size={18} color="#fff" />
                      </span>
                      <span className="btn-wrapper--label">Status change</span>
                    </Button>
                  </Hidden>
                </ListItem>
                <ListItem className="d-block rounded-bottom pb-0 text-center">
                  <Button
                    fullWidth
                    onClick={() => {
                      changeStatusToOutServce();
                      setUserAuthenticated(false);
                    }}
                    variant="contained"
                    size="medium"
                    color="primary">
                    <span className="btn-wrapper--icon">
                      <NotificationsOffIcon fontSize="small" />
                    </span>
                    <span className="btn-wrapper--label">OFF DUTY</span>
                  </Button>
                </ListItem>
                <ListItem className="d-block rounded-bottom pb-0 text-center">
                  <Button
                    fullWidth
                    onClick={() => {
                      if (agencyBasedUnitSetting) {
                        setRemoveDialog(true);
                      } else {
                        setUserAuthenticated(false);
                      }
                    }}
                    variant="contained"
                    size="medium"
                    color="primary">
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                    </span>
                    <span className="btn-wrapper--label">Logout</span>
                  </Button>
                </ListItem>
              </List>
            </div>
          </Menu>
        </Toolbar>
        <Hotkeys
          keyName="ctrl+1"
          // onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
        <Hotkeys
          keyName="ctrl+2"
          // onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
        <Hotkeys
          keyName="ctrl+3"
          // onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
        <Hotkeys keyName="ctrl+4" onKeyUp={onKeyUp} />
      </AppBar>
      {helpBar && (
        <Hidden smDown>
          <div
            style={{
              position: 'fixed',
              zIndex: 999,
              width: '100%',
              bottom: 0,
              height: '50px',
              backgroundColor: '#2d3f5d',
              color: 'white',
              boxSizing: 'border-box'
              // margin: viewType == 'complex' ? '0px 300px' : '0px 400px 0px 550px'
            }}>
            <Grid container spacing={2} className="p-3">
              <Grid item>
                <h6>Ctrl+1 -> Plate </h6>
              </Grid>
              <Grid item className="pl-2">
                <h6>Ctrl+2 -> OLN </h6>
              </Grid>
              <Grid item className="pl-2">
                <h6>Ctrl+3 -> Status </h6>
              </Grid>
              <Grid item className="pl-2">
                <h6>Ctrl+4 -> Refresh </h6>
              </Grid>
            </Grid>
            <AiOutlineCloseCircle
              onClick={() => {
                dispatch(setHelpBar(false));
                saveUserSettings();
              }}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                right: '6px',
                top: '3px',
                fontSize: '1.5rem',
                color: 'white'
              }}
            />
          </div>
        </Hidden>
      )}
      {displayAbout && <AboutDialog close={handleAboutClick} />}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  network: state.offline,
  themeMode: state.theme.mode,
  user: state.user,
  isAuthenticated: state.user.isAuthenticated,
  wsClient: state.websocket,
  viewType: state.settings.viewType,
  unreadAlertsCount: state.alerts.unreadAlertsCount,
  eventInfowindow: state.map.eventInfowindow,
  eventDispatchedNotification: state.events.eventDispatchedNotification,
  pendingResponses: state.ncic.pendingResponses,
  finishedResponses: state.ncic.finishedResponses,
  colors: state.codes.colorsCodes,
  mapQuickSearchBoxVisibility: state.ui.mapQuickSearchBoxVisibility,
  alertTone: state.settings.alertTone,
  helpBar: state.settings.helpBar,
  searchView: state.settings.searchView,
  unitActionCodes: state.data.normalizedUnitActionCodesCategory,
  appInfo: state.appInfo,
  alertTypes: state.alerts.alertTypes
});

export default withRouter(
  connect(mapStateToProps, {
    setNavigationDrawerMobileState,
    setEventsDrawerMobileState,
    setUnitsDrawerMobileState,
    setMode,
    setUserAuthenticated,
    toggleDialog,
    ToggleUnitDialog,
    unitStatusChange,
    showMapSearchBox,
    setNewAlert,
    updateAlert,
    deleteAlert,
    saveUserSettings,
    clearFilter,
    setUnits,
    setEvents,
    setEventInfowindow,
    setEventDispatchedNotification,
    processResponse,
    pinEvent,
    toggleExpanded,
    responseNotification,
    hideMapQuickSearchBox
  })(TopBar)
);
