import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { closeAddVehicle, showEditVehicle } from 'reducers/DialogsReducer';
import Dialog from '../Dialog';
import { Button } from '@material-ui/core';
import { handleError } from 'reducers/ErrorReducer';
import FormDivider from '../FormDivider';
import Autocomplete2 from '../Autocomplete2';
import TextField2 from '../TextField2';
import Checkbox2 from '../Checkbox2';
import formStyles, {
  gridStyle,
  Row,
  RowInner,
  ColCard,
  Fills,
  validateVin,
  formatSaveData,
  isEmptySaveData
} from 'utils/formStyles';
import PersonLookup2 from '../PersonLookup2';
import PlaceLookup from '../PlaceLookup';
import DatePicker2 from '../DatePicker2';
import { searchPlaceAddresses, addVehicle } from 'reducers/SearchReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import Dictionary from '../Dictionary';
import { notify } from 'reducers/NotifierReducer';

const useStyles = makeStyles(theme => ({
  ...formStyles,
  item: gridStyle(200, 320),
  field: gridStyle(210, 400)
}));

const PartyTypes = [{ Code: 'Person' }, { Code: 'Place' }];

function AddVehicleDialog(props) {
  const classes = useStyles();
  const { dictionary } = props;
  const { VehicleCommercial, vehicleModelsCodes } = dictionary;

  // ptsVehicleReg table entries
  const [PartyType, setPartyType] = useState(null);
  const [partyTypePerson, setPartyTypePerson] = useState(null);
  const [partyTypePlace, setPartyTypePlace] = useState(null);
  const [ptsParentID, setParentID] = useState(null);
  const [PlateNumber, setPlateNumber] = useState('');
  const [PlateState, setPlateState] = useState(null);
  const [PlateType, setPlateType] = useState(null);
  const [PlateExpiration, setPlateExpiration] = useState(null);
  const [authority, setAuthority] = useState(null);
  const [authorityAddress, setAuthorityAddress] = useState(null);
  const [AuthorityAddressOptions, setAuthorityAddressOptions] = useState(null);
  const [PlateColor, setPlateColor] = useState(null);
  const [PlateBackgroundText, setPlateBackgroundText] = useState('');
  const [PlateStatus, setPlateStatus] = useState(null);
  const [DecalExpiration, setDecalExpiration] = useState(null);
  const [PlateDetail, setPlateDetail] = useState(null);
  const [TitleText, setTitleText] = useState('');
  const [InsuranceCompany, setRegInsuranceCompany] = useState('');
  const [VehicleValue, setVehicleValue] = useState('');
  const [OdometerReading, setOdometerReading] = useState('');
  const [GrossWeightMeasure, setGrossWeightMeasure] = useState('');
  const [IsPermanentReg, setIsPermanentReg] = useState(false);
  const [RoadIndicator, setRoadIndicator] = useState(false);
  const [IsLeased, setIsLeased] = useState(false);
  const [LesseeType, setLesseeType] = useState(null);
  const [LessorType, setLessor] = useState(null);
  const [lessorPerson, setLessorPerson] = useState(null);
  const [lessorPlace, setLessorPlace] = useState(null);
  const [lesseePerson, setLesseePerson] = useState(null);
  const [lesseePlace, setLesseePlace] = useState(null);
  const [ptsLesseeID, setPtsLesseeID] = useState(null);
  const [ptsLessorID, setLessorID] = useState(null);
  const [Year, setYear] = useState('');
  const [Make, setMake] = useState(null);
  const [Model, setModel] = useState(null);
  const [Style, setStyle] = useState(null);
  const [DoorQuantity, setDoorQuantity] = useState('');
  const [PassengeSafeQty, setPassengeSafeQty] = useState('');
  const [SeatQty, setSeatQty] = useState('');
  const [PrimaryColor, setPrimaryColor] = useState(null);
  const [SecondaryColor, setSecondaryColor] = useState(null);
  const [InteriorColor, setInteriorColor] = useState(null);
  const [Classification, setClassification] = useState(null);
  const [Description, setDescription] = useState('');
  const [Brand, setBrand] = useState(null);
  const [Brander, setBrander] = useState(null);
  const [PropertyID, setPropertyID] = useState('');
  const [VIN, setVIN] = useState('');
  const [VINAnalysisText, setVINAnalysisText] = useState('');
  const [CommercialID, setCommercialID] = useState('');
  const [EmissionInspectionQty, setEmissionInspectionQty] = useState('');
  const [Property, setProperty] = useState(null);
  const [Commercial, setCommercial] = useState(null);
  const [Transmission, setTransmission] = useState(null);
  const [IsCMVIndicator, setIsCMVIndicator] = useState(false);
  const [IsRented, setIsRented] = useState(false);
  const [IsWanted, setIsWanted] = useState(false);
  const [GarageIndicator, setGarageIndicator] = useState(false);
  const [GarageFacility, setGarageFacility] = useState(null);
  const [makesModelsOptions, setMakesModelsOptions] = useState(null);
  const [regDisabled, setRegDisabled] = useState(true);

  // console.log('vehicle model ', VehicleMakesModels);
  // useEffect(() => {
  //   if (!authority) {
  //     setAuthorityAddressOptions([]);
  //     setAuthorityAddress(null);
  //   } else {
  //     getPlaceAddresses(authority.ptsPlaceID);
  //   }
  // }, [authority]);

  // useEffect(() => {
  //   const newRegDisabled = !PartyType || !(partyTypePerson || partyTypePlace);
  //   regDisabled !== newRegDisabled && setRegDisabled(newRegDisabled);
  //   // eslint-disable-next-line
  // }, [PartyType, partyTypePerson, partyTypePlace]);

  // const getPlaceAddresses = async ptsPlaceID => {
  //   const addresses = await searchPlaceAddresses(ptsPlaceID);
  //   setAuthorityAddressOptions(addresses);
  //   if (addresses.length === 1) {
  //     setAuthorityAddress(addresses[0]);
  //   } else {
  //     setAuthorityAddress(null);
  //   }
  // };

  const close = () => props.closeAddVehicle();

  const save = async () => {
    const vehRegData = formatSaveData(
      {
        PlateNumber,
        PlateState,
        PlateType,
        PlateExpiration,

        IsDeleted: 0
      },
      true
    );
    if (PlateNumber === '' || PlateState === null) {
      props.notify('Plate number and plate state are required', 'warning');
      return;
    }

    vehRegData.ptsPlateIssuingAuthorityID = authority
      ? authority.ptsPlateIssuingAuthorityID
      : null;
    vehRegData.ptsAddressID = authorityAddress
      ? authorityAddress.ptsAddressID
      : null;

    const vehicleData = formatSaveData(
      {
        Year,
        Make,
        Model,
        Style,
        DoorQuantity,
        PassengeSafeQty,
        SeatQty,
        PrimaryColor,
        SecondaryColor,

        VIN,

        Description,
        IsTowed: 0,
        IsDeleted: 0
      },
      true
    );
    // if (isEmptySaveData(vehicleData)) {
    //   props.notify('Vehicle data empty', 'warning');
    //   return;
    // }

    props.showSpinner();
    try {
      const data = await addVehicle({ vehicleData, vehRegData });
      const { ptsVehicleID } = data.vehicle;
      props.notifyDataUpdate({ type: 'add-vehicle', data: { ptsVehicleID } });
      props.closeAddVehicle();
      props.showEditVehicle({ ptsVehicleID });
    } catch (err) {
      props.handleError(err);
    }
    props.hideSpinner();
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>

        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderPlateNumber = () => {
    const onChange = (ev, val) => setPlateNumber(val);
    return (
      <TextField2
        className={classes.item}
        label="Plate Number"
        value={PlateNumber}
        onChange={onChange}
        max={25}
        // disabled={regDisabled}
      />
    );
  };

  const renderPlateState = () => {
    const onChange = (ev, val) => setPlateState(val);
    return (
      <Dictionary
        options="statesCodes"
        className={classes.item}
        onChange={onChange}
        value={PlateState}
        label="Plate State"
        // disabled={regDisabled}
      />
    );
  };

  const renderPlateType = () => {
    const onChange = (ev, val) => setPlateType(val);
    return (
      <Dictionary
        options="plateTypesCodes"
        className={classes.item}
        onChange={onChange}
        value={PlateType}
        label="Plate Type"
        // disabled={regDisabled}
      />
    );
  };

  const renderPlateExpiration = () => {
    const onChange = (ev, val) => setPlateExpiration(val);
    return (
      <DatePicker2
        onChange={onChange}
        label="Plate Expiration Date"
        value={PlateExpiration}
        className={classes.item}
        // disabled={regDisabled}
      />
    );
  };

  const renderYear = () => {
    const onChange = (ev, val) => setYear(val);
    // const error = !Year || Year > 1900 ? false : 'Not Valid';
    return (
      <TextField2
        className={classes.item}
        label="Year"
        value={Year}
        // error={error}
        onChange={onChange}
        type="number"
        min={0}
        max={2100}
      />
    );
  };

  const renderMake = () => {
    const onChange = (ev, val) => {
      setMake(val);
      setModel(null);
      if (val) {
        const makesModels = (vehicleModelsCodes || [])
          .filter(mm => mm.MakeCode === val.Code)
          .map(m => {
            return {
              Code: m.ModelCode,
              Description: m.ModelDescription,
              IsDefault: m.IsDefault
            };
          });
        setMakesModelsOptions(makesModels);
        const defaultOption = makesModels.filter(item => item.IsDefault);
        if (defaultOption.length) setModel(defaultOption[0]);
      } else {
        setMakesModelsOptions(null);
      }
    };
    return (
      <Dictionary
        options="vehicleMakesCodes"
        className={classes.item}
        onChange={onChange}
        value={Make}
        label="Make"
      />
    );
  };

  const renderModel = () => {
    const onChange = (ev, val) => setModel(val);
    return (
      <Autocomplete2
        options={makesModelsOptions}
        className={classes.item}
        onChange={onChange}
        value={Model}
        label="Model"
      />
    );
  };

  const renderStyle = () => {
    const onChange = (ev, val) => setStyle(val);
    return (
      <Dictionary
        options="vehicleStylesCodes"
        className={classes.item}
        onChange={onChange}
        value={Style}
        label="Style"
      />
    );
  };

  const renderDoors = () => {
    const onChange = (ev, val) => setDoorQuantity(val);
    return (
      <TextField2
        className={classes.item}
        label="Doors"
        value={DoorQuantity}
        onChange={onChange}
        type="number"
        min={0}
        max={20}
      />
    );
  };

  const renderPrimaryColor = () => {
    const onChange = (ev, val) => setPrimaryColor(val);
    return (
      <Dictionary
        options="vehicleColorCodes"
        className={classes.item}
        onChange={onChange}
        value={PrimaryColor}
        label="Primary Color"
      />
    );
  };

  const renderSecondaryColor = () => {
    const onChange = (ev, val) => setSecondaryColor(val);
    return (
      <Dictionary
        options="vehicleColorCodes"
        className={classes.item}
        onChange={onChange}
        value={SecondaryColor}
        label="Secondary Color"
      />
    );
  };

  const renderDescription = () => {
    const onChange = (ev, val) => setDescription(val);
    return (
      <TextField2
        className={classes.item}
        label="Description"
        value={Description}
        onChange={onChange}
        max={8000}
      />
    );
  };

  const renderVin = () => {
    const onChange = (ev, val) => setVIN(val.toUpperCase());
    const error = VIN.length > 0 && !validateVin(VIN);
    return (
      <TextField2
        className={classes.item}
        label="VIN"
        value={VIN}
        onChange={onChange}
        max={17}
        error={error}
      />
    );
  };

  return (
    <Dialog
      toolbar
      onClose={close}
      title="Add Vehicle"
      actions={renderActions()}
      fullScreen>
      <Row>
        <ColCard minWidth={500}>
          <FormDivider title="Details" />
          <RowInner>
            {renderYear()}
            {renderMake()}
            {renderModel()}
            {renderStyle()}
            {renderDoors()}

            {renderPrimaryColor()}
            {renderSecondaryColor()}
            {renderVin()}
            {renderDescription()}

            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
      </Row>
      <Row>
        <ColCard minWidth={500}>
          <FormDivider title="Plate" />
          <RowInner>
            {renderPlateNumber()}
            {renderPlateState()}
            {renderPlateType()}
            {renderPlateExpiration()}

            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
      </Row>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    dictionary: state.codes.dictionary
  };
};

export default connect(mapStateToProps, {
  closeAddVehicle,
  handleError,
  notifyDataUpdate,
  showSpinner,
  hideSpinner,
  notify,
  showEditVehicle
})(AddVehicleDialog);
