import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { toggleNewTripDialog } from 'reducers/UiReducer';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '2px',
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    width: 320,
    minHeight: 150
  },
  footer: {
    fontSize: 13,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    '& p': {
      marginBottom: 0
    }
  },
  action: {
    marginBottom: 10
  },
  body: {
    background: theme.palette.bgElements
  }
}));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

function NewTripDialog(props) {
  const classes = useStyles();

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const noClick = () => {
    props.toggleNewTripDialog();
    props.no();
  };

  const yesClick = () => {
    props.toggleNewTripDialog();
    props.yes();
  };

  return (
    <Dialog onClose={props.close} open={true}>
      <DialogTitle onClose={props.close}>Trip Number</DialogTitle>
      <DialogContent dividers className={classes.body}>
        <div className={classes.content}>
          <div>Do You want to create a new trip?</div>
          <div>
            <Button
              onClick={() => yesClick()}
              className="m-1"
              variant="contained"
              color="primary">
              YES
            </Button>
            <Button
              onClick={() => noClick()}
              className="m-1"
              variant="contained"
              color="primary">
              NO
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    appInfo: state.appInfo
  };
};

export default connect(mapStateToProps, { toggleNewTripDialog })(NewTripDialog);
