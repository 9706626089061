import React, { useState } from 'react';
import nl2br from 'react-newline-to-break';
import ContextMenuComponent from 'global-components/ContextMenu';
import ShortcutsFunctionality from 'global-components/ShortcutsFunctionality';
import SearchResult from 'global-components/NcicSearchComponents/SearchResult';
function RawData(props) {
  const { data, images } = props;
  const [searchText, setSearchText] = useState('');
  const [fontSize, setFontSize] = useState(16);
  return (
    <div>
      {data !== '' && (
        <ShortcutsFunctionality
          value={data}
          parentClassName="ncic-person-result"
          searchText={searchText}
          setSearchText={setSearchText}
          fontSize={fontSize}
          setFontSize={setFontSize}
        />
      )}
      {/* {generateRawData()} */}
      <SearchResult
        data={data}
        images={images}
        searchText={searchText}
        fontSize={fontSize}
        parentClassName="ncic-person-result"
      />

      {/* <ContextMenuTrigger id="same_unique_identifier">
        <div className="well"> {nl2br(data)}</div>
      </ContextMenuTrigger>

      <ContextMenu id="same_unique_identifier">
        <MenuItem data={{ foo: 'bar' }} onClick={this.handleClick}>
          Person
        </MenuItem>
        <MenuItem data={{ foo: 'bar' }} onClick={this.handleClick}>
          Vehicle
        </MenuItem>
      </ContextMenu> */}
    </div>
  );
}

export default RawData;
