/* eslint-disable no-unused-expressions */
/* eslint-disable no-case-declarations */
import React, { useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Tabs,
  Tab,
  Box
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';

import CloseIcon from '@material-ui/icons/Close';

import Info from './Info';
import RawData from './RawData';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  body: {
    background: theme.palette.bgElements,
    minHeight: '50vh',
    padding: '10px 10px'
  },
  itemWrap: {
    fontWeight: 500
  },
  itemLabel: {
    fontWeight: 200
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function PersonDetails(props) {
  const theme = useTheme();
  const classes = useStyles();
  const { data, themeMode, images } = props;

  const [tabs, setTabs] = useState([]);
  const [tab, setTab] = useState(0);
  console.log('parent', data);
  //const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const fullScreen = true;

  const handleClose = () => {
    props.close();
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  React.useEffect(() => {
    const setTabsConfig = () => {
      const _tabs = [];
      let tabKey = 0;
      Object.entries(data).map(([key, val], idx) => {
        switch (key) {
          case 'General':
            let _warnings = [];
            let _parsedLocalData = val !== null ? JSON.parse(val)[0] : null;
            let _parsedNcicData =
              data.NcicResponseData && data.NcicResponseData !== null
                ? JSON.parse(data.NcicResponseData)
                : null;
            let _parsedStateData =
              data.StateResponseData && data.StateResponseData !== null
                ? JSON.parse(data.StateResponseData)
                : null;
            let _parsedLocalMugshots =
              data.Mugshots !== null ? JSON.parse(data.Mugshots) : null;
            if (_parsedNcicData !== null && _parsedNcicData.warnings)
              _warnings = _parsedNcicData.warnings;
            if (
              _parsedLocalData !== null &&
              _parsedLocalData.ActiveWarrant === true
            )
              _warnings.push({ Banner: _parsedLocalData.ActiveWarrant });

            const _data = {
              localData:
                _parsedLocalData !== null
                  ? { ..._parsedLocalData, ptsPersonID: data.ptsPersonID }
                  : null,
              ncicData: _parsedNcicData,
              localMugshots: _parsedLocalMugshots,
              ncicMugshots:
                _parsedNcicData !== null && _parsedNcicData.images
                  ? _parsedNcicData.images
                  : null,
              stateMugshots: _parsedStateData,
              localContactInfo:
                data.ContactInfo !== null
                  ? {
                      data: JSON.parse(data.ContactInfo),
                      label: 'Contact Info'
                    }
                  : null,
              localAddresses:
                data.Addresses !== null
                  ? { data: JSON.parse(data.Addresses), label: 'Addresses' }
                  : null,
              localWarrants:
                data.Warrants !== null
                  ? { data: JSON.parse(data.Warrants), label: 'Warrants' }
                  : null,
              localCitations:
                data.Citations !== null
                  ? { data: JSON.parse(data.Citations), label: 'Citations' }
                  : null,
              localBookings:
                data.Bookings !== null
                  ? { data: JSON.parse(data.Bookings), label: 'Bookings' }
                  : null,
              localCadIncidents:
                data.CadIncidents !== null
                  ? {
                      data: JSON.parse(data.CadIncidents),
                      label: 'Cad Incidents'
                    }
                  : null,
              localRmsIncidents:
                data.RmsIncidents !== null
                  ? {
                      data: JSON.parse(data.RmsIncidents),
                      label: 'Rms Incidents'
                    }
                  : null,
              localAlerts:
                data.Alerts !== null
                  ? { data: JSON.parse(data.Alerts), label: 'Alerts' }
                  : null,
              warnings: _warnings
            };
            _tabs.push({
              label: 'Info',
              data: _data,
              key: tabKey,
              component: 'Info'
            });
            tabKey++;
            break;
          case 'NcicResponseData':
            if (val && val.length > 0) {
              _tabs.push({
                label: 'NCIC/State',
                data: data.RawData,
                key: tabKey,
                component: 'RawData'
              });
              tabKey++;
            }
            break;
          default:
        }
      });
      setTabs(_tabs);
    };
    setTabsConfig();
  }, [data]);

  const generateTab = (data, idx) => {
    return <Tab key={idx} label={data.label} />;
  };

  const generateTabs = () => {
    return (
      <Tabs
        value={tab}
        variant="scrollable"
        scrollButtons="on"
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="Tabs">
        {tabs.map((row, idx) => {
          return generateTab(row, idx);
        })}
      </Tabs>
    );
  };

  const generateTabContent = (data, idx) => {
    if (data.component === 'Info') {
      return (
        <TabPanel key={idx} value={tab} index={data.key}>
          <Info
            data={data.data}
            images={images}
            themeMode={themeMode}
            origin={props.origin || ''}
          />
        </TabPanel>
      );
    } else if (data.component === 'RawData') {
      return (
        <TabPanel key={idx} value={tab} index={data.key}>
          <RawData
            data={data.data}
            images={images}
            themeMode={themeMode}
            handleClose={handleClose}
          />
        </TabPanel>
      );
    }
  };
  if (props.origin == 'VehicleRegistration') {
    return (
      <div>
        <h4 style={{ padding: '10px 0px 0px 0px' }} id="scroll-dialog-title">
          {generateTabs()}
        </h4>
        <div className={classes.body} id="person-dialog">
          {tabs.map((row, idx) => {
            return generateTabContent(row, idx);
          })}
        </div>
      </div>
    );
  }
  return (
    <Dialog
      TransitionComponent={Transition}
      open={true}
      onClose={handleClose}
      scroll="paper"
      fullWidth={true}
      fullScreen={fullScreen}
      maxWidth={'md'}>
      <DialogTitle
        style={{ padding: '10px 0px 0px 0px' }}
        id="scroll-dialog-title">
        {generateTabs()}
      </DialogTitle>
      <DialogContent className={classes.body} id="person-dialog">
        {tabs.map((row, idx) => {
          return generateTabContent(row, idx);
        })}
      </DialogContent>
      {props.origin !== 'VehicleRegistration' && (
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<CloseIcon />}
            onClick={handleClose}
            color="primary">
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default PersonDetails;
