import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeTowingDialog } from 'reducers/DialogsReducer';
import Dialog from '../Dialog';
import formStyles, { gridStyle, Row } from 'utils/formStyles';
import Checkbox2 from '../Checkbox2';
import PersonLookup2 from '../PersonLookup2';
import {
  addVehicleTowing,
  updateVehicleTowing,
  getVehicleTowing
} from 'reducers/VehicleReducer';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import PlaceLookup from '../PlaceLookup';
import { notify } from 'reducers/NotifierReducer';
import EventFilter from '../EventFilter';

const useStyles = makeStyles(theme => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3)
  },
  addressCategory: gridStyle(220, 220),
  item: gridStyle(200, 400),
  btn: {
    marginTop: theme.spacing(3),
    display: 'block',
    marginRight: 0,
    marginLeft: 'auto',
    '& svg': {
      marginRight: theme.spacing(1)
    }
  }
}));

function TowingDialog(props) {
  const classes = useStyles();
  const [IsTowed, setIsTowed] = useState(true);
  const [place, setPlace] = useState(null);
  const [person, setPerson] = useState(null);
  const [ptsPersonID, setPtsPersonID] = useState(null);
  const [ptsPlaceID, setPtsPlaceID] = useState(null);
  const [ptsEventID, setPtsEventID] = useState(props.data.ptsEventID || null);
  const { unitResources, units } = props;
  const { ptsVehicleID, ptsTowingID, ptsUnitID, ptsUnitIDS } = props.data;
  const [unitPersons, setUnitPersons] = useState();
  const mountedRef = useRef(true);
  const valid = Boolean(place);

  useEffect(() => {
    getTowingDetails();
    if (ptsUnitID || ptsUnitIDS) updateUnitsResources();
    return () => (mountedRef.current = false);
    // eslint-disable-next-line
  }, []);

  const getTowingDetails = async () => {
    if (!ptsTowingID) return;
    props.showSpinner();
    try {
      const data = await getVehicleTowing(ptsTowingID);
      setPtsPersonID(data.ptsDriverID);
      setPtsPlaceID(data.ptsPlaceID);
      setIsTowed(data.IsTowed);
      setPtsEventID(data.ptsEventID);
    } catch (err) {
      props.handleError(err);
    }
    props.hideSpinner();
  };

  const updateUnitsResources = () => {
    let resources = [];
    if (ptsUnitID) resources = unitResources[ptsUnitID];
    if (ptsUnitIDS) {
      ptsUnitIDS.forEach(id => {
        if (unitResources?.length) {
          resources = [...resources, ...unitResources[id]];
        }
      });
    }
    if (!resources || !resources.length) return;
    const persons = resources
      .filter(r => r.ParentType === 'Unit' && r.ChildType === 'Person')
      .map(r => {
        const names = r.ComponentName.split(' ').map(s => s.trim());
        const FirstName = names[0] ? names[0] : '';
        const LastName = names[1] ? names[1] : '';
        return {
          ptsPersonID: r.ptsChildID,
          FullName: r.ComponentName,
          FirstName,
          LastName,
          ActiveWarrant: null
        };
      });
    setUnitPersons(persons);
  };

  const close = () => {
    props.closeTowingDialog();
  };

  const save = async () => {
    if (ptsTowingID) {
      // update data
      const data = {
        ptsTowingID,
        IsTowed,
        ptsEventID
      };
      if (place) data.ptsPlaceID = place.ptsPlaceID;
      if (person) data.ptsDriverID = person.ptsPersonID;
      try {
        await updateVehicleTowing(data);
        props.notify('Towing updated', 'success');
        props.closeTowingDialog();
      } catch (err) {
        props.handleError(err);
      }
    } else {
      try {
        const data = {
          ptsVehicleID,
          IsTowed,
          ptsPlaceID: place.ptsPlaceID || ptsPlaceID,
          ptsDriverID: person?.ptsPersonID,
          ptsEventID: ptsEventID || null
        };
        await addVehicleTowing(data);
        props.notify('Towing created', 'success');
        props.closeTowingDialog();
      } catch (err) {
        props.handleError(err, 'Error adding towing to vehicle');
      }
    }
  };

  const onTowingChange = (ev, val) => {
    setIsTowed(val);
  };

  const onPlaceChange = place => {
    setPlace(place);
  };

  const onPersonChange = (person = null) => {
    if (!person) {
      setPerson(null);
      setPlace(null);
      setPtsPlaceID(null);
      return;
    }
    setPerson(person);
    let ptsPlaceID = null;
    ptsPlaceID = getPersonPlaceID(person.ptsPersonID);
    if (!ptsPlaceID) {
      const ptsUnitID = getPersonUnit(person.ptsPersonID);
      if (!ptsUnitID) return;
      ptsPlaceID = getUnitPlaceID(ptsUnitID) || null;
    }
    setPtsPlaceID(ptsPlaceID);
  };

  const getPersonUnit = ptsPersonID => {
    const unit = Object.entries(unitResources).find(
      ([ptsUnitID, Resources]) => {
        return Resources.find(
          r =>
            r.ParentType === 'Unit' &&
            r.ChildType === 'Person' &&
            r.ptsChildID === ptsPersonID
        );
      }
    );
    if (!unit) return null;
    return parseInt(unit[0]);
  };

  const getUnitPlaceID = ptsUnitID => {
    const unit = units.find(u => u.ptsUnitID === ptsUnitID);
    if (!unit) return null;
    return unit.ptsPlaceID || null;
  };

  const getPersonPlaceID = ptsPersonID => {
    const unit = Object.entries(unitResources || {}).find(
      ([ptsUnitID, Resources]) => {
        return Resources.find(
          r =>
            r.ParentType === 'Unit' &&
            r.ChildType === 'Person' &&
            r.ptsChildID === ptsPersonID
        );
      }
    );
    if (!unit || !unit[1]) return null;
    const person = unit[1].find(
      p => p.ptsChildID === ptsPersonID && p.ChildType === 'Person'
    );
    return person.ptsPlaceID || null;
  };

  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={save}
          disabled={!valid}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderEvent = () => {
    const onChange = (ev, val) => setPtsEventID(parseInt(val));
    return (
      <EventFilter
        className={classes.item}
        ptsEventID={ptsEventID}
        onChange={onChange}
      />
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Towing" actions={renderActions()}>
      <div style={{ width: 400 }}>
        <Row>
          <Checkbox2
            checked={IsTowed}
            onChange={onTowingChange}
            label="Towed"
          />
        </Row>
        <Row>
          <PersonLookup2
            className={classes.item}
            onPersonChange={onPersonChange}
            label="Search Driver"
            ptsPersonID={ptsPersonID}
            defaultOptions={unitPersons}
            compact="true"
          />
        </Row>
        <Row>
          <PlaceLookup
            onChange={onPlaceChange}
            className={classes.item}
            ptsPlaceID={ptsPlaceID}
            label="Tower Reference"
          />
        </Row>
        {!ptsTowingID && <Row>{renderEvent()}</Row>}
      </div>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    unitResources: state.unitResources,
    units: state.units.units,
    events: state.events.events
  };
};

export default connect(mapStateToProps, {
  closeTowingDialog,
  handleError,
  showSpinner,
  hideSpinner,
  notify
})(TowingDialog);
