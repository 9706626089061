import React from 'react';
import { connect } from 'react-redux';

import AddVehicleDialog from './AddVehicleDialog';
import EditVehicleDialog from './EditVehicleDialog';
import VehicleAddEditRegDialog from './EditVehicleDialog/VehicleAddEditRegDialog';
import TowingDialog from './TowingDialog';
import AddAttachment from './AddAttachment';
import EditAttachment from './EditAttachment';
import EditCredentialDialog from './EditCredentialDialog';
import AddPersonDialog from './AddPersonDialog';
import EditPersonDialog from './EditPersonDialog';
import EditNameDialog from './EditNameDialog';

import ContactMethodDialog from './ContactMethodDialog';
import PeopleCredentialsDialog from './PeopleCredentialsDialog';
import PeopleEmploymentDialog from './PeopleEmploymentDialog';
import AddDnaSampleDialog from './EditPersonDialog/AddDnaSampleDialog';
import AddFeatureDialog from './EditPersonDialog/AddFeatureDialog';
import AddPersonPhotoAttachment from './AddPersonPhotoAttachment';
import EditPersonPhotoDialog from './EditPersonDialog/EditPersonPhotoDialog';
import PersonNameDialog from './EditPersonDialog/PersonNameDialog';
import AddBulletin from './AddBulletin';
import EditBulletin from './EditBulletin';
import DisplayData from './DisplayData';

function Dialogs(props) {
  const { isAuthenticated, dialogs } = props;
  const {
    addVehicle,
    editVehicle,
    vehicleEditReg,
    towing,
    addAttachment,
    editAttachment,
    editCredential,
    editPlace,
    editName,
    editContactMethod,
    editPeopleCredentials,
    editPeopleEmployment,
    addBulletin,
    editBulletin,
    bulletinDetails,
    addPerson,
    editPerson,
    warrants,
    personDnaSample,
    personFeature,
    personPhotoAttachment,
    editPersonPhoto,
    personName,
    displayData,

    editPlaceAddress
  } = dialogs;

  return (
    <>
      {isAuthenticated && (
        <>
          {addVehicle !== null && <AddVehicleDialog data={addVehicle} />}
          {editVehicle !== null && (
            <EditVehicleDialog vehicleData={editVehicle} />
          )}
          {vehicleEditReg !== null && (
            <VehicleAddEditRegDialog vehicleData={vehicleEditReg} />
          )}
          {towing !== null && <TowingDialog data={towing} />}
          {addAttachment !== null && <AddAttachment data={addAttachment} />}
          {editAttachment !== null && <EditAttachment data={editAttachment} />}
          {editCredential !== null && (
            <EditCredentialDialog data={editCredential} />
          )}

          {addPerson !== null && <AddPersonDialog data={addPerson} />}
          {editPerson !== null && <EditPersonDialog person={editPerson} />}
          {editName !== null && <EditNameDialog ptsNameID={editName} />}

          {editContactMethod !== null && (
            <ContactMethodDialog data={editContactMethod} />
          )}
          {editPeopleCredentials !== null && (
            <PeopleCredentialsDialog data={editPeopleCredentials} />
          )}
          {editPeopleEmployment !== null && (
            <PeopleEmploymentDialog ptsPersonID={editPeopleEmployment} />
          )}

          {personDnaSample != null && (
            <AddDnaSampleDialog ptsPersonID={personDnaSample} />
          )}
          {personFeature != null && (
            <AddFeatureDialog ptsPersonID={personFeature} />
          )}
          {personPhotoAttachment !== null && (
            <AddPersonPhotoAttachment ptsPersonID={personPhotoAttachment} />
          )}
          {editPersonPhoto !== null && (
            <EditPersonPhotoDialog data={editPersonPhoto} />
          )}
          {personName !== null && <PersonNameDialog data={personName} />}
          {addBulletin !== null && <AddBulletin data={addBulletin} />}
          {editBulletin !== null && <EditBulletin ptsAlertID={editBulletin} />}
          {displayData !== null && (
            <DisplayData
              data={displayData.data}
              title={displayData.title}
              ptsAlertID={displayData.ptsAlertID}
            />
          )}
          {/* {bulletinDetails !== null && (
            <BulletinDetails data={bulletinDetails} />
          )} */}
        </>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.user.isAuthenticated,
    dialogs: state.dialogs
  };
};

export default connect(mapStateToProps)(Dialogs);
