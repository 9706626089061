import { enqueueSnackbar } from '../reducers/NotifierReducer';
import store from '../config/configureStore';
import tableConfig from '../config/table-config-persons.json';
import { showSpinner, hideSpinner } from './UiReducer';
import {
  setCitationTabsFormData,
  SET_CITATION_FORM_DATA
} from './CitationsReducer';
import {
  SET_FORM_VEHICLE_CHECKBOX,
  SET_NCIC_FORM_VEHICLE_CHECKBOX
} from './VehicleSearchReducer';
import {
  SET_FORM_GUN_CHECKBOX,
  SET_NCIC_FORM_GUN_CHECKBOX
} from './GunSearchReducer';

// Person Search
export const CITATION_FLAG = 'CITATION_FLAG';
export const CITATION_DATA = 'CITATION_DATA';
export const CLEAR_CITATION_DETAILS = 'CLEAR_CITATION_DETAILS';
export const FIND_PERSONS = 'PERSONSEARCH/FIND';
export const LOADING_PERSONS = 'PERSONSEARCH/LOADING';
export const LOADING_PERSONS_ERROR = 'PERSONSEARCH/LOADING_ERROR';

export const FIND_PERSON = 'PERSONSEARCH/FIND_PERSON';
export const OPEN_DETAILS = 'PERSONSEARCH/OPEN_DETAILS';
export const CLOSE_DETAILS = 'PERSONSEARCH/CLOSE_DETAILS';

export const CLEAR_RESULTS = 'PERSONSEARCH/CLEAR_RESULTS';

export const SET_FORM_PERSON_PICKERS = 'NCIC/SET_FORM_PERSON_PICKERS';
export const SET_FORM_PERSON_FILTERS = 'NCIC/SET_FORM_PERSON_FILTERS';
export const SET_FORM_PERSON_FILTERS_VALUE =
  'NCIC/SET_FORM_PERSON_FILTERS_VALUE';
export const SET_FORM_PERSON_CHECKBOX = 'PERSONSEARCH/SET_FORM_PERSON_CHECKBOX';
export const SET_NCIC_FORM_PERSON_CHECKBOX =
  'NCIC/SET_NCIC_FORM_PERSON_CHECKBOX';
export const SET_NCIC_RESPONSE_PERSONS_QUERY_ID =
  'NCIC/SET_RESPONSE_PERSONS_QUERY_ID';

export const SET_NCIC_FINISHED_RESPONSES = 'NCIC/SET_NCIC_FINISHED_RESPONSES';
export const SET_NCIC_PENDING_RESPONSES = 'NCIC/SET_NCIC_PENDING_RESPONSES';

export const findPersons = params => {
  return async dispatch => {
    const state = store.store.getState();
    const client = state.websocket.websocket;
    try {
      const service = client.service('search-person');
      service.timeout = 20000;
      dispatch({ type: SET_NCIC_RESPONSE_PERSONS_QUERY_ID, payload: null });
      dispatch({ type: LOADING_PERSONS, payload: true });
      const persons = await service.find({ query: params });
      dispatch({ type: FIND_PERSONS, payload: { params, persons } });
      dispatch({ type: LOADING_PERSONS, payload: false });
    } catch (error) {
      dispatch({ type: LOADING_PERSONS_ERROR });
      dispatch(
        enqueueSnackbar({
          message: 'No data found.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          }
        })
      );
    }
  };
};

export const sortPersons = orderBy => {
  return async dispatch => {
    const changeDirection = direction => (direction === 'ASC' ? 'DESC' : 'ASC');
    const persons = store.store.getState().personSearch.persons;
    const { filters, pageNo, rowsPerPage } = persons;
    const orderDirection =
      persons.orderBy === orderBy
        ? changeDirection(persons.orderDirection)
        : 'ASC';
    const params = { filters, pageNo, orderBy, rowsPerPage, orderDirection };
    dispatch(findPersons(params));
  };
};

export const findPerson = ptsPersonID => {
  const state = store.store.getState();
  const client = state.websocket.websocket;
  return async dispatch => {
    if (ptsPersonID === null) {
      dispatch({
        type: CLOSE_DETAILS,
        payload: null
      });
    } else {
      dispatch(showSpinner());
      try {
        const service = client.service('search-person');
        service.timeout = 20000;
        const row = await service.get(ptsPersonID);

        dispatch({ type: FIND_PERSON, payload: row });
        dispatch({ type: CITATION_DATA, payload: row });
        dispatch({
          type: OPEN_DETAILS,
          payload: null
        });
      } catch (error) {
        dispatch(
          enqueueSnackbar({
            message: 'No data found.',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'warning',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            }
          })
        );
      }
      dispatch(hideSpinner());
    }
  };
};

export const setCitaionFlag = flag => {
  return dispatch => {
    dispatch({ type: CITATION_FLAG, payload: flag });
  };
};
/* New ncic implementation */

export const handleDateChange = (date, dbName, param) => {
  const pickerValues = store.store.getState().personSearch.personForm.pickers;
  return async dispatch => {
    const value = pickerValues[dbName]
      ? pickerValues[dbName]
      : { from: null, to: null };
    value[param] = date;
    if (param === 'from' && !value.to) value.to = date;
    if (param === 'to' && !value.from) value.from = date;
    if (param === 'single') {
      value.from = date;
      value.to = date;
    }
    const currentPickerValue = { ...pickerValues };
    if (value.from !== null && value.to !== null) {
      currentPickerValue[dbName] = value;
    } else {
      delete currentPickerValue[dbName];
    }
    dispatch({
      type: SET_FORM_PERSON_PICKERS,
      payload: currentPickerValue
    });
  };
};
export const handlePersonFilltersAutoFill = values => {
  return dispatch => {
    if (values['p.Born']) {
      dispatch({
        type: SET_FORM_PERSON_PICKERS,
        payload: { 'p.Born': values['p.Born'] } || {}
      });
    } else {
      dispatch({
        type: SET_FORM_PERSON_PICKERS,
        payload: {}
      });
    }
    delete values['p.Born'];
    dispatch({
      type: SET_FORM_PERSON_FILTERS,
      payload: values
    });
  };
};
export const handleFilterChange = (ev, filter) => {
  const values = store.store.getState().personSearch.personForm.filters;
  return async dispatch => {
    values[filter.dbName] = {
      type: filter.type,
      search: ev.target ? ev.target.value.toUpperCase() : ev.toUpperCase()
    };
    dispatch({
      type: SET_FORM_PERSON_FILTERS,
      payload: cleanFilters(values)
    });
  };
};
export const handlePersonQuickSearch = (oln, state) => {
  return async dispatch => {
    const params = {};
    if (oln) {
      params['OLN.Number'] = {
        type: 'string',
        search: oln.toUpperCase()
      };
    }
    if (state) {
      params['OLN.State'] = {
        type: 'select',
        search: state.toUpperCase()
      };
    }
    const options = {
      pageNo: 0,
      rowsPerPage: tableConfig.rowsPerPage,
      orderBy: tableConfig.orderBy,
      orderDirection: tableConfig.orderDirection,
      filters: params
    };
    dispatch({
      type: SET_FORM_PERSON_FILTERS_VALUE,
      payload: params
    });
    dispatch(findPersons(options));
  };
};
export const handleCheckboxChange = ev => {
  return async dispatch => {
    dispatch({
      type: SET_FORM_PERSON_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_NCIC_FORM_PERSON_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_FORM_VEHICLE_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_NCIC_FORM_VEHICLE_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_FORM_GUN_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_NCIC_FORM_GUN_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
  };
};

export const clearPersonForm = () => {
  const { APP_DEFAULT_STATE } = store.store.getState().appInfo.appEnvInfo;
  return async dispatch => {
    dispatch({
      type: SET_FORM_PERSON_FILTERS,
      payload: {
        // ['OLN.State']: {
        //   search: APP_DEFAULT_STATE,
        //   type: 'select'
        // }
      }
    });
    dispatch({
      type: SET_FORM_PERSON_PICKERS,
      payload: {}
    });
  };
};

export const clearResults = () => {
  return async dispatch => {
    dispatch({
      type: CLEAR_RESULTS,
      payload: null
    });
    dispatch({ type: SET_NCIC_RESPONSE_PERSONS_QUERY_ID, payload: null });
    dispatch({
      type: SET_NCIC_FINISHED_RESPONSES,
      payload: 0
    });
    dispatch({
      type: SET_NCIC_PENDING_RESPONSES,
      payload: 0
    });
  };
};

const cleanFilters = obj => {
  const result = {};
  Object.entries(obj).forEach(([key, val]) => {
    if (val.search !== '') result[key] = val;
  });
  return result;
};
export const clearCitationDetails = () => {
  return dispatch => {
    dispatch({ type: CLEAR_CITATION_DETAILS });
  };
};
export const setDefaultEnvFORPerson = envInfo => {
  const { APP_DEFAULT_STATE } = envInfo;
  return dispatch => {
    const vehicleFilter = {
      // ['OLN.State']: {
      //   search: APP_DEFAULT_STATE,
      //   type: 'select'
      // }
    };

    dispatch({ type: SET_FORM_PERSON_FILTERS_VALUE, payload: vehicleFilter });
  };
};
export default function reducer(
  state = {
    // Person
    persons: {
      rows: [],
      rawData: '',
      images: '[]',
      count: 5000,
      pageNo: 0,
      search: '',
      rowsPerPage: tableConfig.rowsPerPage,
      orderBy: tableConfig.orderBy,
      orderDirection: tableConfig.orderDirection,
      loading: false,
      loaded: false,
      error: false
    },
    personForm: {
      filters: {},
      pickers: {},
      ncic: false
    },
    personDetails: {
      data: null,
      ptsPersonID: null,
      isShowing: false
    },
    citationDetails: {
      data: null,
      ptsPersonID: null,
      isShowing: false,
      flag: false
    }
  },
  action
) {
  switch (action.type) {
    // Person
    case LOADING_PERSONS:
      return {
        ...state,
        persons: {
          ...state.persons,
          loading: action.payload,
          loaded: action.payload ? false : state.persons.loaded,
          error: false
        }
      };

    case LOADING_PERSONS_ERROR:
      return {
        ...state,
        persons: {
          ...state.persons,
          loading: false,
          loaded: true,
          error: true
        }
      };
    case FIND_PERSONS:
      return {
        ...state,
        persons: {
          rows: action.payload.persons.data,
          rawData: action.payload.rawData ? action.payload.rawData : '',
          images: action.payload.images ? action.payload.images : '[]',
          ...action.payload.params,
          count: action.payload.persons.count,
          loaded: true,
          error: false
        }
      };
    case FIND_PERSON:
      return {
        ...state,
        personDetails: { ...state.personDetails, data: action.payload }
      };
    case CITATION_FLAG:
      return {
        ...state,
        citationDetails: { ...state.citationDetails, flag: action.payload }
      };
    case CITATION_DATA:
      return {
        ...state,
        citationDetails: { ...state.citationDetails, data: action.payload }
      };
    case CLEAR_CITATION_DETAILS:
      return {
        ...state,
        citationDetails: {
          ...state.citationDetails,
          flag: false
        }
      };
    case OPEN_DETAILS:
      return {
        ...state,
        personDetails: { ...state.personDetails, isShowing: true }
      };
    case CLOSE_DETAILS:
      return {
        ...state,
        personDetails: { ...state.personDetails, isShowing: false }
      };
    case CLEAR_RESULTS:
      return {
        ...state,
        persons: {
          rows: [],
          rawData: '',
          images: '[]',
          count: 5000,
          pageNo: 0,
          search: '',
          rowsPerPage: tableConfig.rowsPerPage,
          orderBy: tableConfig.orderBy,
          orderDirection: tableConfig.orderDirection,
          loading: false,
          loaded: false,
          error: false
        }
      };
    case SET_FORM_PERSON_PICKERS:
      return {
        ...state,
        personForm: {
          ...state.personForm,
          pickers: action.payload
        }
      };
    case SET_FORM_PERSON_FILTERS:
      return {
        ...state,
        personForm: {
          ...state.personForm,
          filters: action.payload
        }
      };
    case SET_FORM_PERSON_FILTERS_VALUE:
      return {
        ...state,
        personForm: {
          ...state.personForm,
          filters: action.payload
        }
      };
    case SET_FORM_PERSON_CHECKBOX:
      return {
        ...state,
        personForm: {
          ...state.personForm,
          ncic: action.payload
        }
      };
    default:
      break;
  }
  return state;
}
