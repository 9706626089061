export default function saveSearchSuggestions(
  key,

  filterValues,
  isNcic = false
) {
  const newFilterValues = cleanFilters(
    isNcic ? filterValues.query : filterValues
  );

  if (Object.keys(newFilterValues).length < 1) {
    return;
  }
  let data = JSON.parse(localStorage.getItem(key) || '[]');
  data = [...data];

  if (isNcic) {
    data.unshift({ ...filterValues.query });
  } else {
    data = [...data];
    data.unshift({ ...filterValues });
  }
  data = data.slice(0, 10);
  // console.log('data', data);
  localStorage.setItem(key, JSON.stringify(data));
}
const cleanFilters = obj => {
  const result = {};
  Object.entries(obj).forEach(([key, val]) => {
    if (val && val.search !== '' && val !== '') result[key] = val;
  });
  return result;
};
