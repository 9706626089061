import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closePersonFeature } from 'reducers/DialogsReducer';
import Dialog from '../../Dialog';
import Autocomplete2 from '../../Autocomplete2';
import { gridStyle, formatSaveData } from 'utils/formStyles';
import Dictionary from '../../Dictionary';
import { addPersonFeature } from 'reducers/SearchReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

const useStyles = makeStyles(theme => ({
  item: gridStyle(200, 800)
}));

const attributeCategories = [
  'Appearance',
  'Builds',
  'Complexions',
  'EyeDefect',
  'FacialHair',
  'Glasses',
  'MethodOfOperation',
  'SpeechQuality',
  'Talk',
  'Teeth'
];

function ExampleWindow(props) {
  const classes = useStyles();
  const { ptsPersonID } = props;
  const [AttributeCategory, setAttributeCategory] = useState(null);
  const [Attribute, setAttribute] = useState(null);
  const dictionary = useSelector(state => state.codes.dictionary);
  const close = () => {
    props.closePersonFeature();
  };

  const save = async () => {
    const data = formatSaveData({
      Attribute,
      AttributeCategory,
      ptsPersonID
    });
    try {
      await addPersonFeature(data);
      props.notifyDataUpdate({ type: 'person-feature', data: {} });
      props.closePersonFeature();
    } catch (err) {
      props.handleError(err, 'Error saving feature');
    }
  };

  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={save}
          disabled={!Attribute}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderAttributeCategory = () => {
    const handleChange = (ev, val) => {
      setAttributeCategory(val);
      // setAttribute(val);
    };
    return (
      <Autocomplete2
        options={attributeCategories}
        className={classes.item}
        onChange={handleChange}
        value={AttributeCategory}
        label="Attribute Category"
        getOptionSelected={(option, value) => option === value}
        getOptionLabel={option => option}
        renderOption={option => <span>{option}</span>}
      />
    );
  };

  const renderAttributeCode = () => {
    const handleChange = (ev, val) => {
      setAttribute(val);
    };

    return (
      <Autocomplete2
        options={dictionary[AttributeCategory]}
        className={classes.item}
        onChange={handleChange}
        value={Attribute || ''}
        label="Attribute"
        getOptionSelected={() => true}
        getOptionLabel={option => option.Code || ''}
        renderOption={option => (
          <span>
            <b>{option.Code}</b>-{option.Description}
          </span>
        )}
      />
    );
  };

  return (
    <Dialog
      toolbar
      onClose={close}
      title="Add Feature"
      actions={renderActions()}>
      {renderAttributeCategory()}
      {renderAttributeCode()}
    </Dialog>
  );
}

export default connect(null, {
  closePersonFeature,
  handleError,
  notifyDataUpdate
})(ExampleWindow);
