import React, { Fragment, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import nl2br from 'react-newline-to-break';
import { Paper, Tabs, Tab, Typography, Box, Button } from '@material-ui/core';
import tableConfigPersons from '../../../../config/table-config-persons.json';

import Table from './table';

import { useHistory } from 'react-router';
import ContextMenuComponent from 'global-components/ContextMenu';
import { getAccessPermission } from 'reducers/PermissionsReducer';
import ShortcutsFunctionality from 'global-components/ShortcutsFunctionality';
import SearchResult from 'global-components/NcicSearchComponents/SearchResult';
import { setNcicRequestDone } from 'reducers/NcicReducer';
// import '../../Persons/SearchResults/contextStyle.css';
const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
};

const SearchResults = props => {
  const {
    getVehiclesData,
    getResultsData,
    sortVehiclesData,
    tableRowClick,
    localtableconfig,
    vehicles,
    ncicCheckbox,
    ownerDetails,
    owners
  } = props;
  const { loading, loaded, rows, error, rawData } = vehicles;
  const [searchText, setSearchText] = useState('');
  const [fontSize, setFontSize] = useState(16);
  const [activeTab, setActiveTab] = useState(0);
  const persmissions = useSelector(state => state.permissions.globals);
  const [canExecNcic, setCanExecNcic] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const searchView = useSelector(state => state.settings.searchView);
  React.useEffect(() => {
    if (ncicCheckbox) {
      if (searchView == 'regular') {
        setActiveTab(0);
      } else {
        setActiveTab(1);
      }
      dispatch(setNcicRequestDone(false));
    }
  }, [searchView, ncicCheckbox]);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  React.useEffect(() => {
    setCanExecNcic(getAccessPermission('globals', 'Execute NCIC Queries'));
  }, [persmissions]);
  const generateResults = () => {
    if (rows.length > 0) {
      return (
        <Fragment>
          <Table
            getData={getVehiclesData}
            getResultsData={getResultsData}
            data={vehicles}
            owners={owners}
            ownerConfig={tableConfigPersons}
            config={localtableconfig}
            sort={sortVehiclesData}
            rowClick={tableRowClick}
          />
        </Fragment>
      );
    } else {
      if (loading && rows.length === 0)
        return (
          <div className="p-3">
            <LoadingData />
          </div>
        );
      if (!loaded && !loading && rows.length === 0)
        return (
          <div className="p-3">
            <StartSearching />
          </div>
        );
      if (loaded && !loading && rows.length === 0 && !error)
        return (
          <div className="p-3">
            <NoResults setActiveTab={setActiveTab} canExecNcic={canExecNcic} />
          </div>
        );
      if (loaded && !loading && rows.length === 0 && error)
        return (
          <div className="p-3">
            <ErrorLoading />
          </div>
        );
      return null;
    }
  };

  const generateRawData = () => {
    if (rawData !== '') {
      return (
        <ContextMenuComponent
          value={nl2br(rawData)}
          setActiveTab={setActiveTab}
        />
      );
    }
    return <div className="p-3">There is no data to display.</div>;
  };

  return (
    <Fragment>
      <Paper square className="m-3">
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="secondary"
          centered={true}
          variant="fullWidth"
          aria-label="search tabs">
          <Tab label="All Results" />
          {canExecNcic && <Tab label="NCIC/State" />}
        </Tabs>
        <TabPanel value={activeTab} index={0}>
          {generateResults()}
        </TabPanel>
        {canExecNcic && (
          <TabPanel value={activeTab} index={1}>
            {rawData !== '' && (
              <ShortcutsFunctionality
                value={rawData}
                parentClassName="ncic-vehicle-result"
                searchText={searchText}
                setSearchText={setSearchText}
                fontSize={fontSize}
                setFontSize={setFontSize}
              />
            )}
            {/* {generateRawData()} */}
            <SearchResult
              data={rawData}
              searchText={searchText}
              fontSize={fontSize}
              setActiveTab={setActiveTab}
              parentClassName="ncic-vehicle-result"
            />
          </TabPanel>
        )}
      </Paper>
    </Fragment>
  );
};

function NoResults(props) {
  return (
    <div>
      <span>
        Sorry, no results match your search criteria. See Raw Data tab for more
        NCIC/State information.
      </span>
      {props.canExecNcic && (
        <Button
          style={{ marginLeft: '10px' }}
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => props.setActiveTab(1)}>
          Raw Data
        </Button>
      )}
    </div>
  );
}

function LoadingData() {
  return 'Loading the data, please wait...';
}

function StartSearching() {
  return 'Please define your search criteria.';
}

function ErrorLoading() {
  return 'There was an error while receiving the data.';
}

const mapStateToProps = state => ({
  themeMode: state.theme.mode,
  ncicCheckbox: state.ncic.vehicleForm.ncic,
  owners: state.vehicleSearch.owners,
  ownerDetails: state.vehicleSearch.ownerDetails
});

export default connect(mapStateToProps, {})(SearchResults);
