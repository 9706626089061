import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  formSection: {
    paddingBottom: '10px'
  }
}));

const StatusFilter = props => {
  const classes = useStyles();
  const [selectedStatus, setSelectedStatus] = useState('');

  const handleFilterStatusChange = (event, value) => {
    let selectedStatusInfo = props.statusList.filter(
      status => `${status.Code} - ${status.Description}` === value
    );
    if (selectedStatusInfo[0]) {
      setSelectedStatus(
        `${selectedStatusInfo[0].Code} - ${selectedStatusInfo[0].Description}`
      );
      props.setSelectedStatus(selectedStatusInfo[0].Code);
    }
  };

  const statusArray = props.statusList.map(
    status => `${status.Code} - ${status.Description}`
  );

  return (
    <FormControl fullWidth variant="outlined" className={classes.formSection}>
      <Autocomplete
        label="status-autocomplete"
        value={selectedStatus}
        onChange={handleFilterStatusChange}
        options={statusArray}
        autoFocus={true}
        getOptionLabel={option => option || ''}
        getOptionSelected={() => true}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            required
            label="Status"
            autoFocus={true}
            variant="outlined"
          />
        )}
      />
    </FormControl>
  );
};

export default StatusFilter;
