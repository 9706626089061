import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import Spinner from '../../../../global-components/Spinner';

import clsx from 'clsx';

import { Badge, Avatar } from '@material-ui/core';

import {
  findResultVehicle,
  findResultPerson
} from '../../../../reducers/NcicReducer';
import PersonTable from '../../Persons/SearchResults/table';
const stylesDay = {
  spinner: {
    background: 'rgba(255, 255, 255, 0.5)'
  }
};

const stylesNight = {
  spinner: {
    background: 'rgba(0, 0, 0, 0.5)'
  }
};

const styles = themeMode => {
  return themeMode === 'day' ? stylesDay : stylesNight;
};

const useStyles = makeStyles(theme => ({
  tableWrap: {
    overflow: 'auto',
    margin: '0 auto',
    position: 'relative'
  },
  header: {
    cursor: 'pointer',
    fontSize: '2em'
  },
  headerItem: {
    fontSize: '1em !important'
  },
  tableCell: {},
  spinner: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      color: '#999'
    }
  },
  warrantRow: {
    backgroundColor: theme.palette.error.main
  },
  nestedTable: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  }
}));

const Badges = props => {
  function IsWantedBadge(props) {
    if (props.isWanted && props.isWanted === true) {
      return (
        <Badge
          overlap="rectangular"
          style={{ left: '45px' }}
          className="customBagde"
          badgeContent="WANTED"
          color="error"></Badge>
      );
    } else {
      return null;
    }
  }

  function NcicBadge(props) {
    if (props.warnings && props.warnings !== null) {
      return props.warnings.map((item, id) => {
        if (item.Banner) {
          return (
            <div key={id} style={{ marginBottom: '10px' }}>
              <Badge
                overlap="rectangular"
                style={{ left: '45px' }}
                className="customBagde"
                badgeContent={item.Banner.toUpperCase()}
                color="error"></Badge>
            </div>
          );
        } else {
          return null;
        }
      });
    } else {
      return null;
    }
  }

  return (
    <Fragment>
      <IsWantedBadge isWanted={props.IsWanted} />
      <NcicBadge warnings={props.warnings} />
    </Fragment>
  );
};

const Table = props => {
  const classes = useStyles();
  const { data, config, sort, themeMode, owners, ownerConfig } = props;
  const { orderBy, rowsPerPage, orderDirection, filters, loading } = data;
  const [headCells, setHeadCells] = useState(getHeadCells(data.rows, config));

  useEffect(() => {
    setHeadCells(getHeadCells(data.rows, config));
  }, [config, data]);

  const handlePageChange = (ev, pageNo) => {
    let options = {
      pageNo,
      filters,
      orderBy,
      rowsPerPage,
      orderDirection
    };
    if (props.ncicQueryId === null) {
      props.getData(options);
    } else {
      options.QueryID = props.ncicQueryId;
      props.getResultsData(options);
    }
  };

  const handleChangeRowsPerPage = (ev, info) => {
    const rowsPerPage = parseInt(info.props.value);
    let options = {
      pageNo: 0,
      filters,
      orderBy,
      rowsPerPage,
      orderDirection
    };
    if (props.ncicQueryId === null) {
      props.getData(options);
    } else {
      options.QueryID = props.ncicQueryId;
      props.getResultsData(options);
    }
  };

  const handleRowClick = row => {
    if (row.QueryId) {
      props.findResultVehicle(row);
      if (owners.rows.length > 0) {
        props.findResultPerson(owners.rows[0]);
      }
    } else {
      props.rowClick(row);
    }
  };

  const generateResults = row => {
    if (owners.rows.length > 0) {
      return (
        <tr style={{ width: '100%' }} className="nested">
          <td colSpan="6">
            <PersonTable
              data={owners}
              vehicleInfo={row}
              config={ownerConfig}
              origin="vehicleSearch"
            />
          </td>
        </tr>
      );
    } else {
      if (owners.loading && owners.length === 0)
        return (
          <div className="p-3">
            <LoadingData />
          </div>
        );

      return null;
    }
  };
  return (
    <Fragment>
      <div className="table-responsive">
        <table
          className={clsx(
            'table',
            'table-hover',
            themeMode === 'night' && 'table-pts-night',
            themeMode === 'day' && 'table-pts-day',
            'text-nowrap',
            'mb-0'
          )}>
          <thead>
            <tr>
              {headCells.map((row, idx) => {
                if (row.isDisplayed === true) {
                  return (
                    <Fragment key={row.label}>
                      <th
                        className={classes.headerItem}
                        // onClick={() => sort(row.dbName)}
                        title={row.title}>
                        <span>
                          {row.label}
                          {row.dbName === orderBy &&
                            orderDirection === 'ASC' && <ArrowDownwardIcon />}
                          {row.dbName === orderBy &&
                            orderDirection === 'DESC' && <ArrowUpwardIcon />}
                        </span>
                      </th>
                    </Fragment>
                  );
                }
              })}
            </tr>
          </thead>
          <tbody>
            {data.rows.map((row, idx) => {
              let cols = [];
              Object.entries(row).forEach(([key, val]) => {
                if (key !== 'key') {
                  if (config.display[key]) {
                    const dataType = config.display[key].type;
                    const dataName = config.display[key].dbName;
                    const isDisplayed = config.display[key].isDisplayed;
                    if (isDisplayed === true) {
                      if (val && dataType === 'date' && moment(val).isValid()) {
                        if (val.substr(val.length - 1) === 'Z') {
                          const editedDate = val.slice(0, -1);
                          cols.push({
                            text: moment(editedDate).format('MM/DD/YYYY'),
                            seq: config.display[key].seq,
                            name: dataName
                          });
                        } else {
                          cols.push({
                            text: moment(val).format('MM/DD/YYYY'),
                            seq: config.display[key].seq,
                            name: dataName
                          });
                        }
                      } else {
                        cols.push({
                          text: val,
                          seq: config.display[key].seq,
                          name: dataName
                        });
                      }
                    }
                  }
                }
              });
              if (cols.length > 0 && cols[0].seq !== undefined) {
                cols = cols.sort((a, b) => a.seq - b.seq);
              }
              const _parsedStateData =
                row.StateResponseData && row.StateResponseData !== null
                  ? JSON.parse(row.StateResponseData)
                  : null;
              const _parsedNcicData =
                row.NcicResponseData && row.NcicResponseData !== null
                  ? JSON.parse(row.NcicResponseData)
                  : null;
              const _parsedWarnings =
                row.Warnings && row.Warnings !== null
                  ? JSON.parse(row.Warnings)
                  : null;

              if (row.ptsVehicleID === null) {
                if (_parsedStateData !== null) {
                  row['_parsedNcicData'] = _parsedStateData;
                  row['VIN'] =
                    _parsedStateData.data.VehicleIdentificationNumber;
                  row['BrandCode'] = '';
                  row['MakeCode'] = _parsedStateData.data.VehicleMakeCode;
                  row['ModelCode'] = _parsedStateData.data.VehicleModelCode;
                  row['Year'] = _parsedStateData.data.VehicleYear;
                  row['LicensePlateNumber'] =
                    _parsedStateData.data.LicensePlateNumber;
                  row['Name'] = _parsedStateData.data.Name;
                } else {
                  row['_parsedNcicData'] = _parsedNcicData;
                  row['VIN'] = _parsedNcicData.data.VehicleIdentificationNumber;
                  row['BrandCode'] = '';
                  row['MakeCode'] = _parsedNcicData.data.VehicleMakeCode;
                  row['ModelCode'] = _parsedNcicData.data.VehicleModelCode;
                  row['Year'] = _parsedNcicData.data.VehicleYear;
                  row['LicensePlateNumber'] =
                    _parsedNcicData.data.LicensePlateNumber;
                  row['Name'] = _parsedNcicData.data.Name;
                }
              }
              let searchType = 'L';
              if (
                (!row.ptsVehicleID && _parsedNcicData !== null) ||
                (!row.ptsVehicleID && _parsedStateData !== null)
              ) {
                searchType = 'N';
              } else if (
                (row.ptsVehicleID && _parsedNcicData !== null) ||
                (row.ptsVehicleID && _parsedStateData !== null)
              ) {
                searchType = 'LN';
              }
              const _registration =
                row.Registration && row.Registration !== null
                  ? JSON.parse(row.Registration)
                  : null;

              // Generate row if there is a ptsVehicleID

              if (row.ptsVehicleID !== null) {
                return (
                  <Fragment key={idx}>
                    <tr key={idx} onClick={() => handleRowClick(row)}>
                      {cols.map((obj, idx) => {
                        if (obj.name === 'VIN') {
                          return (
                            <Fragment key={idx}>
                              <td
                                className={classes.tableCell}
                                title={obj.text}>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}>
                                  <div style={{ paddingRight: '15px' }}>
                                    <Avatar alt="Avatar">{searchType}</Avatar>
                                  </div>
                                  <div>
                                    <div>
                                      <span
                                        style={{
                                          fontWeight: '500',
                                          fontSize: '18px'
                                        }}>
                                        {obj.text}
                                      </span>
                                    </div>
                                    <div>{row.Name}</div>
                                    <div style={{ color: '#83848b' }}>
                                      <span style={{ fontWeight: '500' }}>
                                        Style:
                                      </span>
                                      <span> {row.StyleDesc}</span>
                                    </div>
                                    <div style={{ color: '#83848b' }}>
                                      <span style={{ fontWeight: '500' }}>
                                        Color:
                                      </span>
                                      <span> {row.PrimaryColorCode}</span>
                                    </div>
                                    <div style={{ color: '#83848b' }}>
                                      <span style={{ fontWeight: '500' }}>
                                        Door Quantity:
                                      </span>
                                      <span> {row.DoorQuantity}</span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </Fragment>
                          );
                        } else if (obj.name === 'IsWanted') {
                          return (
                            <Fragment key={idx}>
                              <td className={classes.tableCell}>
                                <div style={{ marginBottom: '10px' }}>
                                  <Badges
                                    warnings={_parsedWarnings}
                                    IsWanted={row.IsWanted}
                                  />
                                </div>
                              </td>
                            </Fragment>
                          );
                        } else if (obj.name === 'BrandCode') {
                          return (
                            <Fragment key={idx}>
                              <td className={classes.tableCell}>
                                {_registration !== null
                                  ? _registration[0].PlateNumber
                                  : ''}
                              </td>
                            </Fragment>
                          );
                        } else {
                          return (
                            <Fragment key={idx}>
                              <td
                                className={classes.tableCell}
                                title={obj.text}>
                                <span>{obj.text}</span>
                              </td>
                            </Fragment>
                          );
                        }
                      })}
                    </tr>
                  </Fragment>
                );
                // Generate row if there is no ptsVehicleID
              } else {
                return (
                  <Fragment key={idx}>
                    <tr key={idx} onClick={() => handleRowClick(row)}>
                      {cols.map((obj, idx) => {
                        if (obj.name === 'VIN') {
                          return (
                            <Fragment key={idx}>
                              <td
                                className={classes.tableCell}
                                title={obj.text}>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}>
                                  <div style={{ paddingRight: '15px' }}>
                                    <Avatar alt="Avatar">{searchType}</Avatar>
                                  </div>
                                  <div>
                                    <div>
                                      <span
                                        style={{
                                          fontWeight: '500',
                                          fontSize: '18px'
                                        }}>
                                        {obj.text}
                                      </span>
                                    </div>
                                    <div>{row.Name}</div>
                                    <div style={{ color: '#83848b' }}>
                                      <span style={{ fontWeight: '500' }}>
                                        Style:
                                      </span>
                                      <span>{row.VehicleStyleCode}</span>
                                    </div>
                                    <div style={{ color: '#83848b' }}>
                                      <span style={{ fontWeight: '500' }}>
                                        Color:
                                      </span>
                                      <span>{row.VehicleColorCode}</span>
                                    </div>
                                    <div style={{ color: '#83848b' }}>
                                      <span style={{ fontWeight: '500' }}>
                                        Door Quantity:
                                      </span>
                                      <span>{row.DoorQuantity}</span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </Fragment>
                          );
                        } else if (obj.name === 'IsWanted') {
                          return (
                            <Fragment key={idx}>
                              <td className={classes.tableCell}>
                                <div style={{ marginBottom: '10px' }}>
                                  <Badges
                                    warnings={_parsedWarnings}
                                    IsWanted={row.IsWanted}
                                  />
                                </div>
                              </td>
                            </Fragment>
                          );
                        } else if (obj.name === 'BrandCode') {
                          return (
                            <Fragment key={idx}>
                              <td className={classes.tableCell}>
                                {row.LicensePlateNumber}
                              </td>
                            </Fragment>
                          );
                        } else {
                          return (
                            <Fragment key={idx}>
                              <td
                                className={classes.tableCell}
                                title={obj.text}>
                                <span>{obj.text}</span>
                              </td>
                            </Fragment>
                          );
                        }
                      })}
                    </tr>
                    {generateResults(row)}
                  </Fragment>
                );
              }
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        rowsPerPageOptions={config.pageSizeOptions}
        component="div"
        count={data.count}
        rowsPerPage={data.rowsPerPage}
        page={data.pageNo}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {loading && <Spinner />}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  themeMode: state.theme.mode,
  ncicQueryId: state.ncic.personsQueryId
});

export default connect(mapStateToProps, {
  findResultVehicle,
  findResultPerson
})(Table);

// ======================= HELPER FUNCTIONS ====================

function getHeadCells(dataObj, config) {
  const result = [];
  if (dataObj.length === 0) return [];
  const row = dataObj[0];
  Object.keys(row).forEach(key => {
    const option = config.display[key];
    if (option) {
      if (key !== 'key') {
        result.push({
          name: key,
          dbName: option.dbName,
          label: option ? option.label : key,
          width: getColWidth(key, config),
          title: option.title ? option.title : option.label,
          seq: option.seq,
          isDisplayed: option.isDisplayed
        });
      }
    }
  });

  if (result.length > 0 && result[0].seq !== undefined) {
    return result.sort((a, b) => a.seq - b.seq);
  }

  return result;
}

function getTableWidth(config) {
  return Object.values(config.display).reduce((sum, val) => val.width + sum, 0);
}

function getColWidth(key, config) {
  return `${Math.round(
    (config.display[key].width / getTableWidth(config)) * 10000
  ) / 100}%`;
}
function LoadingData() {
  return 'Loading the data, please wait...';
}
