import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import SearchForm from './SearchForm';
import SearchList from './SearchList';

const SearchBoats = props => {
  const { formdefinition, responsedefinition } = props;
  return (
    <Fragment>
      <SearchForm formdefinition={formdefinition} name={formdefinition.name} />
      <SearchList responsedefinition={responsedefinition} list={[]} />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  themeMode: state.theme.mode,
  list: state.ncic.responsesBoats
});

export default connect(mapStateToProps, {})(SearchBoats);
