import React from 'react';
import { connect } from 'react-redux';

import SearchFormNcic from './Ncic';
import SearchFormLocal from './Local';

const SearchForm = props => {
  const {
    ncicformdefinition,
    localformdefinition,
    ncicCheckbox,
    guns,
    getGunsData
  } = props;

  if (ncicCheckbox === true) {
    return (
      <SearchFormNcic
        formdefinition={ncicformdefinition}
        name={ncicformdefinition.name}
      />
    );
  } else {
    return (
      <SearchFormLocal
        getGunsData={getGunsData}
        formdefinition={localformdefinition}
        data={guns}
      />
    );
  }
};

const mapStateToProps = state => ({
  themeMode: state.theme.mode
});

export default connect(mapStateToProps, {})(SearchForm);
