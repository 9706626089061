import {
  Card,
  CardContent,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Fab,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  Tooltip,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  IconButton,
  Button,
  makeStyles,
  Modal
} from '@material-ui/core';
import MessageIcon from '@material-ui/icons/Message';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import DirectionsIcon from '@material-ui/icons/Directions';
import { getEventColor } from '../../../../utils/uiUtils';
import { getUnitColor } from '../../../../utils/uiUtils';

import { Link } from 'react-router-dom';
import EventIcon from '../../../Map/EventIcon';
import AgencyIcon from '../../../Map/AgencyIcon';
import { parseXmlSOP } from '../../../../reducers/SopsReducer';

import React, { useState } from 'react';
import { Fragment } from 'react';
import AttachmentList from 'components/Map/DialogEvent/AttachmentList';
import { useDispatch, useSelector } from 'react-redux';
import { closeShowDetails } from '../../../../reducers/ClosedEventSearchReducer';
import { displayDateTime } from 'reducers/TimeReducer';
const avatarWidth = 44;
const iconWidth = 35;
const useStyles = makeStyles(theme => ({
  eventCard: {
    borderWidth: '5px'
  },
  listItem: {
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  avatar: {
    flex: `0 0 ${avatarWidth}px`,
    width: avatarWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    '& > div': {
      boxSizing: 'border-box',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      background: '#dedfe3',
      borderRadius: '15%',
      width: 35,
      height: 35,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.2em',
      color: '#000',
      fontWeight: 500,
      textTransform: 'uppercase',
      padding: 4
    }
  },
  icon: {
    flex: `0 0 ${iconWidth}px`,
    textAlign: 'center',
    '& > div': {
      height: 20,
      width: 20,
      display: 'inline-block'
    }
  },
  button: {
    border: '1px solid #000',
    borderRadius: '7px',
    width: '60px',
    minWidth: '40px',
    height: '40px',
    marginRight: '10px'
  }
}));
const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
};
const EventDetails = event => {
  event = event.event;
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const dispatch = useDispatch();
  const colors = useSelector(state => state.codes.colorsCodes);
  const onButtonClick = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => {
    dispatch(closeShowDetails());
  };
  const history =
    event && event.UnitStatuses ? JSON.parse(event.UnitStatuses) : [];

  const attachementsData =
    event && event.Attachments ? JSON.parse(event.Attachments) : [];
  let cases = '';
  if (event) {
    cases = JSON.parse(event.CaseIds);
  }
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderCheckbox = (checkbox, idx) => {
    const value = checkbox.value === true || checkbox.value === 'True';
    return (
      <tr key={checkbox.name} className={classes.checkbox}>
        <td>
          <label>{checkbox.text}</label>
        </td>
        <td>
          <Checkbox checked={value} disabled />
        </td>
      </tr>
    );
  };
  const renderText = text => {
    return (
      <tr key={text.name}>
        <td colSpan={2} className={classes.text}>
          <MessageIcon /> &nbsp;{text.text}
        </td>
      </tr>
    );
  };

  const renderQuestion = (question, idx) => {
    return (
      <tr key={question.name}>
        <td>
          <QuestionAnswerIcon /> &nbsp;{question.text}
        </td>
        <td>
          {
            <TextField
              disabled
              variant="outlined"
              size="small"
              style={{ width: '100%' }}
              value={question.value}
            />
          }
        </td>
      </tr>
    );
  };

  return (
    <Modal open style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)' }}>
      <div
        className="pts-event-draggable "
        style={{
          position: 'fixed',
          zIndex: 999,
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          padding: '0 5px'
        }}>
        <Card className="card-box pts-event-box">
          <IconButton
            style={{
              position: 'absolute',
              right: '-10px',
              top: '-10px'
            }}
            onClick={handleClose}
            aria-label="close">
            <CloseIcon variant="contained" />
          </IconButton>
          <CardContent>
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                alignContent: 'flex-end',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}>
              <Tooltip arrow title={event.CallTypeDescription}>
                <div className={classes.avatar}>
                  <div>
                    <EventIcon
                      CallType={event.CallType}
                      CallTypeDescription={event.CallTypeDescription}
                      color={getEventColor(event, colors)}
                    />
                  </div>
                </div>
              </Tooltip>
              <div className="card-header--title">
                <div
                  className="handle"
                  style={{
                    display: 'flex',
                    alignItems: 'end',
                    alignContent: 'flex-end',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    cursor: 'grab'
                  }}>
                  <div>
                    <div style={{ lineHeight: '21px' }}>
                      <b>{event.EventID}</b>
                    </div>{' '}
                    <div
                      style={{
                        lineHeight: '9px',
                        color: getEventColor(event, colors),
                        fontWeight: '500'
                      }}
                      className="pb-2 lead">
                      <small>{event.CallTypeDescription}</small>
                    </div>
                    <div style={{ lineHeight: '10px' }}>
                      {displayDateTime(event.ReceiveDate)}
                    </div>
                  </div>
                  <div>
                    <div
                      className="badge"
                      style={{
                        background: getEventColor(event, colors),
                        width: '90px'
                      }}>
                      {event.Status}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: '10px',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}>
                  <div style={{ lineHeight: '9px' }}>
                    <b>{event.FullLocationAddress}</b>
                  </div>

                  <div>
                    {event.distance && <div>{event.distance.toFixed(2)}</div>}
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: '5px',
                padding: '5px 0px',
                justifyContent: 'center',
                alignContent: 'flex-start',
                borderBottom: '1px solid rgba(7, 9, 25, 0.125)',
                borderBottomRightRadius: '0',
                borderBottomLeftRadius: '0'
              }}
              className="card-footer d-flex align-items-center bg-transparent p-2">
              <Button
                onClick={ev => onButtonClick(ev, 0)}
                className={classes.button}>
                <div className="text-center my-1 p-1">
                  <div className="line-height-sm">
                    <b className="font-size-s">{event.notes.length}</b>
                    <span
                      style={{ fontWeight: '200', fontSize: '11px' }}
                      className="d-block">
                      Notes
                    </span>
                  </div>
                </div>
              </Button>
              <Button
                onClick={ev => onButtonClick(ev, 1)}
                className={classes.button}>
                <div className="text-center my-1 p-1">
                  <div className="line-height-sm">
                    <b className="font-size-s">0</b>
                    <span
                      style={{ fontWeight: '200', fontSize: '11px' }}
                      className="d-block">
                      Dets
                    </span>
                  </div>
                </div>
              </Button>
              <Button
                onClick={ev => onButtonClick(ev, 2)}
                className={classes.button}>
                <div className="text-center my-1 p-1">
                  <div className="line-height-sm">
                    <b className="font-size-s">{event.dispositions.length}</b>
                    <span
                      style={{ fontWeight: '200', fontSize: '11px' }}
                      className="d-block">
                      Disp
                    </span>
                  </div>
                </div>
              </Button>
              <Button
                onClick={ev => onButtonClick(ev, 3)}
                className={classes.button}>
                <div className="text-center my-1 p-1">
                  <div className="line-height-sm">
                    <b className="font-size-s">
                      {event.Attachments !== null
                        ? JSON.parse(event.Attachments).length
                        : 0}
                    </b>
                    <span
                      style={{ fontWeight: '200', fontSize: '11px' }}
                      className="d-block">
                      Attch
                    </span>
                  </div>
                </div>
              </Button>
              <Button
                onClick={ev => onButtonClick(ev, 4)}
                className={classes.button}>
                <div className="text-center my-1 p-1">
                  <div className="line-height-sm">
                    {history ? history.length : 0}
                    <b className="font-size-s"> </b>
                    <span
                      style={{ fontWeight: '200', fontSize: '11px' }}
                      className="d-block">
                      Hist
                    </span>
                  </div>
                </div>
              </Button>
              <Button
                onClick={ev => onButtonClick(ev, 5)}
                className={classes.button}>
                <div className="text-center my-1 p-1">
                  <div className="line-height-sm">
                    <b className="font-size-s">{cases ? cases.length : 0}</b>
                    <span
                      style={{ fontWeight: '200', fontSize: '11px' }}
                      className="d-block">
                      Cases
                    </span>
                  </div>
                </div>
              </Button>
              <Button
                onClick={ev => onButtonClick(ev, 6)}
                className={classes.button}>
                <div className="text-center my-1 p-1">
                  <div className="line-height-sm">
                    {event.sops.length}
                    <b className="font-size-s"></b>
                    <span
                      style={{ fontWeight: '200', fontSize: '11px' }}
                      className="d-block">
                      Sops
                    </span>
                  </div>
                </div>
              </Button>
            </div>
            <div
              className="card-indicator"
              style={{
                backgroundColor: getEventColor(event, colors)
              }}
            />
            <TabPanel value={value} index={0}>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  minHeight: 262,
                  maxHeight: 262,
                  overflow: 'auto'
                }}>
                <ul style={{ listStyle: 'none', padding: 0 }}>
                  {event.notes.map((note, index) => (
                    <Fragment key={note.ptsCommentID}>
                      <li>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontSize: '0.8rem'
                          }}>
                          <div
                            style={{
                              alignSelf: 'center',
                              fontStyle: 'italic',
                              fontWeight: 'bold'
                            }}>
                            {displayDateTime(note.Created)}
                          </div>
                          <div>
                            <span
                              className="badge"
                              style={{ fontSize: '100%' }}>
                              {note.CreatedBy}
                            </span>
                          </div>
                        </div>
                        <div style={{ fontSize: '0.8rem' }}>{note.Comment}</div>
                      </li>
                      <div className="divider my-1" />
                    </Fragment>
                  ))}
                </ul>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column'
                }}>
                <div style={{ minHeight: '140px' }}>
                  <div>
                    <div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                      Caller
                    </div>
                    {event.callers.map((caller, idx) => {
                      let callerPhone;
                      if (caller.Info != null)
                        callerPhone = `tel:${caller.Info}`;
                      return (
                        <Fragment key={idx}>
                          <div style={{ fontSize: '0.8rem' }}>
                            {caller.FullName} /{' '}
                            <a href={callerPhone}>{caller.Info}</a>
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>
                  <div className="divider my-1" />
                  {event.assignedUnits.length > 0 && (
                    <Fragment>
                      <span
                        style={{
                          fontWeight: 'bold',
                          fontSize: '0.8rem'
                        }}>
                        Units on Event
                      </span>
                      {event.assignedUnits.map((unit, index) => (
                        <Fragment key={unit.ptsUnitID}>
                          <div
                            className="d-flex justify-content-between"
                            style={{
                              alignItems: 'center',
                              paddingTop: '5px'
                            }}>
                            <div style={{ display: 'flex' }}>
                              <div className={classes.icon}>
                                <div>
                                  <AgencyIcon
                                    color={getUnitColor(unit, colors)}
                                    AgencyID={unit.AgencyID}
                                  />
                                </div>
                              </div>
                              <div>
                                <div
                                  className="font-weight-bold"
                                  style={{
                                    lineHeight: '10px',
                                    minWidth: '125px'
                                  }}>
                                  <div>
                                    <span>
                                      {unit.Unit !== null
                                        ? unit.Unit
                                        : unit.ptsUnitID}
                                    </span>
                                  </div>
                                  <div>
                                    <small>
                                      {displayDateTime(unit.Occurred)}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div
                                className="badge"
                                style={{
                                  background: getUnitColor(unit, colors),
                                  width: '90px'
                                }}>
                                {unit.UnitStatus}
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      ))}
                    </Fragment>
                  )}
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  minHeight: 262,
                  maxHeight: 262,
                  overflow: 'auto'
                }}>
                <ul style={{ listStyle: 'none', padding: 0 }}>
                  {event.dispositions.map((disposition, index) => (
                    <Fragment key={index}>
                      <li>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontSize: '0.8rem'
                          }}>
                          <div
                            style={{
                              alignSelf: 'center',
                              fontStyle: 'italic',
                              fontWeight: 'bold'
                            }}>
                            {displayDateTime(disposition.Created)}
                          </div>
                          <div>
                            <span
                              className="badge"
                              style={{ fontSize: '100%' }}>
                              {disposition.CreatedBy}
                            </span>
                          </div>
                        </div>
                        <div style={{ fontSize: '0.8rem' }}>
                          {disposition.Disposition} ({disposition.Description})
                          - {disposition.AgencyID}
                        </div>
                      </li>
                      <div className="divider my-1" />
                    </Fragment>
                  ))}
                </ul>
              </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  minHeight: 262,
                  maxHeight: 262,
                  overflow: 'auto'
                }}>
                {event.Attachments && (
                  <ul style={{ listStyle: 'none', padding: 0 }}>
                    {JSON.parse(event.Attachments).map((attachment, i) => (
                      <AttachmentList info={attachment} key={i} />
                    ))}
                  </ul>
                )}
              </div>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  minHeight: 225,
                  maxHeight: 225,
                  overflow: 'auto'
                }}>
                <ul style={{ listStyle: 'none', padding: 0 }}>
                  {history.map((unit, index) => (
                    <li key={index}>
                      <div
                        className="d-flex justify-content-between"
                        style={{
                          alignItems: 'center',
                          paddingTop: '5px',
                          paddingBottom: '5px',
                          backgroundColor:
                            index % 2 == 0
                              ? 'rgba(0, 0, 0, 0.02)'
                              : 'transparent'
                        }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div className={classes.icon}>
                            <div>
                              <AgencyIcon
                                color={getUnitColor(unit, colors)}
                                AgencyID={unit.AgencyID}
                              />
                            </div>
                          </div>
                          <div>
                            <div
                              className="font-weight-bold"
                              style={{
                                lineHeight: '10px',
                                minWidth: '260px'
                              }}>
                              <div>
                                <span>
                                  {unit.Unit !== null
                                    ? unit.Unit
                                    : unit.ptsUnitID}
                                </span>
                              </div>
                              <div>
                                <small>{displayDateTime(unit.Occurred)}</small>
                              </div>
                              {unit.Location !== null && (
                                <div>
                                  <small>
                                    <b>Location: </b>
                                    {unit.Location}
                                  </small>
                                </div>
                              )}
                              {unit.OLN !== null && (
                                <div>
                                  <small>
                                    <b>OLN: </b>
                                    {unit.OLN}{' '}
                                    {unit.OLNState !== null
                                      ? unit.OLNState
                                      : ''}
                                  </small>
                                </div>
                              )}
                              {unit.Plate !== null && (
                                <div>
                                  <small>
                                    <b>Plate: </b>
                                    {unit.Plate}{' '}
                                    {unit.PlateState !== null
                                      ? unit.PlateState
                                      : ''}
                                  </small>
                                </div>
                              )}
                              {unit.Notes !== null && (
                                <div>
                                  <small>
                                    <b>Notes: </b>
                                    {unit.Notes}
                                  </small>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            className="badge"
                            style={{
                              background: getUnitColor(unit, colors),
                              width: '90px'
                            }}>
                            {unit.UnitStatus}
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  minHeight: 400,
                  maxHeight: 400,
                  overflow: 'auto'
                }}>
                {cases && (
                  <ul style={{ listStyle: 'none', padding: 0 }}>
                    {cases.map((c, index) => (
                      <Fragment key={index}>
                        <li style={{ marginBottom: '10px' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              fontSize: '0.8rem',
                              lineHeight: '26px'
                            }}>
                            <div
                              style={{
                                alignSelf: 'center',
                                lineHeight: '26px',
                                fontWeight: 'bold'
                              }}>
                              {c.CaseId ? c.CaseId : ''}
                            </div>
                          </div>
                          <div style={{ lineHeight: '1px' }}>
                            <span
                              style={{
                                fontStyle: 'italic',
                                fontSize: '0.7rem',
                                paddingRight: '3px'
                              }}>
                              {c.AgencyId ? c.AgencyId : ''}
                            </span>
                          </div>
                        </li>
                        <div className="divider my-1" />
                      </Fragment>
                    ))}
                  </ul>
                )}
              </div>
            </TabPanel>
            <TabPanel value={value} index={6}>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  minHeight: 262,
                  maxHeight: 262,
                  overflow: 'auto'
                  // overflow: 'hidden'
                }}>
                <ul style={{ listStyle: 'none', padding: 0 }}>
                  {event.sops.map((sop, index) => {
                    const parsedSop = parseXmlSOP(sop);
                    return (
                      <Fragment key={index}>
                        <li>
                          <Fragment>
                            <Accordion
                              style={{
                                marginBottom: '5px',
                                backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                boxShadow: 'none'
                              }}
                              expanded={expanded === sop.SOPID}
                              onChange={handleChange(sop.SOPID)}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header">
                                <Typography>
                                  <b className="m-1 badge badge-secondary">
                                    {sop.SOPID}
                                  </b>{' '}
                                  ({parsedSop.ParentType} -{' '}
                                  <span>{parsedSop.ParentName})</span>
                                  <br />
                                  {parsedSop.DispatcherMessage}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="d-flex align-items-center flex-column flex-sm-row">
                                  <table className={classes.questions}>
                                    <tbody>
                                      {parsedSop.DispatcherQuestions.map(
                                        (q, idx) => {
                                          switch (q.type) {
                                            case 'Text':
                                              return renderText(q);
                                            case 'Question':
                                              return renderQuestion(q, idx);
                                            case 'CheckBox':
                                              return renderCheckbox(q, idx);
                                            default:
                                              return '';
                                          }
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </Fragment>
                        </li>
                      </Fragment>
                    );
                  })}
                </ul>
              </div>
            </TabPanel>
          </CardContent>
        </Card>
      </div>
    </Modal>
  );
};

export default EventDetails;
