import React, { Fragment } from 'react';

import LoginForm from '../../components/LoginForm';

export default function PagesLogin() {
  return (
    <Fragment>
      <LoginForm />
    </Fragment>
  );
}
