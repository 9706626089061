import store from '../config/configureStore';
import _ from 'underscore';
import { setUserAuthenticated, setUserData, setUserUnit } from './UserReducer';
import { SET_UNIT_INFOWINDOW } from './MapReducer';

// Units
export const SET_ACCRODIN_EXPAND = 'UNITS/SET_ACCRODIN_EXPAND';
export const SET_GROUPED_UNIT = 'UNITS/SET_GROUPED_UNIT';
export const SET_UNITS = 'UNITS/SET_UNITS';
export const SET_GROUPBY = 'UNITS/SET_GROUPBY';
export const SET_UNITS_SORT_BY = 'UNITS/SET_UNITS_SORT_BY';
export const SET_UNITS_SORT_ORDER = 'UNITS/SET_UNITS_SORT_ORDER';
export const SET_UNITS_FILTER_KEYWORD = 'UNITS/SET_UNITS_FILTER_KEYWORD';
export const IS_EMPTY = 'UNITS/IS_EMPTY';
export const SET_UNIT_UPDATE_COUNTER = 'UNITS/SET_UNIT_UPDATE_COUNTER';

export const updateUnitInfoWindow = units => {
  const infoWindow = store.store.getState().map.unitInfowindow;
  return async dispatch => {
    if (infoWindow.open === true && infoWindow.data) {
      const ptsUnitID = infoWindow.data.ptsUnitID;
      const event = units.find(e => e.ptsUnitID === ptsUnitID);
      dispatch({
        type: SET_UNIT_INFOWINDOW,
        payload: { open: true, data: event }
      });
    }
  };
};

const swapUnitsForPin = (units, sourceIndex, destinationIndex) => {
  let temp = units[sourceIndex];
  units[sourceIndex] = units[destinationIndex];
  units[destinationIndex] = temp;
};

export const processUnits = units => {
  const myUnit = store.store.getState().user.userUnit;

  let unitsToPush = [...store.store.getState().units.units];
  let unitUpdatesCounter = store.store.getState().units.unitUpdatesCounter;
  return async dispatch => {
    if (units.unitActionType) {
      const unit = units;
      if (
        unitUpdatesCounter !== 0 &&
        unit.unitUpdatesCounter !== unitUpdatesCounter + 1
      ) {
        dispatch(getUnits());
      } else {
        if (unit.unitActionType == 'remove') {
          // Remove not existing events
          const { removedUnits } = unit;
          unitsToPush = unitsToPush.filter(
            u => removedUnits.indexOf(u.ptsUnitID) === -1
          );
        } else {
          for (let i = 0; i < unit.unitsToPush.length; i++) {
            const _u = unitsToPush.findIndex(e => {
              return e.ptsUnitID === unit.unitsToPush[i].ptsUnitID;
            });

            if (unit.unitsToPush[i].ptsUnitID === myUnit?.ptsUnitID) {
              dispatch(setUserUnit(unit.unitsToPush[i]));
            }

            if (_u === -1) {
              unitsToPush.push(unit.unitsToPush[i]);
            } else {
              unitsToPush[_u] = { ...unitsToPush[_u], ...unit.unitsToPush[i] };
            }
          }
        }
      }
      dispatch({
        type: SET_UNIT_UPDATE_COUNTER,
        payload: unit.unitUpdatesCounter
      });
    } else {
      let _units = [...units];

      // Push or update units in state
      if (_units.length && _units) {
        _units.forEach(unit => {
          const _u = unitsToPush.findIndex(e => {
            return e.ptsUnitID === unit.ptsUnitID;
          });

          if (unit.ptsUnitID === myUnit?.ptsUnitID) {
            dispatch(setUserUnit(unit));
          }

          if (_u === -1) {
            unitsToPush.push(unit);
          } else {
            unitsToPush[_u] = { ...unitsToPush[_u], ...unit };
          }
        });
      }

      // Remove not existing units
      unitsToPush.forEach((unit, index) => {
        const _u = _units.findIndex(u => u.ptsUnitID === unit.ptsUnitID);
        if (_u === -1) unitsToPush.splice(index, 1);
      });
    }

    unitsToPush = filterForUnits(unitsToPush);
    unitsToPush = sortForUnits(unitsToPush);
    if (unitsToPush.length == 0) {
      dispatch({ type: IS_EMPTY, payload: true });
    } else {
      dispatch({ type: IS_EMPTY, payload: false });
    }
    dispatch({ type: SET_UNITS, payload: unitsToPush });
    dispatch(updateUnitInfoWindow(unitsToPush));
    dispatch(makeUnitGroupForPin());
  };
};

const filterForUnits = units => {
  const agencies = store.store.getState().settings.agenciesUnit;
  const divisions = store.store.getState().settings.divisionsUnit;
  const zones = store.store.getState().settings.zonesUnit;
  // console.log('agencies', agencies);
  // console.log('divisoin', divisions);
  // console.log('zone', zones);
  let filteredUnits = [];

  units.forEach(unit => {
    let pass = false;
    let pass2 = false;
    let pass3 = false;
    if (agencies) {
      if (agencies.some(e => e.AgencyID === unit.AgencyID)) {
        pass = true;
      } else {
        pass = false;
      }
    } else {
      pass = true;
    }

    if (divisions) {
      if (divisions.some(e => e.Code === unit.Division) || !unit.Division) {
        pass2 = true;
      } else {
        pass2 = false;
      }
    } else {
      pass2 = true;
    }
    if (zones) {
      if (zones.some(e => e.ZoneCode === unit.Zone) || !unit.Zone) {
        pass3 = true;
      } else {
        pass3 = false;
      }
    } else {
      pass3 = true;
    }
    if (pass && pass2 && pass3) {
      filteredUnits.push(unit);
    }
  });
  if (!agencies && !divisions && !zones) {
    filteredUnits = units;
  }
  return filteredUnits;
};

const sortForUnits = units => {
  let sortedUnits = [];
  let aUnitsArray = [];
  if (!units) return [];
  aUnitsArray = [...units];

  let onlyPinedUnits = aUnitsArray.filter(event => event.Pined);
  let onlyNonPinedUnits = aUnitsArray.filter(event => !event.Pined);

  const sortBy = store.store.getState().units.sortBy;
  const sortOrder = store.store.getState().units.sortOrder;

  if (sortOrder === 'DESC') {
    sortedUnits = _.chain(onlyNonPinedUnits)
      .sortBy('ptsUnitID')
      .sortBy(sortBy)
      .value()
      .reverse();
    // sortedUnits = _.sortBy(onlyNonPinedUnits, sortBy).reverse();
  }
  if (sortOrder === 'ASC') {
    sortedUnits = _.chain(onlyNonPinedUnits)
      .sortBy('ptsUnitID')
      .sortBy(sortBy)
      .value();
    // sortedUnits = _.sortBy(onlyNonPinedUnits, sortBy);
  }
  return [...onlyPinedUnits, ...sortedUnits];
};

export const getUnits = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('cmplx-units');
      service.timeout = 30000;
      const units = await service.find();
      dispatch(processUnits(units));
      dispatch(makeUnitGroup());
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const setUnits = units => {
  return async dispatch => {
    dispatch(processUnits(units));
  };
};
export const setUnitGroupBy = group => {
  return dispatch => {
    dispatch({ type: SET_GROUPBY, payload: group });
  };
};
export const updateUnits = () => {
  let _units = [...store.store.getState().units.units];
  return async dispatch => {
    dispatch(processUnits(_units));
  };
};

export const unitsSortBy = value => {
  const events = store.store.getState().units.units;
  return async dispatch => {
    dispatch({ type: SET_UNITS_SORT_BY, payload: value });
    dispatch(processUnits(events));
  };
};

export const unitsSortOrder = value => {
  const events = store.store.getState().units.units;
  return async dispatch => {
    dispatch({ type: SET_UNITS_SORT_ORDER, payload: value });
    dispatch(processUnits(events));
  };
};

export const unitsDragReorder = (destination, source, draggableId) => {
  const units = store.store.getState().units.units;
  return async dispatch => {
    let unitsToPush = [...units];
    let temp = unitsToPush[source.index];
    unitsToPush[source.index] = unitsToPush[destination.index];
    unitsToPush[destination.index] = temp;
    dispatch({ type: SET_UNITS, payload: unitsToPush });
    dispatch(makeUnitGroupForPin());
  };
};

export const pinUnit = unit => {
  const units = [...store.store.getState().units.units];
  return async dispatch => {
    let unitsToPush = [...units];
    unitsToPush.forEach(unitMapped => {
      if (unitMapped.ptsUnitID === unit.ptsUnitID) {
        if (!unitMapped.Pined) {
          unitMapped.Pined = !unitMapped.Pined;
          unitsToPush = [
            unitMapped,
            ...units.filter(item => item.ptsUnitID !== unit.ptsUnitID)
          ];
        } else {
          unitMapped.Pined = !unitMapped.Pined;
        }
      }
    });
    unitsToPush = sortForUnits(unitsToPush);
    dispatch({ type: SET_UNITS, payload: unitsToPush });
    dispatch(makeUnitGroupForPin());
  };
};

export const toggleExpanded = unit => {
  let unitsToPush = [...store.store.getState().units.units];
  return async dispatch => {
    unitsToPush.map(_u => {
      if (_u.ptsUnitID === unit.ptsUnitID) {
        _u.expanded = !_u.expanded;
      }
    });
    dispatch({ type: SET_UNITS, payload: unitsToPush });
  };
};

export const unitsFilterKeywordChange = event => {
  const filter = event.target ? event.target.value.toLowerCase() : event;
  const units = store.store.getState().units.units;

  return async dispatch => {
    const filtered = units.filter(unit => {
      if (
        unit.UnitResources?.toLowerCase().includes(filter) ||
        unit.Unit?.toLowerCase().includes(filter) ||
        unit.UnitStatus?.toLowerCase().includes(filter) ||
        unit.Division?.toLowerCase().includes(filter) ||
        unit.Zone?.toLowerCase().includes(filter)
      ) {
        return unit;
      }
    });

    dispatch({ type: SET_UNITS_FILTER_KEYWORD, payload: filter });
    dispatch(makeUnitGroupFilter(filtered));
  };
};
export const makeUnitGroup = key => {
  const units = store.store.getState().units.units;
  const groupBy = store.store.getState().units.groupBy;

  return dispatch => {
    if (!key) {
      key = groupBy;
    }
    let k;
    if (key == 'agency') k = 'AgencyID';
    else if (key == 'status') k = 'UnitStatus';
    else if (key == 'zone') k = 'Zone';
    else if (key == 'division') k = 'Division';

    const groupedUnit = _.groupBy(units, function(unit) {
      return unit[k] || 'Unknown';
    });
    const sortedUnits = Object.keys(groupedUnit)
      .sort()
      .reduce((r, k) => ((r[k] = groupedUnit[k]), r), {});
    dispatch({ type: SET_GROUPED_UNIT, payload: sortedUnits });
  };
};
export const makeUnitGroupFilter = (units, key) => {
  const groupBy = store.store.getState().units.groupBy;
  return dispatch => {
    if (!key) {
      key = groupBy;
    }
    let k;
    if (key == 'agency') k = 'AgencyID';
    else if (key == 'status') k = 'UnitStatus';
    else if (key == 'zone') k = 'Zone';
    else if (key == 'division') k = 'Division';

    const groupedUnit = _.groupBy(units, function(unit) {
      return unit[k] || 'Unknown';
    });
    const sortedUnits = Object.keys(groupedUnit)
      .sort()
      .reduce((r, k) => ((r[k] = groupedUnit[k]), r), {});

    dispatch({ type: SET_GROUPED_UNIT, payload: sortedUnits });
  };
};
export const makeUnitGroupForPin = key => {
  const units = store.store.getState().units.units;
  const gUnit = store.store.getState().units.groupedUnit;
  const groupBy = store.store.getState().units.groupBy;
  return dispatch => {
    if (!key) {
      key = groupBy;
    }
    let k;
    if (key == 'agency') k = 'AgencyID';
    else if (key == 'status') k = 'UnitStatus';
    else if (key == 'zone') k = 'Zone';
    else if (key == 'division') k = 'Division';

    const groupedUnit = _.groupBy(units, function(unit) {
      return unit[k] || 'Unknown';
    });

    const sortedUnits = Object.keys(groupedUnit)
      .sort()
      .reduce((r, k) => ((r[k] = groupedUnit[k]), r), {});

    dispatch({ type: SET_GROUPED_UNIT, payload: sortedUnits });
  };
};
export const toggleAccrodinExpanded = index => {
  return dispatch => {
    dispatch({ type: SET_ACCRODIN_EXPAND, payload: index });
  };
};
export default function reducer(
  state = {
    // Units
    groupBy: 'none',
    groupedUnit: {},
    units: [],
    sortBy: 'Unit',
    sortOrder: 'DESC',
    filterKeyword: '',
    isEmpty: false,
    unitUpdatesCounter: 0,
    accrodinExpand: -1
  },
  action
) {
  switch (action.type) {
    // Units

    case SET_UNITS:
      return {
        ...state,
        units: action.payload
      };
    case IS_EMPTY:
      return {
        ...state,
        isEmpty: action.payload
      };
    case SET_ACCRODIN_EXPAND:
      return {
        ...state,
        accrodinExpand: action.payload
      };
    case SET_UNIT_UPDATE_COUNTER:
      return {
        ...state,
        unitUpdatesCounter: action.payload
      };
    case SET_GROUPBY: {
      return {
        ...state,
        groupBy: action.payload
      };
    }
    case SET_GROUPED_UNIT: {
      return {
        ...state,
        groupedUnit: action.payload
      };
    }
    case SET_UNITS_SORT_BY:
      return {
        ...state,
        sortBy: action.payload
      };
    case SET_UNITS_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.payload
      };
    case SET_UNITS_FILTER_KEYWORD:
      return {
        ...state,
        filterKeyword: action.payload
      };
    default:
      break;
  }
  return state;
}
