import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles, Paper } from '@material-ui/core';

import SearchForm from './SearchForm';
import SearchResults from './SearchResults';
import PersonDetails from './SearchDetails/index';

import {
  findPersons,
  sortPersons,
  findPerson
} from '../../../reducers/PersonSearchReducer';
import { findResultsPerson, finishedResponses } from 'reducers/NcicReducer';
import clsx from 'clsx';
const useStyles = makeStyles(theme => ({
  filterDiv: {
    position: '-webkit-sticky',
    position: 'sticky',
    top: 130,
    bottom: 20,
    padding: '24px',
    margin: '24px',
    paddingBottom: '20px',
    zIndex: 5,
    [theme.breakpoints.down('md')]: {
      top: 0,
      bottom: 0,
      position: 'relative',
      position: 'relative'
    }
  }
}));
const SearchNcic = props => {
  const {
    ncicformdefinition,
    localformdefinition,
    localtableconfig,
    themeMode
  } = props;
  const classes = useStyles();
  const { persons, personDetails } = props.personSearch;

  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    if (personDetails.data !== null && personDetails.isShowing === true) {
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  }, [personDetails]);

  useEffect(() => {
    props.finishedResponses(0);
  }, []);

  const getPersonsData = options => {
    props.findPersons(options);
  };

  const getPersonsNcicData = options => {
    props.findResultsPerson(options);
  };

  const sortPersonsData = name => {
    props.sortPersons(name);
  };

  const tableRowClick = row => {
    props.findPerson(row.ptsPersonID);
  };

  const closeDetails = () => {
    props.findPerson(null);
  };

  return (
    <Fragment>
      <Paper
        square
        className={clsx('m-3 p-3', classes.filterDiv)}
        style={{ background: themeMode === 'day' ? 'white' : '#424242' }}>
        <SearchForm
          getPersonsData={getPersonsData}
          getResultsData={getPersonsNcicData}
          localformdefinition={localformdefinition}
          ncicformdefinition={ncicformdefinition}
          name={ncicformdefinition.name}
          persons={persons}
          ncicCheckbox={props.ncicSearch.personForm.ncic}
        />
      </Paper>
      <SearchResults
        getPersonsData={getPersonsData}
        getResultsData={getPersonsNcicData}
        sortPersonsData={sortPersonsData}
        tableRowClick={tableRowClick}
        localtableconfig={localtableconfig}
        persons={persons}
      />
      {showDetails && (
        <PersonDetails
          data={personDetails.data}
          images={persons.images}
          showDetails={showDetails}
          close={closeDetails}
          themeMode={themeMode}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  themeMode: state.theme.mode,
  list: state.ncic.responsesNames,
  ncicSearch: state.ncic,
  personSearch: state.personSearch
});

export default connect(mapStateToProps, {
  findPersons,
  sortPersons,
  findPerson,
  findResultsPerson,
  finishedResponses
})(SearchNcic);
