import { isMobile } from 'react-device-detect';
import store from '../config/configureStore';
import _ from 'underscore';
import { enqueueSnackbar } from './NotifierReducer';
import { getService } from './service';
import { notify } from './NotifierReducer';
import { getLocalSettings, saveLocalSettings } from './ConfigReducer';
import {
  setAlertTone,
  setHelpBar,
  setSearchView,
  setShowZonesOnMap,
  setViewType,
  SET_AGENCY_BASED_UNIT_SETTING,
  SET_MAP_DEFAULT_VIEW
} from './SettingsReducer';
import { setMode } from './ThemeReducer';
import { hideSpinner, showSpinner } from './UiReducer';
import { saveNcicCredentials } from './NcicReducer';
// User
export const SET_ALL_PERMISSIONS = 'USER/SET_ALL_PERMISSIONS';
export const USER_SET_AUTHENTICATED = 'USER/SET_AUTHENTICATED';
export const USER_SECOND_FACTOR = 'USER/USER_SECOND_FACTOR';
export const USER_SET_USER_UNITS = 'USER/SET_USER_UNITS';
export const USER_SET_DATA = 'USER/SET_DATA';
export const USER_IS_PASSWORD_VALID = 'USER/USER_IS_PASSWORD_VALID';
export const USER_SET_USER_UNIT = 'USER/USER_SET_USER_UNIT';
export const USER_SET_LOCATION = 'USER/SET_LOCATION';
export const USER_SET_WARNING_ACCEPTED = 'USER/USER_SET_WARNING_ACCEPTED';
export const SET_UNIT_UPDATE_COUNTER = 'UNITS/SET_UNIT_UPDATE_COUNTER';
export const SET_EVENT_UPDATE_COUNTER = 'EVENTS/SET_EVENT_UPDATE_COUNTER';
const PASS_EXPIRATION = 'PASS_EXPIRATION';
const PASS_RESET = 'PASS_RESET';
export const SET_UNIT_SELECTED = 'UNIT/SET_UNIT_SELECTED';
export const SET_UNIT_HISTORY = 'UNIT/SET_UNIT_HISTORY';
export const TYPED_WRONG_PASSWORD = 'USER/TYPED_WRONG_PASSWORD';
export const changePass = (currentPass, newPass) => {
  const service = getService('authenticate');
  return service.patch(0, { currentPass, newPass, AppName: 'eMobile' });
};
export const changeNCICPassword = newPass => {
  // console.log('|new pass', newPass);
  const service = getService('sec-user');
  return service.patch(0, { newPass });
};
export const resetPass = (hash, newPass) => {
  const service = getService('pass-reset');
  return service.patch(hash, { newPass, AppName: 'eMobile' });
};

export const sendPassResetLink = email => {
  const service = getService('pass-reset');
  return service.create({
    action: 'pass-reset',
    email,
    ResetMethod: 'email',
    AppName: 'eMobile',
    RedirectTo: process.env.REACT_APP_PUBLIC_URL + '/Reset-password'
  });
};

export const validateResetPassHash = hash => {
  const service = getService('pass-reset');
  return service.get(hash);
};

export const getAuthInfo = () => {
  const service = getService('authenticate');
  return service.get(0);
};

export const checkPassExpiration = () => {
  const service = getService('authenticate');
  return async dispatch => {
    try {
      const user = await service.get(0);
      if (!user) return;
      const { PassExpirationDays, ptsUserID } = user;
      dispatch({ type: PASS_EXPIRATION, payload: PassExpirationDays });
      const currentTime = new Date().getTime();
      let prevTime = getLocalSettings(`changePassReminder${ptsUserID}`);
      if (!prevTime) prevTime = 0;
      const showReminder =
        (currentTime - prevTime > 12 * 60 * 60 * 1000 &&
          PassExpirationDays < 4) ||
        PassExpirationDays === 0;
      if (showReminder) dispatch(showPassExpiration());
    } catch (err) {
      console.log(err);
    }
  };
};
export const setTypedWrongPassword = payload => {
  return dispatch => {
    dispatch({ type: TYPED_WRONG_PASSWORD, payload });
  };
};
export const showPassExpiration = () => dispatch => {
  dispatch({ type: PASS_RESET, payload: true });
};

export const hidePassExpiration = () => dispatch => {
  const state = store.store.getState();
  const ptsUserID = state?.user?.userData?.user?.ptsUserID;
  const savedSetting = {
    [`changePassReminder${ptsUserID}`]: new Date().getTime()
  };
  saveLocalSettings(savedSetting);
  dispatch({ type: PASS_RESET, payload: false });
};

// Ncic
export const SET_NCIC_RAWDATA = 'NCIC/SET_RAWDATA';

export const validateCredentials = (
  username,
  password,
  isToken = false,
  secondFactor
) => {
  const client = store.store.getState().websocket.websocket;
  const { APP_DEFAULT_STATE } = store.store.getState().appInfo.appEnvInfo;

  return async dispatch => {
    try {
      let user;
      if (!client) {
        console.log('Connection not initiated yet');
      } else if (isToken) {
        user = await client.reAuthenticate();
      } else {
        if (!secondFactor) {
          user = await client.authenticate({
            strategy: 'local',
            username: username,
            password: password,
            AppName: 'eMobile',
            isMobile: isMobile
          });
        } else {
          user = await client.authenticate({
            strategy: 'local',
            username: username,
            password: password,
            AppName: 'eMobile',
            isMobile: isMobile,
            otp: secondFactor
          });
        }
      }
      if (!client) {
        console.log('Connection not initiated yet');
      }
      // console.log('user', user.user);
      const isUnitSettings = await client.service('cdr').find({
        query: { Path: 'AgencyBasedUnitSetting', CDRKey: user.user.AgencyID }
      });
      dispatch({
        type: SET_AGENCY_BASED_UNIT_SETTING,
        payload: isUnitSettings[0]?.ValueBoolean ? true : false
      });
      // console.log('isunitSettings', isunitSettings);
      let units = await client.service('availableunits').find({
        query: {
          username: user.user.Username,
          AgencyID: user.user.AgencyID,
          userSettings: isUnitSettings[0]?.ValueBoolean ? true : false
        }
      });
      if (units.length > 0) {
        const head = [];
        const tail = [];
        const assigned = units.map(unit => {
          const resources = unit.Resources ? JSON.parse(unit.Resources) : [];
          if (resources.length > 0) {
            const isAssigned = resources.filter(
              resource => resource.ptsChildID == user.user.ptsPersonID
            );
            if (isAssigned.length > 0) {
              head.push(unit);
            } else {
              tail.push(unit);
            }
          } else {
            tail.push(unit);
          }
        });
        const sorted = _.sortBy(head, ['ptsUnitID']);
        // console.log('sorted', sorted);
        units = [...sorted, ...tail];
      }
      dispatch({ type: USER_SET_USER_UNITS, payload: units });
      dispatch({ type: USER_SET_DATA, payload: user });
      dispatch({ type: USER_SECOND_FACTOR, payload: false });
      if (APP_DEFAULT_STATE !== 'MS') {
        const rid = user.user.RID;
        const ori = user.user.ORI;
        dispatch(saveNcicCredentials(rid, ori));
      }
      const jsonSettings =
        user?.user?.UserSettings && JSON.parse(user.user.UserSettings);
      const mobileSettings =
        jsonSettings &&
        jsonSettings.filter(st => st.SettingApp?.toLowerCase() == 'mobile');

      if (mobileSettings && mobileSettings.length > 0) {
        const settings =
          mobileSettings[0]?.ptsUserSettingID &&
          JSON.parse(mobileSettings[0].JsonValue);
        if (mobileSettings[0]?.ptsUserSettingID) {
          const {
            themeMode,
            alertTone,
            helpBar,
            isFormat24,
            mapDefaultView,
            quickSearchType,
            showZonesOnMap,
            viewType,
            searchView
          } = settings;
          // console.log('settings', settings);
          dispatch(setHelpBar(helpBar));
          if (viewType) {
            dispatch(setViewType(viewType));
          }
          if (searchView) {
            dispatch(setSearchView(searchView));
          }
          dispatch(setAlertTone(alertTone));
          dispatch(setMode(themeMode));

          if (mapDefaultView) {
            const { center, zoom } = mapDefaultView;
            dispatch({ type: SET_MAP_DEFAULT_VIEW, payload: { center, zoom } });
          }
          if (showZonesOnMap) {
            dispatch(setShowZonesOnMap(showZonesOnMap));
          }
        }
      }

      dispatch({ type: USER_IS_PASSWORD_VALID, payload: true });
      dispatch({ type: SET_NCIC_RAWDATA, payload: '' });
      if (units.length === 1) {
        dispatch({ type: USER_SET_USER_UNIT, payload: units[0] });

        dispatch({ type: USER_SET_AUTHENTICATED, payload: true });
        const service = client.service('cmplx-units');
        service.timeout = 20000;
        await service.update(units[0].ptsUnitID, {
          login: 'login'
        });
        const histories = await getUnitHistory(units[0].ptsUnitID);
        dispatch({ type: SET_UNIT_HISTORY, payload: histories });
      }
      if (units.length === 0) {
        dispatch({ type: USER_SET_USER_UNITS, payload: [] });
        dispatch({ type: USER_SET_DATA, payload: null });
        dispatch({ type: USER_IS_PASSWORD_VALID, payload: false });
        client.logout();
        dispatch(
          enqueueSnackbar({
            message:
              'There are no units assigned. Please contact administrator and try again.',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'warning',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            }
          })
        );
      }
    } catch (error) {
      console.log(error);
      if (!error.data) {
        const { code, message } = error;
        switch (code) {
          case 408:
            dispatch(
              notify(
                'It takes too long to connect to the server. Please check your connection and try again.',
                'warning'
              )
            );
            break;
          case 401:
            if (message) {
              // console.log('here 401');
              dispatch(notify(message, 'error'));
              dispatch({ type: TYPED_WRONG_PASSWORD, payload: true });
            } else {
              dispatch(notify('Login failed. Please try again.', 'error'));
            }
            break;
          default:
            if (message) {
              dispatch(notify(message, 'error'));
            } else {
              dispatch(
                notify(
                  `Unknown error ${code}. Please check you connection and try again.`,
                  'error'
                )
              );
            }
        }
        dispatch({ type: USER_IS_PASSWORD_VALID, payload: false });
        dispatch({ type: USER_SET_AUTHENTICATED, payload: false });
      } else {
        if (error.data.secondFactor) {
          dispatch(
            enqueueSnackbar({
              message: 'Authentication token has been sent (email)',
              options: {
                key: new Date().getTime() + Math.random(),
                variant: 'warning',
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center'
                }
              }
            })
          );
          dispatch({ type: USER_IS_PASSWORD_VALID, payload: false });
          dispatch({ type: USER_SET_AUTHENTICATED, payload: false });
          dispatch({ type: USER_SECOND_FACTOR, payload: true });
        } else {
          dispatch(
            notify(
              'It takes too long to connect to the server. Please check your connection and try again.',
              'warning'
            )
          );
        }
      }
    }
  };
};

export const setUserUnitAndLogin = unit => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    dispatch({ type: USER_SET_USER_UNIT, payload: unit });
    dispatch({ type: USER_SET_AUTHENTICATED, payload: true });
    dispatch({ type: SET_EVENT_UPDATE_COUNTER, payload: 0 });
    dispatch({ type: SET_UNIT_UPDATE_COUNTER, payload: 0 });
    dispatch({ type: SET_NCIC_RAWDATA, payload: '' });
    const service = client.service('cmplx-units');
    service.timeout = 20000;
    await service.update(unit.ptsUnitID, {
      login: 'login'
    });
    const histories = await getUnitHistory(unit.ptsUnitID);
    dispatch({ type: SET_UNIT_HISTORY, payload: histories });
  };
};

export const getAvailableUserUnits = user => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const units = await client.service('availableunits').get(user);
      dispatch({ type: USER_SET_USER_UNITS, payload: units });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message:
            'Something went wrong when retrieving user units. Please try again.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          }
        })
      );
    }
  };
};

export const setUserAuthenticated = state => {
  const client = store.store.getState().websocket.websocket;
  const myUnit = store.store.getState().user.userUnit;
  return async dispatch => {
    if (client) {
      if (state === false) {
        const service = client.service('cmplx-units');
        service.timeout = 20000;
        try {
          await client.service('authenticate').remove('eMobile');
          await service.update(myUnit.ptsUnitID, {
            login: 'logout'
          });
          dispatch({ type: USER_SET_USER_UNIT, payload: 0 });
          client.logout();
        } catch (error) {
          client.logout();
        }
      }
    }
    dispatch({ type: SET_EVENT_UPDATE_COUNTER, payload: 0 });
    dispatch({ type: SET_UNIT_UPDATE_COUNTER, payload: 0 });
    dispatch({ type: USER_SET_AUTHENTICATED, payload: state });
  };
};

export const setIsPasswordValid = state => {
  return dispatch => {
    dispatch({ type: USER_IS_PASSWORD_VALID, payload: state });
  };
};

export const setAllPermission = () => {
  const user = store.store.getState().user;
  const permissions = user?.userData?.user.Permissions;
  return dispatch => {
    const allpermissions = permissions ? JSON.parse(permissions) : [];
    dispatch({ type: SET_ALL_PERMISSIONS, payload: allpermissions });
  };
};

export const setUserData = data => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    if (data !== null) {
      const _unit = store.store.getState().user.userUnit;
      if (_unit !== null) {
        try {
          const unit = await client.service('cmplx-units').get(_unit.ptsUnitID);
          dispatch({ type: USER_SET_USER_UNIT, payload: unit[0] });
          const histories = await getUnitHistory(unit[0].ptsUnitID);
          dispatch({ type: SET_UNIT_HISTORY, payload: histories });
        } catch (error) {
          console.log(error);
        }
      }
    }
    dispatch({ type: USER_SET_DATA, payload: data });
  };
};

export const setUserUnits = units => {
  return dispatch => {
    dispatch({ type: USER_SET_USER_UNITS, payload: units });
  };
};

export const setUserUnit = unit => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    if (unit) {
      dispatch({ type: USER_SET_USER_UNIT, payload: unit });
      const histories = await getUnitHistory(unit.ptsUnitID);
      dispatch({ type: SET_UNIT_HISTORY, payload: histories });
    } else {
      const unitID = store.store.getState().user.userUnit.ptsUnitID;
      try {
        const unit = await client.service('cmplx-units').get(unitID);
        dispatch({ type: USER_SET_USER_UNIT, payload: unit[0] });
        const histories = await getUnitHistory(unit[0].ptsUnitID);
        dispatch({ type: SET_UNIT_HISTORY, payload: histories });
      } catch (error) {
        console.log(error);
      }
    }
  };
};
export const getUnitHistory = async unitId => {
  const client = store.store.getState().websocket.websocket;

  if (client && unitId) {
    try {
      const historyService = client.service('unit-history');
      const histories = await historyService.find({
        query: unitId
      });

      return histories || [];
    } catch (err) {
      console.log('err', err);
    }
  }
};
export const newUserLocation = location => {
  const state = store.store.getState();
  const client = state.websocket.websocket;
  const myUnit = state.user.userUnit;

  const unitsCoords = state.map.unitsCoordinates;
  // console.log('myUnit', myUnit);

  return async dispatch => {
    // Make API call to store location in database
    if (client && myUnit && myUnit.ptsUnitID) {
      try {
        const service = client.service('unit-location');
        service.timeout = 20000;

        if (
          myUnit.UnitLatitudeDegree !== Math.abs(location.latitude) ||
          myUnit.UnitLongitudeDegree !== Math.abs(location.longitude)
        ) {
          await service.patch(myUnit.ptsUnitID, {
            LatitudeSign: location.latitude < 0 ? '-' : '+',
            LongitudeSign: location.longitude < 0 ? '-' : '+',
            LatitudeDegree: Math.abs(location.latitude),
            LongitudeDegree: Math.abs(location.longitude)
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    dispatch({ type: USER_SET_LOCATION, payload: location });
  };
};

export const newUserLocationFromChannel = location => {
  const obj = {
    ...location,
    latitude: parseFloat(location.LatitudeSign + location.LatitudeDegree),
    longitude: parseFloat(location.LongitudeSign + location.LongitudeDegree)
  };
  return async dispatch => {
    dispatch({ type: USER_SET_LOCATION, payload: obj });
  };
};
export const newUserLocationForImprovedChannel = location => {
  const obj = {
    ...location,
    latitude: location.lat,
    longitude: location.lng
  };
  return async dispatch => {
    dispatch({ type: USER_SET_LOCATION, payload: obj });
  };
};
export const setIsWarningAccepted = state => {
  return dispatch => {
    dispatch({ type: USER_SET_WARNING_ACCEPTED, payload: state });
  };
};

export const setSecondFactor = state => {
  return dispatch => {
    dispatch({ type: USER_SECOND_FACTOR, payload: state });
  };
};
export const CheckPermission = id => {
  const permissions = store.store.getState().user.allPermissions;

  if (permissions) {
    const ncicp = permissions?.filter(p => p.ptsComponentID == id);
    if (ncicp.length > 0 && ncicp[0].Permissions == 1) {
      return true;
    } else {
      return true;
    }
  } else {
    return true;
  }
};
export const saveUserSettings = savedSetting => {
  const state = store.store.getState();
  const client = state.websocket.websocket;
  const user = state.user.userData.user;
  const alertTone = state.settings.alertTone;
  const helpBar = state.settings.helpBar;
  const searchView = state.settings.searchView;
  const viewType = state.settings.viewType;
  const themeMode = state.theme.mode;
  const mapDefaultView = state.settings.mapDefaultView;
  const showZonesOnMap = state.settings.showZonesOnMap;

  return async dispatch => {
    const settings = {
      alertTone,
      helpBar,
      viewType,
      themeMode,
      mapDefaultView,
      showZonesOnMap,
      searchView
    };
    const jsonValue = JSON.stringify(settings);

    try {
      if (!client) {
        return;
      }
      dispatch(showSpinner());
      const service = client.service('user-settings');
      service.timeout = 20000;
      // console.log('usr', user);
      let response;
      const exist = await service.find({
        query: {
          ptsUserID: user.ptsUserID,
          ApplicationName: 'Mobile',
          IsDeleted: false
        }
      });
      // console.log('exist', exist);
      if (exist.data.length > 0) {
        response = await service.patch(exist.data[0].ptsUserSettingID, {
          JsonValue: jsonValue
        });
      } else {
        // console.log('coming', jsonValue);
        response = await service.create({
          ptsUserID: user.ptsUserID,
          ApplicationName: 'Mobile',
          JsonValue: jsonValue
        });
      }
      // console.log('response', response);

      // const isExist = service.get();
    } catch (err) {
      console.log('error', err);
    } finally {
      dispatch(hideSpinner());
    }
  };
};
export const setUnitSelected = value => {
  return dispatch => {
    dispatch({ type: SET_UNIT_SELECTED, payload: value });
  };
};
export default function reducer(
  state = {
    // User
    isAuthenticated: false,
    secondFactor: false,
    isPasswordValid: false,
    isTypedWrongPassword: false,
    isWarningAccepted: false,
    userUnits: [],
    userUnit: null,
    userData: null,
    location: null,
    passExpirationDays: null,
    showPassChange: false,
    allPermissions: [],
    isUnitSeleteted: true
  },
  action
) {
  switch (action.type) {
    // User
    case USER_SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload
      };
    case USER_SECOND_FACTOR:
      return {
        ...state,
        secondFactor: action.payload
      };
    case SET_ALL_PERMISSIONS:
      return {
        ...state,
        allPermissions: action.payload
      };
    case USER_SET_USER_UNITS:
      return {
        ...state,
        userUnits: action.payload
      };
    case USER_SET_DATA:
      return {
        ...state,
        userData: action.payload
      };
    case USER_IS_PASSWORD_VALID:
      return {
        ...state,
        isPasswordValid: action.payload
      };
    case USER_SET_USER_UNIT:
      return {
        ...state,
        userUnit: { ...state.userUnit, ...action.payload }
      };
    case USER_SET_LOCATION:
      return {
        ...state,
        location: action.payload
      };
    case USER_SET_WARNING_ACCEPTED:
      return {
        ...state,
        isWarningAccepted: action.payload
      };
    case PASS_EXPIRATION:
      return {
        ...state,
        passExpirationDays: action.payload
      };
    case SET_UNIT_SELECTED:
      return {
        ...state,
        isUnitSeleteted: action.payload
      };
    case PASS_RESET:
      return {
        ...state,
        showPassChange: action.payload
      };
    case SET_UNIT_HISTORY:
      return {
        ...state,
        userUnit:
          state.userUnit !== null
            ? { ...state.userUnit, UnitStatuses: action.payload }
            : null
      };
    case TYPED_WRONG_PASSWORD:
      return {
        ...state,
        isTypedWrongPassword: action.payload
      };
    default:
      break;
  }
  return state;
}
