import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closePeopleCredentials } from 'reducers/DialogsReducer';
import Dialog from '../Dialog';
import { gridStyle, useError } from 'utils/formStyles';
import { getService } from 'reducers/service';
import Dictionary from '../Dictionary';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { formatSaveData } from 'utils/formStyles';
import TextField2 from '../TextField2';

import { notify } from 'reducers/NotifierReducer';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  fileInfo: {
    minHeight: 35,
    marginBottom: 16,
    '& img': {
      maxHeight: 24,
      borderRadius: 3,
      marginRight: 8
    }
  },
  item: gridStyle(300, 500),
  txtField: {
    minWidth: 350
  }
}));

function PeopleCredentialsDialog(props) {
  const classes = useStyles();
  const { data } = props;
  const [Type, setType] = useState('');
  const [Number, setNumber] = useState('');
  const [State, setState] = useState(null);
  const [errors, setErrors] = useState();
  const dictionary = useSelector(state => state.codes.dictionary);

  const { statesCodes } = dictionary;
  const dispatch = useDispatch();
  const { err, delErr, isValidAndChanged, formChanged } = useError(
    errors,
    setErrors
  );

  const userName = useSelector(state => state.user.userData.user.Username);

  useEffect(() => {
    if (data.ptsCredentialID) {
      const { ptsCredentialID, Type, ptsPersonID, Number, State } = data;
      setType(Type);
      setNumber(Number);

      const credState = statesCodes.find(s => s.Code === State);
      setState(credState || null);
    }

    // eslint-disable-next-line
  }, []);

  const close = () => {
    dispatch(closePeopleCredentials());
  };

  const save = async () => {
    const formData = formatSaveData({ Type, Number, State });
    try {
      props.showSpinner();
      const service = getService('record-person-credentials');
      if (data.ptsPersonID && data.ptsCredentialID) {
        const res = await service.patch(data.ptsCredentialID, {
          ...formData,
          UpdatedBy: userName
        });
        dispatch(notify('Credential updated', 'success'));
      } else {
        const res = await service.create({
          ...formData,
          ptsPersonID: data.ptsPersonID,
          CreatedBy: userName
        });
        dispatch(notify('Credential Created', 'success'));
      }
    } catch (err) {
      props.handleError(err);
    } finally {
      close();
      props.hideSpinner();
    }
  };

  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={save}
          disabled={!isValidAndChanged()}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderType = () => {
    const onChange = (ev, val) => {
      setType(val);
      formChanged();
    };
    const error = err(Type, 'Type');
    return (
      <Dictionary
        className={classes.item}
        options="CredentialTypes"
        onChange={onChange}
        value={Type}
        label="Type"
        error={error}
        compact="true"
      />
    );
  };

  const renderInfo = () => {
    const onChange = (ev, val) => {
      setNumber(val);
      formChanged();
    };
    const error = err(Number, 'Credential Number');
    return (
      <TextField2
        className={classes.item}
        label="Info"
        value={Number}
        onChange={onChange}
        max={100}
        error={error}
        disabled={!Type}
        compact="true"
      />
    );
  };

  const renderState = () => {
    const onChange = (ev, val) => {
      setState(val);
      // formChanged();
    };

    return (
      <Autocomplete
        id="state"
        loading={true}
        size="small"
        key="sate"
        getOptionSelected={(option, value) => option.Code === value.Code}
        value={State}
        onChange={(event, newValue) => {
          setState(newValue);
        }}
        autoHighlight
        autoSelect
        options={[{ Code: '', Description: '' }, ...statesCodes] || []}
        getOptionLabel={option => option.Code || ''}
        renderOption={option =>
          (
            <>
              <b>{option.Code}</b> - {option.Description}
            </>
          ) || '-'
        }
        //   style={{ width: '49%' }}
        renderInput={params => (
          <TextField {...params} variant="outlined" label="State" />
        )}
      />
    );
  };

  return (
    <Dialog
      toolbar
      onClose={close}
      title="Edit Credential"
      actions={renderActions()}>
      {renderType()}
      {renderInfo()}
      {renderState()}
    </Dialog>
  );
}

export default connect(null, {
  showSpinner,
  hideSpinner,
  handleError,
  notifyDataUpdate
})(PeopleCredentialsDialog);
