/* eslint-disable no-restricted-globals */
import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { Draggable } from 'react-beautiful-dnd';
import ClickNHold from 'react-click-n-hold';

import { makeStyles } from '@material-ui/core/styles';

import {
  setEventInfowindow,
  setEventInfowindowTab,
  actualUnit
} from '../../reducers/MapReducer';
import { pinEvent, toggleExpanded } from '../../reducers/EventsReducer';
import {
  setEventsDrawerMobileState,
  setUnitsDrawerMobileState
} from '../../reducers/UiReducer';

import { getEventColor } from '../../utils/uiUtils';
import { displayDateTime } from 'reducers/TimeReducer';

const avatarWidth = 44;
const iconWidth = 35;

const mapActions = {
  notes: 0,
  dispositions: 2,
  sops: 6,
  attachments: 3,
  cases: 5
};

const useStyles = makeStyles(theme => ({
  eventCard: {
    borderWidth: '5px',
    cursor: 'pointer'
  },
  listItem: {
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  avatar: {
    flex: `0 0 ${avatarWidth}px`,
    width: avatarWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    marginBottom: 10,
    '& > div': {
      boxSizing: 'border-box',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      background: '#dedfe3',
      borderRadius: '15%',
      width: 35,
      height: 35,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.2em',
      color: '#000',
      fontWeight: 500,
      textTransform: 'uppercase',
      padding: 4
    }
  },
  icon: {
    flex: `0 0 ${iconWidth}px`,
    textAlign: 'center',
    '& > div': {
      height: 20,
      width: 20,
      display: 'inline-block'
    }
  },
  button: {
    border: '1px solid #000',
    borderRadius: '7px',
    width: '40px',
    minWidth: '40px',
    height: '40px',
    marginRight: '10px'
  }
}));

const EventItem = props => {
  const {
    event,
    index,
    pinEvent,
    toggleExpanded,
    setEventInfowindow,
    eventInfowindow,
    setEventInfowindowTab,
    setEventsDrawerMobileState,
    expanded,
    colors,
    setUnitInfowindow,
    actualUnit
  } = props;

  const classes = useStyles();

  const handleUnitClick = (e, unit) => {
    e.stopPropagation();
    actualUnit(unit);
  };

  const onEventClick = event => {
    setEventsDrawerMobileState(false);
    setEventInfowindow(true, event);
  };

  const onEventHold = (e, event) => {
    console.log(event);
  };

  let boxShadow =
    'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px';
  if (
    eventInfowindow.open === true &&
    eventInfowindow.data &&
    eventInfowindow.data.ptsEventID === event.ptsEventID
  ) {
    boxShadow = '4px 0 0 -1px #0153a3';
  }

  return (
    <Draggable
      key={event.ptsEventID}
      draggableId={event.ptsEventID.toString()}
      index={index}>
      {provided => (
        <div
          style={{ position: 'relative' }}
          ref={provided.innerRef}
          {...provided.draggableProps}>
          <ClickNHold time={1} onClickNHold={e => onEventHold(e, event)}>
            <div
              onClick={() => onEventClick(event)}
              className={clsx(
                'MuiPaper-root',
                'MuiPaper-rounded',
                'card-box',
                'card-border-top',
                'card-border-bottom',
                'mb-2',
                'pb-1',
                'badge-wrapper',
                classes.eventCard
              )}
              style={{
                borderColor: getEventColor(event, colors),
                boxShadow: boxShadow
              }}>
              <div {...provided.dragHandleProps}>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-between',
                    padding: '5px 5px 0px 5px'
                  }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ lineHeight: '15px', paddingRight: '5px' }}>
                      <b>{event.CallTypeDescription}</b>
                    </div>
                    <div style={{ lineHeight: '15px' }}>
                      {event.FullLocationAddress !== null && (
                        <span>
                          <small>
                            <b>{event.FullLocationAddress}</b>
                          </small>
                        </span>
                      )}
                      {event.LocationDescription !== null && (
                        <span>
                          <small>
                            <b>{event.LocationDescription}</b>
                          </small>
                        </span>
                      )}
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ lineHeight: '15px' }}>
                      <small>{displayDateTime(event.ReceiveDate)}</small>
                    </div>
                    <div
                      className="badge"
                      style={{
                        background: getEventColor(event, colors),
                        maxWidth: '90px',
                        marginLeft: '5px'
                      }}>
                      {event.Status}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ClickNHold>
        </div>
      )}
    </Draggable>
  );
};

const mapStateToProps = state => ({
  eventInfowindow: state.map.eventInfowindow,
  colors: state.codes.colorsCodes
});

export default connect(mapStateToProps, {
  setEventInfowindow,
  pinEvent,
  toggleExpanded,
  setEventsDrawerMobileState,
  setUnitsDrawerMobileState,
  setEventInfowindowTab,
  actualUnit
})(EventItem);
