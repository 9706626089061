import store from '../config/configureStore';
import { setUserAuthenticated, setUserData, setUserUnit } from './UserReducer';
import _ from 'underscore';
import { getUnits } from '../reducers/UnitsReducer';
import { SET_EVENT_INFOWINDOW } from './MapReducer';
import { calculateDistance } from '../utils/functions';
import { SET_MY_EVENTS } from './SettingsReducer';
import { getService } from './service';

export const SET_EVENTS = 'EVENTS/SET_EVENTS';
export const IS_EMPTY = 'EVENTS/IS_EMPTY';
// export const SET_MY_EVENTS = 'EVENTS/SET_MY_EVENTS';
export const SET_EVENTS_SORT_BY = 'EVENTS/SET_EVENTS_SORT_BY';
export const SET_EVENTS_SORT_ORDER = 'EVENTS/SET_EVENTS_SORT_ORDER';
export const SET_EVENTS_FILTER_KEYWORD = 'UNITS/SET_EVENTS_FILTER_KEYWORD';
export const SET_EVENTS_INITIAL_LOAD = 'EVENTS/SET_MY_EVENTS';
export const SET_EVENT_DISPATCHED_NOTIFICATION =
  'EVENTS/SET_EVENT_DISPATCHED_NOTIFICATION';
export const SET_REORDER_EVENTS = 'EVENTS/SET_REORDER_EVENTS';
export const SET_EVENT_UPDATE_COUNTER = 'EVENTS/SET_EVENT_UPDATE_COUNTER';

// Actions
export const getEvents = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('cmplx-events');
      service.timeout = 30000;
      const events = await service.find();
      dispatch(processEvents(events));
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const setEvents = events => {
  return async dispatch => {
    dispatch(processEvents(events.eventActionType ? events : [...events]));
  };
};
export const getEventUnitHistory = ptsEventID => {
  const service = getService('event-history');
  return service.get(ptsEventID, {
    query: { maxResults: 100, origin: 'mobile' }
  });
};
export const updateEvents = () => {
  let _events = [...store.store.getState().events.events];
  return async dispatch => {
    dispatch(processEvents(_events));
  };
};

export const processEvents = events => {
  return async dispatch => {
    let eventsToPush = [...store.store.getState().events.events];
    let eventUpdatesCounter = store.store.getState().events.eventUpdatesCounter;
    if (events.eventActionType) {
      const event = events;
      if (
        eventUpdatesCounter !== 0 &&
        event.eventUpdatesCounter !== eventUpdatesCounter + 1
      ) {
        dispatch(getEvents());
      } else {
        if (events.eventActionType == 'remove') {
          eventsToPush = eventsToPush.filter(
            item => item.ptsEventID !== event.ptsEventID
          );
        } else {
          const _e = eventsToPush.findIndex(
            e => e.ptsEventID === event.ptsEventID
          );
          if (_e === -1) {
            eventsToPush.push(event);
          } else {
            eventsToPush[_e] = { ...eventsToPush[_e], ...event };
          }
        }
      }
      dispatch({
        type: SET_EVENT_UPDATE_COUNTER,
        payload: event.eventUpdatesCounter
      });
    } else {
      let _events = [...events];
      // Push or update events in a state
      _events.forEach(event => {
        const _e = eventsToPush.findIndex(
          e => e.ptsEventID === event.ptsEventID
        );
        if (_e === -1) {
          eventsToPush.push(event);
        } else {
          eventsToPush[_e] = { ...eventsToPush[_e], ...event };
        }
      });

      // Remove not existing events
      eventsToPush.forEach((event, index) => {
        const _e = _events.findIndex(e => e.ptsEventID === event.ptsEventID);
        if (_e === -1) eventsToPush.splice(index, 1);
      });
    }
    eventsToPush = calculateEventDistance(eventsToPush);
    eventsToPush = filterByAgencies(eventsToPush);
    eventsToPush = sortEvents(eventsToPush);
    dispatch({ type: SET_EVENTS, payload: eventsToPush });
    dispatch(displayEventNotification(eventsToPush));
    dispatch(updateEventInfoWindow(eventsToPush));
    if (eventsToPush.length == 0) {
      dispatch({ type: IS_EMPTY, payload: true });
    } else {
      dispatch({ type: IS_EMPTY, payload: false });
    }
  };
};

export const updateEventInfoWindow = events => {
  const infoWindow = store.store.getState().map.eventInfowindow;
  return async dispatch => {
    if (infoWindow.open === true && infoWindow.data) {
      const ptsEventID = infoWindow.data.ptsEventID;
      const event = events.find(e => e.ptsEventID === ptsEventID);
      dispatch({
        type: SET_EVENT_INFOWINDOW,
        payload: { open: true, data: event }
      });
    }
  };
};

export const setEventDispatchedNotification = (state, event) => {
  return async dispatch => {
    dispatch({
      type: SET_EVENT_DISPATCHED_NOTIFICATION,
      payload: { available: state, data: event }
    });
  };
};

export const setEventNote = (event, note) => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('cmplx-events');
      service.timeout = 20000;
      await service.patch(event.ptsEventID, {
        event: event,
        note: note
      });
      dispatch(getEvents());
    } catch (error) {
      console.log(error);
    }
  };
};

export const setEventDispositions = (event, dispositions) => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('event-dispositions');
      service.timeout = 20000;
      await service.create(dispositions);
      dispatch(getEvents());
    } catch (error) {
      console.log(error);
    }
  };
};

export const setEventStatus = (event, status) => {
  const client = store.store.getState().websocket.websocket;
  const myUnit = store.store.getState().user.userUnit;
  const myEvents = store.store.getState().settings.myEvents;
  let _myEvents = [...myEvents];
  return async dispatch => {
    try {
      const service = client.service('cmplx-events');
      service.timeout = 20000;
      if (event && event?.ptsEventID) {
        await handleCaseIdCreation(event, status);
      }

      await service.patch(event.ptsEventID, {
        event: event,
        unit: myUnit,
        status: status,
        plate: null,
        plateState: null,
        oln: null,
        olnState: null,
        statusNotes: null,
        unitName: myUnit.Unit,
        mileage: null
      });
      _myEvents.push(event);
      const mEv = _myEvents.map(ev => {
        return { ptsEventID: ev.ptsEventID };
      });
      dispatch({ type: SET_MY_EVENTS, payload: mEv });
      dispatch(getEvents());
      dispatch(getUnits());
      dispatch(setUserUnit());
    } catch (error) {
      console.log(error);
    }
  };
};
export const handleCaseIdCreation = async (eventData, status) => {
  const client = store.store.getState().websocket.websocket;
  const myUnit = store.store.getState().user.userUnit;
  const eventList = store.store.getState().events.events;
  // const actions = store.store.getState().data.normalizedUnitActionCodesCategory;
  const actionCodes = store.store.getState().data.normalizedUnitActionCodes;
  // console.log('actionCodes', actionCodes);
  const mapedStatus = actionCodes[status]?.Category?.toUpperCase() || null;

  // console.log('mapedStatus', mapedStatus);
  let event = '';
  // console.log('status', status);
  // console.log('eventData', eventData);
  if (typeof eventData !== 'object') {
    event = eventList.find(item => item.ptsEventID === eventData);
  } else {
    event = eventData;
  }
  // console.log('Filterevent', event);
  if (
    mapedStatus === 'DISPATCH' ||
    mapedStatus === 'ENROUTE' ||
    mapedStatus === 'ARRIVED' ||
    mapedStatus === 'COMPLETED'
  ) {
    try {
      const agencyService = client.service('settings-agency-cad');
      agencyService.timeout = 200000;
      const agencyCaseIdSettings = await agencyService.get(myUnit.AgencyID);

      const caseIdSetings = agencyCaseIdSettings.find(item =>
        item.Path.includes('SetCaseID')
      );
      const statusSetForCase = caseIdSetings?.ValueString || false;
      if (
        statusSetForCase &&
        (statusSetForCase === 'Acknowledged' ||
          statusSetForCase === 'Dispatched')
      ) {
        if (
          (statusSetForCase === 'Acknowledged' && mapedStatus !== 'DISPATCH') ||
          statusSetForCase === 'Dispatched'
        ) {
          const caseIds =
            (event?.CaseIds && JSON.parse(event.CaseIds || '[]')) || [];

          const isCaseExist = caseIds.filter(
            caseid => caseid.AgencyId === myUnit?.AgencyID
          );

          if (isCaseExist.length < 1) {
            const cadService = client.service('cad');
            const data = {
              ptsEventID: event.ptsEventID,
              AgencyID: myUnit.AgencyID
            };
            const result = await cadService.create({
              type: 'add-caseid',
              data
            });
          }
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  }
};
export const setEventAttachment = (eventId, fileInfo) => {
  const { file, Description } = fileInfo;
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function() {
      const service = client.service('upload-attachment');
      service.timeout = 30000;
      await service.create({
        parentType: 'Event',
        ptsParentID: eventId,
        fileName: file.name,
        description: Description,
        fileType: file.name.split('.')[1],
        data: reader.result.split(',')[1]
      });

      dispatch(getEvents());
    };
    reader.onerror = function(error) {
      console.log('Error: ', error);
    };
  };
};

export const removeEventDisposition = (eventId, disposition) => {
  const { AgencyID, Disposition } = disposition;
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    const service = client.service('event-dispositions');
    await service.remove(eventId, { query: { AgencyID, Disposition } });
    dispatch(getEvents());
  };
};

export const removeEventAttachment = attachmentId => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    const service = client.service('upload-attachment');
    await service.remove(attachmentId);
    dispatch(getEvents());
  };
};

export const eventsSortBy = value => {
  const events = store.store.getState().events.events;
  return async dispatch => {
    dispatch({ type: SET_EVENTS_SORT_BY, payload: value });
    dispatch({ type: SET_EVENTS, payload: sortEvents(events) });
  };
};

export const eventsSortOrder = value => {
  const events = store.store.getState().events.events;
  return async dispatch => {
    dispatch({ type: SET_EVENTS_SORT_ORDER, payload: value });
    dispatch({ type: SET_EVENTS, payload: sortEvents(events) });
  };
};

export const eventsFilterKeywordChange = event => {
  const filter = event.target.value.toLowerCase();
  return async dispatch => {
    dispatch({ type: SET_EVENTS_FILTER_KEYWORD, payload: filter });
  };
};

export const eventsDragReorder = (destination, source, draggableId) => {
  const events = [...store.store.getState().events.events];
  return async dispatch => {
    let eventsToPush = [...events];
    let temp = eventsToPush[source.index];
    eventsToPush[source.index] = eventsToPush[destination.index];
    eventsToPush[destination.index] = temp;
    dispatch({ type: SET_EVENTS, payload: eventsToPush });
  };
};

export const pinEvent = event => {
  const events = [...store.store.getState().events.events];
  return async dispatch => {
    let eventsToPush = [...events];
    eventsToPush.forEach(eventMapped => {
      if (eventMapped.ptsEventID === event.ptsEventID) {
        if (!eventMapped.Pined) {
          eventMapped.Pined = !eventMapped.Pined;
          eventsToPush = [
            eventMapped,
            ...events.filter(item => item.ptsEventID !== event.ptsEventID)
          ];
        } else {
          eventMapped.Pined = !eventMapped.Pined;
        }
      }
    });
    eventsToPush = sortEvents(eventsToPush);
    dispatch({ type: SET_EVENTS, payload: eventsToPush });
  };
};

export const toggleExpanded = (event, fromEventDispatchWindow) => {
  let eventsToPush = [...store.store.getState().events.events];
  return async dispatch => {
    eventsToPush.map(_e => {
      if (_e.ptsEventID === event.ptsEventID) {
        _e.expanded = !_e.expanded;
        if (fromEventDispatchWindow) _e.expanded = true;
      }
    });
    dispatch({ type: SET_EVENTS, payload: eventsToPush });
  };
};

// Helpers
const filterByAgencies = events => {
  let filteredEvents = [];
  const agencies = store.store.getState().settings.agencies; //contain agencies setting;
  const zones = store.store.getState().settings.zones;

  [...events].forEach(event => {
    let pass = false;
    let pass2 = false;

    if (agencies !== null) {
      const caseIds = event.EventRouting
        ? JSON.parse(event.EventRouting)
        : null;

      if (caseIds !== null) {
        for (let i = 0; i < caseIds.length; i++) {
          if (agencies.some(e => e.AgencyID === caseIds[i].AgencyId)) {
            pass = true;
            break;
          }
        }
      }
    } else {
      if (event.EventRouting && event.EventRouting !== null) {
        if (JSON.parse(event.EventRouting) !== null) {
          pass = true;
        }
      }
    }
    if (zones) {
      let eventZone = event.zones;
      for (let i = 0; i < eventZone.length; i++) {
        if (zones.some(e => e.ZoneCode == eventZone[i].ZoneCode)) {
          pass2 = true;
          break;
        }
      }
    } else {
      pass2 = true;
    }

    if (pass && pass2) {
      filteredEvents.push(event);
    }
  });
  return filteredEvents;
};

const calculateEventDistance = events => {
  const userLocation = store.store.getState().user.location;
  events.forEach(event => {
    if (
      event.LatitudeDegree &&
      event.LongitudeDegree &&
      userLocation !== null
    ) {
      const lat1 =
        event.LatitudeSign === '-'
          ? event.LatitudeDegree * -1
          : event.LatitudeDegree;

      const lng1 =
        event.LongitudeSign === '-'
          ? event.LongitudeDegree * -1
          : event.LongitudeDegree;

      const lat2 = userLocation.latitude;
      const lng2 = userLocation.longitude;

      const distance = calculateDistance(lat1, lng1, lat2, lng2, 'M');
      event['distance'] = distance;
    }
  });
  return events;
};

const sortEvents = events => {
  let sortedEvents = [];
  let aEventsArray = [];
  if (!events) return [];
  aEventsArray = [...events];

  let onlyPinedEvents = aEventsArray.filter(event => event.Pined);
  let onlyNonPinedEvents = aEventsArray.filter(event => !event.Pined);

  const sortBy = store.store.getState().events.sortBy;
  const sortOrder = store.store.getState().events.sortOrder;
  if (sortOrder === 'DESC') {
    if (sortBy == 'UpdateDate') {
      sortedEvents = onlyNonPinedEvents.sort(function(a, b) {
        return (
          new Date(b.UpdateDate).getTime() - new Date(a.UpdateDate).getTime()
        );
      });
    } else {
      sortedEvents = _.chain(onlyNonPinedEvents)
        .sortBy('ptsEventID')
        .sortBy(sortBy)
        .value()
        .reverse();
    }
  }
  if (sortOrder === 'ASC') {
    if (sortBy == 'UpdateDate') {
      sortedEvents = onlyNonPinedEvents.sort(function(a, b) {
        return (
          new Date(a.UpdateDate).getTime() - new Date(b.UpdateDate).getTime()
        );
      });
    } else {
      sortedEvents = _.chain(onlyNonPinedEvents)
        .sortBy('ptsEventID')
        .sortBy(sortBy)
        .value();
    }

    // sortedEvents = _.sortBy(onlyNonPinedEvents, sortBy);
  }
  return [...onlyPinedEvents, ...sortedEvents];
};

const displayEventNotification = events => {
  const myUnit = store.store.getState().user.userUnit;
  const myEvents = store.store.getState().settings.myEvents;

  let _myEvents = [...myEvents];

  return async dispatch => {
    events.forEach(event => {
      if (event.assignedUnits.length > 0) {
        for (let i = 0; i < event.assignedUnits.length; i++) {
          let unit = event.assignedUnits[i];
          if (myUnit && unit.ptsUnitID === myUnit.ptsUnitID) {
            event.myStatus = unit.Path;
            const isIn = myEvents.find(e => e.ptsEventID === event.ptsEventID);
            if (!isIn) {
              _myEvents.push(event);
              dispatch(setUserUnit());
              dispatch(setEventDispatchedNotification(true, event));
            } else {
              dispatch(setEventDispatchedNotification(false, null));
            }
            break;
          } else {
            /*
          _myEvents = _myEvents.filter(
            e => e.ptsEventID !== event.ptsEventID
          );
          */
          }
        }
      } else {
        _myEvents = _myEvents.filter(e => e.ptsEventID !== event.ptsEventID);
      }
    });
    const mEv = _myEvents.map(ev => {
      return { ptsEventID: ev.ptsEventID };
    });
    dispatch({ type: SET_MY_EVENTS, payload: mEv });
  };
};
export const findPartyPerson = searchText => {
  const service = getService('cad');
  return service.get({
    type: 'find-party-person',
    data: { searchText }
  });
};

export const getPersonContactInfo = ptsPersonID => {
  const service = getService('cad');
  return service.get({
    type: 'get-person-contact-info',
    data: { ptsPersonID }
  });
};

export const getPartyPerson = ptsPersonID => {
  const service = getService('cad');
  return service.get({
    type: 'get-party-person',
    data: { ptsPersonID }
  });
};

export default function reducer(
  state = {
    // Events
    events: [],
    sortBy: 'EventID',
    sortOrder: 'DESC',
    filterKeyword: '',
    eventUpdatesCounter: 0,
    reorder: [],
    myEvents: [],
    isEmpty: false,
    initialLoad: true,
    eventDispatchedNotification: null
  },
  action
) {
  switch (action.type) {
    // Events
    case SET_EVENTS:
      return {
        ...state,
        events: action.payload
      };
    case IS_EMPTY:
      return {
        ...state,
        isEmpty: action.payload
      };

    // case SET_MY_EVENTS:
    //   return {
    //     ...state,
    //     myEvents: action.payload
    //   };
    case SET_EVENTS_INITIAL_LOAD:
      return {
        ...state,
        initialLoad: action.payload
      };
    case SET_EVENT_UPDATE_COUNTER:
      return { ...state, eventUpdatesCounter: action.payload };
    case SET_EVENT_DISPATCHED_NOTIFICATION:
      return {
        ...state,
        eventDispatchedNotification: action.payload
      };
    case SET_EVENTS_SORT_BY:
      return {
        ...state,
        sortBy: action.payload
      };
    case SET_EVENTS_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.payload
      };
    case SET_REORDER_EVENTS:
      return {
        ...state,
        reorder: action.payload
      };
    case SET_EVENTS_FILTER_KEYWORD:
      return {
        ...state,
        filterKeyword: action.payload
      };
    default:
      break;
  }
  return state;
}
