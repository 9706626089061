import React, { Fragment } from 'react';
import nl2br from 'react-newline-to-break';
import ReactHtmlParser from 'react-html-parser';
import Highlighter from 'react-highlight-words';
import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router';

import personimg from 'assets/images/person.png';

import ContextMenuComponent from 'global-components/ContextMenu';
const SearchResult = props => {
  const { data, searchText, parentClassName, fontSize, images } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  // let _data = nl2br(data);
  // console.log(_data);
  // data.replace(/(?:\r\n|\r|\n)/g, '<br />');
  // console.log(data);
  // let _data = ReactHtmlParser(data);
  // console.log(_data);

  let parsedImages;
  if (images && images.length > 0) {
    parsedImages = JSON.parse(images);
  }

  return (
    <div>
      <div className="py-2">
        {data.split('\n').map((item, key) => {
          let parsedData = ReactHtmlParser(item);
          if (parsedData.length === 1) {
            return (
              <span key={key}>
                {parentClassName == 'ncic-gun-result' ? (
                  <p className="px-3">
                    <Highlighter
                      highlightClassName={parentClassName}
                      searchWords={[searchText]}
                      autoEscape={true}
                      textToHighlight={parsedData[0]}
                      style={{ fontSize: fontSize + 'px' }}
                    />
                  </p>
                ) : (
                  <ContextMenuComponent
                    value={
                      <Highlighter
                        highlightClassName={parentClassName}
                        searchWords={[searchText]}
                        autoEscape={true}
                        textToHighlight={parsedData[0]}
                        style={{ fontSize: fontSize + 'px' }}
                      />
                    }
                    setActiveTab={props.setActiveTab}
                  />
                )}
              </span>
            );
          } else {
            return (
              <span key={key}>
                {parsedData}
                <br />
              </span>
            );
          }
        })}
      </div>
      {parsedImages && parsedImages.length > 0 &&
        <div>
          {parsedImages.map(i => {
            return (
              <div className="p-3">
                <img
                  alt="mugshot"
                  style={{ maxWidth: '400px' }}
                  className="img-fluid border-3 border-white"
                  src={`data:image/png;base64,${i.ImageData}`}
                />
              </div>
            )
          })}
        </div>
      }
    </div>
  );
};

export default SearchResult;
