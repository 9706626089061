import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  filter: {
    margin: '0 10px 10px',
    width: '200px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  filterDropDown: {
    margin: '0px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0px'
    }
  }
}));

const AlertTypeFilter = props => {
  const classes = useStyles();
  const [change, setChange] = useState(false);

  const handleFilterOlnStateChange = (event, value) => {
    props.setAlertType('');
    if (value) props.setAlertType(value);
  };

  useEffect(() => {
    setChange(!change);
  }, [props.selectedAlertType]);

  const typesArray = props.alertTypes.map(alertType => alertType.Code);

  return (
    <FormControl
      size="small"
      className={`${classes.filterDropDown} ${classes.filterDropDown}`}
      variant="outlined">
      <Autocomplete
        key={change}
        label="plate-state"
        autoSelect
        autoHighlight
        value={props.selectedAlertType ? props.selectedAlertType : ''}
        onChange={handleFilterOlnStateChange}
        options={['', ...typesArray]}
        getOptionLabel={option => option || ''}
        getOptionSelected={() => true}
        renderOption={option => option || '-'}
        renderInput={params => (
          <TextField
            {...params}
            size="small"
            className={classes.filter}
            label="Bulletin Type"
            variant="outlined"
          />
        )}
      />
    </FormControl>
  );
};

export default AlertTypeFilter;
