import SearchResult from 'global-components/NcicSearchComponents/SearchResult';
import ShortcutsFunctionality from 'global-components/ShortcutsFunctionality';
import React, { useState } from 'react';
import nl2br from 'react-newline-to-break';

function RawData(props) {
  const { data } = props;
  const [searchText, setSearchText] = useState('');
  const [fontSize, setFontSize] = useState(16);
  return (
    <div>
      {data !== '' && (
        <ShortcutsFunctionality
          value={data}
          parentClassName="ncic-gun-result"
          searchText={searchText}
          setSearchText={setSearchText}
          fontSize={fontSize}
          setFontSize={setFontSize}
        />
      )}
      {/* {generateRawData()} */}
      <SearchResult
        data={data}
        searchText={searchText}
        fontSize={fontSize}
        parentClassName="ncic-gun-result"
      />
    </div>
  );
}

export default RawData;
