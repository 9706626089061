import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import XGrid3 from '../../XGrid3';
import { displayDateTime } from 'reducers/TimeReducer';
import {
  getPersonDnaSamples,
  getPlaceByID,
  getPtsPerson
} from 'reducers/SearchReducer';
import QuickFilterSearch from '../../Search/components/QuickFilterSearch';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '../../Tooltip';
import Fab from '@material-ui/core/Fab';
import { showPersonDnaSample } from 'reducers/DialogsReducer';
// import { getFullPermissions } from 'reducers/PermissionsReducer';

const useStyles = makeStyles(theme => ({
  wrap: {
    padding: theme.spacing(3)
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1)
    }
  }
}));

const defaultColumns = [
  { field: 'DNASampleID', headerName: 'Sample ID' },
  {
    field: 'Taken',
    headerName: 'Taken Date',
    valueFormatter: params => displayDateTime(params.value)
  },
  'TakenBy',
  'Type',
  'Method',
  'Reason',
  'Status',
  'CrimeLab',
  {
    field: 'CrimeLabSentDateTime',
    headerName: 'Sent Date',
    valueFormatter: params => displayDateTime(params.value)
  },
  'Created',
  'CreatedBy',
  'Updated'
];

function DnaSamples(props) {
  const classes = useStyles();
  const { ptsPersonID, dataUpdate } = props;
  const [columns, setColumns] = useState(defaultColumns);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  // const perms = getFullPermissions('globals', 'People', 'any');
  // const canEditPerson = perms.Edit;

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (dataUpdate?.type === 'dna-sample') fetchData();
  }, [dataUpdate]);

  const fetchData = async () => {
    const samples = await getPersonDnaSamples(ptsPersonID);
    const processed = await process(samples);
    setRows(processed);
  };

  const process = async samples => {
    const processed = samples.map(async sample => {
      const { ptsCrimeLabPlaceID, ptsTakenByPersonID } = sample;
      if (ptsCrimeLabPlaceID) {
        const crimeLabPlace = await getPlaceByID(ptsCrimeLabPlaceID);
        if (crimeLabPlace) sample.CrimeLab = crimeLabPlace.PlaceName;
      }
      if (ptsTakenByPersonID) {
        const takenByPerson = await getPtsPerson(ptsTakenByPersonID);
        if (takenByPerson.length > 0)
          sample.TakenBy = takenByPerson[0].FullName;
      }
      return {
        ...sample,
        id: sample.ptsDNASampleID
      };
    });
    return Promise.all(processed);
  };

  const handleAdd = () => props.showPersonDnaSample(ptsPersonID);

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch
            rows={rows}
            columns={columns}
            setFilteredRows={setFilteredRows}
            style={{ marginTop: 0, paddingBottom: 14 }}
          />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Add Sample">
            <span>
              <Fab
                size="small"
                color="secondary"
                onClick={handleAdd}
                // disabled={!canEditPerson}
              >
                <AddIcon />
              </Fab>
            </span>
          </Tooltip>
        </div>
      </div>
      <XGrid3
        name="PeopleDnaSamples"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        setSelection={setSelection}
      />
    </div>
  );
}
const mapStateToProps = state => {
  return {
    dataUpdate: state.dataUpdate
  };
};
export default connect(mapStateToProps, { showPersonDnaSample })(DnaSamples);
