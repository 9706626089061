/* eslint-disable no-restricted-globals */
import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { Button, Tooltip, IconButton } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { Draggable } from 'react-beautiful-dnd';
import ClickNHold from 'react-click-n-hold';

import { makeStyles } from '@material-ui/core/styles';

import { IconContext } from 'react-icons/lib';
import { AiFillPushpin } from 'react-icons/ai';
import { AiOutlinePushpin } from 'react-icons/ai';

import EventIcon from '../../components/Map/EventIcon';
import AgencyIcon from '../../components/Map/AgencyIcon';

import {
  setEventInfowindow,
  setEventInfowindowTab,
  actualUnit
} from '../../reducers/MapReducer';
import { pinEvent, toggleExpanded } from '../../reducers/EventsReducer';
import {
  setEventsDrawerMobileState,
  setUnitsDrawerMobileState
} from '../../reducers/UiReducer';

import { getEventColor } from '../../utils/uiUtils';
import { getUnitColor } from '../../utils/uiUtils';
import { displayDateTime } from 'reducers/TimeReducer';

const avatarWidth = 44;
const iconWidth = 35;

const mapActions = {
  notes: 0,
  dispositions: 2,
  sops: 6,
  attachments: 3,
  cases: 5
};

const useStyles = makeStyles(theme => ({
  eventCard: {
    borderWidth: '5px',
    cursor: 'pointer'
  },
  listItem: {
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  avatar: {
    flex: `0 0 ${avatarWidth}px`,
    width: avatarWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    marginBottom: 10,
    '& > div': {
      boxSizing: 'border-box',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      background: '#dedfe3',
      borderRadius: '15%',
      width: 35,
      height: 35,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.2em',
      color: '#000',
      fontWeight: 500,
      textTransform: 'uppercase',
      padding: 4
    }
  },
  icon: {
    flex: `0 0 ${iconWidth}px`,
    textAlign: 'center',
    '& > div': {
      height: 20,
      width: 20,
      display: 'inline-block'
    }
  },
  button: {
    border: '1px solid #000',
    borderRadius: '7px',
    width: '40px',
    minWidth: '40px',
    height: '40px',
    marginRight: '10px'
  }
}));

const EventItem = props => {
  const {
    event,
    index,
    pinEvent,
    toggleExpanded,
    setEventInfowindow,
    eventInfowindow,
    setEventInfowindowTab,
    setEventsDrawerMobileState,
    expanded,
    colors,
    setUnitInfowindow,
    actualUnit
  } = props;

  const classes = useStyles();

  const handleUnitClick = (e, unit) => {
    e.stopPropagation();
    actualUnit(unit);
  };

  const onEventClick = event => {
    setEventsDrawerMobileState(false);
    setEventInfowindow(true, event);
  };

  const onEventHold = (e, event) => {
    console.log(event);
  };

  const onIconClick = (e, event) => {
    e.stopPropagation();
    pinEvent(event);
  };

  const onButtonClick = (e, action) => {
    e.stopPropagation();
    setEventsDrawerMobileState(false);
    setEventInfowindow(true, event);
    setEventInfowindowTab(mapActions[action]);
  };

  const onExpandClick = (e, event) => {
    e.stopPropagation();
    toggleExpanded(event);
  };

  let boxShadow =
    'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px';
  if (
    eventInfowindow.open === true &&
    eventInfowindow.data &&
    eventInfowindow.data.ptsEventID === event.ptsEventID
  ) {
    boxShadow = '4px 0 0 -1px #0153a3';
  }
  const cases = event.CaseIds !== null ? JSON.parse(event.CaseIds).length : 0;

  return (
    <Draggable
      key={event.ptsEventID}
      draggableId={event.ptsEventID.toString()}
      index={index}
      isDragDisabled={!event.Pined}>
      {provided => (
        <div
          style={{ position: 'relative' }}
          ref={provided.innerRef}
          {...provided.draggableProps}>
          <ClickNHold time={1} onClickNHold={e => onEventHold(e, event)}>
            <div
              onClick={() => onEventClick(event)}
              className={clsx(
                'MuiPaper-root',
                'MuiPaper-rounded',
                'card-box',
                'card-border-top',
                'card-border-bottom',
                'mb-2',
                'pb-1',
                'badge-wrapper',
                classes.eventCard
              )}
              style={{
                borderColor: getEventColor(event, colors),
                boxShadow: boxShadow
              }}>
              <div
                style={{
                  alignItems: 'end',
                  marginBottom: '2px',
                  borderBottom: 'none'
                }}
                className="card-header MuiPaper-root p-2">
                <div>
                  <Tooltip arrow title={event.CallTypeDescription}>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={ev => onIconClick(ev, event)}
                      className={classes.avatar}>
                      <div>
                        <EventIcon
                          CallType={event.CallType}
                          CallTypeDescription={event.CallTypeDescription}
                          color={getEventColor(event, colors)}
                        />
                      </div>
                    </div>
                  </Tooltip>
                  <IconButton
                    style={{ padding: '2px', marginLeft: '8px' }}
                    onClick={ev => {
                      onExpandClick(ev, event);
                    }}
                    aria-label="delete">
                    {expanded ? (
                      <ExpandLessIcon fontSize="medium" color="primary" />
                    ) : (
                      <ExpandMoreIcon fontSize="medium" color="primary" />
                    )}
                  </IconButton>
                </div>
                <div className="card-header--title">
                  <div
                    {...provided.dragHandleProps}
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      alignContent: 'flex-end',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}>
                    <div>
                      <div style={{ lineHeight: '15px' }}>
                        <b>{event.EventID}</b>
                      </div>
                      <div
                        style={{
                          lineHeight: '10px',
                          fontSize: '1rem'
                          // color: getEventColor(event, colors)
                        }}
                        className="pb-1 lead">
                        <small style={{ fontWeight: '500' }}>
                          {event.CallTypeDescription}
                        </small>
                      </div>
                      <div style={{ lineHeight: '10px' }}>
                        <small>{displayDateTime(event.UpdateDate)}</small>
                      </div>
                    </div>
                    <div>
                      {event.Pined && (
                        <div
                          onClick={ev => onIconClick(ev, event)}
                          style={{
                            zIndex: '2',
                            right: '-2px',
                            top: '1px',
                            backgroundColor: 'transparent',
                            cursor: 'pointer'
                          }}
                          className="badge badge-first badge-position badge-position--top-right"
                          title="Badge top left">
                          <IconContext.Provider
                            value={{
                              color: getEventColor(event, colors),
                              size: '2em'
                            }}>
                            <AiFillPushpin />
                          </IconContext.Provider>
                        </div>
                      )}
                      {!event.Pined && (
                        <div
                          onClick={ev => onIconClick(ev, event)}
                          style={{
                            zIndex: '2',
                            right: '-2px',
                            top: '1px',
                            backgroundColor: 'transparent',
                            cursor: 'pointer',
                            opacity: '0.5'
                          }}
                          className="badge badge-first badge-position badge-position--top-right"
                          title="Badge top right">
                          <IconContext.Provider
                            value={{
                              color: getEventColor(event, colors),
                              size: '2em'
                            }}>
                            <AiOutlinePushpin />
                          </IconContext.Provider>
                        </div>
                      )}
                      <div
                        className="badge"
                        style={{
                          background: getEventColor(event, colors),
                          width: '90px'
                        }}>
                        {event.Status}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}>
                    <div style={{ lineHeight: '10px', marginTop: '10px' }}>
                      {event.FullLocationAddress !== null && (
                        <div>
                          <small>
                            <b>{event.FullLocationAddress}</b>
                          </small>
                        </div>
                      )}
                      {event.LocationDescription !== null && (
                        <div>
                          <small>
                            <b>{event.LocationDescription}</b>
                          </small>
                        </div>
                      )}
                    </div>
                    <div>
                      {event.distance && (
                        <div>
                          <small>{event.distance.toFixed(2)}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <div
                  style={{
                    justifyContent: 'center',
                    alignContent: 'flex-start',
                    borderBottom: '1px solid rgba(7, 9, 25, 0.125)',
                    borderBottomRightRadius: '0',
                    borderBottomLeftRadius: '0'
                  }}
                  className="card-footer d-flex align-items-center bg-transparent p-2">
                  <Button
                    onClick={ev => onButtonClick(ev, 'notes')}
                    className={classes.button}>
                    <div className="text-center my-1 p-1">
                      <div className="line-height-sm">
                        <b className="font-size-s">{event.notes.length}</b>
                        <span
                          style={{ fontWeight: '200', fontSize: '11px' }}
                          className="d-block">
                          Notes
                        </span>
                      </div>
                    </div>
                  </Button>
                  <Button
                    onClick={ev => onButtonClick(ev, 'dispositions')}
                    className={classes.button}>
                    <div className="text-center my-1 p-1">
                      <div className="line-height-sm">
                        <b className="font-size-s">
                          {event.dispositions.length}
                        </b>
                        <span
                          style={{ fontWeight: '200', fontSize: '11px' }}
                          className="d-block">
                          Disp
                        </span>
                      </div>
                    </div>
                  </Button>
                  <Button
                    onClick={ev => onButtonClick(ev, 'sops')}
                    className={classes.button}>
                    <div className="text-center my-1 p-1">
                      <div className="line-height-sm">
                        <b className="font-size-s">{event.sops.length}</b>
                        <span
                          style={{ fontWeight: '200', fontSize: '11px' }}
                          className="d-block">
                          Sops
                        </span>
                      </div>
                    </div>
                  </Button>
                  <Button
                    onClick={ev => onButtonClick(ev, 'attachments')}
                    className={classes.button}>
                    <div className="text-center my-1 p-1">
                      <div className="line-height-sm">
                        <b className="font-size-s">
                          {event.Attachments !== null
                            ? JSON.parse(event.Attachments).length
                            : 0}
                        </b>
                        <span
                          style={{ fontWeight: '200', fontSize: '11px' }}
                          className="d-block">
                          Attch
                        </span>
                      </div>
                    </div>
                  </Button>
                  <Button
                    onClick={ev => onButtonClick(ev, 'cases')}
                    className={classes.button}>
                    <div className="text-center my-1 p-1">
                      <div className="line-height-sm">
                        <b className="font-size-s">{cases}</b>
                        <span
                          style={{ fontWeight: '200', fontSize: '11px' }}
                          className="d-block">
                          Cases
                        </span>
                      </div>
                    </div>
                  </Button>
                </div>
                {event.assignedUnits.length > 0 && (
                  <div>
                    {event.assignedUnits.map((unit, index) => (
                      <div
                        onClick={ev => handleUnitClick(ev, unit)}
                        style={{
                          backgroundColor:
                            index % 2 == 0
                              ? 'rgba(0, 0, 0, 0.02)'
                              : 'transparent'
                        }}
                        key={unit.ptsUnitID}
                        className="px-2 pb-1 pt-1">
                        <div
                          className="d-flex justify-content-between"
                          style={{ alignItems: 'end' }}>
                          <div style={{ display: 'flex' }}>
                            <div className={classes.icon}>
                              <div>
                                <AgencyIcon
                                  color={getUnitColor(unit, colors)}
                                  AgencyID={unit.AgencyID}
                                />
                              </div>
                            </div>
                            <div>
                              <div
                                className="font-weight-bold"
                                style={{
                                  lineHeight: '10px',
                                  minWidth: '125px'
                                }}>
                                <div>
                                  <span>
                                    {unit.Unit !== null
                                      ? unit.Unit
                                      : unit.ptsUnitID}
                                  </span>
                                </div>
                                <div>
                                  <small>
                                    {' '}
                                    {displayDateTime(unit.Occurred)}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div
                              className="badge"
                              style={{
                                background: getUnitColor(unit, colors),
                                width: '90px'
                              }}>
                              {unit.UnitStatus}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Collapse>
            </div>
          </ClickNHold>
        </div>
      )}
    </Draggable>
  );
};

const mapStateToProps = state => ({
  eventInfowindow: state.map.eventInfowindow,
  colors: state.codes.colorsCodes
});

export default connect(mapStateToProps, {
  setEventInfowindow,
  pinEvent,
  toggleExpanded,
  setEventsDrawerMobileState,
  setUnitsDrawerMobileState,
  setEventInfowindowTab,
  actualUnit
})(EventItem);
