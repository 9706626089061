import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Tooltip from '../../Tooltip';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { showPeopleCredentials } from 'reducers/DialogsReducer';
import XGrid3 from '../../XGrid3';
import QuickFilterSearch from '../../Search/components/QuickFilterSearch';
import { useDispatch } from 'react-redux';
import { getPeopleCredentials } from 'reducers/SearchReducer';
import { getService } from 'reducers/service';
import { notify } from 'reducers/NotifierReducer';
// import { getFullPermissions } from 'reducers/PermissionsReducer';

const useStyles = makeStyles(theme => ({
  wrap: {
    padding: theme.spacing(3)
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1)
    }
  },
  fileInput: {
    display: 'none'
  }
}));

const defaultColumns = [
  'Type',
  'Number',
  'Created',
  'State',
  'CreatedBy',
  'Updated',
  'UpdatedBy'
];
const Credential = props => {
  const classes = useStyles();
  const { personId, editPeopleCredentials } = props;
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const [isDeleted, setIsDeleted] = useState(false);
  // const perms = getFullPermissions('globals', 'People', 'any');
  // const canEditPerson = perms.Edit;

  useEffect(() => {
    personId && getData();
  }, [editPeopleCredentials, isDeleted]);

  const getData = async () => {
    try {
      const data = await getPeopleCredentials(personId);
      if (!mountedRef.current) return;

      setRows(processData(data.data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err);
    }
  };

  const processData = data => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx
      };
    });
  };

  const addContact = () => {
    dispatch(showPeopleCredentials({ ptsPersonID: personId }));
  };

  const edit = () => {
    const item = rows.find(row => row.id === selection);
    dispatch(showPeopleCredentials({ ...item }));
  };

  const deleteContact = async () => {
    const item = rows.find(row => row.id === selection);
    try {
      props.showSpinner();
      const service = getService('record-person-credentials');
      const res = await service.remove(item.ptsCredentialID);
      dispatch(notify('Credential Deleted', 'warning'));
      setIsDeleted(!isDeleted);
    } catch (err) {
      props.handleError(err);
    } finally {
      props.hideSpinner();
    }
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch
            rows={rows}
            columns={columns}
            setFilteredRows={setFilteredRows}
          />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Add Credential">
            <span>
              <Fab size="small" color="secondary" onClick={addContact}>
                <AddIcon />
              </Fab>
            </span>
          </Tooltip>

          {selection !== null && (
            <>
              <Tooltip title="Edit Credential">
                <span>
                  <Fab
                    size="small"
                    color="secondary"
                    onClick={edit}
                    // disabled={!canEditPerson}
                  >
                    <EditIcon />
                  </Fab>
                </span>
              </Tooltip>

              <Tooltip title="Delete Credential">
                <span>
                  <Fab
                    size="small"
                    color="secondary"
                    onClick={deleteContact}
                    // disabled={!canEditPerson}
                  >
                    <DeleteIcon />
                  </Fab>
                </span>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <XGrid3
        name="PeopleCredentials"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};
const mapStateToProps = state => {
  return {
    editPeopleCredentials: state.dialogs.editPeopleCredentials
  };
};
export default connect(mapStateToProps, {
  handleError,
  showSpinner,
  hideSpinner
})(Credential);
