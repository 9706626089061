import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Tooltip
} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { useTheme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import {
  GoogleMap,
  OverlayView,
  Polygon,
  StandaloneSearchBox
} from '@react-google-maps/api';
import { getService, joinChannel, leaveChannel } from 'reducers/service';
import React, { Fragment, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MdSettingsBackupRestore } from 'react-icons/md';
import { RiSave3Fill } from 'react-icons/ri';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  handleVehicleCheckboxChange,
  quickPersonSearcher,
  quickVehicleSearcher
} from 'reducers/NcicReducer';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import {
  handleCheckboxChange,
  handlePersonQuickSearch
} from 'reducers/PersonSearchReducer';
import { setQuickSearchType } from 'reducers/SettingsReducer';
import {
  clearVehicleForm,
  handleVehicleQuickSearch
} from 'reducers/VehicleSearchReducer';
import { mapDay, mapNight } from '../../config/configureMap';
import {
  restoreMapPositionReducer,
  saveMapPositionReducer,
  setMap,
  setUnitsCoordinates
} from '../../reducers/MapReducer';
import {
  hideMapQuickSearchBox,
  hideMapSearchBox,
  setSearchActiveTab
} from '../../reducers/UiReducer';
import MarkerSearchedPlace from './MarkerSearchedPlace';
import MarkersEvents from './MarkersEvents';
import MarkersUnits from './MarkersUnits';
import PageviewIcon from '@material-ui/icons/Pageview';
import { getAccessPermission } from 'reducers/PermissionsReducer';
import { setShowZonesOnMap } from 'reducers/SettingsReducer';
import { saveUserSettings } from 'reducers/UserReducer';
import { handleError } from 'reducers/ErrorReducer';
import { useRef } from 'react';
const useStyles = makeStyles(theme => ({
  label: {
    padding: 0,
    marginLeft: -5,
    marginTop: -6,
    color: 'blue',
    fontWeight: 600
  }
}));
const RenderMap = props => {
  const classes = useStyles();
  const {
    restoreMapPositionReducer,
    saveMapPositionReducer,
    themeMode,
    websocket,
    setMap,
    mapcenter,
    mapzoom,
    units,
    events,
    map,
    user,
    mapSearchBoxVisibility,
    mapQuickSearchBoxVisibility,
    hideMapSearchBox,
    hideMapQuickSearchBox,
    vehicleFilterValues,
    viewType,
    statesCodes,
    showZonesOnMap,
    setShowZonesOnMap,
    plateTypesCodes,
    zones,
    unitsCoordinates
  } = props;
  var [searchedPlace, setSearchedPlace] = useState(null);
  var [searchedPlaceAddress, setSearchedPlaceAddress] = useState(null);
  var [searchBox, setSearchBox] = useState(null);
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const { APP_DEFAULT_STATE, YEAR_REQUIRED } = useSelector(
    state => state.appInfo.appEnvInfo
  );

  const [quickSearchPersonForm, setQuickSearchPersonForm] = useState({
    State: APP_DEFAULT_STATE || ''
  });
  const [quickSearchVehicleForm, setQuickSearchVehicleForm] = useState({
    State: APP_DEFAULT_STATE || '',
    ...(YEAR_REQUIRED == true && {
      LicensePlateYear: vehicleFilterValues?.LicensePlateYear?.value || ''
    }),
    LicensePlateTypeCode: vehicleFilterValues?.LicensePlateTypeCode?.value || ''
  });
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const persmissions = useSelector(state => state.permissions.globals);
  const [canExecNcic, setCanExecNcic] = useState('');

  const [anchorElShowMore, setAnchorElShowMore] = useState(null);
  const [geofences, setGeofences] = useState([]);
  React.useEffect(() => {
    setCanExecNcic(getAccessPermission('globals', 'Execute NCIC Queries'));
  }, [persmissions]);

  useEffect(() => {
    updateGeofences();
    // eslint-disable-next-line
  }, [showZonesOnMap, zones?.length]);
  const SaveMapPosition = () => {
    return (
      <Fab
        onClick={() => {
          saveMapPositionReducer();
        }}
        style={{ marginBottom: '10px', backgroundColor: '#FFF' }}
        className="MuiButtonBase-root MuiFab-root MuiFab-sizeSmall"
        aria-label="savemapview">
        <RiSave3Fill />
      </Fab>
    );
  };
  const MoreButton = () => {
    return (
      <Fab
        onClick={ev => {
          setAnchorElShowMore(ev.currentTarget);
        }}
        style={{
          marginBottom: '10px',
          marginLeft: '10px',
          backgroundColor: '#FFF'
        }}
        className="MuiButtonBase-root MuiFab-root MuiFab-sizeSmall"
        aria-label="showmoreitems">
        <MoreVertIcon />
      </Fab>
    );
  };

  const RestoreMapPosition = () => {
    return (
      <Fab
        onClick={() => {
          restoreMapPositionReducer();
        }}
        style={{
          marginBottom: '10px',
          marginLeft: '10px',
          backgroundColor: '#FFF'
        }}
        className="MuiButtonBase-root MuiFab-root MuiFab-sizeSmall"
        aria-label="restoremapview">
        <MdSettingsBackupRestore />
      </Fab>
    );
  };

  const mapOptions = {
    styles: themeMode === 'day' ? mapDay : mapNight,
    zoomControl: true,
    mapTypeControl: true,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    mapTypeControlOptions: {
      style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: window.google.maps.ControlPosition.BOTTOM_CENTER
    },
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.BOTTOM_CENTER
    }
  };

  const onLoad = React.useCallback(function onLoad(map) {
    setMap(map, units, events);
    const saveMapButtonDiv = document.createElement('div');
    ReactDOM.render(
      <Fragment>
        <SaveMapPosition />
        <RestoreMapPosition />
        <MoreButton />
      </Fragment>,
      saveMapButtonDiv
    );
    map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(
      saveMapButtonDiv
    );
  });

  const onLoadSearchBox = ref => setSearchBox(ref);

  const onPlacesChanged = () => {
    const place = searchBox.getPlaces();
    if (place.length > 0) {
      let position = {
        lat: parseFloat(place[0].geometry.location.lat()),
        lng: parseFloat(place[0].geometry.location.lng())
      };
      let address = place[0].formatted_address;

      map.setCenter(position);
      map.setZoom(16);

      setSearchedPlace(position);
      setSearchedPlaceAddress(address);
    }
  };

  const closeMapSearchBox = () => {
    hideMapSearchBox();
  };
  const closeMapQuickSearchBox = () => {
    hideMapQuickSearchBox();
  };

  const closeMapSearchedPlaceMarker = () => {
    setSearchedPlace(null);
  };
  const handleQuickSearch = value => {
    if (canExecNcic) {
      if (value == 'person') {
        if (quickSearchPersonForm.OperatorLicenseNumber) {
          dispatch(setSearchActiveTab(1));
          dispatch(handleCheckboxChange(true));
          history.push('/Search');
          dispatch(quickPersonSearcher(quickSearchPersonForm, 'quick'));
        } else {
          alert('Operator License Number Required');
        }

        // dispatch(handlePersonQuickSearch(quickSearchValue));
      } else if (value == 'vehicle') {
        if (
          quickSearchVehicleForm.LicensePlateNumber &&
          quickSearchVehicleForm.LicensePlateTypeCode
        ) {
          if (
            YEAR_REQUIRED != true ||
            quickSearchVehicleForm.LicensePlateYear
          ) {
            dispatch(setSearchActiveTab(0));
            dispatch(handleVehicleCheckboxChange(true));

            history.push('/Search');
            dispatch(clearVehicleForm());
            dispatch(quickVehicleSearcher(quickSearchVehicleForm, 'quick'));
          } else {
            alert(
              'LicensePlateNumber and LicensePlateTypeCode and LicensePlateYear are required fields'
            );
          }
        } else {
          alert(
            'LicensePlateNumber and LicensePlateTypeCode and LicensePlateYear are required fields'
          );
        }
      }
    } else {
      if (value == 'person') {
        dispatch(setSearchActiveTab(1));
        dispatch(handleCheckboxChange(false));
        history.push('/Search');
        dispatch(
          handlePersonQuickSearch(
            quickSearchPersonForm.OperatorLicenseNumber,
            quickSearchPersonForm.State
          )
        );
      } else if (value == 'vehicle') {
        dispatch(setSearchActiveTab(0));
        dispatch(handleVehicleCheckboxChange(false));
        history.push('/Search');
        dispatch(handleVehicleQuickSearch(quickSearchVehicleForm));
      }
    }
  };
  const handleSearchTypeChange = event => {
    dispatch(setQuickSearchType(event.target.value));
  };

  const handlePersonSearch = (event, name = '', newValue = '') => {
    const newForm = { ...quickSearchPersonForm };
    if (name) {
      newForm[name] = newValue;
    } else {
      newForm[event.target.name] = event.target.value;
    }
    setQuickSearchPersonForm(newForm);
  };
  const handleVehicleSearch = (event, name = '', newValue = '') => {
    const newForm = { ...quickSearchVehicleForm };
    if (name) {
      newForm[name] = newValue;
    } else {
      newForm[event.target.name] = event.target.value;
    }
    setQuickSearchVehicleForm(newForm);
  };

  const renderMoreMenu = () => {
    // const handleESNVisibleChange = ev => {
    //   const val = ev.target.checked;
    //   props.saveUserSetting('showESNsOnMap', val);
    //   if (val) props.saveUserSetting('showZonesOnMap', false);
    //   closeMoreMenu();
    // };

    const handleZonesVisibleChange = ev => {
      const val = ev.target.checked;
      setShowZonesOnMap(val);
      dispatch(saveUserSettings());
    };

    return (
      <Menu
        id="customized-menu"
        anchorEl={anchorElShowMore}
        keepMounted
        open={Boolean(anchorElShowMore)}
        onClose={() => setAnchorElShowMore(null)}>
        <MenuItem className={classes.checkbox}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showZonesOnMap}
                onChange={ev => handleZonesVisibleChange(ev)}
                size="small"
              />
            }
            label="Show Zones"
          />
        </MenuItem>
      </Menu>
    );
  };

  const updateGeofences = () => {
    const agencyId = user.userData.user.AgencyID;

    if (showZonesOnMap) {
      if (agencyId) {
        setGeofences(zones.filter(z => z.AgencyID === agencyId));
      } else {
        setGeofences(zones);
      }
    } else {
      setGeofences([]);
    }
  };

  const geofenceOptions = {
    fillColor: 'blue',
    fillOpacity: 0.4,
    strokeColor: 'blue',
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
  };
  const onShapeClick = ev => {};
  const renderGeofences = () => {
    return geofences
      .filter(geofence => Boolean(geofence.path))
      .map(g => {
        return (
          <Fragment key={g.ptsGeofenceID}>
            <Polygon
              paths={g.path}
              options={{
                ...geofenceOptions,
                fillColor: g.Color,
                strokeColor: g.Color
              }}
              onClick={onShapeClick}
            />
            <OverlayView
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              position={g.center}
              onClick={onShapeClick}>
              <div className={classes.label}>{g.ZoneCode}</div>
            </OverlayView>
          </Fragment>
        );
      });
  };

  return (
    <Fragment>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }}>
        <GoogleMap
          id="map"
          center={mapcenter}
          zoom={mapzoom}
          clickableIcons={false}
          options={mapOptions}
          mapContainerStyle={{
            height: '100%',
            width: '100%'
          }}
          onLoad={onLoad}>
          {mapSearchBoxVisibility && (
            <StandaloneSearchBox
              onLoad={onLoadSearchBox}
              onPlacesChanged={onPlacesChanged}>
              <div
                style={{
                  left: '50%',
                  height: `35px`,
                  marginLeft: '-150px',
                  marginTop: `40px`,
                  position: 'fixed',
                  display: `flex`,
                  alignItems: `center`
                }}>
                <Paper elevation={3} style={{ padding: '1.2rem' }}>
                  <TextField
                    size="small"
                    label="Address Search"
                    variant="outlined"
                    style={{ width: 260 }}
                  />
                  <AiOutlineCloseCircle
                    onClick={closeMapSearchBox}
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      right: '3px',
                      top: '3px',
                      fontSize: '1.1rem'
                    }}
                  />
                </Paper>
              </div>
            </StandaloneSearchBox>
          )}
          <Hidden smDown>
            {mapQuickSearchBoxVisibility && (
              <div
                style={{
                  margin:
                    viewType == 'complex' ? '0px 300px' : '0px 400px 0px 550px',
                  borderBottom: '1px solid #333'
                }}>
                <div
                  style={{
                    // height: `35px`,

                    // position: 'fixed',
                    // left: '300px',
                    width: '100%',
                    alignItems: `center`,
                    borderBottom: '1px solid #333'
                  }}>
                  <Paper
                    variant="outlined"
                    square
                    style={{
                      backgroundColor: theme.palette.bgElements,
                      padding: '5px 10px',
                      borderBottom: '1px solid #888'
                    }}>
                    <h6 style={{ color: 'rgb(11, 85, 161)' }}>Quick Search</h6>
                    <Grid
                      container
                      spacing={2}
                      className="pb-2"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}>
                      <Grid item sm={12} lg={7}>
                        <Grid container spacing={2}>
                          <Grid item sm={3}>
                            <TextField
                              size="small"
                              label="LPN"
                              name="LicensePlateNumber"
                              value={
                                quickSearchVehicleForm['LicensePlateNumber']
                              }
                              onChange={handleVehicleSearch}
                              variant="outlined"
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item sm={3}>
                            <Autocomplete
                              id="state"
                              name="State"
                              autoHighlight
                              autoSelect
                              size="small"
                              value={
                                { Code: quickSearchVehicleForm['State'] } || ''
                              }
                              onChange={(event, newValue) => {
                                handleVehicleSearch(
                                  event,
                                  'State',
                                  newValue?.Code
                                );
                              }}
                              options={[
                                { Code: '', Description: '' },
                                ...statesCodes
                              ]}
                              getOptionLabel={option => option.Code || ''}
                              getOptionSelected={() => true}
                              renderOption={option => (
                                <small>
                                  <b>{option.Code || ''} </b> -
                                  {option.Description || ''}
                                </small>
                              )}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="State"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item sm={2}>
                            <TextField
                              size="small"
                              label="Year"
                              name="LicensePlateYear"
                              value={quickSearchVehicleForm['LicensePlateYear']}
                              onChange={handleVehicleSearch}
                              variant="outlined"
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item sm={3}>
                            <Autocomplete
                              id="LPN Type"
                              name="LicensePlateTypeCode"
                              size="small"
                              autoHighlight
                              autoSelect
                              getOptionSelected={() => true}
                              value={{
                                Code:
                                  quickSearchVehicleForm[
                                    'LicensePlateTypeCode'
                                  ] || ''
                              }}
                              onChange={(event, newValue) => {
                                handleVehicleSearch(
                                  event,
                                  'LicensePlateTypeCode',
                                  newValue?.Code
                                );
                              }}
                              options={[
                                { Code: '', Description: '' },
                                ...plateTypesCodes
                              ]}
                              getOptionLabel={option => option.Code || ''}
                              renderOption={option => option.Code || '-'}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="LPN Type"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item sm={1}>
                            <Tooltip title="Vehicle Search">
                              <Button
                                onClick={() => handleQuickSearch('vehicle')}
                                variant="contained"
                                size="small"
                                style={{
                                  minWidth: '38px',
                                  minHeight: '38px',
                                  padding: '4px',
                                  borderRadius: '50%'
                                }}
                                color="primary">
                                <SearchIcon style={{ fontSize: '1.5rem' }} />
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} lg={5}>
                        <Grid container spacing={2}>
                          <Grid item sm={6}>
                            <TextField
                              size="small"
                              label="OLN"
                              name="OperatorLicenseNumber"
                              value={
                                quickSearchPersonForm['OperatorLicenseNumber']
                              }
                              onChange={handlePersonSearch}
                              variant="outlined"
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item sm={4}>
                            <Autocomplete
                              id="state"
                              name="State"
                              autoHighlight
                              autoSelect
                              size="small"
                              getOptionSelected={() => true}
                              value={
                                { Code: quickSearchPersonForm['State'] } || ''
                              }
                              onChange={(event, newValue) => {
                                handlePersonSearch(
                                  event,
                                  'State',
                                  newValue?.Code
                                );
                              }}
                              options={[
                                { Code: '', Description: '' },
                                ...statesCodes
                              ]}
                              getOptionLabel={option => option.Code || ''}
                              renderOption={option => (
                                <small>
                                  <b>{option.Code || ''} </b> -{' '}
                                  {option.Description || ''}
                                </small>
                              )}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="State"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item sm={2}>
                            <Tooltip title="Person Search">
                              <Button
                                onClick={() => handleQuickSearch('person')}
                                variant="contained"
                                size="small"
                                style={{
                                  minWidth: '38px',
                                  minHeight: '38px',
                                  padding: '4px',
                                  borderRadius: '50%'
                                }}
                                color="primary">
                                <SearchIcon style={{ fontSize: '1.5rem' }} />
                              </Button>
                            </Tooltip>
                            {/* <Fab
                              size="small"
                              backgroundColor={'red'}
                              // color={theme.palette.primary}
                              aria-label="add"
                              onClick={() => handleQuickSearch('person')}>
                              <SearchIcon />
                            </Fab> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </div>
            )}
          </Hidden>
          <Hidden mdUp>
            {mapQuickSearchBoxVisibility && (
              <div
                style={{
                  margin: '0px',
                  borderBottom: '1px solid #333'
                }}>
                <div
                  style={{
                    width: '100%',
                    alignItems: `center`,
                    borderBottom: '1px solid #333'
                  }}>
                  <Paper
                    variant="outlined"
                    square
                    style={{
                      backgroundColor: theme.palette.bgElements,
                      padding: '5px 10px',
                      borderBottom: '1px solid #888'
                    }}>
                    <h6 style={{ color: 'rgb(11, 85, 161)' }}>Quick Search</h6>
                    <Grid container spacing={2} className="pb-2">
                      <Grid item sm={12} lg={7}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              size="small"
                              label="LPN"
                              name="LicensePlateNumber"
                              value={
                                quickSearchVehicleForm['LicensePlateNumber']
                              }
                              onChange={handleVehicleSearch}
                              variant="outlined"
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Autocomplete
                              id="state"
                              name="State"
                              size="small"
                              autoHighlight
                              autoSelect
                              getOptionSelected={() => true}
                              value={quickSearchVehicleForm['State']}
                              onChange={(event, newValue) => {
                                handleVehicleSearch(
                                  event,
                                  'State',
                                  newValue?.Code
                                );
                              }}
                              options={[
                                { Code: '', Description: '' },
                                ...statesCodes
                              ]}
                              getOptionLabel={option => option.Code || ''}
                              renderOption={option => (
                                <>
                                  <b>{option.Code || ''} </b> -{' '}
                                  {option.Description || ''}
                                </>
                              )}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="State"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              size="small"
                              label="Year"
                              name="LicensePlateYear"
                              value={quickSearchVehicleForm['LicensePlateYear']}
                              onChange={handleVehicleSearch}
                              variant="outlined"
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={10} sm={5}>
                            <Autocomplete
                              id="Plate Type"
                              name="LicensePlateTypeCode"
                              size="small"
                              autoHighlight
                              autoSelect
                              getOptionSelected={() => true}
                              value={
                                quickSearchVehicleForm['LicensePlateTypeCode']
                              }
                              onChange={(event, newValue) => {
                                handleVehicleSearch(
                                  event,
                                  'LicensePlateTypeCode',
                                  newValue?.Code
                                );
                              }}
                              options={[
                                { Code: '', Description: '' },
                                ...plateTypesCodes
                              ]}
                              getOptionLabel={option => option.Code || ''}
                              renderOption={option => option.Code || '-'}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="LPN Type"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={2} sm={1}>
                            <Tooltip title="Vehicle Search">
                              <Button
                                onClick={() => handleQuickSearch('vehicle')}
                                variant="contained"
                                size="small"
                                style={{
                                  minWidth: '38px',
                                  minHeight: '38px',
                                  padding: '4px',
                                  borderRadius: '50%'
                                }}
                                color="primary">
                                <SearchIcon style={{ fontSize: '1.5rem' }} />
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} lg={5}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              size="small"
                              label="OLN"
                              name="OperatorLicenseNumber"
                              value={
                                quickSearchPersonForm['OperatorLicenseNumber']
                              }
                              onChange={handlePersonSearch}
                              variant="outlined"
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={10} sm={4}>
                            <Autocomplete
                              id="state"
                              name="State"
                              size="small"
                              autoHighlight
                              autoSelect
                              getOptionSelected={() => true}
                              value={quickSearchPersonForm['State']}
                              onChange={(event, newValue) => {
                                handlePersonSearch(
                                  event,
                                  'State',
                                  newValue?.Code
                                );
                              }}
                              options={[
                                { Code: '', Description: '' },
                                ...statesCodes
                              ]}
                              getOptionLabel={option => option.Code || ''}
                              renderOption={option => (
                                <>
                                  <b>{option.Code || ''} </b> -{' '}
                                  {option.Description || ''}
                                </>
                              )}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  style={{ fontSize: '1 rem' }}
                                  variant="outlined"
                                  label="State"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={2} sm={2}>
                            <Tooltip title="Person Search">
                              <Button
                                onClick={() => handleQuickSearch('person')}
                                variant="contained"
                                size="small"
                                style={{
                                  minWidth: '38px',
                                  minHeight: '38px',
                                  padding: '4px',
                                  borderRadius: '50%'
                                }}
                                color="primary">
                                <SearchIcon style={{ fontSize: '1.5rem' }} />
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </div>
            )}
          </Hidden>
          {searchedPlace && (
            <MarkerSearchedPlace
              closeMapSearchedPlaceMarker={closeMapSearchedPlaceMarker}
              searchedPlace={searchedPlace}
              searchedPlaceAddress={searchedPlaceAddress}
            />
          )}
          {zones?.length > 0 && renderGeofences()}
          <MarkersEvents />
          <MarkersUnits unitsCoords={unitsCoordinates} />
        </GoogleMap>
        {renderMoreMenu()}
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  themeMode: state.theme.mode,
  websocket: state.websocket,
  map: state.map.map,
  mapcenter: state.map.mapcenter,
  mapzoom: state.map.mapzoom,
  mapbounds: state.map.mapbounds,
  units: state.units.units,
  events: state.events.events,
  quickSearchType: state.settings.quickSearchType,
  mapSearchBoxVisibility: state.ui.mapSearchBoxVisibility,
  mapQuickSearchBoxVisibility: state.ui.mapQuickSearchBoxVisibility,
  mapDefaultView: state.settings.mapDefaultView,
  filterValues: state.personSearch.personForm.filters,
  user: state.user,
  ncicPickerValues: state.ncic.personForm.pickers,
  ncicFilterValues: state.ncic.personForm.filters,
  rid: state.ncic.rid,
  plateTypesCodes: state.codes.dictionary.plateTypesCodes || [],
  statesCodes: state.codes.dictionary.statesCodes || [],
  ori: state.ncic.ori,
  vehicleFilterValues: state.ncic.vehicleForm.filters,
  metadata: state.ncic.metadata,
  viewType: state.settings.viewType,
  showZonesOnMap: state.settings.showZonesOnMap,
  zones: state.zones,
  unitsCoordinates: state.map.unitsCoordinates
});

export default connect(mapStateToProps, {
  setMap,
  hideMapSearchBox,
  hideMapQuickSearchBox,
  saveMapPositionReducer,
  restoreMapPositionReducer,
  setShowZonesOnMap
})(RenderMap);
