import React from 'react';
import { connect } from 'react-redux';
import EventTypeIcons from 'components/EventTypeIcons';
// import BombThreat from './icons/BombThreat';
// import BurglaryOccured from './icons/BurglaryOccured';
// import BurglaryInProgress from './icons/BurlaryInProgress';
// import BurglaryJustNow from './icons/BurglaryJustNow';
// import { getInitials } from '../../../utils/functions';

// const callTypes = {
//   'DIST-W': BombThreat,
//   BOMB: BombThreat,
//   BURG: BurglaryOccured,
//   DIST: BurglaryInProgress,
//   ROBBERY: BurglaryJustNow
// };

// function Initials(props) {
//   const { CallType, CallTypeDescription } = props;
//   return (
//     <span style={{ color: props.color, fontSize: '20px' }}>
//       {getInitials(CallType, CallTypeDescription)}
//     </span>
//   );
// }

function Icon(props) {
  const { CallType, CallTypes, color } = props;

  const event = CallTypes ? CallTypes.find(c => c.Code === CallType) : null;
  const iconIndex = event ? event.Icon : 0;
  const Icon = EventTypeIcons[iconIndex] || EventTypeIcons[0];

  //const Icon = callTypes[CallType] ? callTypes[CallType] : Initials;
  return <Icon style={{ color: color }} />;
}

const mapStateToProps = state => {
  return {
    CallTypes: state.codes.dictionary.CallTypes||[]
  };
};

export default connect(mapStateToProps)(Icon);
