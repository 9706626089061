import CheckIcon from '@material-ui/icons/Check';
import { displayDateTime } from 'reducers/TimeReducer';

// eslint-disable-next-line react/react-in-jsx-scope
const bool = params => (params.value ? <CheckIcon /> : <span></span>);

export default [
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date'
  },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date'
  },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 120,
    format: 'bool',
    renderCell: bool
  },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 120,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date'
  }
];
