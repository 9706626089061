import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as notifications } from 'react-notification-system-redux';

import AppInfoReducer from './AppInfoReducer';
import ThemeReducer from './ThemeReducer';
import WebsocketReducer from './WebsocketReducer';
import NotifierReducer from './NotifierReducer';
import UiReducer from './UiReducer';
import SettingsReducer from './SettingsReducer';
import UserReducer from './UserReducer';
import MapReducer from './MapReducer';
import UnitsReducer from './UnitsReducer';
import EventsReducer from './EventsReducer';
import DataReducer from './DataReducer';
import PersonSearchReducer from './PersonSearchReducer';
import VehicleSearchReducer from './VehicleSearchReducer';
import GunSearchReducer from './GunSearchReducer';
import CodeReducer from './CodeReducer';
import UnitStatusReducer from './UnitStatusReducer';
import * as CitationsReducer from './CitationsReducer';
import ThemeOptions from './CitationsThemeReducer';
import AlertsReducer from './AlertsReducer';
import NcicReducer from './NcicReducer';
import ClosedEventSearchReducer from './ClosedEventSearchReducer';
import Warrants from './WarrantsReducer';
import PermissionsReducer from './PermissionsReducer';
import TimeReducer from './TimeReducer';
import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import localforage from 'localforage';
import zoneReducer from './ZonesReducer';
import dialogReducer from 'reducers/DialogsReducer';
import keyboardReducer from './KeyboardReducer';
import dataUpdateReducer from './DataUpdateReducer';
import unitResourceReducer from './UnitResourcesReducer';
const unitsPersistConfig = {
  key: 'units',
  storage: localforage,
  blacklist: ['units', 'groupedUnit', 'isEmpty', 'unitUpdatesCounter']
};
const eventsPersistConfig = {
  key: 'events',
  storage: localforage,
  // whitelist: ['events'],
  blacklist: [
    'events',
    'myEvents',
    'reorder',
    'initialLoad',
    'eventUpdatesCounter',
    'isEmpty'
  ]
};
const createRootReducer = history =>
  combineReducers({
    appInfo: AppInfoReducer,
    router: connectRouter(history),
    theme: ThemeReducer,
    websocket: WebsocketReducer,
    notifier: NotifierReducer,
    notifications: notifications,
    ui: UiReducer,
    settings: SettingsReducer,
    user: UserReducer,
    map: MapReducer,
    units: persistReducer(unitsPersistConfig, UnitsReducer),
    events: persistReducer(eventsPersistConfig, EventsReducer),
    closeEvents: ClosedEventSearchReducer,
    data: DataReducer,
    personSearch: PersonSearchReducer,
    vehicleSearch: VehicleSearchReducer,
    gunSearch: GunSearchReducer,
    codes: CodeReducer,
    unitStatus: UnitStatusReducer,
    ThemeOptions,
    citationFormDefination: CitationsReducer.citationDefinition,
    CitationReducer: CitationsReducer.citationFormData,
    alerts: AlertsReducer,
    ncic: NcicReducer,
    permissions: PermissionsReducer,
    warrants: Warrants,
    time: TimeReducer,
    zones: zoneReducer,
    dialogs: dialogReducer,
    keyboard: keyboardReducer,
    dataUpdate: dataUpdateReducer,
    unitResources: unitResourceReducer
  });

export default createRootReducer;
