import {
  formatDateForCitation,
  makeAddress,
  makePhoneNumber,
  parseName
} from 'utils/functions';
import store from '../config/configureStore';
import { clearCitationDetails } from './PersonSearchReducer';
import { clearCitationVehicleDetails } from './VehicleSearchReducer';

export const GET_CITATION_FORM_DEFINITION = 'CITATION/form_definition';
export const SET_CITATION_FORM_DATA = 'CITATION/set_form_data';
const SET_CITATION_FORM_DATA_WITHOUT_COPY =
  'CITATION/SET_CITATION_FORM_DATA_WITHOUT_COPY';
export const SET_CITATION_FORM_EMPTY = 'CITATION/SET_CITATION_FORM_EMPTY';
export const SET_ACTIVE_CITATION_TYPE = 'CITATION/set_active_citation_type';
export const SET_EDITING_STATE_DATA = 'CITATION/SET_EDITING_STATE_DATA';
export const SET_PRINTED_CITATION_DATA = 'CITATION/SET_PRINTED_CITATION_DATA';
export const SET_CLIENT_TYPE = 'CITATION/SET_CLIENT_TYPE';
export const SET_NUMBER_OF_VIOLATION = 'CITATION/SET_NUMBER_OF_VIOLATION';
export const SET_COMPAIRABLE_CITATION_DATA =
  'CITATION/SET_COMPAIRABLE_CITATION_DATA';
export function getCitationFormDefinition(payload) {
  const state = store.store.getState();
  const { APP_DEFAULT_STATE } = state.appInfo.appEnvInfo;
  return dispatch => {
    // Fetch form definition from database/cloud and store it to redux.
    const parsedJson = payload ? JSON.parse(payload) : '';

    dispatch({ type: SET_CLIENT_TYPE, payload: parsedJson.clientType || '' });

    dispatch({
      type: GET_CITATION_FORM_DEFINITION,
      payload: parsedJson
    });
  };
}

export function setCitationTabsFormData(payload) {
  return dispatch => {
    dispatch({ type: SET_CITATION_FORM_DATA, payload });
  };
}
export function setEditingStateData(payload) {
  return dispatch => {
    dispatch({ type: SET_EDITING_STATE_DATA, payload });
  };
}

const CITATION_TYPES_STATE = {
  citationDefinitions: {}
};

export const citationDefinition = (state = CITATION_TYPES_STATE, action) => {
  switch (action.type) {
    case GET_CITATION_FORM_DEFINITION:
      return {
        ...state,
        citationDefinitions: action.payload
      };

    default:
      return state;
  }
};
export function setCitationFormData(payload) {
  return dispatch => {
    dispatch({ type: SET_CITATION_FORM_DATA, payload });
  };
}
export function setCitationFormDataWithoutCopy(payload) {
  return dispatch => {
    dispatch({ type: SET_CITATION_FORM_DATA_WITHOUT_COPY, payload });
  };
}
export const setCitationDataInitialLoad = (type = '', passedValue) => {
  const state = store.store.getState();
  const personDetails = state.personSearch.citationDetails;
  const vehicleDetails = state.vehicleSearch.citationDetails;
  const defaultState = state.appInfo?.appEnvInfo.APP_DEFAULT_STATE || '';
  return dispatch => {
    let formInfo = {};
    if (personDetails?.flag || (type == 'person' && passedValue)) {
      if (personDetails?.data?.QueryId || type == 'person') {
        let data = {};
        if (type == 'person' && passedValue) {
          data = passedValue;
        } else {
          const { NcicResponseData, RawData } = personDetails?.data;
          const { data: resData } = JSON.parse(NcicResponseData);
          data = resData;
        }

        let parsedName = {};
        if (data.Name) {
          parsedName = parseName(data.Name);
        }
        const personInfo = {
          operator_license_number: data.OperatorLicenseNumber || '',
          first_name: data?.NameFirst || parsedName.firstName || '',
          middle_name: data?.NameMiddle || parsedName.middleName || '',
          last_name: data?.NameLast || parsedName.lastName || '',
          dob: data?.BirthDate ? formatDateForCitation(data.BirthDate) : '',
          ssn: data?.SocialSecurityNumber || '',
          hair_color: data?.HairColorCode || '',
          eye_color: data?.EyeColorCode || '',
          sex: data?.SexCode || '',
          race: data?.RaceCode || '',
          address: data.AddressStreetName || data?.AddressStreet || '',
          street: data.AddressStreetName || data?.AddressStreet || '',
          residence_state: data?.AddressStateCode || defaultState || '',
          residence_city: data.AddressCity || '',
          residence_zip: data.AddressZipCode || '',
          height: data?.Height || '',
          weight: data?.Weight || '',
          operator_license_expire_date: data.ExpirationDate
            ? formatDateForCitation(data.ExpirationDate)
            : '',
          operator_license_class: data?.OperatorLicenseClassCode || '',
          operator_license_state:
            data.ImpliedOperatorLicenseStateCode ||
            data.OperatorLicenseStateCode ||
            defaultState ||
            ''
        };
        formInfo = { ...personInfo };
      } else {
        const General = personDetails?.data?.General;
        const address = personDetails?.data?.Addresses;

        const gData = General && JSON.parse(General)[0];
        const parseAddress = address && JSON.parse(address)[0];
        // console.log('add', parseAddress);
        // console.log('general ', gData);
        const personInfo = {
          first_name: gData?.FirstName || '',
          middle_name: gData?.MiddleName || '',
          last_name: gData?.LastName || '',
          address: parseAddress?.FullAddressText || '',
          dob: gData?.DOB ? formatDateForCitation(gData?.DOB) : '',
          hair_color: gData?.HairColorCode || '',
          eye_color: gData?.EyeColorCode || '',
          sex: gData?.SexCode || '',
          race: gData?.RaceCode || '',
          ssn: gData?.SSN || '',
          operator_license_class: gData?.OLNClass || '',
          operator_license_number: gData?.OLNNumber || '',
          operator_license_state: gData?.OLNState || defaultState || '',
          height: gData?.Height || '',
          weight: gData?.Weight || '',
          residence_state: '',
          residence_city: '',
          residence_zip: '',
          operator_license_expire_date: ''
        };
        formInfo = { ...personInfo };
      }
    }
    if (vehicleDetails?.flag || (type == 'vehicle' && passedValue)) {
      if (vehicleDetails?.data?.QueryId || passedValue) {
        let VehicleData = {};
        if (type == 'vehicle' && passedValue) {
          VehicleData = passedValue;
        } else {
          const vehicleInfo =
            vehicleDetails?.data.NcicResponseData ||
            vehicleDetails?.data.StateResponseData;
          const { data } = vehicleInfo && JSON.parse(vehicleInfo);
          VehicleData = data;
        }

        // console.log('details', VehicleData);

        formInfo = {
          ...formInfo,
          vin: VehicleData.VIN || '',
          vehicle_year: VehicleData?.VehicleYear || '',
          vehicle_make: VehicleData?.VehicleMakeCode || '',
          vehicle_style: VehicleData?.VehicleStyleCode || '',
          vehicle_color: VehicleData?.VehicleColorCode || '',

          license_plate_number: VehicleData?.LicensePlateNumber || '',
          license_plate_state:
            VehicleData?.LicensePlateStateCode ||
            VehicleData.ImpliedLicensePlateStateCode ||
            defaultState ||
            '',
          license_plate_tag_year:
            VehicleData?.LicensePlateYear ||
            formatDateForCitation(VehicleData.ExpirationDate).split('/')[2] ||
            formatDateForCitation(VehicleData.ExpirationDate) ||
            ''
        };
      } else {
        const vehicleData = vehicleDetails?.data;
        const vehicleRegInfo =
          vehicleData?.Registration && JSON.parse(vehicleData.Registration)[0];
        formInfo = {
          ...formInfo,
          vehicle_year: vehicleData?.Year || '',
          vehicle_make: vehicleData?.MakeCode || '',
          vehicle_style: vehicleData?.StyleCode || '',
          vehicle_color: vehicleData?.PrimaryColorCode || '',
          license_plate_number: vehicleRegInfo?.PlateNumber || '',
          license_plate_state:
            vehicleRegInfo?.PlateStateCode || defaultState || '',
          license_plate_tag_year: ''
        };
      }
    }
    dispatch({ type: SET_CITATION_FORM_DATA, payload: formInfo });
    if (!type) {
      dispatch(clearCitationVehicleDetails());
      dispatch(clearCitationDetails());
    }
  };
};
export const getDefaultValues = (key, ticket) => {
  const state = store.store.getState();
  const defaultState = state.appInfo?.appEnvInfo.APP_DEFAULT_STATE || '';
  if (key === 'citation_time') {
    return ticket.FormType === 'LEXINGTON' ? new Date() : null;
  } else if (key === 'citation_date') {
    return ticket.FormType === 'LEXINGTON' ? new Date() : null;
  } else if (key === 'operator_license_state') {
    return defaultState || '';
  } else if (key === 'residence_state') {
    return defaultState || '';
  } else if (key === 'license_plate_state') {
    return defaultState || '';
  }
};
export const setCitationFormEmpty = (isClear, ticket = {}) => {
  const state = store.store.getState();
  const user = state.user;
  const citationForm = state.CitationReducer.tabsData;
  const personDetails = state.personSearch.citationDetails;
  const vehicleDetails = state.vehicleSearch.citationDetails;
  const defaultState = state.appInfo?.appEnvInfo.APP_DEFAULT_STATE || '';
  const lastUsedCourtJson = localStorage.getItem('LastCourtDetails');
  // const CourtValue = lastUsedCourtJson && JSON.parse(lastUsedCourtJson);
  let officer_name = '';
  let officer_badge = '';

  if (
    ticket.FormType === 'ABITA' ||
    ticket.FormType === 'LEESVILLE' ||
    ticket.FormType === 'LEXINGTON' ||
    ticket.FormType === 'STARR_COUNTY'
  ) {
    officer_name =
      (user?.userData?.user.LastName || '') +
      ', ' +
      (user?.userData?.user?.FirstName || '') +
      ' ' +
      (user?.userData?.user?.MiddleName || '');
    officer_badge = user?.userUnit.Unit || '';
  } else {
    officer_name =
      (user?.userData?.user.LastName || '') +
      ', ' +
      (user?.userData?.user?.FirstName?.slice(0, 1) || '');
    officer_badge = user?.userData.user.PersonID || '';
  }
  let courtObj = {};
  if (ticket['Court_Name']) {
    courtObj = addCourtDetails(ticket);
  }
  return dispatch => {
    const initial = {
      ticketId: ticket.CitationDefinitionID,
      officer_name: officer_name,
      officer_div: user?.userUnit.Division || '',
      officer_badge: officer_badge,
      officer_unit: user?.userUnit?.Unit || '',
      agency_Name:
        user?.userData.user.AgencyDescription ||
        user?.userData.user.AgencyID ||
        '',

      officer_secndary_badge: user?.userData.user.PersonID || '',
      ori: user?.userData.user.AgencyORI || '',
      citation_time: ticket.FormType === 'LEXINGTON' ? new Date() : null,
      citation_date: ticket.FormType === 'LEXINGTON' ? new Date() : null,

      operator_license_state: defaultState || '',
      residence_state: defaultState || '',
      license_plate_state: defaultState || '',

      Id: personDetails?.data?.ptsPersonID || personDetails?.data?.QueryId,
      vehicleId:
        vehicleDetails?.data?.ptsVehicleID || vehicleDetails?.data?.QueryId,
      ...courtObj
    };

    //  else {
    //   initial['Court_Name'] = CourtValue?.Court_Name || '';
    //   initial['court_location'] = CourtValue?.court_location || '';
    //   initial['court_city'] = CourtValue?.court_city || '';
    //   initial['court_time'] = CourtValue?.court_time || null;
    //   initial['court_date'] = CourtValue?.court_date || null;
    //   initial['court_type'] = CourtValue?.court_type || '';
    //   initial['CourtAddress'] = CourtValue.address || '';
    //   initial['CourtPhone'] = CourtValue.phone || '';
    // }
    const agencyForm = {};
    if (ticket['AgencyEmail']) {
      agencyForm['AgencyEmail'] = ticket.AgencyEmail || '';
    }
    if (ticket['AgencyWebsite']) {
      agencyForm['AgencyWebsite'] = ticket.AgencyWebsite || '';
    }
    if (isClear) {
      dispatch({
        type: SET_CITATION_FORM_EMPTY,
        payload: { ...initial, ...agencyForm }
      });
    } else {
      dispatch({
        type: SET_CITATION_FORM_DATA,
        payload: { ...initial, ...citationForm, ...agencyForm }
      });
    }
  };
};

export const addCourtDetails = ticket => {
  const obj = {};
  if (ticket['Court_Name']) {
    const {
      Court_Name,
      CourtPhone,
      CourtAreaCode,
      CourtZip,
      CourtState,
      CourtCity,
      CourtStreet,
      CourtType
    } = ticket || {};

    const address = makeAddress(CourtStreet, CourtCity, CourtState, CourtZip);
    const phone = makePhoneNumber(CourtAreaCode, CourtPhone);
    obj['Court_Name'] = Court_Name || '';
    obj['CourtAddress'] = address || '';
    obj['court_location'] = CourtStreet || '';
    obj['court_city'] = CourtCity || '';
    obj['CourtPhone'] = phone || '';
    obj['court_type'] = CourtType || '';
    return obj;
  } else {
    obj['Court_Name'] = '';
    obj['CourtAddress'] = '';
    obj['court_location'] = '';
    obj['court_city'] = '';
    obj['CourtPhone'] = '';
    obj['court_type'] = '';
    return obj;
  }
};
export const setPrintedCitationData = data => {
  return async dispatch => {
    dispatch({ type: SET_PRINTED_CITATION_DATA, payload: data });
  };
};
export const setCompairableCitationData = data => {
  return async dispatch => {
    dispatch({ type: SET_COMPAIRABLE_CITATION_DATA, payload: data });
  };
};

const FORM_DATA_STATE = {};
export const citationFormData = (
  state = {
    tabsData: {},
    editingStateData: {},
    printedCitationData: null,
    compairableCitationData: null,
    clientType: '',
    numberOfViolations: 0
  },
  action
) => {
  switch (action.type) {
    case SET_CITATION_FORM_DATA:
      return {
        ...state,
        tabsData: { ...state.tabsData, ...action.payload }
      };
    case SET_CITATION_FORM_DATA_WITHOUT_COPY:
      return {
        ...state,
        tabsData: { ...action.payload }
      };
    case SET_CITATION_FORM_EMPTY: {
      return {
        ...state,
        tabsData: action.payload
      };
    }
    case SET_EDITING_STATE_DATA:
      return {
        ...state,
        editingStateData: action.payload
      };
    case SET_PRINTED_CITATION_DATA:
      return {
        ...state,
        printedCitationData: action.payload
      };
    case SET_COMPAIRABLE_CITATION_DATA:
      return {
        ...state,
        compairableCitationData: action.payload
      };
    case SET_CLIENT_TYPE:
      return {
        ...state,
        clientType: action.payload
      };
    case SET_NUMBER_OF_VIOLATION:
      return {
        ...state,
        numberOfViolations: action.payload
      };
    default:
      return state;
  }
};
