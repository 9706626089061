import React, { Fragment, useState } from 'react';
import nl2br from 'react-newline-to-break';
import ReactHtmlParser from 'react-html-parser';
import Highlighter from 'react-highlight-words';
import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router';

import ContextMenuComponent from 'global-components/ContextMenu';
import ShortcutsFunctionality from 'global-components/ShortcutsFunctionality';
import SearchResult from 'global-components/NcicSearchComponents/SearchResult';
import { Button, Paper, useTheme } from '@material-ui/core';
function RawData(props) {
  const { data } = props;
  const [searchText, setSearchText] = useState('');
  const theme = useTheme();
  const [fontSize, setFontSize] = useState(16);
  const dispatch = useDispatch();
  const history = useHistory();
  const clearResults = () => {
    props.clearNiciHistory();
  };
  // let _data = nl2br(data);
  // console.log(_data);
  // data.replace(/(?:\r\n|\r|\n)/g, '<br />');
  // console.log(data);
  // let _data = ReactHtmlParser(data);
  // console.log(_data);

  return (
    <div>
      {data !== '' ? (
        <>
          <ShortcutsFunctionality
            value={data}
            parentClassName="ncic-history"
            searchText={searchText}
            clearResults={clearResults}
            setSearchText={setSearchText}
            fontSize={fontSize}
            setFontSize={setFontSize}
          />
          <div>
            <SearchResult
              data={data}
              searchText={searchText}
              fontSize={fontSize}
              parentClassName="ncic-history"
            />
          </div>
        </>
      ) : (
        <p>No History Found</p>
      )}
    </div>
  );
}

export default RawData;
